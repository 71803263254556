import React from "react";
import { connect } from "react-redux";
import "./deleteLibraryItemModal.css";
import Modal from "react-modal";

const DeleteLibraryItemModal: React.FC<{
  style: any;
  itemTitle: string;
  itemLibrary: string;
  itemCancel: any;
  itemDelete: any;
  deleteM: boolean;
  setDeleteModal: any;
}> = ({
  style,
  itemTitle,
  itemLibrary,
  itemCancel,
  itemDelete,
  deleteM,
  setDeleteModal,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
  };

  const cancelDelete = () => {
    itemCancel();
    setDeleteModal(false);
  };

  const deleteItem = () => {
    itemDelete();
    setDeleteModal(false);
  };

  return (
    <Modal isOpen={deleteM} onRequestClose={cancelDelete} style={customStyles}>
      <p style={{ paddingBottom: "1px", textAlign: "center" }}>
        You are about to delete:
      </p>

      <div style={{ paddingBottom: "2px", textAlign: "center" }}>
        <p>
          {itemTitle} from the {itemLibrary} Library
        </p>
        <p style={{ paddingBottom: "2px" }}>
          Are you sure you would like to continue?
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: 20,
          marginTop: "10px",
        }}
      >
        <button
          style={{
            backgroundColor: "grey",
            width: "100px",
            height: "30px",
            color: style.colors.white,
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={cancelDelete}
        >
          Cancel
        </button>
        <button
          style={{
            backgroundColor: style.colors.primaryBlue,
            width: "100px",
            height: "30px",
            color: style.colors.white,
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={deleteItem}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
});

export default connect(mapStateToProps, {})(DeleteLibraryItemModal) as any;
