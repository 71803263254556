import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./newProjectModal.css";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import {
  createProject,
  editProjectCanvas,
  setNewProjectIncoming,
} from "../../redux/actions/Projects";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  longDescription,
  shortDescriptions,
} from "../../lib/data/modelDescriprtions";
import HelpIcon from "@mui/icons-material/Help";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
  },
};

const NewProjectModal: React.FC<{
  closeNewProjectM: any;
  newProjectM: any;
  style: any;
  createProject: (FormData: any) => void;
  editProjectCanvas: (edit: boolean) => void;
  activeProject: any;
  setNewProjectIncoming: (incoming: boolean) => void;
}> = ({
  closeNewProjectM,
  newProjectM,
  createProject,
  style,
  editProjectCanvas,
  activeProject,
  setNewProjectIncoming,
}) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    modelId: "1",
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const createNewProject = async () => {
    if (formData.name && formData.modelId) createProject(formData);
    closeNewProjectM();
    // navigate("/project");
    setTimeout(function () {
      editProjectCanvas(false);
      navigate("/project");
    }, 1000);
    // setNewProjectIncoming(true);
    editProjectCanvas(false);
    // setNewProjectIncoming(true);
  };

  const changeName = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const changeDescription = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
  };

  const changeModel = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      modelId: e.target.value,
    }));
  };

  return (
    <Modal
      isOpen={newProjectM}
      onRequestClose={closeNewProjectM}
      style={customStyles}
    >
      <p style={{ paddingBottom: "1px", fontWeight: "bold" }}>Project Name:</p>
      <input style={{ width: "600px" }} onChange={changeName} />

      <div style={{ paddingBottom: "2px" }}>
        <p style={{ paddingBottom: "2px", fontWeight: "bold" }}>
          Project Description:
        </p>
        <textarea className="newPMInputs" onChange={changeDescription} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <p style={{ paddingRight: "15px", fontWeight: "bold" }}>
          Pathway Template:
        </p>
        <select
          name="base"
          className="base"
          style={{ width: "260px", height: "25px" }}
          onChange={changeModel}
        >
          <option value="1">Urgent Capability Acquisition Pathway</option>
          <option value="2">Middle Tier Acquisition Pathway</option>
          <option value="3">Major Capability Acquisition Pathway</option>
          <option value="4">Software Acquisition Pathway</option>
          <option value="5">Defense Business Systems Pathway</option>
          <option value="6">Acquisition of Services Pathway</option>
        </select>
        <HelpIcon
          fontSize="small"
          style={{ marginLeft: "5px" }}
          onClick={handleClick}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <div
            style={{
              width: "200px",
              padding: "5px",
              textAlign: "center",
              background: style.colors.grey2,
            }}
          >
            <span>
              Not sure which pathway to choose?{" "}
              <span
                onClick={() =>
                  window.open("https://aaf.dau.edu/aaf/aaf-pathways/", "_blank")
                }
                style={{ color: style.colors.primaryBlue, cursor: "pointer" }}
              >
                Learn More Here
              </span>
            </span>
          </div>
        </Popover>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: 20,
        }}
      >
        <p style={{ width: "380px", margin: 0 }}>
          {shortDescriptions[Number(formData.modelId) - 1]}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 20,
          }}
        >
          <button
            style={{
              backgroundColor: "grey",
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={closeNewProjectM}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: style.colors.primaryBlue,
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={createNewProject}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  activeProject: state.projects.activeProject,
});

export default connect(mapStateToProps, {
  createProject,
  editProjectCanvas,
  setNewProjectIncoming,
})(NewProjectModal) as any;
