const acronymSort = (
  acronyms,
  acronymAscending,
  meaningAscending,
  dateAscending
) => {
  let acronymList = acronyms;

  if (acronymAscending === 1) {
    acronymList = acronymList.sort((a, b) =>
      a.acronymName.localeCompare(b.acronymName)
    );
  }
  if (acronymAscending === 2) {
    acronymList = acronymList.sort((a, b) =>
      b.acronymName.localeCompare(a.acronymName)
    );
  }
  if (meaningAscending === 1) {
    acronymList = acronymList.sort((a, b) =>
      a.definition.localeCompare(b.definition)
    );
  }
  if (meaningAscending === 2) {
    acronymList = acronymList.sort((a, b) =>
      b.definition.localeCompare(a.definition)
    );
  }
  if (dateAscending === 1) {
    acronymList = acronymList.sort(
      (a, b) =>
        new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()
    );
  }
  if (dateAscending === 2) {
    acronymList = acronymList.sort(
      (a, b) =>
        new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime()
    );
  }

  return acronymList;
};

const productSort = (
  products,
  nameAscending,
  domainAscending,
  phaseAscending,
  typeAscending,
  intOutAscending,
  conOwnAscending,
  dateAscending
) => {
  let productList = products;
  productList = productList.filter(
    (item) => item.name && typeof item.name !== "undefined"
  );
  if (nameAscending === 1) {
    productList = productList.sort((a, b) => a.name.localeCompare(b.name));
  }
  if (nameAscending === 2) {
    productList = productList.sort((a, b) => b.name.localeCompare(a.name));
  }
  if (domainAscending === 1) {
    productList = productList.sort((a, b) =>
      a.domainId.localeCompare(b.domainId)
    );
  }
  if (domainAscending === 2) {
    productList = productList.sort((a, b) =>
      b.domainId.localeCompare(a.domainId)
    );
  }
  if (phaseAscending === 1) {
    productList = productList.sort((a, b) => a.phase.localeCompare(b.phase));
  }
  if (phaseAscending === 2) {
    productList = productList.sort((a, b) => b.phase.localeCompare(a.phase));
  }
  if (typeAscending === 1) {
    productList = productList.sort((a, b) => a.scope.localeCompare(b.scope));
  }
  if (typeAscending === 2) {
    productList = productList.sort((a, b) => b.scope.localeCompare(a.scope));
  }
  if (intOutAscending === 1) {
    productList = productList.sort((a, b) => a.type.localeCompare(b.type));
  }
  if (intOutAscending === 2) {
    productList = productList.sort((a, b) => b.type.localeCompare(a.type));
  }
  if (conOwnAscending === 1) {
    productList = productList.sort((a, b) =>
      a.contributorOwner.localeCompare(b.contributorOwner)
    );
  }
  if (conOwnAscending === 2) {
    productList = productList.sort((a, b) =>
      b.contributorOwner.localeCompare(a.contributorOwner)
    );
  }
  if (dateAscending === 1) {
    productList = productList.sort(
      (a, b) =>
        new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()
    );
  }
  if (dateAscending === 2) {
    productList = productList.sort(
      (a, b) =>
        new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime()
    );
  }

  return productList;
};

const referenceSort = (
  reference,
  shortAscending,
  longAscending,
  versionAscending,
  sectionAscending,
  yearAscending,
  typeAscending,
  domainAscending,
  phaseAscending,
  serviceAscending,
  dateAscending
) => {
  let referenceList = reference;

  if (shortAscending === 1) {
    referenceList = referenceList.sort((a, b) =>
      a.shortName.localeCompare(b.shortName)
    );
  }
  if (shortAscending === 2) {
    referenceList = referenceList.sort((a, b) =>
      b.shortName.localeCompare(a.shortName)
    );
  }
  if (longAscending === 1) {
    referenceList = referenceList.sort((a, b) =>
      a.fullName.localeCompare(b.fullName)
    );
  }
  if (longAscending === 2) {
    referenceList = referenceList.sort((a, b) =>
      b.fullName.localeCompare(a.fullName)
    );
  }
  if (versionAscending === 1) {
    referenceList = referenceList.sort((a, b) =>
      a.version.localeCompare(b.version)
    );
  }
  if (versionAscending === 2) {
    referenceList = referenceList.sort((a, b) =>
      b.version.localeCompare(a.version)
    );
  }
  if (sectionAscending === 1) {
    referenceList = referenceList.sort((a, b) =>
      a.section.localeCompare(b.section)
    );
  }
  if (sectionAscending === 2) {
    referenceList = referenceList.sort((a, b) =>
      b.section.localeCompare(a.section)
    );
  }
  if (yearAscending === 1) {
    referenceList = referenceList.sort((a, b) => a.year - b.year);
  }
  if (yearAscending === 2) {
    referenceList = referenceList.sort((a, b) => b.year - a.year);
  }
  if (typeAscending === 1) {
    referenceList = referenceList.sort((a, b) => a.type.localeCompare(b.type));
  }
  if (typeAscending === 2) {
    referenceList = referenceList.sort((a, b) => b.type.localeCompare(a.type));
  }
  if (domainAscending === 1) {
    referenceList = referenceList.sort((a, b) =>
      a.domainId.localeCompare(b.domainId)
    );
  }
  if (domainAscending === 2) {
    referenceList = referenceList.sort((a, b) =>
      b.domainId.localeCompare(a.domainId)
    );
  }
  if (phaseAscending === 1) {
    referenceList = referenceList.sort((a, b) =>
      a.phaseId.localeCompare(b.phaseId)
    );
  }
  if (phaseAscending === 2) {
    referenceList = referenceList.sort((a, b) =>
      b.phaseId.localeCompare(a.phaseId)
    );
  }
  if (serviceAscending === 1) {
    referenceList = referenceList.sort((a, b) =>
      a.serviceOrganization.localeCompare(b.serviceOrganization)
    );
  }
  if (serviceAscending === 2) {
    referenceList = referenceList.sort((a, b) =>
      b.serviceOrganization.localeCompare(a.serviceOrganization)
    );
  }
  if (dateAscending === 1) {
    referenceList = referenceList.sort(
      (a, b) =>
        new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()
    );
  }
  if (dateAscending === 2) {
    referenceList = referenceList.sort(
      (a, b) =>
        new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime()
    );
  }

  return referenceList;
};

const didsSort = (dids, documentIDAscending, nameAscending, linkAscending) => {
  let didsList = dids;

  if (documentIDAscending === 1) {
    didsList = didsList.sort((a, b) =>
      a.documentID.localeCompare(b.documentID)
    );
  }
  if (documentIDAscending === 2) {
    didsList = didsList.sort((a, b) =>
      b.documentID.localeCompare(a.documentID)
    );
  }

  if (nameAscending === 1) {
    didsList = didsList.sort((a, b) => a.name.localeCompare(b.name));
  }
  if (nameAscending === 2) {
    didsList = didsList.sort((a, b) => b.name.localeCompare(a.name));
  }

  if (linkAscending === 1) {
    didsList = didsList.sort((a, b) => a.links.localeCompare(b.links));
  }
  if (linkAscending === 2) {
    didsList = didsList.sort((a, b) => b.links.localeCompare(a.links));
  }

  return didsList;
};

const toolSort = (tools, nameAscending, meaningAscending, dateAscending) => {
  let toolsList = tools;

  if (nameAscending === 1) {
    toolsList = toolsList.sort((a, b) => a.name.localeCompare(b.name));
  }
  if (nameAscending === 2) {
    toolsList = toolsList.sort((a, b) => b.name.localeCompare(a.name));
  }
  if (meaningAscending === 1) {
    toolsList = toolsList.sort((a, b) =>
      a.description.localeCompare(b.description)
    );
  }
  if (meaningAscending === 2) {
    toolsList = toolsList.sort((a, b) =>
      b.description.localeCompare(a.description)
    );
  }
  if (dateAscending === 1) {
    toolsList = toolsList.sort(
      (a, b) =>
        new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()
    );
  }
  if (dateAscending === 2) {
    toolsList = toolsList.sort(
      (a, b) =>
        new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime()
    );
  }

  return toolsList;
};

const projectSort = (projects, projectAscending) => {
  let projectsList = projects;

  if (projectAscending === 1) {
    projectsList = projectsList.sort((a, b) => a.name.localeCompare(b.name));
  }
  if (projectAscending === 2) {
    projectsList = projectsList.sort((a, b) => b.name.localeCompare(a.name));
  }

  return projectsList;
};

export {
  acronymSort,
  productSort,
  referenceSort,
  didsSort,
  toolSort,
  projectSort,
};
