export const rows1 = [
  {
    id: "STM1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14340,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM1 rowDivider",
  },
  {
    id: "A1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14340,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A1 rowDivider",
  },
  {
    id: "B1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B1 rowDivider",
  },
  {
    id: "C1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C1 rowDivider",
  },
  {
    id: "D1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D1 rowDivider",
  },
  {
    id: "E1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E1 rowDivider",
  },
  {
    id: "F1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F1 rowDivider",
  },
  {
    id: "G1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G1 rowDivider",
  },
  {
    id: "H1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H1 rowDivider",
  },
  {
    id: "I1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I1 rowDivider",
  },
  {
    id: "J1",
    type: "group",
    data: { label: null },
    position: { x: 0, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14440,
      height: 63,
      backgroundColor: "#E97272",
    },
    className: "J1",
  },
  {
    id: "STM2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM2 rowDivider",
  },
  {
    id: "A2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A2 rowDivider",
  },
  {
    id: "B2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B2 rowDivider",
  },
  {
    id: "C2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C2 rowDivider",
  },
  {
    id: "D2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D2 rowDivider",
  },
  {
    id: "E2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E2 rowDivider",
  },
  {
    id: "F2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F2 rowDivider",
  },
  {
    id: "G2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G2 rowDivider",
  },
  {
    id: "H2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H2 rowDivider",
  },
  {
    id: "I2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I2 rowDivider",
  },
  {
    id: "J2",
    type: "group",
    data: { label: null },
    position: { x: 14462, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#944F99",
    },
    className: "J2",
  },
  {
    id: "STM3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM3 rowDivider",
  },
  {
    id: "A3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A3 rowDivider",
  },
  {
    id: "B3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B3 rowDivider",
  },
  {
    id: "C3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C3 rowDivider",
  },
  {
    id: "D3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D3 rowDivider",
  },
  {
    id: "E3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E3 rowDivider",
  },
  {
    id: "F3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F3 rowDivider",
  },
  {
    id: "G3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G3 rowDivider",
  },
  {
    id: "H3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H3 rowDivider",
  },
  {
    id: "I3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I3 rowDivider",
  },
  {
    id: "J3",
    type: "group",
    data: { label: null },
    position: { x: 28904, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#17BDAC",
    },
    className: "J3",
  },
  {
    id: "STM4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM4 rowDivider",
  },
  {
    id: "A4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "B4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "C4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "D4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "E4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "F4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "G4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "H4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "I4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "J4",
    type: "group",
    data: { label: null },
    position: { x: 43346, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#6071BF",
    },
    className: "A4 rowDivider",
  },
];

export const rows2 = [
  {
    id: "STM1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14340,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM1 rowDivider",
  },
  {
    id: "A1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14340,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A1 rowDivider",
  },
  {
    id: "B1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B1 rowDivider",
  },
  {
    id: "C1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C1 rowDivider",
  },
  {
    id: "D1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D1 rowDivider",
  },
  {
    id: "E1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E1 rowDivider",
  },
  {
    id: "F1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F1 rowDivider",
  },
  {
    id: "G1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G1 rowDivider",
  },
  {
    id: "H1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H1 rowDivider",
  },
  {
    id: "I1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I1 rowDivider",
  },
  {
    id: "J1",
    type: "group",
    data: { label: null },
    position: { x: 0, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14440,
      height: 63,
      backgroundColor: "#E97272",
    },
    className: "J1",
  },
  {
    id: "STM2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM2 rowDivider",
  },
  {
    id: "A2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A2 rowDivider",
  },
  {
    id: "B2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B2 rowDivider",
  },
  {
    id: "C2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C2 rowDivider",
  },
  {
    id: "D2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D2 rowDivider",
  },
  {
    id: "E2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E2 rowDivider",
  },
  {
    id: "F2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F2 rowDivider",
  },
  {
    id: "G2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G2 rowDivider",
  },
  {
    id: "H2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H2 rowDivider",
  },
  {
    id: "I2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I2 rowDivider",
  },
  {
    id: "J2",
    type: "group",
    data: { label: null },
    position: { x: 14462, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#944F99",
    },
    className: "J2",
  },
  {
    id: "STM3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM3 rowDivider",
  },
  {
    id: "A3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A3 rowDivider",
  },
  {
    id: "B3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B3 rowDivider",
  },
  {
    id: "C3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C3 rowDivider",
  },
  {
    id: "D3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D3 rowDivider",
  },
  {
    id: "E3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E3 rowDivider",
  },
  {
    id: "F3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F3 rowDivider",
  },
  {
    id: "G3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G3 rowDivider",
  },
  {
    id: "H3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H3 rowDivider",
  },
  {
    id: "I3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I3 rowDivider",
  },
  {
    id: "J3",
    type: "group",
    data: { label: null },
    position: { x: 28904, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#17BDAC",
    },
    className: "J3",
  },
];

export const rows3 = [
  {
    id: "STM1",
    type: "group",
    data: { label: null },
    position: { x: 200, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14240,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM1 rowDivider",
  },
  {
    id: "A1",
    type: "group",
    data: { label: null },
    position: { x: 200, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14240,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A1 rowDivider",
  },
  {
    id: "B1",
    type: "group",
    data: { label: null },
    position: { x: 200, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14240,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B1 rowDivider",
  },
  {
    id: "C1",
    type: "group",
    data: { label: null },
    position: { x: 200, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14240,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C1 rowDivider",
  },
  {
    id: "D1",
    type: "group",
    data: { label: null },
    position: { x: 200, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14240,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D1 rowDivider",
  },
  {
    id: "E1",
    type: "group",
    data: { label: null },
    position: { x: 200, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14240,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E1 rowDivider",
  },
  {
    id: "F1",
    type: "group",
    data: { label: null },
    position: { x: 200, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14240,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F1 rowDivider",
  },
  {
    id: "G1",
    type: "group",
    data: { label: null },
    position: { x: 200, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14240,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G1 rowDivider",
  },
  {
    id: "H1",
    type: "group",
    data: { label: null },
    position: { x: 200, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14240,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H1 rowDivider",
  },
  {
    id: "I1",
    type: "group",
    data: { label: null },
    position: { x: 200, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14240,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I1 rowDivider",
  },
  {
    id: "J1",
    type: "group",
    data: { label: null },
    position: { x: 0, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14440,
      height: 63,
      backgroundColor: "#E97272",
    },
    className: "J1",
  },
  {
    id: "STM2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM2 rowDivider",
  },
  {
    id: "A2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A2 rowDivider",
  },
  {
    id: "B2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B2 rowDivider",
  },
  {
    id: "C2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C2 rowDivider",
  },
  {
    id: "D2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D2 rowDivider",
  },
  {
    id: "E2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E2 rowDivider",
  },
  {
    id: "F2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F2 rowDivider",
  },
  {
    id: "G2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G2 rowDivider",
  },
  {
    id: "H2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H2 rowDivider",
  },
  {
    id: "I2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I2 rowDivider",
  },
  {
    id: "J2",
    type: "group",
    data: { label: null },
    position: { x: 14462, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#944F99",
    },
    className: "J2",
  },
  {
    id: "STM3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM3 rowDivider",
  },
  {
    id: "A3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A3 rowDivider",
  },
  {
    id: "B3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B3 rowDivider",
  },
  {
    id: "C3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C3 rowDivider",
  },
  {
    id: "D3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D3 rowDivider",
  },
  {
    id: "E3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E3 rowDivider",
  },
  {
    id: "F3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F3 rowDivider",
  },
  {
    id: "G3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G3 rowDivider",
  },
  {
    id: "H3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H3 rowDivider",
  },
  {
    id: "I3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I3 rowDivider",
  },
  {
    id: "J3",
    type: "group",
    data: { label: null },
    position: { x: 28904, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#17BDAC",
    },
    className: "J3",
  },
  {
    id: "STM4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM4 rowDivider",
  },
  {
    id: "A4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "B4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "C4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "D4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "E4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "F4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "G4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "H4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "I4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "J4",
    type: "group",
    data: { label: null },
    position: { x: 43346, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#6071BF",
    },
    className: "A4 rowDivider",
  },
  {
    id: "STM5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM5 rowDivider",
  },
  {
    id: "A5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A5 rowDivider",
  },
  {
    id: "B5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B5 rowDivider",
  },
  {
    id: "C5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C5 rowDivider",
  },
  {
    id: "D5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D5 rowDivider",
  },
  {
    id: "E5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E5 rowDivider",
  },
  {
    id: "F5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F5 rowDivider",
  },
  {
    id: "G5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G5 rowDivider",
  },
  {
    id: "H5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H5 rowDivider",
  },
  {
    id: "I5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I5 rowDivider",
  },
  {
    id: "J5",
    type: "group",
    data: { label: null },
    position: { x: 57788, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#F3A08B",
    },
    className: "J5",
  },
  {
    id: "STM6",
    type: "group",
    data: { label: null },
    position: { x: 72330, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM6 rowDivider",
  },
  {
    id: "A6",
    type: "group",
    data: { label: null },
    position: { x: 72330, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A6 rowDivider",
  },
  {
    id: "B6",
    type: "group",
    data: { label: null },
    position: { x: 72330, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B6 rowDivider",
  },
  {
    id: "C6",
    type: "group",
    data: { label: null },
    position: { x: 72330, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C6 rowDivider",
  },
  {
    id: "D6",
    type: "group",
    data: { label: null },
    position: { x: 72330, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D6 rowDivider",
  },
  {
    id: "E6",
    type: "group",
    data: { label: null },
    position: { x: 72330, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E6 rowDivider",
  },
  {
    id: "F6",
    type: "group",
    data: { label: null },
    position: { x: 72330, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F6 rowDivider",
  },
  {
    id: "G6",
    type: "group",
    data: { label: null },
    position: { x: 72330, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G6 rowDivider",
  },
  {
    id: "H6",
    type: "group",
    data: { label: null },
    position: { x: 72330, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H6 rowDivider",
  },
  {
    id: "I6",
    type: "group",
    data: { label: null },
    position: { x: 72330, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I6 rowDivider",
  },
  {
    id: "J6",
    type: "group",
    data: { label: null },
    position: { x: 72230, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#F3A08B",
    },
    className: "J6",
  },
];

export const rows4 = [
  {
    id: "STM1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14340,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM1 rowDivider",
  },
  {
    id: "A1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14340,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A1 rowDivider",
  },
  {
    id: "B1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B1 rowDivider",
  },
  {
    id: "C1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C1 rowDivider",
  },
  {
    id: "D1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 906 },
    draggable: false,
    selectable: false,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D1 rowDivider",
  },
  {
    id: "E1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E1 rowDivider",
  },
  {
    id: "F1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F1 rowDivider",
  },
  {
    id: "G1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1455 },
    draggable: false,
    selectable: false,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G1 rowDivider",
  },
  {
    id: "H1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1638 },
    draggable: false,
    selectable: false,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H1 rowDivider",
  },
  {
    id: "I1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1821 },
    draggable: false,
    selectable: false,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I1 rowDivider",
  },
  {
    id: "J1",
    type: "group",
    data: { label: null },
    position: { x: 0, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14440,
      height: 63,
      backgroundColor: "#E97272",
    },
    className: "J1",
  },
  {
    id: "STM2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM2 rowDivider",
  },
  {
    id: "A2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A2 rowDivider",
  },
  {
    id: "B2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B2 rowDivider",
  },
  {
    id: "C2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C2 rowDivider",
  },
  {
    id: "D2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D2 rowDivider",
  },
  {
    id: "E2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E2 rowDivider",
  },
  {
    id: "F2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F2 rowDivider",
  },
  {
    id: "G2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G2 rowDivider",
  },
  {
    id: "H2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H2 rowDivider",
  },
  {
    id: "I2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I2 rowDivider",
  },
  {
    id: "J2",
    type: "group",
    data: { label: null },
    position: { x: 14462, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#944F99",
    },
    className: "J2",
  },
];

export const rows5 = [
  {
    id: "STM1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14340,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM1 rowDivider",
  },
  {
    id: "A1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14340,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A1 rowDivider",
  },
  {
    id: "B1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B1 rowDivider",
  },
  {
    id: "C1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C1 rowDivider",
  },
  {
    id: "D1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D1 rowDivider",
  },
  {
    id: "E1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E1 rowDivider",
  },
  {
    id: "F1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F1 rowDivider",
  },
  {
    id: "G1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G1 rowDivider",
  },
  {
    id: "H1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H1 rowDivider",
  },
  {
    id: "I1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I1 rowDivider",
  },
  {
    id: "J1",
    type: "group",
    data: { label: null },
    position: { x: 0, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14440,
      height: 63,
      backgroundColor: "#E97272",
    },
    className: "J1",
  },
  {
    id: "STM2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM2 rowDivider",
  },
  {
    id: "A2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A2 rowDivider",
  },
  {
    id: "B2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B2 rowDivider",
  },
  {
    id: "C2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C2 rowDivider",
  },
  {
    id: "D2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D2 rowDivider",
  },
  {
    id: "E2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E2 rowDivider",
  },
  {
    id: "F2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F2 rowDivider",
  },
  {
    id: "G2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G2 rowDivider",
  },
  {
    id: "H2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H2 rowDivider",
  },
  {
    id: "I2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I2 rowDivider",
  },
  {
    id: "J2",
    type: "group",
    data: { label: null },
    position: { x: 14462, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#944F99",
    },
    className: "J2",
  },
  {
    id: "STM3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM3 rowDivider",
  },
  {
    id: "A3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A3 rowDivider",
  },
  {
    id: "B3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B3 rowDivider",
  },
  {
    id: "C3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C3 rowDivider",
  },
  {
    id: "D3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D3 rowDivider",
  },
  {
    id: "E3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E3 rowDivider",
  },
  {
    id: "F3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F3 rowDivider",
  },
  {
    id: "G3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G3 rowDivider",
  },
  {
    id: "H3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H3 rowDivider",
  },
  {
    id: "I3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I3 rowDivider",
  },
  {
    id: "J3",
    type: "group",
    data: { label: null },
    position: { x: 28904, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#17BDAC",
    },
    className: "J3",
  },
  {
    id: "STM4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM4 rowDivider",
  },
  {
    id: "A4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "B4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "C4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "D4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "E4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "F4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "G4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "H4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "I4",
    type: "group",
    data: { label: null },
    position: { x: 43446, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A4 rowDivider",
  },
  {
    id: "J4",
    type: "group",
    data: { label: null },
    position: { x: 43346, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#6071BF",
    },
    className: "A4 rowDivider",
  },
  {
    id: "STM5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM5 rowDivider",
  },
  {
    id: "A5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A5 rowDivider",
  },
  {
    id: "B5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B5 rowDivider",
  },
  {
    id: "C5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C5 rowDivider",
  },
  {
    id: "D5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D5 rowDivider",
  },
  {
    id: "E5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E5 rowDivider",
  },
  {
    id: "F5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F5 rowDivider",
  },
  {
    id: "G5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G5 rowDivider",
  },
  {
    id: "H5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H5 rowDivider",
  },
  {
    id: "I5",
    type: "group",
    data: { label: null },
    position: { x: 57888, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I5 rowDivider",
  },
  {
    id: "J5",
    type: "group",
    data: { label: null },
    position: { x: 57788, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#F3A08B",
    },
    className: "J5",
  },
];

export const rows6 = [
  {
    id: "STM1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14340,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM1 rowDivider",
  },
  {
    id: "A1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14340,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A1 rowDivider",
  },
  {
    id: "B1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B1 rowDivider",
  },
  {
    id: "C1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C1 rowDivider",
  },
  {
    id: "D1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D1 rowDivider",
  },
  {
    id: "E1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E1 rowDivider",
  },
  {
    id: "F1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F1 rowDivider",
  },
  {
    id: "G1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G1 rowDivider",
  },
  {
    id: "H1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H1 rowDivider",
  },
  {
    id: "I1",
    type: "group",
    data: { label: null },
    position: { x: 100, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      width: 14340,
      padding: 0,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I1 rowDivider",
  },
  {
    id: "J1",
    type: "group",
    data: { label: null },
    position: { x: 0, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14440,
      height: 63,
      backgroundColor: "#E97272",
    },
    className: "J1",
  },
  {
    id: "STM2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM2 rowDivider",
  },
  {
    id: "A2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A2 rowDivider",
  },
  {
    id: "B2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B2 rowDivider",
  },
  {
    id: "C2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C2 rowDivider",
  },
  {
    id: "D2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D2 rowDivider",
  },
  {
    id: "E2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E2 rowDivider",
  },
  {
    id: "F2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F2 rowDivider",
  },
  {
    id: "G2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G2 rowDivider",
  },
  {
    id: "H2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H2 rowDivider",
  },
  {
    id: "I2",
    type: "group",
    data: { label: null },
    position: { x: 14562, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I2 rowDivider",
  },
  {
    id: "J2",
    type: "group",
    data: { label: null },
    position: { x: 14462, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#944F99",
    },
    className: "J2",
  },
  {
    id: "STM3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 165 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "STM3 rowDivider",
  },
  {
    id: "A3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 357 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "A3 rowDivider",
  },
  {
    id: "B3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 540 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "B3 rowDivider",
  },
  {
    id: "C3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 723 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "C3 rowDivider",
  },
  {
    id: "D3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 906 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "D3 rowDivider",
  },
  {
    id: "E3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1089 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "E3 rowDivider",
  },
  {
    id: "F3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1272 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "F3 rowDivider",
  },
  {
    id: "G3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1455 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "G3 rowDivider",
  },
  {
    id: "H3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1638 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "H3 rowDivider",
  },
  {
    id: "I3",
    type: "group",
    data: { label: null },
    position: { x: 29004, y: 1821 },
    draggable: false,
    selectable: false,
    isDivider: true,
    style: {
      padding: 0,
      width: 14320,
      height: 2,
      backgroundColor: "lightgrey",
      opacity: 0.25,
      border: "none",
    },
    className: "I3 rowDivider",
  },
  {
    id: "J3",
    type: "group",
    data: { label: null },
    position: { x: 28904, y: 2014 },
    draggable: false,
    selectable: false,
    style: {
      width: 14420,
      height: 63,
      backgroundColor: "#17BDAC",
    },
    className: "J3",
  },
];
