//Delay async function
const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const reportTime = () => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let current = new Date();
  let year = current.getFullYear();
  let month = months[current.getMonth()];
  let date =
    current.getDate() < 10 ? `0${current.getDate()}` : current.getDate();
  let hour =
    current.getHours() - 12 < 10
      ? `0${
          current.getHours() > 12 ? current.getHours() - 12 : current.getHours()
        }`
      : current.getHours() > 12
      ? current.getHours() - 12
      : current.getHours();
  let minute =
    current.getUTCMinutes() < 10
      ? `0${current.getUTCMinutes()}`
      : current.getUTCMinutes();

  return `${date} ${month} ${year} ${hour}:${minute}`;
};

export { delay, reportTime };
