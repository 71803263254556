import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./notesRiskPModal.css";
import { MdClose } from "react-icons/md";
import { getId } from "../../lib/helpers/IdFactory";
import { editActivityHSIRisks } from "../../redux/actions/Activities";
import { setActivityRisksChanged } from "../../redux/actions/Projects";

const NotesRiskModal: React.FC<{
  closeNotesHSI: any;
  notesM: any;
  style: any;
  activeProjectActivity: any;
  editLibrariesHSI: boolean;
  editActivityHSIRisks: (activityId: string, risks: any) => void;
  setActivityRisksChanged: (state: any) => void;
  activityRisksChanged: boolean;
  initialRisks: any;
}> = ({
  closeNotesHSI,
  notesM,
  style,
  activeProjectActivity,
  editLibrariesHSI,
  editActivityHSIRisks,
  setActivityRisksChanged,
  activityRisksChanged,
  initialRisks,
}) => {
  const [notesObject, setNotesObject] = useState([]) as any;
  const [firstOccurrence, setFirstOccurrence] = useState(false) as any;

  useEffect(() => {
    setNotesObject(
      activeProjectActivity?.hsiRisk ? activeProjectActivity?.hsiRisk : []
    );

    const setOneItem = async () => {
      setNotesObject([
        {
          riskLvl: "Low",
          description: "",
          riskID: (await getId()) as string,
        },
      ]);
    };

    if (
      activeProjectActivity?.hsiRisk.length === 0 ||
      notesObject.length === 0
    ) {
      setOneItem();
      setFirstOccurrence(true);
    }
  }, [activeProjectActivity, editLibrariesHSI]);

  const changeLvl = (e: React.ChangeEvent<HTMLSelectElement>, riskID: any) => {
    setNotesObject(
      notesObject.map((obj: any) => {
        if (obj.riskID === riskID) {
          return {
            ...obj,
            riskLvl: e.target.value,
          };
        } else {
          return obj;
        }
      })
    );
  };

  const changeDescription = (
    e: React.ChangeEvent<HTMLInputElement>,
    riskID: any
  ) => {
    setNotesObject(
      notesObject.map((obj: any) => {
        if (obj.riskID === riskID) {
          return {
            ...obj,
            description: e.target.value,
          };
        } else {
          return obj;
        }
      })
    );
  };

  const removeRisk = (riskID: any) => {
    setNotesObject(notesObject.filter((item) => item.riskID !== riskID));
  };

  const cancelButton = () => {
    closeNotesHSI();
    setNotesObject(
      activeProjectActivity?.hsiRisk ? activeProjectActivity?.hsiRisk : []
    );
  };

  const addRisk = async () => {
    setNotesObject([
      ...notesObject,
      {
        riskLvl: "Low",
        description: "",
        riskID: (await getId()) as string,
      },
    ]);
  };

  const checkRisksChanges = (closeType: string) => {
    if (initialRisks.length === 0) {
      if (notesObject.length > 0) {
        if (closeType === "save") {
          setActivityRisksChanged(true);
          editActivityHSIRisks(activeProjectActivity.activityId, notesObject);
          closeNotesHSI();
        } else if (closeType === "exit") {
          if (firstOccurrence && notesObject[0].description === "") {
            setFirstOccurrence(false);
            setNotesObject([]);
            closeNotesHSI();
          } else {
            showChangesToast();
            setActivityRisksChanged(true);
          }
        } else {
          setActivityRisksChanged(false);
          cancelButton();
        }
      }
    } else {
      if (notesObject.length === initialRisks.length) {
        if (
          initialRisks.every((element_1) =>
            notesObject.some(
              (element_2) => element_1.riskID === element_2.riskID
            )
          )
        ) {
          if (closeType === "save") {
            setActivityRisksChanged(true);
            editActivityHSIRisks(activeProjectActivity.activityId, notesObject);
            closeNotesHSI();
          } else if (closeType === "exit") {
            setActivityRisksChanged(false);
            cancelButton();
          } else {
            setActivityRisksChanged(false);
            cancelButton();
          }
        } else {
          showChangesToast();
          setActivityRisksChanged(true);
        }
      } else {
        if (closeType === "save") {
          setActivityRisksChanged(true);
          editActivityHSIRisks(activeProjectActivity.activityId, notesObject);
          closeNotesHSI();
        } else if (closeType === "exit") {
          showChangesToast();
          setActivityRisksChanged(true);
        } else {
          setActivityRisksChanged(false);
          cancelButton();
        }
      }
    }
  };

  return (
    <div style={{ visibility: notesM ? "initial" : "hidden" }}>
      <div className="notesRiskPModalmodal-container">
        <section className="notesRiskPModalmodal">
          <i
            className="fa fa-times"
            onClick={() => checkRisksChanges("exit")}
            style={{
              cursor: "pointer",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
              paddingBottom: "10px",
            }}
          ></i>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "-20px",
            }}
          >
            <p>HSI Risks</p>
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                gap: 10,
                paddingBottom: "10px",
              }}
            >
              <button
                onClick={cancelButton}
                style={{
                  backgroundColor: "grey",
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => checkRisksChanges("save")}
                style={{
                  backgroundColor: style.colors.primaryBlue,
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Save
              </button>
            </div>
          </div>

          {notesObject.length !== 0
            ? notesObject.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "6px",
                    }}
                  >
                    <select
                      value={item.riskLvl}
                      onChange={(e) => changeLvl(e, item.riskID)}
                    >
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option value="High">High</option>
                    </select>

                    <input
                      value={item.description}
                      style={{ width: "430px", marginLeft: "10px" }}
                      onChange={(e) => changeDescription(e, item.riskID)}
                    />
                    <MdClose
                      onClick={() => removeRisk(item.riskID)}
                      style={{
                        color: "grey",
                        fontSize: 20,
                        cursor: "pointer",
                        marginLeft: "auto",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    />
                  </div>
                );
              })
            : null}

          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              onClick={addRisk}
              style={{
                // marginTop: "-5px",
                paddingBottom: "2px",
                fontSize: "13px",
                color: "rgb(18, 18, 201)",
                cursor: "pointer",
              }}
            >
              {"+ Add Risk(s)"}
            </p>
          </div>
        </section>
      </div>
    </div>
  );

  function showChangesToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar6");
    if (element[0]) {
      element[0].className = "snackbar6 show";
      setTimeout(function () {
        element[0].className = "snackbar6";
      }, 5000);
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  activeProjectActivity: state.projects.activeProjectActivity,
  editLibrariesHSI: state.projects.editLibrariesHSI,
  activityRisksChanged: state.projects.activityRisksChanged,
  initialRisks: state.projects.initialRisks,
});

export default connect(mapStateToProps, {
  editActivityHSIRisks,
  setActivityRisksChanged,
})(NotesRiskModal) as any;
