import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./productLibraryModal.css";
import Modal from "react-modal";
import {
  getProductLibrary,
  addNewProduct,
  updateProduct,
  deleteProduct,
} from "../../redux/actions/Data";
import { productSort } from "../../lib/helpers/columnFilters";
import { ReactComponent as Editsvg } from "../../lib/assets/img/Edit.svg";
import DeleteLibraryItemModal from "../deleteLibraryItemModal/deleteLibraryItemModal";

const ProductLibraryModal: React.FC<{
  closeProductM: any;
  productM: any;
  productLibrary: any;
  getProductLibrary: () => void;
  style: any;
  navSearch: string;
  addNewProduct: (
    name: string,
    domain: string,
    phases: string,
    type: string,
    inputOutput: string,
    ConOwn: string
  ) => void;
  updateProduct: (
    name: string,
    domain: string,
    phases: string,
    type: string,
    inputOutput: string,
    ConOwn: string,
    id: number
  ) => void;
  deleteProduct: (id: number, intOut: string) => void;
  user: any;
}> = ({
  closeProductM,
  productM,
  productLibrary,
  getProductLibrary,
  style,
  navSearch,
  addNewProduct,
  updateProduct,
  deleteProduct,
  user,
}) => {
  const [newProductModal, setNewProductModal] = useState(false) as any;
  const [productSearch, setProductSearch] = useState("") as any;
  const [searchFiltered, setSearchFiltered] = useState([]) as any;
  const [nameAscending, setNameAscending] = useState(1) as any;
  const [domainAscending, setDomainAscending] = useState(0) as any;
  const [phaseAscending, setPhaseAscending] = useState(0) as any;
  const [typeAscending, setTypeAscending] = useState(0) as any;
  const [intOutAscending, setIntOutAscending] = useState(0) as any;
  const [conOwnAscending, setConOwnAscending] = useState(0) as any;
  const [dateAscending, setDateAscending] = useState(0) as any;
  const [newName, setNewName] = useState("") as any;
  const [newDomain, setNewDomain] = useState("") as any;
  const [phaseTMRR, setPhaseTMRR] = useState(false) as any;
  const [phaseMSA, setPhaseMSA] = useState(false) as any;
  const [phaseOS, setPhaseOS] = useState(false) as any;
  const [phasePD, setPhasePD] = useState(false) as any;
  const [phaseEMD, setPhaseEMD] = useState(false) as any;
  const [phasePREMDD, setPhasePREMDD] = useState(false) as any;
  const [newType, setNewType] = useState("SETR") as any;
  const [newInput, setNewInput] = useState(false) as any;
  const [newOutput, setNewOutput] = useState(false) as any;
  const [newConOwn, setNewConOwn] = useState("") as any;
  const [deleteOption, setDeleteOption] = useState(false) as any;
  const [focusId, setFocusId] = useState(0) as any;
  const [focusOccurr, setFocusOccurr] = useState([]) as any;
  const [nameError, setNameError] = useState(false) as any;
  const [typeError, setTypeError] = useState(false) as any;
  const [intOutError, setIntOutError] = useState(false) as any;
  const [conOwnError, setConOwnError] = useState(false) as any;
  const [deleteModal, setDeleteModal] = useState(false) as any;
  const [currentSort, setCurrentSort] = useState("name") as any;
  const [dateCreated, setDateCreated] = useState() as any;

  useEffect(() => {
    getProductLibrary();
  }, [getProductLibrary]);

  useEffect(() => {
    // if (searchText.length > 0) {
    setProductSearch(navSearch);
    // }
  }, [navSearch]);

  useEffect(() => {
    let productList = productLibrary.filter(
      (x) => !x.hasOwnProperty("init_id")
    );

    if (productSearch !== "" && productLibrary?.length > 0) {
      productList = productList?.filter(
        (item: any) => item.productId !== null
      );
      productList = productList.filter(
        (item: any) =>
          item?.name?.toLowerCase().indexOf(productSearch.toLowerCase()) > -1
        //revisit  ||
        // item?.domainId?.toLowerCase().indexOf(productSearch.toLowerCase()) >
        //   -1 ||
        // item?.phase?.toLowerCase().indexOf(productSearch.toLowerCase()) >
        //   -1 ||
        // item?.scope?.toLowerCase().indexOf(productSearch.toLowerCase()) >
        //   -1 ||
        // item?.type?.toLowerCase().indexOf(productSearch.toLowerCase()) > -1 ||
        // item?.contributorOwner
        //   ?.toLowerCase()
        //   .indexOf(productSearch.toLowerCase()) > -1
      );
    }

    productList = productSort(
      productList,
      nameAscending,
      domainAscending,
      phaseAscending,
      typeAscending,
      intOutAscending,
      conOwnAscending,
      dateAscending
    );

    setSearchFiltered(productList);
  }, [
    productSearch,
    productLibrary,
    nameAscending,
    domainAscending,
    phaseAscending,
    typeAscending,
    intOutAscending,
    conOwnAscending,
    dateAscending,
  ]);

  const customStyles = {
    content: {
      top: "40%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
  };

  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
    overlay: {
      background: "none",
    },
  };

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // const dateColumn = () => {
  //   dateAscending === 0
  //     ? setDateAscending(1)
  //     : dateAscending === 1
  //     ? setDateAscending(2)
  //     : setDateAscending(0);
  // };

  const nameColumn = () => {
    setCurrentSort(currentSort !== "name" ? "name" : currentSort);
    setDomainAscending(0);
    setPhaseAscending(0);
    setTypeAscending(0);
    setIntOutAscending(0);
    setConOwnAscending(0);
    nameAscending === 1 ? setNameAscending(2) : setNameAscending(1);
  };

  const domainColumn = () => {
    setCurrentSort(currentSort !== "domain" ? "domain" : currentSort);
    setNameAscending(0);
    setPhaseAscending(0);
    setTypeAscending(0);
    setIntOutAscending(0);
    setConOwnAscending(0);
    domainAscending === 1 ? setDomainAscending(2) : setDomainAscending(1);
  };

  const phaseColumn = () => {
    setCurrentSort(currentSort !== "phase" ? "phase" : currentSort);
    setDomainAscending(0);
    setNameAscending(0);
    setTypeAscending(0);
    setIntOutAscending(0);
    setConOwnAscending(0);
    phaseAscending === 1 ? setPhaseAscending(2) : setPhaseAscending(1);
  };

  const typeColumn = () => {
    setCurrentSort(currentSort !== "type" ? "type" : currentSort);
    setDomainAscending(0);
    setPhaseAscending(0);
    setNameAscending(0);
    setIntOutAscending(0);
    setConOwnAscending(0);
    typeAscending === 1 ? setTypeAscending(2) : setTypeAscending(1);
  };

  const intOutColumn = () => {
    setCurrentSort(currentSort !== "intOut" ? "intOut" : currentSort);
    setDomainAscending(0);
    setPhaseAscending(0);
    setTypeAscending(0);
    setNameAscending(0);
    setConOwnAscending(0);
    intOutAscending === 1 ? setIntOutAscending(2) : setIntOutAscending(1);
  };

  const conOwnColumn = () => {
    setCurrentSort(currentSort !== "conOwn" ? "conOwn" : currentSort);
    setDomainAscending(0);
    setPhaseAscending(0);
    setTypeAscending(0);
    setIntOutAscending(0);
    setNameAscending(0);
    conOwnAscending === 1 ? setConOwnAscending(2) : setConOwnAscending(1);
  };

  const domainHSICheck = () => {
    if (newDomain === "HSI") {
      setNewDomain("");
    } else setNewDomain("HSI");
  };

  const domainTECheck = () => {
    if (newDomain === "T&E") {
      setNewDomain("");
    } else setNewDomain("T&E");
  };

  const domainMPTCheck = () => {
    if (newDomain === "MPT") {
      setNewDomain("");
    } else setNewDomain("MPT");
  };

  const domainESOHCheck = () => {
    if (newDomain === "ESOH") {
      setNewDomain("");
    } else setNewDomain("ESOH");
  };

  const domainHFECheck = () => {
    if (newDomain === "HFE") {
      setNewDomain("");
    } else setNewDomain("HFE");
  };

  const phaseTMRRCheck = () => {
    setPhaseTMRR(!phaseTMRR);
  };

  const phaseMSACheck = () => {
    setPhaseMSA(!phaseMSA);
  };

  const phaseOSCheck = () => {
    setPhaseOS(!phaseOS);
  };

  const phasePDCheck = () => {
    setPhasePD(!phasePD);
  };

  const phaseEMDCheck = () => {
    setPhaseEMD(!phaseEMD);
  };

  const phasePREMDDCheck = () => {
    setPhasePREMDD(!phasePREMDD);
  };

  const inputCheck = () => {
    setNewInput(!newInput);
  };

  const outputCheck = () => {
    setNewOutput(!newOutput);
  };

  const contributorCheck = () => {
    if (newConOwn === "Contributor") {
      setNewConOwn("");
    } else setNewConOwn("Contributor");
  };

  const ownerCheck = () => {
    if (newConOwn === "Owner") {
      setNewConOwn("");
    } else setNewConOwn("Owner");
  };

  const submitProduct = (submitType: string) => {
    var phaseString: any = [];
    if (phaseEMD) {
      phaseString.push("EMD");
    }
    if (phaseMSA) {
      phaseString.push("MSA");
    }
    if (phaseOS) {
      phaseString.push("OS");
    }
    if (phasePD) {
      phaseString.push("PD");
    }
    if (phasePREMDD) {
      phaseString.push("PRE:MDD");
    }
    if (phaseTMRR) {
      phaseString.push("TMRR");
    }
    phaseString = phaseString.join().replace(/,[s]*/g, ", ");
    let intOut;
    if (newInput && newOutput) {
      intOut = "InputOutput";
    } else if (newInput && !newOutput) {
      intOut = "Input";
    } else if (!newInput && newOutput) {
      intOut = "Output";
    } else {
      intOut = "";
    }

    if (submitType === "newProduct") {
      setNameAscending(nameAscending);
      setDomainAscending(domainAscending);
      setPhaseAscending(phaseAscending);
      setTypeAscending(typeAscending);
      setIntOutAscending(intOutAscending);
      setConOwnAscending(conOwnAscending);
      setDateAscending(dateAscending);
      if (intOut === "InputOutput") {
        // addNewProduct(
        //   newName,
        //   newDomain,
        //   phaseString,
        //   newType,
        //   "Input",
        //   newConOwn
        // );
        addNewProduct(
          newName,
          newDomain,
          phaseString,
          newType,
          intOut,
          newConOwn
        );
      } else {
        addNewProduct(
          newName,
          newDomain,
          phaseString,
          newType,
          intOut,
          newConOwn
        );
      }
    } else if (submitType === "updateProduct") {
      setNameAscending(nameAscending);
      setDomainAscending(domainAscending);
      setPhaseAscending(phaseAscending);
      setTypeAscending(typeAscending);
      setIntOutAscending(intOutAscending);
      setConOwnAscending(conOwnAscending);
      setDateAscending(dateAscending);
      if (intOut === "InputOutput") {
        // updateProduct(
        //   newName,
        //   newDomain,
        //   phaseString,
        //   newType,
        //   "Input",
        //   newConOwn,
        //   focusId
        // );
        updateProduct(
          newName,
          newDomain,
          phaseString,
          newType,
          intOut,
          newConOwn,
          focusId
        );
      } else {
        updateProduct(
          newName,
          newDomain,
          phaseString,
          newType,
          intOut,
          newConOwn,
          focusId
        );
      }
    }
    cancelEntry();
  };

  const cancelEntry = () => {
    setNewName("");
    setNewDomain("");
    setPhaseTMRR(false);
    setPhaseMSA(false);
    setPhaseOS(false);
    setPhasePD(false);
    setPhaseEMD(false);
    setPhasePREMDD(false);
    setNewType("SETR");
    setNewInput(false);
    setNewOutput(false);
    setNewConOwn(false);
    setFocusId(0);
    setFocusOccurr([]);
    setNewProductModal(false);
    setNameError(false);
    setTypeError(false);
    setIntOutError(false);
    setConOwnError(false);
    setDateCreated();
  };

  const deleteEntry = () => {
    let intOut;
    if (newInput && !newOutput) {
      intOut = "Input";
    } else if (!newInput && newOutput) {
      intOut = "Output";
    } else {
      intOut = "";
    }
    deleteProduct(focusId, intOut);
    cancelEntry();
  };

  const closingModal = () => {
    closeProductM();
    setProductSearch("");
  };

  const closeMini = () => {
    setDeleteOption(false);
    cancelEntry();
  };

  const saveProduct = () => {
    if (
      newName.trim() !== "" &&
      // newType !== "" &&
      (newInput || newOutput)
      // &&
      // (newConOwn === "Contributor" || newConOwn === "Owner")
    ) {
      if (focusId === 0) {
        submitProduct("newProduct");
      } else if (focusId !== 0) {
        submitProduct("updateProduct");
      }
    } else {
      if (newName.trim() === "") {
        setNameError(true);
      } else {
        setNameError(false);
      }
      // if (newType === "") {
      //   setTypeError(true);
      // } else {
      //   setTypeError(false);
      // }
      if (!newInput && !newOutput) {
        setIntOutError(true);
      } else {
        setIntOutError(false);
      }
      // if (newConOwn !== "Contributor" && newConOwn !== "Owner") {
      //   setConOwnError(true);
      // } else {
      //   setConOwnError(false);
      // }
    }
  };

  return (
    <div>
      <Modal
        isOpen={productM}
        onRequestClose={closingModal}
        style={customStyles}
      >
        <i
          className="fa fa-times"
          onClick={closingModal}
          style={{
            cursor: "pointer",
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-10px",
          }}
        ></i>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "-20px",
          }}
        >
          <p>Product Library</p>
          <input
            className="productSearchBox"
            value={productSearch}
            onChange={(e) => setProductSearch(e.target.value)}
            style={{
              width: "160px",
              height: "20px",
              alignSelf: "center",
              marginRight: "40px",
              padding: "2px",
              border: "1px solid black",
              paddingLeft: "20px",
              borderRadius: "8px",
            }}
            placeholder="Search Products"
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <p
            onClick={() => setNewProductModal(true)}
            style={{
              marginTop: "-5px",
              paddingBottom: "2px",
              fontSize: "13px",
              color: style.colors.primaryBlue,
              cursor: "pointer",
            }}
          >
            + Add New Product
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            onClick={nameColumn}
            style={{
              // width: "284px",
              width: "23vw",
              minWidth: "246px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {nameAscending === 0
                ? "Name"
                : nameAscending === 1
                ? "Name ↓"
                : "Name ↑"}
            </p>
          </div>
          {/* <div
            onClick={domainColumn}
            style={{
              // width: "154px",
              width: "8vw",
              minWidth: "87px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {domainAscending === 0
                ? "Domain"
                : domainAscending === 1
                ? "Domain ↓"
                : "Domain ↑"}
            </p>
          </div> */}
          {/* <div
            onClick={phaseColumn}
            style={{
              // width: "154px",
              width: "8vw",
              minWidth: "87px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {phaseAscending === 0
                ? "Phase"
                : phaseAscending === 1
                ? "Phase ↓"
                : "Phase ↑"}
            </p>
          </div> */}
          {/* <div
            onClick={typeColumn}
            style={{
              // width: "154px",
              width: "8vw",
              minWidth: "87px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {typeAscending === 0
                ? "Type"
                : typeAscending === 1
                ? "Type ↓"
                : "Type ↑"}
            </p>
          </div> */}
          <div
            onClick={intOutColumn}
            style={{
              // width: "154px",
              width: "8vw",
              minWidth: "87px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: `1px solid ${style.colors.primaryBlue}`,
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {intOutAscending === 0
                ? "Input/Output"
                : intOutAscending === 1
                ? "Input/Output ↓"
                : "Input/Output ↑"}
            </p>
          </div>
          {/* <div
            onClick={conOwnColumn}
            style={{
              // width: "154px",
              width: "8vw",
              minWidth: "87px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: `1px solid ${style.colors.primaryBlue}`,
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {conOwnAscending === 0
                ? "Contributor/Owner"
                : conOwnAscending === 1
                ? "Contributor/Owner ↓"
                : "Contributor/Owner ↑"}
            </p>
          </div> */}
          <div
            // onClick={dateColumn}
            style={{
              // width: "174px",
              width: "50px",
              // minWidth: "120px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: `1px solid ${style.colors.primaryBlue}`,
              borderRight: "1px solid black",
              height: "29px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
          ></div>
        </div>
        <div
          className="modal-body"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {searchFiltered?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor:
                  index % 2 === 0 ? style.colors.lightBlue : "white",
              }}
            >
              <div
                style={{
                  // width: "284px",
                  width: "23vw",
                  minWidth: "246px",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  color: "black",
                  // height: "38px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextProduct responsiveFont"
                  style={{ paddingLeft: "5px" }}
                  title={item.name}
                >
                  {item.name}
                </p>
              </div>
              {/* <div
                style={{
                  // width: "154px",
                  width: "8vw",
                  minWidth: "87px",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  color: "black",
                  // height: "38px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveFont">{item.domainId}</p>
              </div> */}
              {/* <div
                style={{
                  // width: "154px",
                  width: "8vw",
                  minWidth: "87px",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  color: "black",
                  // height: "38px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="overFlowTextProduct responsiveFont">
                  {item.phaseId}
                </p>
              </div> */}
              {/* <div
                style={{
                  // width: "154px",
                  width: "8vw",
                  minWidth: "87px",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  color: "black",
                  // height: "38px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveFont">{item.scope}</p>
              </div> */}
              <div
                style={{
                  // width: "154px",
                  width: "8vw",
                  minWidth: "87px",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  color: "black",
                  // height: "38px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveFont">
                  {item.type === "InputOutput"
                    ? item.type.slice(0, 5) + "/" + item.type.slice(5)
                    : item.type}
                </p>
              </div>
              {/* <div
                style={{
                  // width: "154px",
                  width: "8vw",
                  minWidth: "87px",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  color: "black",
                  // height: "38px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveFont">{item.contributorOwner}</p>
              </div> */}
              <div
                style={{
                  width: "1vw",
                  minWidth: "30px",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  color: "black",
                  // height: "38px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Editsvg
                  className="modalPencil"
                  onClick={() => {
                    setDeleteOption(true);
                    setNewProductModal(true);
                    setFocusId(item.productId);
                    setFocusOccurr(item.occurrences);
                    setNewName(item.name);
                    setNewDomain(item.domainId);
                    let phaseList = item.phaseId
                      ? item.phaseId.length > 0
                        ? item.phaseId.split(", ")
                        : []
                      : [];
                    setPhaseTMRR(phaseList?.includes("TMRR") ? true : false);
                    setPhaseMSA(phaseList?.includes("MSA") ? true : false);
                    setPhaseOS(phaseList?.includes("OS") ? true : false);
                    setPhasePD(phaseList?.includes("PD") ? true : false);
                    setPhaseEMD(phaseList?.includes("EMD") ? true : false);
                    setPhasePREMDD(
                      phaseList?.includes("PRE:MDD") ? true : false
                    );
                    setNewType(item.scope);
                    setNewInput(
                      item.type === "Input" || item.type === "InputOutput"
                        ? true
                        : false
                    );
                    setNewOutput(
                      item.type === "Output" || item.type === "InputOutput"
                        ? true
                        : false
                    );
                    setNewConOwn(item.contributorOwner);
                    setDateCreated(item.dateAdded);
                  }}
                  fill={style.colors.darkBlue}
                  height="2vh"
                  width="2vw"
                  style={{
                    marginLeft: "auto",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </Modal>

      <Modal
        isOpen={newProductModal}
        onRequestClose={closeMini}
        style={customStyles2}
      >
        <div style={{ maxHeight: "420px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          >
            <i
              className="fa fa-times"
              onClick={closeMini}
              style={{
                cursor: "pointer",
                alignSelf: "center",
              }}
            ></i>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                // height: "70px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: nameError ? "red" : "black",
                }}
              >
                Name*:
              </p>
            </div>
            <input
              readOnly={
                dateCreated === 1695280517 || dateCreated === 1695280575
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                // marginTop: "-20px",
              }}
              placeholder="Product name..."
            />
          </div>

          {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "150px",
              borderRight: "2px solid lightgrey",
              justifyContent: "flex-end",
              height: "70px",
            }}
          >
            <p style={{ paddingRight: "10px" }}>Domain:</p>
          </div>
          <div style={{ marginLeft: "10px", marginTop: "4px" }}>
            <input
              type="checkbox"
              id="domain1"
              name="domain"
              value="HSI"
              checked={newDomain === "HSI" ? true : false}
              onClick={domainHSICheck}
            />
            <label>HSI</label> <br></br>
            <input
              type="checkbox"
              id="domain2"
              name="domain"
              value="ESOH"
              checked={newDomain === "ESOH" ? true : false}
              onClick={domainESOHCheck}
            />
            <label>ESOH</label> <br></br>
          </div>
          <div style={{ marginLeft: "10px", marginTop: "4px" }}>
            <input
              type="checkbox"
              id="domain3"
              name="domain"
              value="T&E"
              checked={newDomain === "T&E" ? true : false}
              onClick={domainTECheck}
            />
            <label>T&E</label> <br></br>
            <input
              type="checkbox"
              id="domain4"
              name="domain"
              value="HFE"
              checked={newDomain === "HFE" ? true : false}
              onClick={domainHFECheck}
            />
            <label>HFE</label> <br></br>
          </div>
          <div style={{ marginLeft: "10px", marginTop: "4px" }}>
            <input
              type="checkbox"
              id="domain5"
              name="domain"
              value="MPT"
              checked={newDomain === "MPT" ? true : false}
              onClick={domainMPTCheck}
            />
            <label>MPT</label> <br></br>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "150px",
              borderRight: "2px solid lightgrey",
              justifyContent: "flex-end",
              height: "70px",
            }}
          >
            <p style={{ paddingRight: "10px" }}>Phase:</p>
          </div>
          <div style={{ marginLeft: "10px", marginTop: "-4px" }}>
            <input
              type="checkbox"
              id="phase1"
              name="phase"
              value="TMRR"
              checked={phaseTMRR}
              onClick={phaseTMRRCheck}
            />
            <label>TMRR</label> <br></br>
            <input
              type="checkbox"
              id="phase2"
              name="phase"
              value="MSA"
              checked={phaseMSA}
              onClick={phaseMSACheck}
            />
            <label>MSA</label> <br></br>
            <input
              type="checkbox"
              id="phase3"
              name="phase"
              value="OS"
              checked={phaseOS}
              onClick={phaseOSCheck}
            />
            <label>OS</label> <br></br>
          </div>
          <div style={{ marginLeft: "10px", marginTop: "-4px" }}>
            <input
              type="checkbox"
              id="phase4"
              name="phase"
              value="PD"
              checked={phasePD}
              onClick={phasePDCheck}
            />
            <label>PD</label> <br></br>
            <input
              type="checkbox"
              id="phase5"
              name="phase"
              value="EMD"
              checked={phaseEMD}
              onClick={phaseEMDCheck}
            />
            <label>EMD</label> <br></br>
            <input
              type="checkbox"
              id="phase6"
              name="phaseco"
              value="PRE:MDD"
              checked={phasePREMDD}
              onClick={phasePREMDDCheck}
            />
            <label>PRE:MDD</label> <br></br>
          </div>
        </div> */}

          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "70px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: typeError ? "red" : "black",
                }}
              >
                Type*:
              </p>
            </div>

            <div style={{ marginLeft: "10px", marginTop: "15px" }}>
              <select
                value={newType}
                name="input_output"
                id="input_output"
                style={{ height: "20px", width: "250px" }}
                onChange={(e) => setNewType(e.target.value)}
              >
                <option value="SETR">SETR</option>
                <option value="Guidance">Guidance</option>
                <option value="Specification">Specification</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "70px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: intOutError ? "red" : "black",
                }}
              >
                Input/Output*:
              </p>
            </div>
            <div style={{ marginLeft: "10px", marginTop: "5px" }}>
              <input
                readOnly={
                  dateCreated === 1695280517 || dateCreated === 1695280575
                    ? user?.userRole?.includes("Admin")
                      ? false
                      : true
                    : false
                }
                type="radio"
                id="ip1"
                name="ip1"
                value="Input"
                checked={newInput}
                onClick={inputCheck}
              />
              <label>Input</label> <br></br>
              <input
                readOnly={
                  dateCreated === 1695280517 || dateCreated === 1695280575
                    ? user?.userRole?.includes("Admin")
                      ? false
                      : true
                    : false
                }
                type="radio"
                id="ip2"
                name="ip2"
                value="Output"
                checked={newOutput}
                onClick={outputCheck}
              />
              <label>Output</label> <br></br>
            </div>
          </div>
          <div
            style={{
              display: focusId !== 0 ? "flex" : "none",
              flexDirection: "row",
            }}
          >
            <div>
              <p style={{ fontWeight: "bold" }}>Occurrences in Pathways</p>
              {focusOccurr?.length > 0 ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "29px",
                      borderTop: "1px solid black",
                      borderLeft: "1px solid black",
                    }}
                  >
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "209px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p className="responsiveFont">Pathway</p>
                    </div>
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "100px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        borderLeft: "1px solid white",
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p className="responsiveFont">Phase</p>
                    </div>
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "107px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        borderLeft: "1px solid white",
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRight: "1px solid black",
                      }}
                    >
                      <p className="responsiveFont">Domain</p>
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid black",
                      paddingTop: ".75px",
                    }}
                  >
                    {focusOccurr?.map((occur, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor:
                            index % 2 === 0 ? style.colors.lightBlue : "white",
                        }}
                      >
                        <div
                          style={{
                            width: "209px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="responsiveFont"
                            style={{ paddingLeft: "5px" }}
                          >
                            {occur.pathwayName}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="responsiveFont"
                            style={{ paddingLeft: "5px" }}
                          >
                            {occur.phaseName}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="responsiveFont"
                            style={{ paddingLeft: "5px" }}
                          >
                            {occur.domainName}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p
                  style={{
                    width: "400px",
                  }}
                >
                  No occurrences since the product is currently not linked to
                  any activities.
                </p>
              )}
            </div>
          </div>

          <div
            style={{
              display:
                dateCreated === 1695280517 || dateCreated === 1695280575
                  ? user?.userRole?.includes("Admin")
                    ? "flex"
                    : "none"
                  : "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 20,
              marginTop: "10px",
              paddingBottom: focusOccurr?.length > 0 ? "20px" : 0,
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: deleteOption ? "pointer" : "default",
                opacity: deleteOption ? 1 : 0,
              }}
              onClick={() => {
                if (deleteOption) setDeleteModal(true);
              }}
            >
              Delete
            </button>
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={cancelEntry}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={saveProduct}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <DeleteLibraryItemModal
        itemTitle={newName}
        itemLibrary="Product"
        itemCancel={cancelEntry}
        itemDelete={deleteEntry}
        deleteM={deleteModal}
        setDeleteModal={setDeleteModal}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  productLibrary: state.data.productLibrary,
  style: state.local.style,
  navSearch: state.data.navSearch,
  user: state.user.user,
});

export default connect(mapStateToProps, {
  getProductLibrary,
  addNewProduct,
  updateProduct,
  deleteProduct,
})(ProductLibraryModal) as any;
