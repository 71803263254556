import React from "react";
import { connect } from "react-redux";
import "./deleteModal.css";
import Modal from "react-modal";
import { deleteProject, saveDeleteProject } from "../../redux/actions/Projects";

const DeleteModal: React.FC<{
  deleteProject: (project: any) => void;
  saveDeleteProject: (project: any) => void;
  project: any;
  style: any;
}> = ({ deleteProject, saveDeleteProject, project, style }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
  };

  const closeUp = () => {
    deleteProject(null);
  };

  const deleteUp = () => {
    // deleteProject(null);
    // saveDeleteProject(project);
  };

  return (
    <Modal
      isOpen={project?.hasOwnProperty("projectId")}
      onRequestClose={closeUp}
      style={customStyles}
    >
      <p style={{ paddingBottom: "1px", textAlign: "center" }}>
        You are about to delete:
      </p>

      <div style={{ paddingBottom: "2px", textAlign: "center" }}>
        <p style={{ paddingBottom: "2px", color: "red" }}>{closeUp}</p>
        <p>This is a shared project. Deleting this project will delete the</p>
        <p
          style={{
            paddingBottom: "2px",
            marginTop: "-15px",
          }}
        >
          project for all collaborators.
        </p>
        <p style={{ paddingBottom: "2px" }}>
          Are you sure you would like to continue?
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: 20,
          marginTop: "10px",
        }}
      >
        <button
          style={{
            backgroundColor: "grey",
            width: "100px",
            height: "30px",
            color: style.colors.white,
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={closeUp}
        >
          Cancel
        </button>
        <button
          style={{
            backgroundColor: style.colors.primaryBlue,
            width: "100px",
            height: "30px",
            color: style.colors.white,
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={deleteUp}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  project: state.projects.deleteProject,
  style: state.local.style,
});

export default connect(mapStateToProps, { deleteProject, saveDeleteProject })(
  DeleteModal
) as any;
