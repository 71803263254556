import axios from "axios";
import config from "../../config.json";
import { GET_USER, AUTH_LOADING, GET_USER_TYPE } from "./types";

const getUser = () => async (dispatch: any) => {
  try {
    const url = `${config.api}user/get_user`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const getUserType = () => async (dispatch: any) => {
  try {
    const url = `${config.api}user/get_user_type`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_USER_TYPE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const authLoading = (loading: boolean) => async (dispatch: any) => {
  try {
    dispatch({
      type: AUTH_LOADING,
      payload: loading,
    });
  } catch (error) {
    console.log(error);
  }
};

const searchUsers = async (value: string) => {
  try {
    const url = `${config.api}user/search_users`;
    const res = await axios.post(
      url,
      { value },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const dowloadPdf = async () => {
  try {
    const url = `${config.api}user/download_pdf`;
    const res = await axios.post(
      url,
      {},
      {
        responseType: "blob",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const file = new Blob([res.data], { type: "application/pdf" });

    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(file);

    link.download = "QRG.pdf";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log(error);
  }
};

const dowloadManual = async () => {
  try {
    const url = `${config.api}user/download_manual`;
    const res = await axios.post(
      url,
      {},
      {
        responseType: "blob",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const file = new Blob([res.data], { type: "application/pdf" });

    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(file);

    link.download = "MANUAL.pdf";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log(error);
  }
};

export {
  getUser,
  authLoading,
  getUserType,
  searchUsers,
  dowloadPdf,
  dowloadManual,
};
