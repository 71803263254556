import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./feedbackModal.css";
import { addFeedback } from "../../redux/actions/Data";

const AcronymModal: React.FC<{
  closeFeedback: any;
  feedback: any;
  style: any;
  user: any;
  addFeedback: (
    userID: string,
    name: string,
    email: string,
    locationType: string,
    text: string,
    feedbackID: string
  ) => void;
  feedbackLibrary: any;
}> = ({
  closeFeedback,
  feedback,
  style,
  user,
  addFeedback,
  feedbackLibrary,
}) => {
  const [textInput, setTextInput] = useState("") as any;
  const [locationState, setLocationState] = useState("") as any;
  const [descriptionError, setDescriptionError] = useState(false) as any;
  const [locationError, setLocationError] = useState(false) as any;

  const closingFeedback = () => {
    setDescriptionError(false);
    setLocationError(false);
    closeFeedback();
    setTextInput("");
    setLocationState("");
  };

  const submitFeedback = () => {
    if (textInput !== "" && locationState !== "") {
      addFeedback(
        user.oid,
        user.name === null ? "No Valid Name" : user.name,
        user.username === null ? "email" : user.username,
        locationState,
        textInput,
        (feedbackLibrary.length + 1).toString()
      );
      closingFeedback();
      showFeedbackToast();
    } else {
      if (textInput === "") {
        setDescriptionError(true);
      } else {
        setDescriptionError(false);
      }
      if (locationState === "") {
        setLocationError(true);
      } else {
        setLocationError(false);
      }
    }
  };

  return (
    <div style={{ visibility: feedback ? "initial" : "hidden" }}>
      <div className="modal-container">
        <section className="projectModals">
          <p style={{ fontSize: 20, fontWeight: "bold" }}>Submit Feedback</p>
          <p
            style={{
              fontWeight: "bold",
              color: locationError ? "red" : "black",
            }}
          >
            Location in HSI Framework*
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "1vw",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "1px",
              }}
            >
              <input
                type="radio"
                value="Framework"
                name="feedback"
                onClick={() => setLocationState("Framework")}
                checked={locationState === "Framework" ? true : false}
                className="feedbackRadios"
              />
              <span
                onClick={() => setLocationState("Framework")}
                style={{ cursor: "default" }}
              >
                HSI Framework Model/Project
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "9px",
              }}
            >
              <input
                type="radio"
                value="Dashboard"
                name="feedback"
                onClick={() => setLocationState("Dashboard")}
                checked={locationState === "Dashboard" ? true : false}
                className="feedbackRadios"
              />
              <span
                onClick={() => setLocationState("Dashboard")}
                style={{ cursor: "default" }}
              >
                Dashboard
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "9px",
              }}
            >
              <input
                type="radio"
                value="Reference"
                name="feedback"
                onClick={() => setLocationState("Reference")}
                checked={locationState === "Reference" ? true : false}
                className="feedbackRadios"
              />
              <span
                onClick={() => setLocationState("Reference")}
                style={{ cursor: "default" }}
              >
                Reference Library
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "9px",
              }}
            >
              <input
                type="radio"
                value="Product"
                name="feedback"
                onClick={() => setLocationState("Product")}
                checked={locationState === "Product" ? true : false}
                className="feedbackRadios"
              />
              <span
                onClick={() => setLocationState("Product")}
                style={{ cursor: "default" }}
              >
                Product Library
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "9px",
              }}
            >
              <input
                type="radio"
                value="Acronym"
                name="feedback"
                onClick={() => setLocationState("Acronym")}
                checked={locationState === "Acronym" ? true : false}
                className="feedbackRadios"
              />
              <span
                onClick={() => setLocationState("Acronym")}
                style={{ cursor: "default" }}
              >
                Acronym Library
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "9px",
              }}
            >
              <input
                type="radio"
                value="Reports"
                name="feedback"
                onClick={() => setLocationState("Reports")}
                checked={locationState === "Reports" ? true : false}
                className="feedbackRadios"
              />
              <span
                onClick={() => setLocationState("Reports")}
                style={{ cursor: "default" }}
              >
                Reports
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "9px",
              }}
            >
              <input
                type="radio"
                value="Other"
                name="feedback"
                onClick={() => setLocationState("Other")}
                checked={locationState === "Other" ? true : false}
                className="feedbackRadios"
              />
              <span
                onClick={() => setLocationState("Other")}
                style={{ cursor: "default" }}
              >
                Other
              </span>
            </div>
          </div>

          <div style={{ paddingBottom: "1px" }}>
            <p
              style={{
                fontWeight: "bold",
                color: descriptionError ? "red" : "black",
              }}
            >
              Description*
            </p>
            <textarea
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              className="descInputs"
              style={{
                fontFamily: "OpenSans-Regular",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 20,
              marginTop: "10px",
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={closingFeedback}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={submitFeedback}
            >
              Submit
            </button>
          </div>
        </section>
      </div>
    </div>
  );

  function showFeedbackToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar");
    if (element[0]) {
      element[0].className = "snackbar show";
      setTimeout(function () {
        element[0].className = "snackbar";
      }, 5000);
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  user: state.user.user,
  feedbackLibrary: state.data.feedbackLibrary,
});

export default connect(mapStateToProps, {
  addFeedback,
})(AcronymModal) as any;
