import React from "react";
import { connect } from "react-redux";
import Navbar from "../../../components/navbar/navbar";
import "./SideMenu.css";
import { MdClose } from "react-icons/md";

const SideMenu: React.FC<{
  sideMenu: any;
  setSideMenu: any;
  activeProjectPhase: any;
  style: any;
  productLibrary: any;
}> = ({ sideMenu, setSideMenu, activeProjectPhase, style, productLibrary }) => {
  return (
    <div
      id="mySidenav"
      className="sidenav"
      style={{
        width: "35vw",
        right: sideMenu ? 0 : `-35vw`,
        zIndex: 8,
        borderLeft: sideMenu ? "2px solid black" : "none",
        borderTop: sideMenu ? "2px solid black" : "none",
        // marginTop: "40px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: style.colors.primaryBlue,
          paddingRight: "10px",
          paddingLeft: "10px",
          height: "51px",
        }}
      >
        <p style={{ marginLeft: "auto", color: "black", fontWeight: "bold" }}>
          {activeProjectPhase ? activeProjectPhase?.phaseFullName : ""}
        </p>
        <MdClose
          onClick={() => setSideMenu(false)}
          style={{
            color: "black",
            fontSize: 20,
            cursor: "pointer",
            marginLeft: "auto",
            justifyContent: "center",
            alignSelf: "center",
          }}
        />
      </div>

      <div
        className="CancelSaveSectionBuffer"
        style={{
          marginLeft: "50px",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Description</p>
        <p
          style={{
            marginBottom: "5px",
            fontSize: "14px",
          }}
        >
          {activeProjectPhase ? activeProjectPhase.phaseDescription : null}
        </p>

        <p style={{ fontWeight: "bold" }}>
          Products at{" "}
          {activeProjectPhase ? activeProjectPhase.phaseShortName : null}
        </p>
        <ul style={{ marginLeft: "-17px" }}>
          {activeProjectPhase
            ? productLibrary.map((products) => {
                if (products.phaseId) {
                  if (products.phaseId.length > 0) {
                    if (
                      products.phaseId.includes(
                        activeProjectPhase.phaseShortName
                      )
                    )
                      return (
                        <li style={{ fontSize: "14px", margin: "5px" }}>
                          {products.name}
                        </li>
                      );
                  }
                }
              })
            : null}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  activeProjectPhase: state.projects.activeProjectPhase,
  style: state.local.style,
  productLibrary: state.data.productLibrary,
});

export default connect(mapStateToProps, {})(SideMenu) as any;
