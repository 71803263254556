import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./RightClickMenu.css";

const RightClickMenu: React.FC<{
  style: any;
  topPoint: any;
  leftPoint: any;
  openActivityM: any;
  clearMenu: any;
}> = ({ style, topPoint, leftPoint, openActivityM, clearMenu }) => {
  const navigate = useNavigate();

  return (
    <div
      onMouseLeave={clearMenu}
      className="rightMenu"
      style={{
        position: "absolute",
        backgroundColor: "#e1e2e1",
        minWidth: "150px",
        boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
        zIndex: 8,
        top: topPoint,
        left: leftPoint,
      }}
    >
      <div onClick={openActivityM}>Add New Activity</div>
      <div>Reset Layout</div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({ style: state.local.style });

export default connect(mapStateToProps, {})(RightClickMenu) as any;
