export const shortDescriptions = [
  "The UCA pathway fields capabilities to fulfill urgent existing and/or emerging operational needs or quick reactions in less than 2 years.",
  "The MTA pathway rapidly develops fieldable prototypes within an acquisition program to demonstrate new capabilities and/or rapidly field production quantities of systems with proven technologies that require minimal development.",
  "The MCA pathway acquires and modernizes military unique programs that provide enduring capability.",
  "The SW pathway facilitates rapid and iterative delivery of software capability (e.g., software-intensive systems and/or software-intensive components or sub-systems) to the user.",
  "The DBS pathway acquires existing commercial or government systems that could be adopted to satisfy DoD needs.",
  "The Acquisition of Services pathway acquires services, researches potential contractors, contracts for the services, and manages performance from the private sector.",
];

export const longDescription = [
  "The Urgent Capability Acquisition (UCA) pathway quickly fulfills urgent existing and/or emerging operational needs in less than 2 years. The goal is to plan for the capability in a few weeks, develop and produce in months, and aggressively streamline sustainment processes and reviews due to operational urgency.",
  "The Middle Tier Acquisition (MTA) pathway rapidly develops fieldable prototypes within an acquisition program to demonstrate new capabilities and/or rapidly field systems with proven technologies that require minimal development. Production should begin within 6 months and fielding should begin within 5 years of the MTA program start date.",
  "The Major Capability Acquisition (MCA) pathway supports the major defense acquisition programs, major systems, and other complex acquisitions. These acquisitions typically follow a structured analyze, design, develop, integrate, test, evaluate, produce, and support approach.",
  "The Software Acquisition (SW) pathway integrates modern software development practice such as Agile Software Development, Development, Security, and Operations (DevSecOps), and Lean Practices. Government-industry teams leverage automated tools for iterative development, builds, integration, testing, production, certification, and deployment of capabilities to the operational environment.",
  "The Defense Business Systems (DBS) pathway identifies and acquires existing commercial or government solutions that have been successfully demonstrated in the commercial marketplace and could be adopted to satisfy DoD needs.",
  "The Acquisition of Services pathway identifies required services, research potential contractors, contracts for the services, and manages performance from the private sector.",
];
