import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./toolsModal.css";
import Modal from "react-modal";
import {
  getTools,
  addNewAcronym,
  deleteAcronym,
  updateAcronym,
} from "../../redux/actions/Data";
import { toolSort } from "../../lib/helpers/columnFilters";
import { ReactComponent as Editsvg } from "../../lib/assets/img/Edit.svg";

const ToolsModal: React.FC<{
  closeToolsM: any;
  toolsM: any;
  toolsLibrary: any;
  getTools: () => void;
  style: any;
  // addNewAcronym: (acronym: string, meaning: string) => void;
  // deleteAcronym: (id: number) => void;
  // updateAcronym: (id: number, acronym: string, meaning: string) => void;
  navSearch: string;
}> = ({
  closeToolsM,
  toolsM,
  toolsLibrary,
  getTools,
  style,
  // addNewAcronym,
  // deleteAcronym,
  // updateAcronym,
  navSearch,
}) => {
  const [toolSearch, setToolSearch] = useState("") as any;
  const [searchFiltered, setSearchFiltered] = useState([]) as any;
  const [nameAscending, setNameAscending] = useState(1) as any;
  const [meaningAscending, setMeaningAscending] = useState(0) as any;
  const [dateAscending, setDateAscending] = useState(0) as any;
  const [currentSort, setCurrentSort] = useState("name") as any;

  const customStyles = {
    content: {
      top: "40%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
  };

  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
    overlay: {
      background: "none",
    },
  };

  useEffect(() => {
    getTools();
  }, [getTools]);

  useEffect(() => {
    setToolSearch(navSearch);
  }, [navSearch]);

  useEffect(() => {
    let toolsList = toolsLibrary.filter((x) => !x.hasOwnProperty("init_id"));

    if (toolSearch !== "" && toolsLibrary?.length > 0) {
      toolsList = toolsList.filter(
        (item: any) =>
          item?.name?.toLowerCase().indexOf(toolSearch.toLowerCase()) > -1 ||
          item?.description?.toLowerCase().indexOf(toolSearch.toLowerCase()) >
            -1
      );
    }

    toolsList = toolSort(
      toolsList,
      nameAscending,
      meaningAscending,
      dateAscending
    );

    setSearchFiltered(toolsList);
  }, [
    toolSearch,
    toolsLibrary,
    nameAscending,
    meaningAscending,
    dateAscending,
  ]);

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const nameColumn = () => {
    setCurrentSort(currentSort !== "acronym" ? "acronym" : currentSort);
    setMeaningAscending(0);
    nameAscending === 1 ? setNameAscending(2) : setNameAscending(1);
  };

  const meaningColumn = () => {
    setCurrentSort(currentSort !== "meaining" ? "meaining" : currentSort);
    setNameAscending(0);
    meaningAscending === 1 ? setMeaningAscending(2) : setMeaningAscending(1);
  };

  const closingModal = () => {
    closeToolsM();
    setToolSearch("");
  };

  // const cancelAcronym = () => {
  //   setNewToolsModal(false);
  //   setToolError(false);
  //   setMeaningError(false);
  //   setNewToolName("");
  //   setNewMeaning("");
  // };

  // const saveAcronym = () => {
  //   if (newToolName !== "" && newMeaning !== "") {
  //     if (focusId === 0) {
  //       setNameAscending(0);
  //       setMeaningAscending(0);
  //       setDateAscending(0);
  //       addNewAcronym(newToolName, newMeaning);
  //       setNewToolsModal(false);
  //       setFocusId(0);
  //       setNewToolName("");
  //       setNewMeaning("");
  //       setToolError(false);
  //       setMeaningError(false);
  //     } else if (focusId !== 0) {
  //       setNameAscending(0);
  //       setMeaningAscending(0);
  //       setDateAscending(0);
  //       updateAcronym(focusId, newToolName, newMeaning);
  //       setNewToolsModal(false);
  //       setFocusId(0);
  //       setNewToolName("");
  //       setNewMeaning("");
  //       setToolError(false);
  //       setMeaningError(false);
  //     }
  //   } else {
  //     if (newToolName === "") {
  //       setToolError(true);
  //     } else {
  //       setToolError(false);
  //     }
  //     if (newMeaning === "") {
  //       setMeaningError(true);
  //     } else {
  //       setMeaningError(false);
  //     }
  //   }
  // };

  // const deleteAcronymAction = () => {
  //   deleteAcronym(focusId);
  //   setNewToolsModal(false);
  //   setFocusId(0);
  //   setNewToolName("");
  //   setNewMeaning("");
  //   setToolError(false);
  //   setMeaningError(false);
  // };

  // const closeMini = () => {
  //   setDeleteOption(false);
  //   setFocusId(0);
  //   setNewToolName("");
  //   setNewMeaning("");
  //   cancelAcronym();
  // };

  return (
    <div>
      <Modal isOpen={toolsM} onRequestClose={closingModal} style={customStyles}>
        <i
          className="fa fa-times"
          onClick={closingModal}
          style={{
            cursor: "pointer",
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-10px",
          }}
        ></i>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "-20px",
          }}
        >
          <p>Tools Library</p>
          <input
            className="toolSearchBox"
            value={toolSearch}
            onChange={(e) => setToolSearch(e.target.value)}
            style={{
              width: "160px",
              height: "20px",
              alignSelf: "center",
              marginRight: "40px",
              padding: "2px",
              border: "1px solid black",
              paddingLeft: "20px",
              borderRadius: "8px",
            }}
            placeholder="Search Tools"
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <p
            onClick={() =>
              window.open(
                "https://wss.apan.org/osd/HSI-BOKM/Lists/tblHSI_TTAMs_full/AllItems.aspx",
                "_blank"
              )
            }
            style={{
              marginTop: "-5px",
              paddingBottom: "2px",
              fontSize: "13px",
              color: style.colors.primaryBlue,
              cursor: "pointer",
            }}
          >
            Link to Tools List in APAN
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            onClick={nameColumn}
            style={{
              // width: "192px",
              width: "15vw",
              minWidth: "106px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {nameAscending === 0
                ? "Name"
                : nameAscending === 1
                ? "Name ↓"
                : "Name ↑"}
            </p>
          </div>
          <div
            onClick={meaningColumn}
            style={{
              // width: "518px",
              width: "35vw",
              minWidth: "288px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "1px solid white",
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {meaningAscending === 0
                ? "Description"
                : meaningAscending === 1
                ? "Description ↓"
                : "Description ↑"}
            </p>
          </div>
          <div
            // onClick={dateColumn}
            style={{
              // width: "172px",
              width: "13vw",
              minWidth: "133px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "29px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              borderRight: "1px solid black",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont" style={{ marginLeft: "15px" }}>
              {dateAscending === 0
                ? "HSI Activity(ies)"
                : dateAscending === 1
                ? "HSI Activity(ies) ↓"
                : "HSI Activity(ies) ↑"}
            </p>
          </div>
        </div>
        <div
          className="modal-body"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {searchFiltered?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor:
                  index % 2 === 0 ? style.colors.lightBlue : "white",
                minWidth: "139px",
              }}
            >
              <div
                style={{
                  // width: "192px",
                  width: "15vw",
                  minWidth: "106px",
                  borderLeft: "none",
                  borderRight: "none",
                  color: "black",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextTools responsiveFont"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    textAlign: "center",
                  }}
                >
                  {item.name}
                </p>
              </div>
              <div
                title={item.description}
                style={{
                  // width: "518px",
                  width: "35vw",
                  minWidth: "288px",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  color: "black",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextTools responsiveFont"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  {item.description}
                </p>
              </div>
              <div
                style={{
                  // width: "153px",
                  width: "11vw",
                  minWidth: "117px",
                  borderLeft: "none",
                  borderRight: "none",
                  color: "black",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextTools responsiveFont"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    textAlign: "center",
                  }}
                  //  style={{ marginLeft: "16px" }}
                >
                  {item.activities}
                  {/* {new Date(item.dateAdded * 1000).getDate() < 10 ? "0" : ""}
                  {new Date(item.dateAdded * 1000).getDate() +
                    " " +
                    months[new Date(item.dateAdded * 1000).getMonth()] +
                    " " +
                    new Date(item.dateAdded * 1000).getFullYear()} */}
                </p>
                {/* <Editsvg
                  onClick={() => {
                    setDeleteOption(true);
                    setNewToolsModal(true);
                    setFocusId(item.acronymId);
                    setNewToolName(item.acronymName);
                    setNewMeaning(item.definition);
                  }}
                  fill="rgb(18, 18, 201)"
                  height="1.5vh"
                  width="1.5vw"
                  style={{
                    marginLeft: "auto",
                    cursor: "pointer",
                  }}
                /> */}
              </div>
            </div>
          ))}
        </div>
      </Modal>

      {/* <Modal
        isOpen={newToolsModal}
        onRequestClose={closeMini}
        style={customStyles2}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-10px",
          }}
        >
          <i
            className="fa fa-times"
            onClick={closeMini}
            style={{
              cursor: "pointer",
              alignSelf: "center",
            }}
          ></i>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "150px",
              borderRight: "2px solid lightgrey",
              justifyContent: "flex-end",
              height: "70px",
            }}
          >
            <p
              style={{
                paddingRight: "10px",
                color: toolError ? "red" : "black",
              }}
            >
              Acronym*:
            </p>
          </div>
          <input
            value={newToolName}
            onChange={(e) => setNewToolName(e.target.value)}
            style={{
              width: "240px",
              height: "20px",
              cursor: "default",
              alignSelf: "center",
              marginLeft: "10px",
              marginTop: "-20px",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "150px",
              borderRight: "2px solid lightgrey",
              justifyContent: "flex-end",
              height: "70px",
            }}
          >
            <p
              style={{
                paddingRight: "10px",
                color: meaningError ? "red" : "black",
              }}
            >
              Meaning*:
            </p>
          </div>
          <input
            value={newMeaning}
            onChange={(e) => setNewMeaning(e.target.value)}
            style={{
              width: "240px",
              height: "20px",
              cursor: "default",
              alignSelf: "center",
              marginLeft: "10px",
              marginTop: "-20px",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 20,
            marginTop: "10px",
          }}
        >
          <button
            style={{
              backgroundColor: "grey",
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: deleteOption ? "pointer" : "default",
              opacity: deleteOption ? 1 : 0,
            }}
            onClick={deleteAcronymAction}
          >
            Delete
          </button>
          <button
            style={{
              backgroundColor: "grey",
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={cancelAcronym}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: style.colors.primaryBlue,
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={saveAcronym}
          >
            Save
          </button>
        </div>
      </Modal> */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  toolsLibrary: state.data.toolsLibrary,
  style: state.local.style,
  navSearch: state.data.navSearch,
});

export default connect(mapStateToProps, {
  getTools,
  addNewAcronym,
  deleteAcronym,
  updateAcronym,
})(ToolsModal) as any;
