import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../../components/navbar/navbar";
import "./StepTwo.css";
import { useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import FeedbackModal from "../../components/feedbackModal/feedbackModal";
import NewProjectModal from "../../components/newProjectModal/newProjectModal";
import AcronymModal from "../../components/acronymModal/acronymModal";
import ProductLibraryModal from "../../components/productLibraryModal/productLibraryModal";
import ReferenceLibraryModal from "../../components/referenceLibraryModal/referenceLibraryModal";
import DidsModal from "../../components/didsModal/didsModal";
import ToolsModal from "../../components/toolsModal/toolsModal";
import { setNavSearch, setFilterBox } from "../../redux/actions/Data";
import {
  editReportCategories,
  getContentReport,
  getCustomActivityReport,
  getCustomLibraryReport,
  getRelatedItemsReport,
  getRiskReport,
  getStatusReport,
  resetReportCategories,
  setProjectToReport,
} from "../../redux/actions/Projects";
import ReportCategories from "./ReportCategories";
import { ReactFlowProvider } from "reactflow";
import { modelPhases, modelDomains } from "../../lib/data/modelInfo";

const Project: React.FC<{
  style: any;
  setNavSearch: (text: string) => void;
  setFilterBox: (box: boolean) => void;
  user: any;
  navSearch: string;
  editReportCategories: (reportSettings: object) => void;
  userReportType: string;
  userReportObject: any;
  getRiskReport: (
    phase: any,
    domain: any,
    risk: any,
    startDate: string,
    endDate: string,
    id: string
  ) => void;
  getStatusReport: (
    phase: any,
    domain: any,
    status: any,
    startDueDate: string,
    endDueDate: string,
    startUpdateDate: string,
    endUpdateDate: string,
    id: string
  ) => void;
  getCustomActivityReport: (
    phase: any,
    domain: any,
    status: any,
    startDueDate: string,
    endDueDate: string,
    startUpdateDate: string,
    endUpdateDate: string,
    notes: any,
    management: any,
    risk: any,
    riskStartDate: string,
    riskEndDate: string,
    id: string
  ) => void;
  getCustomLibraryReport: (
    phase: any,
    domain: any,
    status: any,
    startDueDate: string,
    endDueDate: string,
    startUpdateDate: string,
    endUpdateDate: string,
    management: any,
    risk: any,
    riskStartDate: string,
    riskEndDate: string,
    id: string
  ) => void;
  getRelatedItemsReport: (
    phase: any,
    domain: any,
    content: any,
    id: string
  ) => void;
  getContentReport: (
    phase: any,
    domain: any,
    content: any,
    addedStartDate: string,
    addedEndDate: string,
    modifiedStartDate: string,
    modifiedEndDate: string,
    deletedStartDate: string,
    deletedEndDate: string,
    id: string
  ) => void;
  setProjectToReport: (project: any) => void;
  resetReportCategories: () => void;
}> = ({
  style,
  setNavSearch,
  setFilterBox,
  user,
  navSearch,
  editReportCategories,
  userReportType,
  userReportObject,
  getRiskReport,
  getStatusReport,
  getCustomActivityReport,
  getCustomLibraryReport,
  getRelatedItemsReport,
  getContentReport,
  setProjectToReport,
  resetReportCategories,
}) => {
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState(false) as any;
  const [settingMenu, setSettingMenu] = useState(false) as any;
  const [helpMenu, setHelpMenu] = useState(false) as any;
  const [newProjectM, setNewProjectM] = useState(false) as any;
  const [acronymM, setAcronymM] = useState(false) as any;
  const [productM, setProductM] = useState(false) as any;
  const [referenceM, setReferenceM] = useState(false) as any;
  const [didsM, setDidsM] = useState(false) as any;
  const [toolsM, setToolsM] = useState(false) as any;
  const [allPhase, setAllPhase] = useState(false) as any;
  const [phaseList, setPhaseList] = useState(
    userReportObject?.phaseReportList
  ) as any;
  const [allDomain, setAllDomain] = useState(false) as any;
  const [domainList, setDomainList] = useState(
    userReportObject?.domainReportList
  ) as any;
  const [allRisk, setAllRisk] = useState(false) as any;
  const [riskList, setRiskList] = useState(
    userReportObject?.riskReportList
  ) as any;
  const [allActivity, setAllActivity] = useState(false) as any;
  const [activityList, setActivityList] = useState(
    userReportObject?.activityStatusList
  ) as any;
  const [allNotes, setAllNotes] = useState(false) as any;
  const [notesList, setNotesList] = useState(
    userReportObject?.activityNotesList
  ) as any;
  const [allContent, setAllContent] = useState(false) as any;
  const [contentList, setContentList] = useState(
    userReportObject?.contentList
  ) as any;
  const [allManagement, setAllManagement] = useState(false) as any;
  const [managementList, setManagementList] = useState(
    userReportObject?.hsiManagementList
  ) as any;
  const [riskItemStartDate, setRiskItemStartDate] = useState(
    userReportObject?.riskStartDate
  ) as any;
  const [riskItemEndDate, setRiskItemEndDate] = useState(
    userReportObject?.riskEndDate
  ) as any;
  const [statusItemStartDate, setStatusItemStartDate] = useState(
    userReportObject?.statusStartDate
  ) as any;
  const [statusItemEndDate, setStatusItemEndDate] = useState(
    userReportObject.statusEndDate
  ) as any;
  const [updateItemStartDate, setUpdateItemStartDate] = useState(
    userReportObject?.updateStartDate
  ) as any;
  const [updateItemEndDate, setUpdateItemEndDate] = useState(
    userReportObject?.updateEndDate
  ) as any;
  const [addedStartDate, setAddedStartDate] = useState(
    userReportObject?.addedStartDate
  ) as any;
  const [addedEndDate, setAddedEndDate] = useState(
    userReportObject?.addedEndDate
  ) as any;
  const [modifiedStartDate, setModifiedStartDate] = useState(
    userReportObject?.modifiedStartDate
  ) as any;
  const [modifiedEndDate, setModifiedEndDate] = useState(
    userReportObject?.modifiedEndDate
  ) as any;
  const [deletedStartDate, setDeletedStartDate] = useState(
    userReportObject?.deletedStartDate
  ) as any;
  const [deletedEndDate, setDeletedEndDate] = useState(
    userReportObject?.deletedEndDate
  ) as any;
  const [riskDateError, setRiskDateError] = useState(false) as any;
  const [statusDateError, setStatusDateError] = useState(false) as any;
  const [updateDateError, setUpdateDateError] = useState(false) as any;
  const [addedDateError, setAddedDateError] = useState(false) as any;
  const [modifiedDateError, setModifiedDateError] = useState(false) as any;
  const [deletedDateError, setDeletedDateError] = useState(false) as any;
  const [productOwnership, setProductOwnership] = useState(false) as any;
  const [contentProduct, setContentProduct] = useState(false) as any;

  const getPhaseLength = () => {
    let phaseObject = modelPhases.filter(
      (phases) => userReportObject?.selectedProject?.modelId === phases.modelId
    );
    return phaseObject[0].phaseShortNames.length;
  };

  const getDomainLength = () => {
    return userReportObject?.selectedProject?.modelId === "3"
      ? modelDomains[1].domainShortNames.length
      : modelDomains[0].domainShortNames.length;
  };

  useEffect(() => {
    setNavSearch("");
    setAllPhase(false);
    setAllDomain(false);
    setAllRisk(false);
    setAllActivity(false);
    setAllNotes(false);
    setAllContent(false);
    setAllManagement(false);
    if (userReportObject.phaseReportList) {
      if (userReportObject.phaseReportList.length === getPhaseLength())
        setAllPhase(true);
    }
    if (userReportObject.domainReportList) {
      if (userReportObject.domainReportList.length === getDomainLength())
        setAllDomain(true);
    }
    if (userReportObject.riskReportList) {
      if (userReportObject.riskReportList.length === 6) setAllRisk(true);
    }
    if (userReportObject.activityStatusList) {
      if (userReportObject.activityStatusList.length === 7)
        setAllActivity(true);
    }
    if (userReportObject.activityNotesList) {
      if (userReportObject.activityNotesList.length === 3) setAllNotes(true);
    }

    if (userReportObject.contentList?.includes("Inputs")) {
      if (userReportObject.contentList?.includes("Outputs")) {
        setContentProduct(true);
      }
    }

    if (userReportObject.userReportType === "Custom Activities Report") {
      if (userReportObject.hsiManagementList.length === 5)
        setAllManagement(true);
    } else if (userReportObject.userReportType === "Custom Libraries Report") {
      if (userReportObject.hsiManagementList.length === 4)
        setAllManagement(true);
    }

    if (userReportObject?.riskStartDate?.length > 0) {
      setRiskItemStartDate(userReportObject?.riskStartDate);
    }
    if (userReportObject?.riskEndDate?.length > 0) {
      setRiskItemEndDate(userReportObject?.riskEndDate);
    }
    if (userReportObject?.statusStartDate?.length > 0) {
      setStatusItemStartDate(userReportObject?.statusStartDate);
    }
    if (userReportObject?.statusEndDate?.length > 0) {
      setStatusItemEndDate(userReportObject?.statusEndDate);
    }
    if (userReportObject?.updateStartDate?.length > 0) {
      setUpdateItemStartDate(userReportObject?.updateStartDate);
    }
    if (userReportObject?.updateEndDate?.length > 0) {
      setUpdateItemEndDate(userReportObject?.updateEndDate);
    }
    if (userReportObject?.addedStartDate?.length > 0) {
      setAddedStartDate(userReportObject?.addedStartDate);
    }
    if (userReportObject?.addedEndDate?.length > 0) {
      setAddedEndDate(userReportObject?.addedEndDate);
    }
    if (userReportObject?.modifiedStartDate?.length > 0) {
      setModifiedStartDate(userReportObject?.modifiedStartDate);
    }
    if (userReportObject?.modifiedEndDate?.length > 0) {
      setModifiedEndDate(userReportObject?.modifiedEndDate);
    }
    if (userReportObject?.deletedStartDate?.length > 0) {
      setDeletedStartDate(userReportObject?.deletedStartDate);
    }
    if (userReportObject?.deletedEndDate?.length > 0) {
      setDeletedEndDate(userReportObject?.deletedEndDate);
    }
  }, [
    userReportObject.phaseReportList,
    userReportObject.domainReportList,
    userReportObject.riskReportList,
    userReportObject.activityStatusList,
    userReportObject.activityNotesList,
    userReportObject.contentList,
    userReportObject.userReportType,
    userReportObject.hsiManagementList,
  ]);

  // useEffect(() => {
  //   setRiskItemStartDate("");
  //   setRiskItemEndDate("");
  //   setStatusItemStartDate("");
  //   setStatusItemEndDate("");
  //   setUpdateItemStartDate("");
  //   setUpdateItemEndDate("");
  //   setAddedStartDate("");
  //   setAddedEndDate("");
  //   setModifiedStartDate("");
  //   setModifiedEndDate("");
  //   setDeletedStartDate("");
  //   setDeletedEndDate("");
  // }, [userReportObject.userReportType]);

  const clickFeedback = () => {
    setFeedback(!feedback);
  };

  const closeFeedback = () => {
    setFeedback(false);
  };

  const closePopups = () => {
    setSettingMenu(false);
    setHelpMenu(false);
  };

  const handleSettings = () => {
    setSettingMenu(!settingMenu);
    setHelpMenu(false);
  };

  const handleHelp = () => {
    setHelpMenu(!helpMenu);
    setSettingMenu(false);
  };

  const openNewProjectM = () => {
    setNewProjectM(true);
  };

  const closeNewProjectM = () => {
    setNewProjectM(false);
  };

  //Acronym Modal
  const openAcronymM = () => {
    setAcronymM(true);
  };
  const closeAcronymM = () => {
    setNavSearch("");
    setAcronymM(false);
  };

  //Product Modal
  const openProductM = () => {
    setProductM(true);
  };
  const closeProductM = () => {
    setProductM(false);
    setFilterBox(navSearch.length > 0 ? true : false);
    setNavSearch("");
  };

  //Reference Modal
  const openReferenceM = () => {
    setReferenceM(true);
  };
  const closeReferenceM = () => {
    setReferenceM(false);
    setFilterBox(navSearch.length > 0 ? true : false);
    setNavSearch("");
  };

  //DIDS Modal
  const openDIDSM = () => {
    setDidsM(true);
  };
  const closeDIDSM = () => {
    setDidsM(false);
  };

  //Tools Modal
  const openToolsM = () => {
    setToolsM(true);
  };
  const closeToolsM = () => {
    setNavSearch("");
    setToolsM(false);
  };

  const getPhaseInfo = () => {
    let phaseObject = modelPhases.filter(
      (phases) => userReportObject?.selectedProject?.modelId === phases.modelId
    );
    return phaseObject[0].phaseShortNames;
  };

  const getDomainInfo = () => {
    return userReportObject?.selectedProject?.modelId === "3"
      ? modelDomains[1].domainShortNames
      : modelDomains[0].domainShortNames;
  };

  const allPhaseCheck = () => {
    if (allPhase) {
      setAllPhase(false);
      setPhaseList([]);
    } else {
      setAllPhase(true);
      setPhaseList(getPhaseInfo());
    }
  };

  const phaseClick = (phase: string) => {
    if (phaseList?.includes(phase)) {
      let filterList = phaseList.filter((item: any) => item !== phase);
      setPhaseList(filterList);
    } else {
      setPhaseList([...phaseList, phase]);
    }
  };

  const allDomainCheck = () => {
    if (allDomain) {
      setAllDomain(false);
      setDomainList([]);
    } else {
      setAllDomain(true);
      setDomainList(getDomainInfo());
    }
  };

  const domainClick = (domain: string) => {
    if (domainList?.includes(domain)) {
      let filterList = domainList.filter((item: any) => item !== domain);
      setDomainList(filterList);
    } else {
      setDomainList([...domainList, domain]);
    }
  };

  const developmentClick = () => {
    if (domainList?.includes("Development Planning")) {
      let filterList = domainList.filter(
        (item: any) =>
          item !== "Development Planning" &&
          item !== "Prep" &&
          item !== "CP&A" &&
          item !== "CD"
      );
      setDomainList(filterList);
    } else {
      setDomainList([
        ...domainList,
        "Development Planning",
        "Prep",
        "CP&A",
        "CD",
      ]);
    }
  };

  const scienceClick = () => {
    if (domainList?.includes("Science & Technology")) {
      let filterList = domainList.filter(
        (item: any) =>
          item !== "Science & Technology" &&
          item !== "Plan1" &&
          item !== "Conduct1" &&
          item !== "Document1"
      );
      setDomainList(filterList);
    } else {
      setDomainList([
        ...domainList,
        "Science & Technology",
        "Plan1",
        "Conduct1",
        "Document1",
      ]);
    }
  };

  const modelingClick = () => {
    if (domainList?.includes("Modeling & Simulation")) {
      let filterList = domainList.filter(
        (item: any) =>
          item !== "Modeling & Simulation" &&
          item !== "Plan2" &&
          item !== "Conduct2" &&
          item !== "Document2"
      );
      setDomainList(filterList);
    } else {
      setDomainList([
        ...domainList,
        "Modeling & Simulation",
        "Plan2",
        "Conduct2",
        "Document2",
      ]);
    }
  };

  const allRiskCheck = () => {
    if (allRisk) {
      setAllRisk(false);
      setRiskList([]);
    } else {
      setAllRisk(true);
      setRiskList(["High", "Medium", "Low", "Not Assigned", "Include", "Date"]);
    }
  };

  const riskClick = (risk: string) => {
    if (riskList?.includes(risk)) {
      let filterList = riskList.filter((item: any) => item !== risk);
      setRiskList(filterList);
    } else {
      setRiskList([...riskList, risk]);
    }
  };

  const allActivityCheck = () => {
    if (allActivity) {
      setAllActivity(false);
      setActivityList([]);
    } else {
      setAllActivity(true);
      setActivityList([
        "Not Started",
        "In Progress",
        "Completed",
        "None",
        "Due Date",
        "Due Status",
        "Include",
      ]);
    }
  };

  const activityClick = (activity: string) => {
    if (activityList?.includes(activity)) {
      let filterList = activityList.filter((item: any) => item !== activity);
      setActivityList(filterList);
    } else {
      setActivityList([...activityList, activity]);
    }
  };

  const allActivityNotesCheck = () => {
    if (allNotes) {
      setAllNotes(false);
      setNotesList([]);
    } else {
      setAllNotes(true);
      setNotesList(["Trade", "Comments", "SME"]);
    }
  };

  const activityNotesClick = (notes: string) => {
    if (notesList?.includes(notes)) {
      let filterList = notesList.filter((item: any) => item !== notes);
      setNotesList(filterList);
    } else {
      setNotesList([...notesList, notes]);
    }
  };

  const allContentCheck = () => {
    if (allContent) {
      setAllContent(false);
      setContentList([]);
    } else {
      setAllContent(true);
      if (userReportObject.userReportType === "Related Items Report") {
        setContentList([
          "Inputs",
          "Outputs",
          "References",
          "Activities",
          "Owner",
          "Contributor",
        ]);
      } else {
        setContentList(["Inputs", "Outputs"]);
      }
    }
  };

  const allContentProductCheck = () => {
    if (contentProduct) {
      setContentProduct(false);
      let newList = contentList.filter((item: any) => item !== "Inputs");
      newList = newList.filter((item: any) => item !== "Outputs");
      setContentList(newList);
    } else {
      setContentProduct(true);
      setContentList([...contentList, "Inputs", "Outputs"]);
    }
  };

  const contentClick = (content: string) => {
    if (contentList?.includes(content)) {
      let filterList = contentList.filter((item: any) => item !== content);
      if (
        filterList?.includes("Inputs") === false &&
        filterList?.includes("Outputs") === false
      ) {
        setContentProduct(false);
      }
      setContentList(filterList);
    } else {
      setContentProduct(true);
      setContentList([...contentList, content]);
    }
  };

  const allManagementCheck = () => {
    if (allManagement) {
      setAllManagement(false);
      setManagementList([]);
    } else {
      setAllManagement(true);
      if (userReportObject.userReportType === "Custom Activities Report") {
        setManagementList([
          "Return",
          "Contractor",
          "Challenges",
          "Recommendations",
          "Notes",
        ]);
      } else {
        setManagementList(["Products", "Inputs", "Outputs", "References"]);
      }
    }
  };

  const managementClick = (management: string) => {
    if (managementList?.includes(management)) {
      let filterList = managementList.filter(
        (item: any) => item !== management
      );
      setManagementList(filterList);
    } else {
      setManagementList([...managementList, management]);
    }
  };

  const allProductOwnershipCheck = () => {
    if (productOwnership) {
      setProductOwnership(false);
      let newList = contentList.filter((item: any) => item !== "Owner");
      newList = newList.filter((item: any) => item !== "Contributor");
      setContentList(newList);
    } else {
      setProductOwnership(true);
      setContentList([...contentList, "Owner", "Contributor"]);
    }
  };

  const ownershipItemsClick = (ownership: string) => {
    if (contentList?.includes(ownership)) {
      let filterList = contentList.filter((item: any) => item !== ownership);
      if (
        filterList?.includes("Owner") === false &&
        filterList?.includes("Contributor") === false
      ) {
        setProductOwnership(false);
      }
      setContentList(filterList);
    } else {
      setProductOwnership(true);
      setContentList([...contentList, ownership]);
    }
  };

  const riskConfirm = () => {
    setRiskDateError(false);
    getRiskReport(
      phaseList,
      domainList,
      riskList,
      riskItemStartDate,
      riskItemEndDate,
      userReportObject.selectedProject.projectId
    );
    navigate("/report");
  };

  const riskSubmit = () => {
    if (riskList?.includes("Date")) {
      if (
        (riskItemStartDate === null || riskItemStartDate === "") &&
        (riskItemEndDate === null || riskItemEndDate === "")
      ) {
        setRiskDateError(true);
      } else if (riskItemStartDate.length > 0 && riskItemEndDate.length > 0) {
        if (
          Math.floor(new Date(riskItemStartDate).getTime() / 1000) >
          Math.floor(new Date(riskItemEndDate).getTime() / 1000)
        ) {
          setRiskDateError(true);
        } else {
          riskConfirm();
        }
      } else if (
        riskItemStartDate.length > 0 &&
        (riskItemEndDate === null || riskItemEndDate === "")
      ) {
        riskConfirm();
      } else if (
        (riskItemStartDate === null || riskItemStartDate === "") &&
        riskItemEndDate.length > 0
      ) {
        riskConfirm();
      }
    } else {
      riskConfirm();
    }
  };

  const statusConfirm = () => {
    setStatusDateError(false);
    setUpdateDateError(false);
    getStatusReport(
      phaseList,
      domainList,
      activityList,
      statusItemStartDate,
      statusItemEndDate,
      updateItemStartDate,
      updateItemEndDate,
      userReportObject.selectedProject.projectId
    );
    navigate("/report");
  };

  const statusSubmit = () => {
    let statusStart =
      statusItemStartDate === null || statusItemStartDate === "";
    let statusEnd = statusItemEndDate === null || statusItemEndDate === "";
    let updateStart =
      updateItemStartDate === null || updateItemStartDate === "";
    let updateEnd = updateItemEndDate === null || updateItemEndDate === "";
    if (
      activityList?.includes("Due Date") &&
      activityList?.includes("Due Status")
    ) {
      if (statusStart && statusEnd) {
        setStatusDateError(true);
      } else if (!statusStart && !statusEnd) {
        setStatusDateError(false);
      } else if (updateStart && updateEnd) {
        setUpdateDateError(true);
      } else if (!updateStart && !updateEnd) {
        setUpdateDateError(false);
      } else if (!statusStart && !statusEnd && !updateStart && !updateEnd) {
        statusConfirm();
      }
    } else if (
      activityList?.includes("Due Date") &&
      !activityList?.includes("Due Status")
    ) {
      setUpdateDateError(false);
      if (statusStart && statusEnd) {
        setStatusDateError(true);
      } else if (!statusStart && !statusEnd) {
        setStatusDateError(false);
      } else if (!statusStart && !statusEnd) {
        statusConfirm();
      }
    } else if (
      !activityList?.includes("Due Date") &&
      activityList?.includes("Due Status")
    ) {
      setStatusDateError(false);
      if (updateStart && updateEnd) {
        setUpdateDateError(true);
      } else if (!updateStart && !updateEnd) {
        setUpdateDateError(false);
      } else if (!updateStart && !updateEnd) {
        statusConfirm();
      }
    } else {
      statusConfirm();
    }
  };

  const customConfirm = (reportType: string) => {
    setRiskDateError(false);
    setStatusDateError(false);
    setUpdateDateError(false);
    if (reportType === "Custom Activities Report") {
      getCustomActivityReport(
        phaseList,
        domainList,
        activityList,
        statusItemStartDate,
        statusItemEndDate,
        updateItemStartDate,
        updateItemEndDate,
        notesList,
        managementList,
        riskList,
        riskItemStartDate,
        riskItemEndDate,
        userReportObject.selectedProject.projectId
      );
    } else {
      getCustomLibraryReport(
        phaseList,
        domainList,
        activityList,
        statusItemStartDate,
        statusItemEndDate,
        updateItemStartDate,
        updateItemEndDate,
        managementList,
        riskList,
        riskItemStartDate,
        riskItemEndDate,
        userReportObject.selectedProject.projectId
      );
    }
    navigate("/report");
  };

  const customSubmit = (reportType: string) => {
    let statusStart =
      statusItemStartDate === null || statusItemStartDate === "";
    let statusEnd = statusItemEndDate === null || statusItemEndDate === "";
    let updateStart =
      updateItemStartDate === null || updateItemStartDate === "";
    let updateEnd = updateItemEndDate === null || updateItemEndDate === "";
    let riskStart = riskItemStartDate === null || riskItemStartDate === "";
    let riskEnd = riskItemEndDate === null || riskItemEndDate === "";
    if (activityList?.includes("Due Date")) {
      if (activityList?.includes("Due Status")) {
        if (riskList?.includes("Date")) {
          if (statusStart && statusEnd) {
            setStatusDateError(true);
          }
          if (!statusStart && !statusEnd) {
            setStatusDateError(false);
          }
          if (updateStart && updateEnd) {
            setUpdateDateError(true);
          }
          if (!updateStart && !updateEnd) {
            setUpdateDateError(false);
          }
          if (riskStart && riskEnd) {
            setRiskDateError(true);
          }
          if (!riskStart && !riskEnd) {
            setRiskDateError(false);
          }
          if (
            !statusStart &&
            !statusEnd &&
            !updateStart &&
            !updateEnd &&
            !riskStart &&
            !riskEnd
          ) {
            customConfirm(reportType);
          }
        } else {
          setRiskDateError(false);
          if (statusStart || statusEnd) {
            setStatusDateError(true);
          }
          if (!statusStart && !statusEnd) {
            setStatusDateError(false);
          }
          if (updateStart || updateEnd) {
            setUpdateDateError(true);
          }
          if (!updateStart && !updateEnd) {
            setUpdateDateError(false);
          }
          if (!statusStart && !statusEnd && !updateStart && !updateEnd) {
            customConfirm(reportType);
          }
        }
      } else {
        if (riskList?.includes("Date")) {
          setUpdateDateError(false);
          if (statusStart && statusEnd) {
            setStatusDateError(true);
          }
          if (!statusStart && !statusEnd) {
            setStatusDateError(false);
          }
          if (riskStart && riskEnd) {
            setRiskDateError(true);
          }
          if (!riskStart && !riskEnd) {
            setRiskDateError(false);
          }
          if (!statusStart && !statusEnd && !riskStart && !riskEnd) {
            customConfirm(reportType);
          }
        } else {
          setUpdateDateError(false);
          setRiskDateError(false);
          if (statusStart && statusEnd) {
            setStatusDateError(true);
          }
          if (!statusStart && !statusEnd) {
            setStatusDateError(false);
          }
          if (!statusStart && !statusEnd) {
            customConfirm(reportType);
          }
        }
      }
    } else {
      if (activityList?.includes("Due Status")) {
        if (riskList?.includes("Date")) {
          setStatusDateError(false);
          if (updateStart && updateEnd) {
            setUpdateDateError(true);
          }
          if (!updateStart && !updateEnd) {
            setUpdateDateError(false);
          }
          if (riskStart && riskEnd) {
            setRiskDateError(true);
          }
          if (!riskStart && !riskEnd) {
            setRiskDateError(false);
          }
          if (!updateStart && !updateEnd && !riskStart && !riskEnd) {
            customConfirm(reportType);
          }
        } else {
          setStatusDateError(false);
          setRiskDateError(false);
          if (updateStart && updateEnd) {
            setUpdateDateError(true);
          }
          if (!updateStart && !updateEnd) {
            setUpdateDateError(false);
          }
          if (!updateStart && !updateEnd) {
            customConfirm(reportType);
          }
        }
      } else {
        if (riskList?.includes("Date")) {
          setStatusDateError(false);
          setUpdateDateError(false);
          if (riskStart && riskEnd) {
            setRiskDateError(true);
          }
          if (!riskStart && !riskEnd) {
            setRiskDateError(false);
          }
          if (!riskStart && !riskEnd) {
            customConfirm(reportType);
          }
        } else {
          customConfirm(reportType);
        }
      }
    }
  };

  const contentConfirm = () => {
    setAddedDateError(false);
    setModifiedDateError(false);
    setDeletedDateError(false);
    getContentReport(
      phaseList,
      domainList,
      contentList,
      addedStartDate,
      addedEndDate,
      modifiedStartDate,
      modifiedEndDate,
      deletedStartDate,
      deletedEndDate,
      userReportObject.selectedProject.projectId
    );
    navigate("/report");
  };

  const contentSubmit = () => {
    let addedStart = addedStartDate === null || addedStartDate === "";
    let addedEnd = addedEndDate === null || addedEndDate === "";
    let modifiedStart = modifiedStartDate === null || modifiedStartDate === "";
    let modifiedEnd = modifiedEndDate === null || modifiedEndDate === "";
    let deletedStart = deletedStartDate === null || deletedStartDate === "";
    let deletedEnd = deletedEndDate === null || deletedEndDate === "";
    if (contentList?.includes("Date Added")) {
      if (contentList?.includes("Date Modified")) {
        if (contentList?.includes("Date Deleted")) {
          if (addedStart && addedEnd) {
            setAddedDateError(true);
          }
          if (!addedStart && !addedEnd) {
            setAddedDateError(false);
          }
          if (modifiedStart && modifiedEnd) {
            setModifiedDateError(true);
          }
          if (!modifiedStart && !modifiedEnd) {
            setModifiedDateError(false);
          }
          if (deletedStart && deletedEnd) {
            setDeletedDateError(true);
          }
          if (!deletedStart && !deletedEnd) {
            setDeletedDateError(false);
          }
          if (
            !addedStart &&
            !addedEnd &&
            !modifiedStart &&
            !modifiedEnd &&
            !deletedStart &&
            !deletedEnd
          ) {
            contentConfirm();
          }
        } else {
          setDeletedDateError(false);
          if (addedStart && addedEnd) {
            setAddedDateError(true);
          }
          if (!addedStart && !addedEnd) {
            setAddedDateError(false);
          }
          if (modifiedStart && modifiedEnd) {
            setModifiedDateError(true);
          }
          if (!modifiedStart && !modifiedEnd) {
            setModifiedDateError(false);
          }
          if (!addedStart && !addedEnd && !modifiedStart && !modifiedEnd) {
            contentConfirm();
          }
        }
      } else {
        if (contentList?.includes("Date Deleted")) {
          setModifiedDateError(false);
          if (addedStart && addedEnd) {
            setAddedDateError(true);
          }
          if (!addedStart && !addedEnd) {
            setAddedDateError(false);
          }
          if (deletedStart && deletedEnd) {
            setDeletedDateError(true);
          }
          if (!deletedStart && !deletedEnd) {
            setDeletedDateError(false);
          }
          if (!addedStart && !addedEnd && !deletedStart && !deletedEnd) {
            contentConfirm();
          }
        } else {
          setModifiedDateError(false);
          setDeletedDateError(false);
          if (addedStart && addedEnd) {
            setAddedDateError(true);
          }
          if (!addedStart && !addedEnd) {
            setAddedDateError(false);
          }
          if (!addedStart && !addedEnd) {
            contentConfirm();
          }
        }
      }
    } else {
      if (contentList?.includes("Date Modified")) {
        if (contentList?.includes("Date Deleted")) {
          setAddedDateError(false);
          if (modifiedStart && modifiedEnd) {
            setModifiedDateError(true);
          }
          if (!modifiedStart && !modifiedEnd) {
            setModifiedDateError(false);
          }
          if (deletedStart && deletedEnd) {
            setDeletedDateError(true);
          }
          if (!deletedStart && !deletedEnd) {
            setDeletedDateError(false);
          }
          if (!modifiedStart && !modifiedEnd && !deletedStart && !deletedEnd) {
            contentConfirm();
          }
        } else {
          setAddedDateError(false);
          setDeletedDateError(false);
          if (modifiedStart && modifiedEnd) {
            setModifiedDateError(true);
          }
          if (!modifiedStart && !modifiedEnd) {
            setModifiedDateError(false);
          }
          if (!modifiedStart && !modifiedEnd) {
            contentConfirm();
          }
        }
      } else {
        if (contentList?.includes("Date Deleted")) {
          setModifiedDateError(false);
          setAddedDateError(false);
          if (deletedStart && deletedEnd) {
            setDeletedDateError(true);
          }
          if (!deletedStart && !deletedEnd) {
            setDeletedDateError(false);
          }
          if (!deletedStart && !deletedEnd) {
            contentConfirm();
          }
        } else {
          contentConfirm();
        }
      }
    }
  };

  const clearArrays = () => {
    let categoriesObject = {
      userReportSelected: userReportObject.selectedProject,
      phase: [],
      domain: [],
      risk: [],
      activity: [],
      notes: [],
      content: [],
      management: [],
      riskStartDate: "",
      riskEndDate: "",
      statusStartDate: "",
      statusEndDate: "",
      updateStartDate: "",
      updateEndDate: "",
      addedStartDate: "",
      addedEndDate: "",
      modifiedStartDate: "",
      modifiedEndDate: "",
      deletedStartDate: "",
      deletedEndDate: "",
      reportType: userReportObject.userReportType,
    };
    editReportCategories(categoriesObject);
  };

  const cancelButton = () => {
    clearArrays();
    setProjectToReport(userReportObject.selectedProject);
    navigate(-1);
    setProjectToReport(userReportObject.selectedProject);
    clearArrays();
  };

  const saveButton = () => {
    let categoriesObject = {
      userReportSelected: userReportObject.selectedProject,
      phase: phaseList,
      domain: domainList,
      risk: riskList,
      activity: activityList,
      notes: notesList,
      content: contentList,
      management: managementList,
      riskStartDate: riskItemStartDate,
      riskEndDate: riskItemEndDate,
      statusStartDate: statusItemStartDate,
      statusEndDate: statusItemEndDate,
      updateStartDate: updateItemStartDate,
      updateEndDate: updateItemEndDate,
      addedStartDate: addedStartDate,
      addedEndDate: addedEndDate,
      modifiedStartDate: modifiedStartDate,
      modifiedEndDate: modifiedEndDate,
      deletedStartDate: deletedStartDate,
      deletedEndDate: deletedEndDate,
      reportType: userReportObject.userReportType,
    };
    editReportCategories(categoriesObject);
    if (userReportObject.userReportType === "Status Report") {
      statusSubmit();
    } else if (userReportObject.userReportType === "Custom Activities Report") {
      customSubmit("Custom Activities Report");
    } else if (userReportObject.userReportType === "Custom Libraries Report") {
      customSubmit("Custom Libraries Report");
    } else if (userReportObject.userReportType === "Related Items Report") {
      getRelatedItemsReport(
        phaseList,
        domainList,
        contentList,
        userReportObject.selectedProject.projectId
      );
      navigate("/report");
    } else if (userReportObject.userReportType === "Content Report") {
      contentSubmit();
    } else {
      riskSubmit();
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: style.colors.white,
      }}
    >
      <ReactFlowProvider>
        <Navbar
          clickFeedback={clickFeedback}
          settingMenu={settingMenu}
          helpMenu={helpMenu}
          handleSettings={handleSettings}
          handleHelp={handleHelp}
          closePopups={closePopups}
          screenName={`Reports`}
          openAcronymM={openAcronymM}
          openProductM={openProductM}
          openReferenceM={openReferenceM}
          openDIDSM={openDIDSM}
          openToolsM={openToolsM}
        />
      </ReactFlowProvider>
      <div
        style={{
          backgroundColor: style.colors.primaryBlue,
          height: "40px",
          display: "flex",
          position: "relative",
        }}
      >
        <AiOutlineLeft
          // className="stepTwoText"
          onClick={cancelButton}
          style={{
            color: style.colors.white,
            fontSize: 20,
            // marginLeft: "40px",
            // marginTop: "9px",
            cursor: "pointer",
            position: "absolute",
            top: 9,
            left: 8,
          }}
        />
        <p
          className="stepTwoText"
          style={{
            color: style.colors.white,
            marginTop: "11px",
          }}
        >
          Step 2: Specify Report Categories
        </p>
        <div
          style={{
            marginLeft: "auto",
          }}
        >
          <p
            style={{
              color: style.colors.white,
              marginRight: "40px",
              marginTop: "11px",
            }}
          >
            Step 2 of 2
          </p>
        </div>
      </div>
      <div className="extraScroll">
        <ReportCategories
          allPhase={allPhase}
          phaseList={phaseList}
          allPhaseCheck={allPhaseCheck}
          phaseClick={phaseClick}
          allDomain={allDomain}
          domainList={domainList}
          allDomainCheck={allDomainCheck}
          domainClick={domainClick}
          allRisk={allRisk}
          riskList={riskList}
          allRiskCheck={allRiskCheck}
          riskClick={riskClick}
          allActivity={allActivity}
          activityList={activityList}
          allActivityCheck={allActivityCheck}
          activityClick={activityClick}
          allNotes={allNotes}
          notesList={notesList}
          allActivityNotesCheck={allActivityNotesCheck}
          activityNotesClick={activityNotesClick}
          allContent={allContent}
          contentList={contentList}
          allContentCheck={allContentCheck}
          contentClick={contentClick}
          allManagement={allManagement}
          managementList={managementList}
          allManagementCheck={allManagementCheck}
          managementClick={managementClick}
          riskItemStartDate={riskItemStartDate}
          setRiskItemStartDate={setRiskItemStartDate}
          riskItemEndDate={riskItemEndDate}
          setRiskItemEndDate={setRiskItemEndDate}
          statusItemStartDate={statusItemStartDate}
          setStatusItemStartDate={setStatusItemStartDate}
          statusItemEndDate={statusItemEndDate}
          setStatusItemEndDate={setStatusItemEndDate}
          updateItemStartDate={updateItemStartDate}
          setUpdateItemStartDate={setUpdateItemStartDate}
          updateItemEndDate={updateItemEndDate}
          setUpdateItemEndDate={setUpdateItemEndDate}
          addedStartDate={addedStartDate}
          setAddedStartDate={setAddedStartDate}
          addedEndDate={addedEndDate}
          setAddedEndDate={setAddedEndDate}
          modifiedStartDate={modifiedStartDate}
          setModifiedStartDate={setModifiedStartDate}
          modifiedEndDate={modifiedEndDate}
          setModifiedEndDate={setModifiedEndDate}
          deletedStartDate={deletedStartDate}
          setDeletedStartDate={setDeletedStartDate}
          deletedEndDate={deletedEndDate}
          setDeletedEndDate={setDeletedEndDate}
          productOwnership={productOwnership}
          allProductOwnershipCheck={allProductOwnershipCheck}
          ownershipItemsClick={ownershipItemsClick}
          riskDateError={riskDateError}
          statusDateError={statusDateError}
          updateDateError={updateDateError}
          addedDateError={addedDateError}
          modifiedDateError={modifiedDateError}
          deletedDateError={deletedDateError}
          allContentProductCheck={allContentProductCheck}
          contentProduct={contentProduct}
          developmentClick={developmentClick}
          scienceClick={scienceClick}
          modelingClick={modelingClick}
        />
        <div
          className="bottomRowArea"
          style={{
            display: "flex",
            // marginTop: "20%",
            marginLeft: "50px",
            // justifyContent: "space-between",
          }}
        >
          <p style={{ cursor: "pointer", color: style.colors.primaryBlue }}>
            Save as New Report Type
          </p>
          <div style={{ marginLeft: "auto", alignSelf: "center" }}>
            <button
              onClick={cancelButton}
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                marginRight: "30px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button
              onClick={saveButton}
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Create Report
            </button>
          </div>
        </div>
      </div>

      <FeedbackModal closeFeedback={closeFeedback} feedback={feedback} />
      <NewProjectModal
        closeNewProjectM={closeNewProjectM}
        newProjectM={newProjectM}
      />
      <AcronymModal closeAcronymM={closeAcronymM} acronymM={acronymM} />
      <ProductLibraryModal closeProductM={closeProductM} productM={productM} />
      <ReferenceLibraryModal
        closeReferenceM={closeReferenceM}
        referenceM={referenceM}
      />
      <DidsModal closeDIDSM={closeDIDSM} didsM={didsM} />
      <ToolsModal closeToolsM={closeToolsM} toolsM={toolsM} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  user: state.user.user,
  navSearch: state.data.navSearch,
  userReportType: state.projects.projectToReport.userReportType,
  userReportObject: state.projects.projectToReport,
});

export default connect(mapStateToProps, {
  setNavSearch,
  setFilterBox,
  editReportCategories,
  getRiskReport,
  getStatusReport,
  getCustomActivityReport,
  getCustomLibraryReport,
  getRelatedItemsReport,
  getContentReport,
  setProjectToReport,
  resetReportCategories,
})(Project) as any;
