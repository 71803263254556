import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./SummaryModal.css";
import { MdClose } from "react-icons/md";
import { getId } from "../../lib/helpers/IdFactory";
import {
  setSummaryMenu,
  setClickedItem,
  setRightClickedItem,
} from "../../redux/actions/Projects";
import { editActivityTitle } from "../../redux/actions/Activities";

const SummaryModal: React.FC<{
  style: any;
  summaryMenu: boolean;
  setSummaryMenu: (state: boolean) => void;
  editActivityTitle: (
    activityId: string,
    projectId: string,
    text: string
  ) => void;
  rightClickedItem: any;
  setClickedItem: (activeItem: any) => void;
  setRightClickedItem: (righClicktItem: any) => void;
  activeProjectActivity: any;
}> = ({
  style,
  summaryMenu,
  setSummaryMenu,
  editActivityTitle,
  rightClickedItem,
  setClickedItem,
  setRightClickedItem,
  activeProjectActivity,
}) => {
  const [summaryText, setSummaryText] = useState("") as any;

  useEffect(() => {
    if (rightClickedItem) {
      setSummaryText(rightClickedItem.summary);
    } else {
      setSummaryText("");
    }
  }, [rightClickedItem, summaryMenu]);

  const cancelButton = () => {
    setSummaryMenu(false);
    setSummaryText("");
    setRightClickedItem(null);
    setClickedItem(null);
  };

  const saveSummary = () => {
    editActivityTitle(
      activeProjectActivity.activityId,
      activeProjectActivity.projectId,
      summaryText
    );
    setSummaryText("");
    setSummaryMenu(false);
    // setClickedItem(null);
  };

  return (
    <div style={{ visibility: summaryMenu ? "initial" : "hidden" }}>
      <div className="summaryModalmodal-container">
        <section className="summaryModalmodal">
          <span>Activity Summary</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "10px",
            }}
          >
            <input
              value={summaryText}
              style={{ width: "430px", marginLeft: "10px" }}
              onChange={(e) => setSummaryText(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                gap: 10,
                paddingBottom: "10px",
                marginLeft: "auto",
              }}
            >
              <button
                onClick={cancelButton}
                style={{
                  backgroundColor: "grey",
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                onClick={saveSummary}
                style={{
                  backgroundColor: style.colors.primaryBlue,
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  summaryMenu: state.projects.summaryMenu,
  rightClickedItem: state.projects.rightClickedItem,
  activeProjectActivity: state.projects.activeProjectActivity,
});

export default connect(mapStateToProps, {
  setSummaryMenu,
  editActivityTitle,
  setClickedItem,
  setRightClickedItem,
})(SummaryModal) as any;
