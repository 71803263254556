import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./collabModal.css";
import {
  setCollabSelectionState,
  clearCollabItems,
  setCollabType,
} from "../../redux/actions/Projects";
import {
  addCollaboration,
  updateCollabLineType,
} from "../../redux/actions/Activities";

const CollabModal: React.FC<{
  closeCollabM: any;
  collabM: any;
  style: any;
  collabSourceData: any;
  collabTargetData: any;
  setCollabSelectionState: (state: boolean) => void;
  clearCollabItems: () => void;
  setCollabType: (state: string) => void;
  collabType: string;
  collaborations: any;
  addCollaboration: (projectId: string, collaboration: any) => void;
  setEdges: any;
  addEdge: any;
  activities: any;
  updateCollabLineType: (collabSourceData: any, collabTargetData: any) => void;
}> = ({
  closeCollabM,
  collabM,
  style,
  collabSourceData,
  collabTargetData,
  setCollabSelectionState,
  clearCollabItems,
  setCollabType,
  collabType,
  collaborations,
  addCollaboration,
  setEdges,
  addEdge,
  activities,
  updateCollabLineType,
}) => {
  const [sourceID1, setSourceID1] = useState("") as any;
  const [summary1, setSummary1] = useState("") as any;
  const [sourceID2, setSourceID2] = useState("") as any;
  const [summary2, setSummary2] = useState("") as any;

  const handleReset = () => {
    setSourceID1("");
    setSummary1("");
    setSourceID2("");
    setSummary2("");
    // closeCollabM();
  };

  const handleClose = () => {
    handleReset();
    closeCollabM();
    setCollabSelectionState(false);
    setCollabType("");
    clearCollabItems();
  };

  const handleSubmit = () => {
    if (collabType === "Local") {
      let collabFind = collaborations.find(
        (item: any) =>
          item.source === collabSourceData?.activityId &&
          item.target === collabTargetData?.activityId
      );

      if (collabFind) {
        handleClose();
        showExtraLocalToast();
      } else {
        setEdges((prevState) =>
          addEdge(
            {
              animated: false,
              source: collabSourceData?.activityId,
              sourceHandle: null,
              style: {
                stroke: "black",
                innerWidth: "20px",
                outerWidth: "20px",
              },
              target: collabTargetData?.activityId,
              targetHandle: null,
              collaborationType: collabType,
              type: "smoothstep",
            },
            prevState
          )
        );
        addCollaboration(collabTargetData?.projectId, {
          animated: false,
          source: collabSourceData?.activityId,
          sourceHandle: null,
          style: { stroke: "black", innerWidth: "20px", outerWidth: "20px" },
          target: collabTargetData?.activityId,
          targetHandle: null,
          collaborationType: collabType,
          type: "smoothstep",
        });
        handleClose();
      }
    } else {
      let columnActivities: any = [];
      activities.map((item: any) => {
        if (
          item.phaseOrder === collabSourceData?.phaseOrder &&
          item.phaseName === collabSourceData?.phaseName
        ) {
          columnActivities.push(item);
        }
      });

      columnActivities.sort((a, b) =>
        a.activityRowID.localeCompare(b.activityRowID)
      );

      for (let x = 0; x < columnActivities.length; x++) {
        if (x !== columnActivities.length - 1) {
          let collabFind = collaborations.find(
            (item: any) =>
              item.source === columnActivities[x].activityId &&
              item.target === columnActivities[x + 1].activityId
          );

          if (collabFind) {
            updateCollabLineType(columnActivities[x], columnActivities[x + 1]);
          } else {
            setEdges((prevState) =>
              addEdge(
                {
                  animated: false,
                  source: columnActivities[x].activityId,
                  sourceHandle: null,
                  style: {
                    stroke: "black",
                    innerWidth: "20px",
                    outerWidth: "20px",
                  },
                  target: columnActivities[x + 1].activityId,
                  targetHandle: null,
                  collaborationType: collabType,
                  type: "smoothstep",
                },
                prevState
              )
            );
            addCollaboration(columnActivities[x].projectId, {
              animated: false,
              source: columnActivities[x].activityId,
              sourceHandle: null,
              style: {
                stroke: "black",
                innerWidth: "20px",
                outerWidth: "20px",
              },
              target: columnActivities[x + 1].activityId,
              targetHandle: null,
              collaborationType: collabType,
              type: "smoothstep",
            });
          }
        }
      }
      handleClose();
    }
  };

  useEffect(() => {
    if (collabSourceData) {
      setSourceID1(collabSourceData?.uniqueID);
      setSummary1(collabSourceData?.summary);
    }
    if (collabTargetData) {
      setSourceID2(collabTargetData?.uniqueID);
      setSummary2(collabTargetData?.summary);
    }
  }, [collabSourceData, collabTargetData]);

  return (
    <div style={{ visibility: collabM ? "initial" : "hidden" }}>
      <div className="modal-container-global-collab">
        <section className="modal-global-collab">
          <i
            className="fa fa-times"
            onClick={handleClose}
            style={{
              cursor: "pointer",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          ></i>
          <span>
            {collabType === "Local"
              ? "Local Collaboration"
              : "Global Collaboration"}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                // height: "70px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Activity ID:</p>
            </div>
            <p
              style={{
                width: "240px",
                alignSelf: "center",
                marginLeft: "10px",
              }}
            >
              {sourceID1}
            </p>
            {/* <input
              value={sourceID1}
              // onChange={descriptionHandler}
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "-20px",
              }}
              placeholder="Activity 1..."
            /> */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Title:</p>
            </div>
            <p
              style={{
                width: "240px",
                alignSelf: "center",
                marginLeft: "10px",
              }}
            >
              {summary1}
            </p>
            {/* <input
              value={summary1}
              // onChange={summaryHandler}
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "-20px",
              }}
              placeholder="Activity 1 Title..."
            /> */}
          </div>

          {collabType === "Local" ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "150px",
                    borderRight: "2px solid lightgrey",
                    justifyContent: "flex-end",
                    // height: "70px",
                  }}
                >
                  <p style={{ paddingRight: "10px" }}>Activity ID:</p>
                </div>
                <p
                  style={{
                    width: "240px",
                    alignSelf: "center",
                    marginLeft: "10px",
                  }}
                >
                  {sourceID2}
                </p>
                {/* <input
                  value={sourceID2}
                  // onChange={descriptionHandler}
                  style={{
                    width: "240px",
                    height: "20px",
                    cursor: "default",
                    alignSelf: "center",
                    marginLeft: "10px",
                    marginTop: "-20px",
                  }}
                  placeholder="Activity 2..."
                /> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "150px",
                    borderRight: "2px solid lightgrey",
                    justifyContent: "flex-end",
                  }}
                >
                  <p style={{ paddingRight: "10px" }}>Title:</p>
                </div>
                <p
                  style={{
                    width: "240px",
                    alignSelf: "center",
                    marginLeft: "10px",
                  }}
                >
                  {summary2}
                </p>
                {/* <input
                  value={summary2}
                  // onChange={summaryHandler}
                  style={{
                    width: "240px",
                    height: "20px",
                    cursor: "default",
                    alignSelf: "center",
                    marginLeft: "10px",
                    marginTop: "-20px",
                  }}
                  placeholder="Activity 2 Title..."
                /> */}
              </div>
            </>
          ) : null}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 20,
              marginTop: "10px",
            }}
          >
            <button
              onClick={handleClose}
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Save
            </button>
          </div>
        </section>
      </div>
    </div>
  );

  function showExtraLocalToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar5");
    if (element[0]) {
      element[0].className = "snackbar5 show";
      setTimeout(function () {
        element[0].className = "snackbar5";
      }, 5000);
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  collabSourceData: state.projects.collabSourceData,
  collabTargetData: state.projects.collabTargetData,
  collabType: state.projects.collabType,
  collaborations: state.activities.collaborations,
  activities: state.activities.activities,
});

export default connect(mapStateToProps, {
  setCollabSelectionState,
  clearCollabItems,
  setCollabType,
  addCollaboration,
  updateCollabLineType,
})(CollabModal) as any;
