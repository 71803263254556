import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./RightClickActivityMenu.css";
import {
  setRightClickMenu,
  setClickedItem,
  setItemMenu,
  setRightClickedItem,
  setSummaryMenu,
  setActivityDeleteModal,
} from "../../../redux/actions/Projects";
import {
  deleteActivity,
  deleteCollaborationOption,
} from "../../../redux/actions/Activities";

const RightClickActivityMenu: React.FC<{
  rightClick_x: any;
  rightClick_y: any;
  setRightClickMenu: (x: any, y: any) => void;
  deleteActivity: (pId: string, aId: string) => void;
  setClickedItem: (activeItem: any) => void;
  setItemMenu: (state: boolean) => void;
  rightClickedItem: any;
  setRightClickedItem: (righClicktItem: any) => void;
  deleteCollaborationOption: (pId: string, aId: string) => void;
  editCanvas: any;
  setSummaryMenu: (state: boolean) => void;
  setActivityDeleteModal: (state: boolean) => void;
}> = ({
  rightClick_x,
  rightClick_y,
  setRightClickMenu,
  deleteActivity,
  setClickedItem,
  setItemMenu,
  rightClickedItem,
  setRightClickedItem,
  deleteCollaborationOption,
  editCanvas,
  setSummaryMenu,
  setActivityDeleteModal,
}) => {
  const navigate = useNavigate();

  const handleDeleteActivity = () => {
    // setClickedItem(null);
    setItemMenu(false);
    setActivityDeleteModal(true);
    // deleteActivity(rightClickedItem.projectId, rightClickedItem.activityId);
    setRightClickMenu(null, null);
  };

  const handleDeleteCollabs = () => {
    setClickedItem(null);
    setItemMenu(false);
    deleteCollaborationOption(
      rightClickedItem.projectId,
      rightClickedItem.activityId
    );
    setRightClickMenu(null, null);
  };

  const handleSummaryEdit = () => {
    setClickedItem(rightClickedItem);
    setItemMenu(false);
    setSummaryMenu(true);
    setRightClickMenu(null, null);
  };

  return (
    <>
      {rightClick_x && editCanvas ? (
        <div
          onMouseLeave={() => {
            setRightClickMenu(null, null);
            setRightClickedItem(null);
          }}
          className="rightMenu"
          style={{
            position: "absolute",
            backgroundColor: "#e1e2e1",
            minWidth: "150px",
            boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
            zIndex: 20,
            top: rightClick_y,
            left: rightClick_x,
          }}
        >
          <div onClick={showGlobalCToast}>Create Global Collaborations</div>
          <div onClick={handleSummaryEdit}>Edit Activity Summary</div>
          <div onClick={handleDeleteCollabs}>Remove Collaborations</div>
          <div onClick={handleDeleteActivity}>Remove Activity</div>
        </div>
      ) : null}
    </>
  );

  function showGlobalCToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar3");
    if (element[0]) {
      element[0].className = "snackbar3 show";
      setTimeout(function () {
        element[0].className = "snackbar3";
      }, 5000);
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  rightClick_x: state.projects.rightClick_x,
  rightClick_y: state.projects.rightClick_y,
  rightClickedItem: state.projects.rightClickedItem,
  editCanvas: state.projects.editCanvas,
});

export default connect(mapStateToProps, {
  setRightClickMenu,
  setClickedItem,
  setItemMenu,
  deleteActivity,
  setRightClickedItem,
  deleteCollaborationOption,
  setSummaryMenu,
  setActivityDeleteModal,
})(RightClickActivityMenu) as any;
