import axios from "axios";

//Add extra auth check
const initIntercepters = () => {
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export { initIntercepters };
