import axios from "axios";
import config from "../../config.json";
import {
  GET_ACRONYMS,
  GET_PRODUCT_LIBRARY,
  GET_REFERENCE_LIBRARY,
  GET_USER_SEARCH,
  ADD_NEW_ACRONYM,
  DELETE_ACRONYM,
  UPDATE_ACRONYM,
  ADD_NEW_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  ADD_NEW_REFERENCE,
  UPDATE_REFERENCE,
  DELETE_REFERENCE,
  SET_NAV_SEARCH,
  SET_FILTER_BOX,
  GET_DIDS_LIBRARY,
  ADD_NEW_DIDS,
  UPDATE_DIDS,
  DELETE_DIDS,
  ADD_FEEDBACK,
  GET_FEEDBACKS,
  GET_TOOLS,
  HANDLE_FOUND_INDEX,
  HANDLE_FOUND_VIEW,
  RESET_ACTIVITY_SEARCH,
  GET_ALL_USERS,
  HANDLE_PRODUCT_INPUTS_OCCURR,
  HANDLE_PRODUCT_OUTPUTS_OCCURR,
  HANDLE_REF_OCCURR,
  HANDLE_DIDS_OCCURR,
  HANDLE_TOOLS_OCCURR,
} from "./types";
import store from "../index";

const getAcronyms = () => async (dispatch: any) => {
  try {
    const url = `${config.api}data/get_acronyms`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_ACRONYMS,
      payload: res.data.sort(() => Math.random() - 0.5),
    });
  } catch (error) {
    console.log(error);
  }
};

const addNewAcronym =
  (acronym: string, meaning: string) => async (dispatch: any) => {
    try {
      let state = store.getState();
      let acronymsLength = state.data.acronyms.length + 1;
      const url = `${config.api}data/add_new_acronym`;
      const body = {
        acronym: acronym,
        meaning: meaning,
        id: acronymsLength,
        date: Math.floor(Date.now() / 1000),
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      let newList = [...state.data.acronyms, res.data];
      dispatch({
        type: ADD_NEW_ACRONYM,
        payload: newList,
      });
    } catch (error) {
      console.log(error);
    }
  };

const deleteAcronym = (id: number) => async (dispatch: any) => {
  try {
    let state = store.getState();
    const url = `${config.api}data/delete_acronym`;
    const body = {
      id: id,
    };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });

    const filterDelete = await state.data.acronyms.filter(
      (x) => x.acronymId !== id
    );

    dispatch({
      type: DELETE_ACRONYM,
      payload: filterDelete,
    });
  } catch (error) {
    console.log(error);
  }
};

const updateAcronym =
  (id: number, acronym: string, meaning: string) => async (dispatch: any) => {
    try {
      let state = store.getState();
      const url = `${config.api}data/update_acronym`;
      let currentDate = Math.floor(Date.now() / 1000);
      const body = {
        id: id,
        acronym: acronym,
        meaning: meaning,
        dateModified: currentDate,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });

      const updateItem = await state.data.acronyms?.map((obj: any) => {
        if (obj.acronymId === id) {
          return {
            ...obj,
            acronymId: id,
            acronymName: acronym,
            definition: meaning,
            dateModified: currentDate,
          };
        } else {
          return obj;
        }
      });

      dispatch({
        type: UPDATE_ACRONYM,
        payload: updateItem,
      });
    } catch (error) {
      console.log(error);
    }
  };

const getProductLibrary = () => async (dispatch: any) => {
  try {
    const url = `${config.api}data/get_product_library`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_PRODUCT_LIBRARY,
      payload: res.data.sort(() => Math.random() - 0.5),
    });
  } catch (error) {
    console.log(error);
  }
};

const addNewProduct =
  (
    name: string,
    domain: string,
    phases: string,
    type: string,
    inputOutput: string,
    ConOwn: string
  ) =>
  async (dispatch: any) => {
    try {
      let state = store.getState();
      let productsLength = state.data.productLibrary.length + 1;
      const url = `${config.api}data/add_new_product`;
      const body = {
        name: name,
        domain: domain,
        id: productsLength,
        phases: phases,
        type: type,
        inputOutput: inputOutput,
        ConOwn: ConOwn,
        date: Math.floor(Date.now() / 1000),
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      let newList = [...state.data.productLibrary, ...res.data];
      dispatch({
        type: ADD_NEW_PRODUCT,
        payload: newList,
      });
    } catch (error) {
      console.log(error);
    }
  };

const updateProduct =
  (
    name: string,
    domain: string,
    phases: string,
    type: string,
    inputOutput: string,
    ConOwn: string,
    id: number
  ) =>
  async (dispatch: any) => {
    try {
      let state = store.getState();
      const url = `${config.api}data/update_product`;
      let currentDate = Math.floor(Date.now() / 1000);
      const body = {
        name: name,
        domain: domain,
        id: id,
        phases: phases,
        type: type,
        inputOutput: inputOutput,
        ConOwn: ConOwn,
        dateModified: currentDate,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });

      // const updateItem = await state.data.productLibrary?.map((obj: any) => {
      //   if (obj.productId === id) {
      //     return {
      //       ...obj,
      //       name: name,
      //       domainId: domain,
      //       productId: id,
      //       phase: phases,
      //       scope: type,
      //       type: inputOutput,
      //       contributorOwner: ConOwn,
      //       dateModified: currentDate,
      //     };
      //   } else {
      //     return obj;
      //   }
      // });

      dispatch({
        type: UPDATE_PRODUCT,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const deleteProduct = (id: number, intOut: string) => async (dispatch: any) => {
  try {
    let state = store.getState();
    const url = `${config.api}data/delete_product`;
    const body = {
      id: id,
      intOut: intOut,
    };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });

    const filterDelete = await state.data.productLibrary.filter(
      (x) => x._id !== res.data.objectID
    );

    dispatch({
      type: DELETE_PRODUCT,
      payload: filterDelete,
    });
  } catch (error) {
    console.log(error);
  }
};

const getReferenceLibrary = () => async (dispatch: any) => {
  try {
    const url = `${config.api}data/get_reference_library`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_REFERENCE_LIBRARY,
      payload: res.data.sort(() => Math.random() - 0.5),
    });
  } catch (error) {
    console.log(error);
  }
};

const addNewReference =
  (
    short: string,
    long: string,
    version: string,
    section: string,
    year: string,
    link: string,
    type: string,
    domain: string,
    phases: string,
    service: string
  ) =>
  async (dispatch: any) => {
    try {
      let state = store.getState();
      let productsLength = state.data.referenceLibrary.length + 1;
      const url = `${config.api}data/add_new_reference`;
      const body = {
        short: short,
        long: long,
        id: productsLength,
        version: version,
        section: section,
        year: Number(year),
        link: link,
        domain: domain,
        phases: phases,
        type: type,
        service: service,
        date: Math.floor(Date.now() / 1000),
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      let newList = [...state.data.referenceLibrary, res.data];
      dispatch({
        type: ADD_NEW_REFERENCE,
        payload: newList,
      });
    } catch (error) {
      console.log(error);
    }
  };

const updateReference =
  (
    short: string,
    long: string,
    version: string,
    section: string,
    year: string,
    link: string,
    type: string,
    domain: string,
    phases: string,
    service: string,
    id: number
  ) =>
  async (dispatch: any) => {
    try {
      let state = store.getState();
      const url = `${config.api}data/update_reference`;
      let currentDate = Math.floor(Date.now() / 1000);
      const body = {
        short: short,
        long: long,
        id: id,
        version: version,
        section: section,
        year: Number(year),
        link: link,
        domain: domain,
        phases: phases,
        type: type,
        service: service,
        dateModified: currentDate,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });

      const updateItem = await state.data.referenceLibrary?.map((obj: any) => {
        if (obj.referenceId === id) {
          return {
            ...obj,
            shortName: short,
            fullName: long,
            referenceId: id,
            version: version,
            section: section,
            year: year,
            domainId: domain,
            phaseId: phases,
            type: type,
            serviceOrganization: service,
            dateModified: currentDate,
          };
        } else {
          return obj;
        }
      });

      dispatch({
        type: UPDATE_REFERENCE,
        payload: updateItem,
      });
    } catch (error) {
      console.log(error);
    }
  };

const deleteReference = (id: number) => async (dispatch: any) => {
  try {
    let state = store.getState();
    const url = `${config.api}data/delete_reference `;
    const body = {
      id: id,
    };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });

    const filterDelete = await state.data.referenceLibrary.filter(
      (x) => x.referenceId !== id
    );

    dispatch({
      type: DELETE_REFERENCE,
      payload: filterDelete,
    });
  } catch (error) {
    console.log(error);
  }
};

const getUserSearch = (searchText: string) => async (dispatch: any) => {
  try {
    let state = store.getState();
    // const url = `${config.api}data/get_user_search`;
    // const body = { searchText: searchText };
    /*
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    */
    await Promise.resolve();
    let count = 0;
    let activitiesFound: any = [];

    let acronymsList = state.data.acronyms.filter(
      (x) => !x.hasOwnProperty("init_id")
    );
    let referenceList = state.data.referenceLibrary.filter(
      (x) => !x.hasOwnProperty("init_id")
    );
    let productsList = state.data.productLibrary.filter(
      (x) => !x.hasOwnProperty("init_id")
    );
    productsList = productsList.filter((x) => x.productId !== null);
    let didsList = state.data.didsLibrary.filter(
      (x) => !x.hasOwnProperty("init_id")
    );
    let toolsList = state.data.toolsLibrary.filter(
      (x) => !x.hasOwnProperty("init_id")
    );
    let activityList = state.activities.activities;

    acronymsList = acronymsList.filter(
      (item: any) =>
        item.acronymName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        item.definition.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );

    referenceList = referenceList?.filter(
      (item: any) =>
        item.fullName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        item.shortName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );

    productsList = productsList?.filter(
      (item: any) =>
        item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      // ||
      // item.domainId.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      // item.phase.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      // item.scope.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      // item.type.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      // item.contributorOwner.toLowerCase().indexOf(searchText.toLowerCase()) >
      //   -1
    );

    didsList = didsList.filter(
      (item: any) =>
        item?.documentID?.toLowerCase().indexOf(searchText.toLowerCase()) >
          -1 || item?.name?.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );

    toolsList = toolsList.filter(
      (item: any) =>
        item?.name?.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        item?.description?.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );

    if (activityList.length > 0) {
      activityList.map((activityItem: any) => {
        let summaryFound = false;
        if (
          activityItem.summary.toLowerCase().includes(searchText.toLowerCase())
        ) {
          count++;
          summaryFound = true;
          activitiesFound.push({
            ...activityItem,
            tabMenu: "details",
            itemFound: "summary",
          });
        }
        if (
          activityItem.description
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          count++;
          activitiesFound.push({
            ...activityItem,
            tabMenu: "details",
            itemFound: "description",
          });
        }
        if (activityItem.hasOwnProperty("hsiRisk")) {
          if (activityItem.hsiRisk.length > 0) {
            activityItem.hsiRisk.map((item: any) => {
              if (
                item.description
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              ) {
                count++;
                activitiesFound.push({
                  ...activityItem,
                  tabMenu: "details",
                  itemFound: "hsiRisk",
                  riskID: item.riskID,
                });
              }
            });
          }
        }
        if (activityItem.hasOwnProperty("productList")) {
          if (activityItem.productList.length > 0) {
            activityItem.productList.map((item: any) => {
              if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
                count++;
                activitiesFound.push({
                  ...activityItem,
                  tabMenu: "libraries",
                  itemFound: "productList",
                  productID: item.productId,
                });
              }
            });
          }
        }
        if (activityItem.hasOwnProperty("referenceList")) {
          if (activityItem.referenceList.length > 0) {
            activityItem.referenceList.map((item: any) => {
              if (
                item.shortName.toLowerCase().includes(searchText.toLowerCase())
              ) {
                count++;
                activitiesFound.push({
                  ...activityItem,
                  tabMenu: "libraries",
                  itemFound: "referenceList",
                  referenceID: item.referenceId,
                });
              }
            });
          }
        }
        if (activityItem.hasOwnProperty("didsList")) {
          if (activityItem.didsList.length > 0) {
            activityItem.didsList.map((item: any) => {
              if (
                item.documentID.toLowerCase().includes(searchText.toLowerCase())
              ) {
                count++;
                activitiesFound.push({
                  ...activityItem,
                  tabMenu: "libraries",
                  itemFound: "didsList",
                  didsID: item.didsID,
                });
              }
            });
          }
        }
        if (activityItem.hasOwnProperty("toolsList")) {
          if (activityItem.toolsList.length > 0) {
            activityItem.toolsList.map((item: any) => {
              if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
                count++;
                activitiesFound.push({
                  ...activityItem,
                  tabMenu: "libraries",
                  itemFound: "toolsList",
                  toolID: item.toolId,
                });
              }
            });
          }
        }
        if (
          activityItem.tradeOffs
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          count++;
          activitiesFound.push({
            ...activityItem,
            tabMenu: "management",
            itemFound: "tradeOffs",
          });
        }
        if (
          activityItem.challenges
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          count++;
          activitiesFound.push({
            ...activityItem,
            tabMenu: "management",
            itemFound: "challenges",
          });
        }
        if (
          activityItem.contractorDeliverables
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          count++;
          activitiesFound.push({
            ...activityItem,
            tabMenu: "management",
            itemFound: "contractorDeliverables",
          });
        }
        if (
          activityItem.recommendations
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          count++;
          activitiesFound.push({
            ...activityItem,
            tabMenu: "management",
            itemFound: "recommendations",
          });
        }
      });
    }

    const data = {
      acronyms: acronymsList,
      references: referenceList,
      products: productsList,
      activityInstances: count,
      dids: didsList,
      tools: toolsList,
      activitiesFound: activitiesFound,
    };

    dispatch({
      type: GET_USER_SEARCH,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

const setNavSearch = (search: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_NAV_SEARCH,
      payload: search,
    });
  } catch (error) {
    console.log(error);
  }
};

const setFilterBox = (box: boolean) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_FILTER_BOX,
      payload: box,
    });
  } catch (error) {
    console.log(error);
  }
};

const getDIDSLibrary = () => async (dispatch: any) => {
  try {
    const url = `${config.api}data/get_dids_library`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_DIDS_LIBRARY,
      payload: res.data.sort(() => Math.random() - 0.5),
    });
  } catch (error) {
    console.log(error);
  }
};

const addNewDIDs =
  (documentID: string, name: string, links: string) =>
  async (dispatch: any) => {
    try {
      let state = store.getState();
      let didsLength = state.data.didsLibrary.length + 1;
      const url = `${config.api}data/add_new_dids`;
      const body = {
        didsID: `DID${didsLength}`,
        documentID: documentID,
        name: name,
        dateAdded: Date.now(),
        links: links,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      let newList = [...state.data.didsLibrary, res.data];
      dispatch({
        type: ADD_NEW_DIDS,
        payload: newList,
      });
    } catch (error) {
      console.log(error);
    }
  };

const updateDIDs =
  (documentID: string, name: string, links: string, didsID: string) =>
  async (dispatch: any) => {
    try {
      let state = store.getState();
      const url = `${config.api}data/update_dids`;
      let currentDate = Date.now();

      const body = {
        documentID: documentID,
        name: name,
        links: links,
        didsID: didsID,
        dateModified: currentDate,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const updateItem = await state.data.didsLibrary?.map((obj: any) => {
        if (obj.didsID === didsID) {
          return {
            ...obj,
            documentID: documentID,
            name: name,
            links: links,
            didsID: didsID,
            dateModified: currentDate,
          };
        } else {
          return obj;
        }
      });
      dispatch({
        type: UPDATE_DIDS,
        payload: updateItem,
      });
    } catch (error) {
      console.log(error);
    }
  };

const deleteDIDs = (id: string) => async (dispatch: any) => {
  try {
    let state = store.getState();
    const url = `${config.api}data/delete_dids `;
    const body = {
      id: id,
    };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    const filterDelete = await state.data.didsLibrary.filter(
      (x) => x.didsID !== id
    );
    dispatch({
      type: DELETE_DIDS,
      payload: filterDelete,
    });
  } catch (error) {
    console.log(error);
  }
};

const addFeedback =
  (
    userID: string,
    name: string,
    email: string,
    locationType: string,
    text: string,
    feedbackID: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}data/add_feedback`;
      const body = {
        userID: userID,
        name: name,
        email: email,
        locationType: locationType,
        text: text,
        feedbackID: feedbackID,
        dateAdded: new Date().valueOf(),
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: ADD_FEEDBACK,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const getFeedbacks = () => async (dispatch: any) => {
  try {
    const url = `${config.api}data/get_feedbacks`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_FEEDBACKS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const getTools = () => async (dispatch: any) => {
  try {
    const url = `${config.api}data/get_tools`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_TOOLS,
      payload: res.data.sort(() => Math.random() - 0.5),
    });
  } catch (error) {
    console.log(error);
  }
};

const handleFoundIndex = (clickType: string) => async (dispatch: any) => {
  try {
    let state = store.getState();
    let activityFound = state.data.userSearch.activitiesFound;
    let foundIndex = state.data.activityFoundIndex;
    let newIndex = 0;

    if (activityFound.length > 1) {
      if (clickType === "left") {
        if (foundIndex === 0) {
          newIndex = activityFound.length - 1;
        } else {
          newIndex = foundIndex - 1;
        }
      } else {
        if (foundIndex + 1 === activityFound.length) {
          newIndex = 0;
        } else {
          newIndex = foundIndex + 1;
        }
      }
    }

    dispatch({
      type: HANDLE_FOUND_INDEX,
      payload: newIndex,
    });
  } catch (error) {
    console.log(error);
  }
};

const handleFoundView = (state: boolean) => async (dispatch: any) => {
  try {
    dispatch({
      type: HANDLE_FOUND_VIEW,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const resetActivtySearch = () => async (dispatch: any) => {
  try {
    dispatch({
      type: RESET_ACTIVITY_SEARCH,
      payload: null,
    });
  } catch (error) {
    console.log(error);
  }
};

const getAllUsers = () => async (dispatch: any) => {
  try {
    const url = `${config.api}data/get_all_users`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_ALL_USERS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const handleProductInputsOccurr =
  (
    originalList: any,
    newList: any,
    phaseName: string,
    domainName: string,
    pathwayName: string,
    actID: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}data/handle_product_inputs_occurrences`;
      const body = {
        initialList: originalList,
        inputProductList: newList,
        itemPhaseName: phaseName,
        itemDomainName: domainName,
        itemPathwayName: pathwayName,
        activityId: actID,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: HANDLE_PRODUCT_INPUTS_OCCURR,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const handleProductOutputsOccurr =
  (
    originalList: any,
    newList: any,
    phaseName: string,
    domainName: string,
    pathwayName: string,
    actID: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}data/handle_product_outputs_occurrences`;
      const body = {
        initialList: originalList,
        outputProductList: newList,
        itemPhaseName: phaseName,
        itemDomainName: domainName,
        itemPathwayName: pathwayName,
        activityId: actID,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: HANDLE_PRODUCT_OUTPUTS_OCCURR,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const handleRefOccurr =
  (
    originalList: any,
    newList: any,
    phaseName: string,
    domainName: string,
    pathwayName: string,
    actID: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}data/handle_reference_occurrences`;
      const body = {
        initialList: originalList,
        referenceList: newList,
        itemPhaseName: phaseName,
        itemDomainName: domainName,
        itemPathwayName: pathwayName,
        activityId: actID,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: HANDLE_REF_OCCURR,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const handleDidsOccurr =
  (
    originalList: any,
    newList: any,
    phaseName: string,
    domainName: string,
    pathwayName: string,
    actID: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}data/handle_dids_occurrences`;
      const body = {
        initialList: originalList,
        didsList: newList,
        itemPhaseName: phaseName,
        itemDomainName: domainName,
        itemPathwayName: pathwayName,
        activityId: actID,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: HANDLE_DIDS_OCCURR,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const handleToolsOccurr =
  (
    originalList: any,
    newList: any,
    phaseName: string,
    domainName: string,
    pathwayName: string,
    actID: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}data/handle_tools_occurrences`;
      const body = {
        initialList: originalList,
        toolsList: newList,
        itemPhaseName: phaseName,
        itemDomainName: domainName,
        itemPathwayName: pathwayName,
        activityId: actID,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: HANDLE_TOOLS_OCCURR,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export {
  getAcronyms,
  getProductLibrary,
  getReferenceLibrary,
  getUserSearch,
  addNewAcronym,
  deleteAcronym,
  updateAcronym,
  addNewProduct,
  updateProduct,
  deleteProduct,
  addNewReference,
  updateReference,
  deleteReference,
  setNavSearch,
  setFilterBox,
  getDIDSLibrary,
  addNewDIDs,
  updateDIDs,
  deleteDIDs,
  addFeedback,
  getFeedbacks,
  getTools,
  handleFoundIndex,
  handleFoundView,
  resetActivtySearch,
  getAllUsers,
  handleProductInputsOccurr,
  handleProductOutputsOccurr,
  handleRefOccurr,
  handleDidsOccurr,
  handleToolsOccurr,
};
