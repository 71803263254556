import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BsTriangleFill } from "react-icons/bs";
import { ReactComponent as RiskLevelLowImg } from "../../../lib/assets/img/RiskLevelLow.svg";
import { ReactComponent as RiskLevelMediumImg } from "../../../lib/assets/img/RiskLevelMedium.svg";
import { ReactComponent as RiskLevelHighImg } from "../../../lib/assets/img/RiskLevelHigh.svg";
import {
  setActivityLibrary,
  setActivityIDList,
  getActivityProductInputsData,
  getActivityProductOutputsData,
  getActivityReferenceData,
  getActivityDidsData,
  getActivityToolsData,
  setActivityChangeState,
  setInitialRisks,
  setActivityRisksChanged,
  setInitialProductsInputs,
  setInitialProductsOutputs,
  setActivityProductsChanged,
  setInitialReferences,
  setActivityReferencesChanged,
  setInitialDids,
  setActivityDidsChanged,
  setInitialTools,
  setActivityToolsChanged,
} from "../../../redux/actions/Projects";
import {
  editActivityDIDSList,
  editActivityHSIRisks,
  editActivityProductInputsList,
  editActivityProductOutputsList,
  editActivityReferenceList,
  editActivityToolsList,
} from "../../../redux/actions/Activities";
import {
  handleProductInputsOccurr,
  handleProductOutputsOccurr,
  handleRefOccurr,
  handleDidsOccurr,
  handleToolsOccurr,
} from "../../../redux/actions/Data";

const pathNames = [
  "Urgent Capability Acquisition",
  "Middle Tier Acquisition",
  "Major Capability Acquisition",
  "Software Acquisition",
  "Defense Business Systems",
  "Acquisition of Services",
];

const MenuTabContent: React.FC<{
  activeProjectActivity;
  tabState;
  setDetailsEdit;
  detailsEdit;
  titleText;
  setTitleText;
  descriptionText;
  setDescriptionText;
  editActivitySMEInteraction;
  editLibrariesHSIRisk;
  editActivityTitle;
  editActivityDescription;
  setLibrariesEdit;
  librariesEdit;
  setManagementEdit;
  managementEdit;
  tradeText;
  setTradeText;
  challengesText;
  setChallengesText;
  contractorText;
  setContractorText;
  recommendationsText;
  setRecommendationsText;
  editActivityTradeOffs;
  editActivityChallenges;
  editActivityContDel;
  editActivityRecommendations;
  style: any;
  setActivityLibrary: (state: boolean) => void;
  openProductM: any;
  openReferenceM: any;
  openDIDSM: any;
  openToolsM: any;
  setActivityIDList: (itemID: string) => void;
  getActivityProductInputsData: (list: any) => void;
  getActivityProductOutputsData: (list: any) => void;
  getActivityReferenceData: (list: any) => void;
  getActivityDidsData: (list: any) => void;
  getActivityToolsData: (list: any) => void;
  activityProductsInputs: any;
  activityProductsOutputs: any;
  activityReferences: any;
  activityDIDS: any;
  activityTools: any;
  setActivityChangeState: (state: boolean) => void;
  editCanvas: any;
  editActivityProductInputsList: (activityId: string, list: any) => void;
  editActivityProductOutputsList: (activityId: string, list: any) => void;
  editActivityReferenceList: (activityId: string, list: any) => void;
  editActivityDIDSList: (activityId: string, list: any) => void;
  editActivityHSIRisks: (activityId: string, risks: any) => void;
  setInitialRisks: (list: any) => void;
  initialRisks: any;
  activityRisksChanged: boolean;
  setActivityRisksChanged: (state: any) => void;
  setInitialProductsInputs: (list: any) => void;
  setInitialProductsOutputs: (list: any) => void;
  initialProductsInputs: any;
  initialProductsOutputs: any;
  activityProductsChanged: boolean;
  setActivityProductsChanged: (state: any) => void;
  setInitialReferences: (list: any) => void;
  initialReferences: any;
  activityReferencesChanged: boolean;
  setActivityReferencesChanged: (state: any) => void;
  setInitialDids: (list: any) => void;
  initialDids: any;
  activityDidsChanged: boolean;
  setActivityDidsChanged: (state: any) => void;
  setInitialTools: (list: any) => void;
  initialTools: any;
  activityToolsChanged: boolean;
  setActivityToolsChanged: (state: any) => void;
  editActivityToolsList: (activityId: string, list: any) => void;
  userSearch: any;
  activityFoundIndex: number;
  activityFoundView: boolean;
  navSearch: any;
  handleRefOccurr: (
    originalList: any,
    newList: any,
    phaseName: string,
    domainName: string,
    pathwayName: string,
    actID: string
  ) => void;
  activeProject: any;
  handleDidsOccurr: (
    originalList: any,
    newList: any,
    phaseName: string,
    domainName: string,
    pathwayName: string,
    actID: string
  ) => void;
  handleToolsOccurr: (
    originalList: any,
    newList: any,
    phaseName: string,
    domainName: string,
    pathwayName: string,
    actID: string
  ) => void;
  setDetailsProduct: any;
  setDetailsReference: any;
  setDetailsDID: any;
  setDetailsTool: any;
  handleProductInputsOccurr: (
    originalList: any,
    newList: any,
    phaseName: string,
    domainName: string,
    pathwayName: string,
    actID: string
  ) => void;
  handleProductOutputsOccurr: (
    originalList: any,
    newList: any,
    phaseName: string,
    domainName: string,
    pathwayName: string,
    actID: string
  ) => void;
}> = ({
  activeProjectActivity,
  tabState,
  setDetailsEdit,
  detailsEdit,
  titleText,
  setTitleText,
  descriptionText,
  setDescriptionText,
  editActivitySMEInteraction,
  editLibrariesHSIRisk,
  editActivityTitle,
  editActivityDescription,
  setLibrariesEdit,
  librariesEdit,
  setManagementEdit,
  managementEdit,
  tradeText,
  setTradeText,
  challengesText,
  setChallengesText,
  contractorText,
  setContractorText,
  recommendationsText,
  setRecommendationsText,
  editActivityTradeOffs,
  editActivityChallenges,
  editActivityContDel,
  editActivityRecommendations,
  style,
  setActivityLibrary,
  openProductM,
  openReferenceM,
  openDIDSM,
  openToolsM,
  setActivityIDList,
  getActivityProductInputsData,
  getActivityProductOutputsData,
  getActivityReferenceData,
  getActivityDidsData,
  getActivityToolsData,
  activityProductsInputs,
  activityProductsOutputs,
  activityReferences,
  activityDIDS,
  activityTools,
  setActivityChangeState,
  editCanvas,
  editActivityProductInputsList,
  editActivityProductOutputsList,
  editActivityReferenceList,
  editActivityDIDSList,
  editActivityHSIRisks,
  setInitialRisks,
  initialRisks,
  activityRisksChanged,
  setActivityRisksChanged,
  setInitialProductsInputs,
  setInitialProductsOutputs,
  initialProductsInputs,
  initialProductsOutputs,
  activityProductsChanged,
  setActivityProductsChanged,
  setInitialReferences,
  initialReferences,
  activityReferencesChanged,
  setActivityReferencesChanged,
  setInitialDids,
  initialDids,
  activityDidsChanged,
  setActivityDidsChanged,
  setInitialTools,
  initialTools,
  activityToolsChanged,
  setActivityToolsChanged,
  editActivityToolsList,
  userSearch,
  activityFoundIndex,
  activityFoundView,
  navSearch,
  handleRefOccurr,
  activeProject,
  handleDidsOccurr,
  handleToolsOccurr,
  setDetailsProduct,
  setDetailsReference,
  setDetailsDID,
  setDetailsTool,
  handleProductInputsOccurr,
  handleProductOutputsOccurr,
}) => {
  const [projectDesc, setprojectDesc] = useState("") as any;
  const [titleChanged, setTitleChanged] = useState(false) as any;
  const [descriptionChanged, setDescriptionChanged] = useState(false) as any;
  const [risksChanged, setRisksChanged] = useState(false) as any;
  const [smeChanged, setSMEChanged] = useState(false) as any;
  const [productChanged, setProductChanged] = useState(false) as any;
  const [referenceChanged, setReferenceChanged] = useState(false) as any;
  const [didsChanged, setDidsChanged] = useState(false) as any;
  const [toolsChanged, setToolsChanged] = useState(false) as any;
  const [tradeChanged, setTradeChanged] = useState(false) as any;
  const [challengesChanged, setChallengesChanged] = useState(false) as any;
  const [contractorChanged, setContractorChanged] = useState(false) as any;
  const [notesChanged, setNotesChanged] = useState(false) as any;
  const [defaultSME, setDefaultSME] = useState(false) as any;
  const [risksObject, setRisksObject] = useState([]) as any;
  const [productsObject, setProductsObject] = useState([]) as any;
  const [referenesObject, setReferencesObject] = useState([]) as any;
  const [didsObject, setDidsObject] = useState([]) as any;
  const [toolssObject, setToolsObject] = useState([]) as any;

  useEffect(() => {
    if (activeProjectActivity?.activityId) {
      setDefaultSME(activeProjectActivity?.smeInteraction);
      // setRisksObject(activeProjectActivity?.hsiRisk);
      // console.log("riskobj", risksObject);
      setInitialRisks(activeProjectActivity?.hsiRisk);
      setInitialProductsInputs(
        activeProjectActivity?.inputProductList
          ? activeProjectActivity?.inputProductList
          : []
      );
      setInitialProductsOutputs(
        activeProjectActivity?.outputProductList
          ? activeProjectActivity?.outputProductList
          : []
      );
      setInitialReferences(
        activeProjectActivity?.referenceList
          ? activeProjectActivity?.referenceList
          : []
      );
      setInitialDids(
        activeProjectActivity?.didsList ? activeProjectActivity?.didsList : []
      );
      setInitialTools(
        activeProjectActivity?.toolsList ? activeProjectActivity?.toolsList : []
      );
    }
  }, [activeProjectActivity?.activityId]);

  useEffect(() => {
    setprojectDesc(
      activeProjectActivity?.description.replace("\u2022", "\n\u2022")
    );
  }, [activeProjectActivity?.description]);

  useEffect(() => {
    if (
      !titleChanged &&
      !descriptionChanged &&
      !activityRisksChanged &&
      !smeChanged &&
      !activityProductsChanged &&
      !activityReferencesChanged &&
      !activityDidsChanged &&
      !activityToolsChanged &&
      !tradeChanged &&
      !challengesChanged &&
      !contractorChanged &&
      !contractorChanged &&
      !notesChanged
    ) {
      setActivityChangeState(false);
    } else if (
      titleChanged ||
      descriptionChanged ||
      activityRisksChanged ||
      smeChanged ||
      activityProductsChanged ||
      activityReferencesChanged ||
      activityDidsChanged ||
      activityToolsChanged ||
      tradeChanged ||
      challengesChanged ||
      contractorChanged ||
      notesChanged
    ) {
      setActivityChangeState(true);
      // console.log(titleChanged);
      // console.log(descriptionChanged);
      // console.log(activityRisksChanged); //
      // console.log(smeChanged);
      // console.log(activityProductsChanged); //
      // console.log(activityReferencesChanged); //
      // console.log(activityDidsChanged); //
      // console.log(activityToolsChanged);
      // console.log(tradeChanged);
      // console.log(challengesChanged);
      // console.log(contractorChanged);
      // console.log(notesChanged);
    }
  }, [
    titleChanged,
    descriptionChanged,
    activityRisksChanged,
    smeChanged,
    activityProductsChanged,
    activityReferencesChanged,
    activityDidsChanged,
    activityToolsChanged,
    tradeChanged,
    challengesChanged,
    contractorChanged,
    notesChanged,
  ]);

  useEffect(() => {
    if (activeProjectActivity) {
      if (activeProjectActivity.hasOwnProperty("inputProductList")) {
        if (activeProjectActivity?.inputProductList.length > 0) {
          getActivityProductInputsData(activeProjectActivity?.inputProductList);
        } else {
          getActivityProductInputsData([]);
        }
      } else {
        getActivityProductInputsData([]);
      }

      if (activeProjectActivity.hasOwnProperty("outputProductList")) {
        if (activeProjectActivity?.outputProductList.length > 0) {
          getActivityProductOutputsData(
            activeProjectActivity?.outputProductList
          );
        } else {
          getActivityProductOutputsData([]);
        }
      } else {
        getActivityProductOutputsData([]);
      }

      if (activeProjectActivity.hasOwnProperty("referenceList")) {
        if (activeProjectActivity?.referenceList.length > 0) {
          getActivityReferenceData(activeProjectActivity?.referenceList);
        } else {
          getActivityReferenceData([]);
        }
      } else {
        getActivityReferenceData([]);
      }

      if (activeProjectActivity.hasOwnProperty("didsList")) {
        if (activeProjectActivity?.didsList.length > 0) {
          getActivityDidsData(activeProjectActivity?.didsList);
        } else {
          getActivityDidsData([]);
        }
      } else {
        getActivityDidsData([]);
      }

      if (activeProjectActivity.hasOwnProperty("toolsList")) {
        if (activeProjectActivity?.toolsList.length > 0) {
          getActivityToolsData(activeProjectActivity?.toolsList);
        } else {
          getActivityToolsData([]);
        }
      } else {
        getActivityToolsData([]);
      }
    }
  }, [activeProjectActivity]);

  let previousLength = 0;
  const handleInput = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    // setDescriptionText(event.target.value);
  };

  const highlightSummary = (
    searchString: string,
    content: string,
    highlightColor: any
  ) => {
    const parts = content.split(new RegExp(`(${searchString})`, "gi"));

    return (
      <div
        style={{
          marginTop: "14px",
          marginBottom: "14px",
        }}
      >
        {parts.map((part, index) => {
          if (part.toLowerCase() === searchString.toLowerCase()) {
            return (
              <span
                key={index}
                style={{
                  backgroundColor: highlightColor,
                  fontSize: "14px",
                }}
              >
                {part}
              </span>
            );
          } else {
            return (
              <span
                key={index}
                style={{
                  fontSize: "14px",
                }}
              >
                {part}
              </span>
            );
          }
        })}
      </div>
    );
  };

  const highlightDescription = (
    searchString: string,
    content: string,
    highlightColor: any
  ) => {
    const parts = content.split(new RegExp(`(${searchString})`, "gi"));

    return (
      <div
        style={{
          marginTop: "14px",
          marginBottom: "14px",
        }}
      >
        {parts.map((part, index) => {
          if (part.toLowerCase() === searchString.toLowerCase()) {
            return (
              <span
                key={index}
                style={{ backgroundColor: highlightColor, fontSize: "14px" }}
              >
                {part}
              </span>
            );
          } else {
            return (
              <span
                key={index}
                style={{
                  fontSize: "14px",
                }}
              >
                {part}
              </span>
            );
          }
        })}
      </div>
    );
  };

  const highlightRisks = (
    searchString: string,
    content: string,
    highlightColor: any
  ) => {
    const parts = content.split(new RegExp(`(${searchString})`, "gi"));

    return (
      <div
        style={{
          marginTop: "14px",
          marginBottom: "14px",
          marginLeft: "3%",
        }}
      >
        {parts.map((part, index) => {
          if (part.toLowerCase() === searchString.toLowerCase()) {
            return (
              <span
                key={index}
                style={{
                  backgroundColor: highlightColor,
                  fontSize: "14px",
                }}
              >
                {part}
              </span>
            );
          } else {
            return (
              <span
                key={index}
                style={{
                  fontSize: "14px",
                }}
              >
                {part}
              </span>
            );
          }
        })}
      </div>
    );
  };

  const highlightMappedItems = (
    searchString: string,
    content: string,
    highlightColor: any,
    libraryName: string,
    isLastItem: boolean
  ) => {
    const parts = content.split(new RegExp(`(${searchString})`, "gi"));

    return (
      <div
        style={{
          marginTop: "14px",
          marginBottom: isLastItem ? "0px" : "14px",
        }}
      >
        {parts.map((part, index) => {
          if (part.toLowerCase() === searchString.toLowerCase()) {
            return (
              <span
                key={index}
                style={{
                  backgroundColor: highlightColor,
                  fontSize: "14px",
                }}
                onClick={() => {
                  if (libraryName === "product") {
                    setDetailsProduct(content);
                    openProductM();
                  } else if (libraryName === "reference") {
                    setDetailsReference(content);
                    openReferenceM();
                  } else if (libraryName === "did") {
                    setDetailsDID(content);
                    openDIDSM();
                  } else if (libraryName === "tool") {
                    setDetailsTool(content);
                    openToolsM();
                  }
                }}
              >
                {part}
              </span>
            );
          } else {
            return (
              <span
                key={index}
                style={{
                  fontSize: "14px",
                }}
                onClick={() => {
                  if (libraryName === "product") {
                    setDetailsProduct(content);
                    openProductM();
                  } else if (libraryName === "reference") {
                    setDetailsReference(content);
                    openReferenceM();
                  } else if (libraryName === "did") {
                    setDetailsDID(content);
                    openDIDSM();
                  } else if (libraryName === "tool") {
                    setDetailsTool(content);
                    openToolsM();
                  }
                }}
              >
                {part}
              </span>
            );
          }
        })}
      </div>
    );
  };

  const highlightManagementItems = (
    searchString: string,
    content: string,
    highlightColor: any
  ) => {
    const parts = content.split(new RegExp(`(${searchString})`, "gi"));

    return (
      <div
        style={{
          marginTop: "14px",
          marginBottom: "14px",
        }}
      >
        {parts.map((part, index) => {
          if (part.toLowerCase() === searchString.toLowerCase()) {
            return (
              <span
                key={index}
                style={{
                  backgroundColor: highlightColor,
                  fontSize: "14px",
                }}
              >
                {part}
              </span>
            );
          } else {
            return (
              <span
                key={index}
                style={{
                  fontSize: "14px",
                }}
              >
                {part}
              </span>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div className="tabcontent">
      {activeProjectActivity && tabState === "details" ? (
        <div
          // className="tabcontent"
          style={{
            paddingLeft: "40px",
          }}
        >
          <div
            style={{
              display: "flex",
              marginTop: "20px",
            }}
          >
            <i
              onClick={() => {
                if (editCanvas) setDetailsEdit(true);
              }}
              style={{
                color: style.colors.primaryBlue,
                cursor: editCanvas
                  ? detailsEdit
                    ? "default"
                    : "pointer"
                  : "default",
                marginLeft: "auto",
                marginRight: "30px",
                opacity: editCanvas ? (detailsEdit ? 0 : 1) : 0,
              }}
              className="fa fa-pencil"
            ></i>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ fontWeight: "bold" }}>Title</p>
          </div>

          <div
            style={{
              marginLeft: "2px",
              paddingRight: "10px",
              marginTop: detailsEdit ? "0px" : "-20px",
              paddingBottom: detailsEdit ? "6px" : 0,
            }}
          >
            {detailsEdit ? (
              <textarea
                value={titleText}
                onChange={(e) => {
                  setTitleText(e.target.value);
                  setTitleChanged(
                    activeProjectActivity?.summary === e.target.value
                      ? false
                      : true
                  );
                }}
                style={{
                  width: "96%",
                  height: "40px",
                  cursor: "default",
                  textAlign: "left",
                  marginLeft: "0px",
                  resize: "none",
                  fontFamily: "OpenSans-Regular",
                }}
                placeholder={
                  activeProjectActivity?.summary.length > 0
                    ? activeProjectActivity?.summary
                    : "Title..."
                }
              />
            ) : activityFoundView &&
              userSearch.hasOwnProperty("activitiesFound") &&
              userSearch.activitiesFound[activityFoundIndex].itemFound ===
                "summary" ? (
              highlightSummary(
                navSearch,
                activeProjectActivity?.summary,
                "yellow"
              )
            ) : (
              <div
                style={{
                  marginTop: "14px",
                  marginBottom: "14px",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {activeProjectActivity?.summary}
                </span>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: detailsEdit ? "-12px" : "-4px",
            }}
          >
            <p style={{ fontWeight: "bold" }}>Description</p>
          </div>

          <div
            style={{
              marginLeft: "2px",
              paddingRight: "10px",
              marginTop: detailsEdit ? "0px" : "-20px",
              paddingBottom: detailsEdit ? "6px" : 0,
            }}
          >
            {detailsEdit ? (
              // <textarea onInput={(e) => handleInput(e)} rows={10}></textarea>// <textarea
              <textarea
                value={descriptionText}
                onChange={(e) => {
                  setDescriptionText(e.target.value);
                  setDescriptionChanged(
                    activeProjectActivity?.description === e.target.value
                      ? false
                      : true
                  );
                }}
                style={{
                  width: "96%",
                  height: "170px",
                  cursor: "default",
                  textAlign: "left",
                  marginLeft: "0px",
                  resize: "none",
                  fontFamily: "OpenSans-Regular",
                }}
                placeholder={
                  activeProjectActivity?.description.length > 0
                    ? activeProjectActivity?.description
                    : "Description..."
                }
              />
            ) : activityFoundView &&
              userSearch.hasOwnProperty("activitiesFound") &&
              userSearch.activitiesFound[activityFoundIndex].itemFound ===
                "description" ? (
              highlightDescription(navSearch, projectDesc, "yellow")
            ) : (
              <div
                style={{
                  marginTop: "14px",
                  marginBottom: "14px",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {projectDesc}
                </span>
              </div>
            )}
          </div>

          {detailsEdit ? (
            <div
              style={{
                display:
                  activeProjectActivity?.domainName === "STM" ? "none" : "flex",
                alignItems: "center",
                marginTop: "-10px",
              }}
            >
              <input
                type="checkbox"
                id="smeInt"
                name="smeInt"
                value="smeInteraction"
                style={{ marginLeft: "auto" }}
                checked={activeProjectActivity?.smeInteraction ? true : false}
                onClick={() => {
                  editActivitySMEInteraction(
                    activeProjectActivity.activityId,
                    activeProjectActivity.projectId,
                    !activeProjectActivity?.smeInteraction
                  );
                  setSMEChanged(
                    smeChanged !== activeProjectActivity?.smeInteraction
                      ? true
                      : false
                  );
                }}
              />
              <label style={{ marginRight: "30px" }}>SME Interaction</label>
              <br></br>
            </div>
          ) : null}

          <div
            style={{
              display:
                activeProjectActivity?.domainName === "STM" ? "none" : "flex",
              alignItems: "center",
              marginTop: detailsEdit ? "-21px" : "-4px",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              HSI Risks
            </p>
            {detailsEdit ? (
              <p
                onClick={() => editLibrariesHSIRisk(true)}
                style={{
                  color: style.colors.primaryBlue,
                  cursor: "pointer",
                  fontSize: 13,
                  marginLeft: "1vw",
                }}
              >
                Add/Edit HSI Risks
              </p>
            ) : null}
          </div>

          <div
            style={{
              marginLeft: "10px",
              // height: "375px",
              paddingRight: "10px",
              marginTop: 0,
              paddingBottom: 0,
            }}
          >
            {activeProjectActivity?.hsiRisk !== "None" ||
            activeProjectActivity?.hsiRisk.length > 0
              ? activeProjectActivity?.hsiRisk.map((item: any) => {
                  return (
                    <div style={{ display: "flex" }} key={item.riskID}>
                      {item.riskLvl === "High" ? (
                        <RiskLevelHighImg
                          title="High Risk"
                          fill="#C00000"
                          style={{
                            color: "black",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                        />
                      ) : item.riskLvl === "Medium" ? (
                        <RiskLevelMediumImg
                          title="Medium Risk"
                          fill="#ED7D31"
                          style={{
                            color: "black",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                        />
                      ) : (
                        <RiskLevelLowImg
                          title="Low Risk"
                          fill="#FFD966"
                          style={{
                            color: "black",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                        />
                      )}
                      {activityFoundView &&
                      userSearch.hasOwnProperty("activitiesFound") &&
                      userSearch.activitiesFound[activityFoundIndex]
                        .itemFound === "hsiRisk" &&
                      item.riskID ===
                        userSearch.activitiesFound[activityFoundIndex]
                          .riskID ? (
                        highlightRisks(navSearch, item.description, "yellow")
                      ) : (
                        <div
                          style={{
                            paddingTop: "14px",
                            paddingBottom: "14px",
                            marginLeft: "3%",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            {item.description}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })
              : null}
          </div>

          <div
            className={"CancelSaveSectionBuffer"}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "25px",
              gap: 10,
              opacity: detailsEdit ? 1 : 0,
              // marginTop: "-50px",
            }}
          >
            <button
              onClick={() => {
                setDetailsEdit(false);
                editActivitySMEInteraction(
                  activeProjectActivity.activityId,
                  defaultSME
                );
                editActivityHSIRisks(
                  activeProjectActivity.activityId,
                  initialRisks
                );
                console.log("cancel initialRisks", initialRisks);
                setTitleChanged(false);
                setDescriptionChanged(false);
                setActivityRisksChanged(false);
                // setRisksChanged(false);
                setSMEChanged(false);
                setActivityChangeState(false);
              }}
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: detailsEdit ? "pointer" : "default",
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                editActivityTitle(
                  activeProjectActivity.activityId,
                  activeProjectActivity.projectId,
                  titleText
                );
                editActivityDescription(
                  activeProjectActivity.activityId,
                  activeProjectActivity.projectId,
                  descriptionText
                );
                setDefaultSME(activeProjectActivity?.smeInteraction);
                // setRisksObject(activeProjectActivity?.hsiRisk);
                setInitialRisks(activeProjectActivity?.hsiRisk);
                setDetailsEdit(false);
                setTitleChanged(false);
                setDescriptionChanged(false);
                setActivityRisksChanged(false);
                // setRisksChanged(false);
                setSMEChanged(false);
                setActivityChangeState(false);
              }}
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: detailsEdit ? "pointer" : "default",
              }}
            >
              Save
            </button>
          </div>
        </div>
      ) : activeProjectActivity && tabState === "libraries" ? (
        <div
          // className="tabcontent"
          style={{
            paddingLeft: "40px",
          }}
        >
          <div
            style={{
              display: "flex",
              marginTop: "20px",
            }}
          >
            <i
              onClick={() => {
                if (editCanvas) setLibrariesEdit(true);
              }}
              style={{
                color: style.colors.primaryBlue,
                marginLeft: "auto",
                marginRight: "30px",
                opacity: editCanvas ? (librariesEdit ? 0 : 1) : 0,
                cursor: editCanvas
                  ? librariesEdit
                    ? "default"
                    : "pointer"
                  : "default",
              }}
              className="fa fa-pencil"
            ></i>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ fontWeight: "bold", marginBottom: 0 }}>
              Products: Inputs
            </p>
            {librariesEdit ? (
              <p
                onClick={() => {
                  if (activeProjectActivity) {
                    if (
                      activeProjectActivity.hasOwnProperty("inputProductList")
                    ) {
                      activeProjectActivity.inputProductList
                        .concat(activeProjectActivity.outputProductList)
                        .map((ids: any) => {
                          setActivityIDList(ids);
                        });
                    }
                  }
                  openProductM();
                  setActivityLibrary(true);
                }}
                style={{
                  color: style.colors.primaryBlue,
                  cursor: "pointer",
                  fontSize: 13,
                  marginLeft: "1vw",
                  marginBottom: 0,
                }}
              >
                Add/Edit Products: Inputs & Outputs
              </p>
            ) : null}
          </div>

          <div
            style={{
              marginLeft: "2px",
              paddingRight: "10px",
            }}
          >
            {activityProductsInputs.length > 0
              ? activityProductsInputs.map((item: any, index: number) => {
                  return (
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      key={item.productId}
                    >
                      {activityFoundView &&
                      userSearch.hasOwnProperty("activitiesFound") &&
                      userSearch.activitiesFound[activityFoundIndex]
                        .itemFound === "productList" &&
                      item.productId ===
                        userSearch.activitiesFound[activityFoundIndex]
                          .productID ? (
                        highlightMappedItems(
                          navSearch,
                          item.name,
                          "yellow",
                          "product",
                          index + 1 === activityProductsInputs.length
                        )
                      ) : (
                        <div
                          style={{
                            paddingTop: "14px",
                            paddingBottom:
                              index + 1 === activityProductsInputs.length
                                ? "0px"
                                : "14px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setDetailsProduct(item.name);
                              openProductM();
                            }}
                          >
                            {item.name}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })
              : null}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontWeight: "bold", marginBottom: 0, marginTop: "26px" }}
            >
              Products: Outputs
            </p>
            {/* {librariesEdit ? (
              <p
                onClick={() => {
                  if (activeProjectActivity) {
                    if (
                      activeProjectActivity.hasOwnProperty("outputProductList")
                    ) {
                      activeProjectActivity.productList.map((ids: any) => {
                        setActivityIDList(ids);
                      });
                    }
                  }
                  openProductM();
                  setActivityLibrary(true);
                }}
                style={{
                  color: style.colors.primaryBlue,
                  cursor: "pointer",
                  fontSize: 13,
                  marginLeft: "1vw",
                  marginBottom: 0,
                }}
              >
                Add/Edit Products: Outputs
              </p>
            ) : null} */}
          </div>

          <div
            style={{
              marginLeft: "2px",
              paddingRight: "10px",
            }}
          >
            {activityProductsOutputs.length > 0
              ? activityProductsOutputs.map((item: any, index: number) => {
                  return (
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      key={item.productId}
                    >
                      {activityFoundView &&
                      userSearch.hasOwnProperty("activitiesFound") &&
                      userSearch.activitiesFound[activityFoundIndex]
                        .itemFound === "productList" &&
                      item.productId ===
                        userSearch.activitiesFound[activityFoundIndex]
                          .productID ? (
                        highlightMappedItems(
                          navSearch,
                          item.name,
                          "yellow",
                          "product",
                          index + 1 === activityProductsOutputs.length
                        )
                      ) : (
                        <div
                          style={{
                            paddingTop: "14px",
                            paddingBottom:
                              index + 1 === activityProductsOutputs.length
                                ? "0px"
                                : "14px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setDetailsProduct(item.name);
                              openProductM();
                            }}
                          >
                            {item.name}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })
              : null}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                marginBottom: 0,
                marginTop: "26px",
              }}
            >
              References
            </p>
            {librariesEdit ? (
              <p
                onClick={() => {
                  if (activeProjectActivity) {
                    if (activeProjectActivity.hasOwnProperty("referenceList")) {
                      activeProjectActivity.referenceList.map((ids: any) => {
                        setActivityIDList(ids);
                      });
                    }
                  }
                  openReferenceM();
                  setActivityLibrary(true);
                }}
                style={{
                  color: style.colors.primaryBlue,
                  cursor: "pointer",
                  fontSize: 13,
                  marginLeft: "1vw",
                  marginBottom: 0,
                }}
              >
                Add/Edit References
              </p>
            ) : null}
          </div>

          <div
            style={{
              marginLeft: "2px",
              paddingRight: "10px",
            }}
          >
            {activityReferences.length > 0
              ? activityReferences.map((item: any, index: number) => {
                  return (
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      key={item.referenceId}
                    >
                      {activityFoundView &&
                      userSearch.hasOwnProperty("activitiesFound") &&
                      userSearch.activitiesFound[activityFoundIndex]
                        .itemFound === "referenceList" &&
                      item.referenceId ===
                        userSearch.activitiesFound[activityFoundIndex]
                          .referenceID ? (
                        highlightMappedItems(
                          navSearch,
                          item.shortName,
                          "yellow",
                          "reference",
                          index + 1 === activityReferences.length
                        )
                      ) : (
                        <div
                          style={{
                            paddingTop: "14px",
                            paddingBottom:
                              index + 1 === activityReferences.length
                                ? "0px"
                                : "14px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setDetailsReference(item.shortName);
                              openReferenceM();
                            }}
                          >
                            {item.shortName}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })
              : null}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontWeight: "bold", marginBottom: 0, marginTop: "26px" }}
            >
              DIDs
            </p>
            {librariesEdit ? (
              <p
                onClick={() => {
                  if (activeProjectActivity) {
                    if (activeProjectActivity.hasOwnProperty("didsList")) {
                      activeProjectActivity.didsList.map((ids: any) => {
                        setActivityIDList(ids);
                      });
                    }
                  }
                  openDIDSM();
                  setActivityLibrary(true);
                }}
                style={{
                  color: style.colors.primaryBlue,
                  cursor: "pointer",
                  fontSize: 13,
                  marginLeft: "1vw",
                  marginBottom: 0,
                }}
              >
                Add/Edit DIDs
              </p>
            ) : null}
          </div>

          <div
            style={{
              marginLeft: "2px",
              paddingRight: "10px",
            }}
          >
            {activityDIDS.length > 0
              ? activityDIDS.map((item: any, index: number) => {
                  return (
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      key={item.didsID}
                    >
                      {activityFoundView &&
                      userSearch.hasOwnProperty("activitiesFound") &&
                      userSearch.activitiesFound[activityFoundIndex]
                        .itemFound === "referenceList" &&
                      item.didsID ===
                        userSearch.activitiesFound[activityFoundIndex]
                          .didsID ? (
                        highlightMappedItems(
                          navSearch,
                          item.documentID,
                          "yellow",
                          "did",
                          index + 1 === activityDIDS.length
                        )
                      ) : (
                        <div
                          style={{
                            paddingTop: "14px",
                            paddingBottom:
                              index + 1 === activityDIDS.length
                                ? "0px"
                                : "14px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setDetailsDID(item.documentID);
                              openDIDSM();
                            }}
                          >
                            {item.documentID}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })
              : null}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontWeight: "bold", marginBottom: 0, marginTop: "26px" }}
            >
              Tools
            </p>
            {librariesEdit ? (
              <p
                onClick={() => {
                  if (activeProjectActivity) {
                    if (activeProjectActivity.hasOwnProperty("toolsList")) {
                      activeProjectActivity.toolsList.map((ids: any) => {
                        setActivityIDList(ids);
                      });
                    }
                  }
                  openToolsM();
                  setActivityLibrary(true);
                }}
                style={{
                  color: style.colors.primaryBlue,
                  cursor: "pointer",
                  fontSize: 13,
                  marginLeft: "1vw",
                  marginBottom: 0,
                }}
              >
                Add/Edit Tools
              </p>
            ) : null}
          </div>

          <div
            style={{
              marginLeft: "2px",
              paddingRight: "10px",
              // height: "91px",
            }}
          >
            {activityTools.length > 0
              ? activityTools.map((item: any, index: number) => {
                  return (
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      key={item.toolId}
                    >
                      {activityFoundView &&
                      userSearch.hasOwnProperty("activitiesFound") &&
                      userSearch.activitiesFound[activityFoundIndex]
                        .itemFound === "referenceList" &&
                      item.toolId ===
                        userSearch.activitiesFound[activityFoundIndex]
                          .toolID ? (
                        highlightMappedItems(
                          navSearch,
                          item.name,
                          "yellow",
                          "tool",
                          index + 1 === activityTools.length
                        )
                      ) : (
                        <div
                          style={{
                            paddingTop: "14px",
                            paddingBottom:
                              index + 1 === activityTools.length
                                ? "0px"
                                : "14px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setDetailsTool(item.name);
                              openToolsM();
                            }}
                          >
                            {item.name}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })
              : null}
          </div>

          <div
            className={"CancelSaveSectionBuffer"}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "25px",
              gap: 10,
              opacity: librariesEdit ? 1 : 0,
              // marginTop: "-18px",
            }}
          >
            <button
              onClick={() => {
                setActivityLibrary(false);
                setLibrariesEdit(false);
                setActivityProductsChanged(false);
                setActivityReferencesChanged(false);
                setActivityDidsChanged(false);
                setActivityToolsChanged(false);
                editActivityProductInputsList(
                  activeProjectActivity?.activityId,
                  initialProductsInputs
                );
                editActivityProductOutputsList(
                  activeProjectActivity?.activityId,
                  initialProductsOutputs
                );
                editActivityReferenceList(
                  activeProjectActivity?.activityId,
                  initialReferences
                );
                editActivityDIDSList(
                  activeProjectActivity.activityId,
                  initialDids
                );
                editActivityToolsList(
                  activeProjectActivity.activityId,
                  initialTools
                );
                setActivityChangeState(false);
              }}
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: librariesEdit ? "pointer" : "default",
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setLibrariesEdit(false);
                handleProductInputsOccurr(
                  initialReferences,
                  activeProjectActivity?.referenceList
                    ? activeProjectActivity?.referenceList
                    : [],
                  activeProjectActivity?.phaseName,
                  activeProjectActivity?.domainName,
                  pathNames[Number(activeProject?.modelId) - 1],
                  activeProjectActivity?.activityId
                );
                handleProductOutputsOccurr(
                  initialReferences,
                  activeProjectActivity?.referenceList
                    ? activeProjectActivity?.referenceList
                    : [],
                  activeProjectActivity?.phaseName,
                  activeProjectActivity?.domainName,
                  pathNames[Number(activeProject?.modelId) - 1],
                  activeProjectActivity?.activityId
                );
                handleRefOccurr(
                  initialReferences,
                  activeProjectActivity?.referenceList
                    ? activeProjectActivity?.referenceList
                    : [],
                  activeProjectActivity?.phaseName,
                  activeProjectActivity?.domainName,
                  pathNames[Number(activeProject?.modelId) - 1],
                  activeProjectActivity?.activityId
                );
                handleDidsOccurr(
                  initialDids,
                  activeProjectActivity?.didsList
                    ? activeProjectActivity?.didsList
                    : [],
                  activeProjectActivity?.phaseName,
                  activeProjectActivity?.domainName,
                  pathNames[Number(activeProject?.modelId) - 1],
                  activeProjectActivity?.activityId
                );
                handleToolsOccurr(
                  initialTools,
                  activeProjectActivity?.toolsList
                    ? activeProjectActivity?.toolsList
                    : [],
                  activeProjectActivity?.phaseName,
                  activeProjectActivity?.domainName,
                  pathNames[Number(activeProject?.modelId) - 1],
                  activeProjectActivity?.activityId
                );
                setInitialProductsInputs(
                  activeProjectActivity?.inputProductList
                    ? activeProjectActivity?.inputProductList
                    : []
                );
                setInitialProductsOutputs(
                  activeProjectActivity?.outputProductList
                    ? activeProjectActivity?.outputProductList
                    : []
                );
                setInitialReferences(
                  activeProjectActivity?.referenceList
                    ? activeProjectActivity?.referenceList
                    : []
                );
                setInitialDids(
                  activeProjectActivity?.didsList
                    ? activeProjectActivity?.didsList
                    : []
                );
                setInitialTools(
                  activeProjectActivity?.toolsList
                    ? activeProjectActivity?.toolsList
                    : []
                );
                setActivityProductsChanged(false);
                setActivityReferencesChanged(false);
                setActivityDidsChanged(false);
                setActivityToolsChanged(false);
                setActivityChangeState(false);
              }}
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: librariesEdit ? "pointer" : "default",
              }}
            >
              Save
            </button>
          </div>
        </div>
      ) : activeProjectActivity && tabState === "management" ? (
        <div
          // className="tabcontent"
          style={{
            paddingLeft: "40px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <i
              onClick={() => {
                if (editCanvas) setManagementEdit(true);
              }}
              style={{
                color: style.colors.primaryBlue,
                opacity: editCanvas ? (managementEdit ? 0 : 1) : 0,
                cursor: editCanvas
                  ? managementEdit
                    ? "default"
                    : "pointer"
                  : "default",
                marginLeft: "auto",
                marginRight: "30px",
              }}
              className="fa fa-pencil"
            ></i>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                marginLeft: "0px",
                fontWeight: "bold",
                marginBottom: 0,
              }}
            >
              Trade Offs
            </p>
          </div>
          <div
            style={{
              marginLeft: "2px",
              paddingRight: "10px",
              marginTop: managementEdit ? "0px" : "-20px",
              paddingBottom: managementEdit ? "6px" : 0,
            }}
          >
            {managementEdit ? (
              <textarea
                value={tradeText}
                onChange={(e) => {
                  setTradeText(e.target.value);
                  setTradeChanged(
                    activeProjectActivity?.tradeOffs === e.target.value
                      ? false
                      : true
                  );
                }}
                style={{
                  width: "96%",
                  height: "97.5px",
                  cursor: "default",
                  textAlign: "left",
                  marginLeft: "0px",
                  resize: "none",
                  fontFamily: "OpenSans-Regular",
                }}
                placeholder={tradeText.length > 0 ? tradeText : "Trade Offs..."}
              />
            ) : activityFoundView &&
              userSearch.hasOwnProperty("activitiesFound") &&
              userSearch.activitiesFound[activityFoundIndex].itemFound ===
                "tradeOffs" ? (
              highlightManagementItems(
                navSearch,
                activeProjectActivity?.tradeOffs,
                "yellow"
              )
            ) : (
              <div
                style={{
                  marginTop: "14px",
                  marginBottom: "14px",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {activeProjectActivity?.tradeOffs}
                </span>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                marginLeft: "0px",
                fontWeight: "bold",
                marginTop: managementEdit ? "16px" : "26px",
                marginBottom: 0,
              }}
            >
              Challenges & Lessons Learned
            </p>
          </div>
          <div
            style={{
              marginLeft: "2px",
              paddingRight: "10px",
              marginTop: managementEdit ? "0px" : "-20px",
              paddingBottom: managementEdit ? "6px" : 0,
            }}
          >
            {managementEdit ? (
              <textarea
                value={challengesText}
                onChange={(e) => {
                  setChallengesText(e.target.value);
                  setChallengesChanged(
                    activeProjectActivity?.challenges === e.target.value
                      ? false
                      : true
                  );
                }}
                style={{
                  width: "96%",
                  height: "97.5px",
                  cursor: "default",
                  textAlign: "left",
                  marginLeft: "0px",
                  resize: "none",
                  fontFamily: "OpenSans-Regular",
                }}
                placeholder={
                  challengesText.length > 0
                    ? challengesText
                    : "Challenges & Lessons Learned..."
                }
              />
            ) : activityFoundView &&
              userSearch.hasOwnProperty("activitiesFound") &&
              userSearch.activitiesFound[activityFoundIndex].itemFound ===
                "challenges" ? (
              highlightManagementItems(
                navSearch,
                activeProjectActivity?.challenges,
                "yellow"
              )
            ) : (
              <div
                style={{
                  marginTop: "14px",
                  marginBottom: "14px",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {activeProjectActivity?.challenges}
                </span>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                marginLeft: "0px",
                fontWeight: "bold",
                marginTop: managementEdit ? "16px" : "26px",
                marginBottom: 0,
              }}
            >
              Contractor Deliverables
            </p>
          </div>
          <div
            style={{
              marginLeft: "2px",
              paddingRight: "10px",
              marginTop: managementEdit ? "0px" : "-20px",
              paddingBottom: managementEdit ? "6px" : 0,
            }}
          >
            {managementEdit ? (
              <textarea
                value={contractorText}
                onChange={(e) => {
                  setContractorText(e.target.value);
                  setContractorChanged(
                    activeProjectActivity?.contractorDeliverables ===
                      e.target.value
                      ? false
                      : true
                  );
                }}
                style={{
                  width: "96%",
                  height: "97.5px",
                  cursor: "default",
                  textAlign: "left",
                  marginLeft: "0px",
                  resize: "none",
                  fontFamily: "OpenSans-Regular",
                }}
                placeholder={
                  contractorText.length > 0
                    ? contractorText
                    : "Contractor Deliverables..."
                }
              />
            ) : activityFoundView &&
              userSearch.hasOwnProperty("activitiesFound") &&
              userSearch.activitiesFound[activityFoundIndex].itemFound ===
                "contractorDeliverables" ? (
              highlightManagementItems(
                navSearch,
                activeProjectActivity?.contractorDeliverables,
                "yellow"
              )
            ) : (
              <div
                style={{
                  marginTop: "14px",
                  marginBottom: "14px",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {activeProjectActivity?.contractorDeliverables}
                </span>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                marginLeft: "0px",
                fontWeight: "bold",
                marginTop: managementEdit ? "16px" : "26px",
                marginBottom: 0,
              }}
            >
              Notes
            </p>
          </div>
          <div
            style={{
              marginLeft: "2px",
              paddingRight: "10px",
              marginTop: managementEdit ? "0px" : "-20px",
              paddingBottom: managementEdit ? "35px" : 0,
            }}
          >
            {managementEdit ? (
              <textarea
                value={recommendationsText}
                onChange={(e) => {
                  setRecommendationsText(e.target.value);
                  setNotesChanged(
                    activeProjectActivity?.recommendations === e.target.value
                      ? false
                      : true
                  );
                }}
                style={{
                  width: "96%",
                  height: "97.5px",
                  cursor: "default",
                  textAlign: "left",
                  marginLeft: "0px",
                  resize: "none",
                  fontFamily: "OpenSans-Regular",
                }}
                placeholder={
                  recommendationsText.length > 0
                    ? recommendationsText
                    : "Notes..."
                }
              />
            ) : activityFoundView &&
              userSearch.hasOwnProperty("activitiesFound") &&
              userSearch.activitiesFound[activityFoundIndex].itemFound ===
                "recommendations" ? (
              highlightManagementItems(
                navSearch,
                activeProjectActivity?.recommendations,
                "yellow"
              )
            ) : (
              <div
                style={{
                  marginTop: "14px",
                  marginBottom: "14px",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {activeProjectActivity?.recommendations}
                </span>
              </div>
            )}
          </div>
          <div
            className={"CancelSaveSectionBuffer"}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "25px",
              gap: 10,
              opacity: managementEdit ? 1 : 0,
              // marginTop: "20px",
            }}
          >
            <button
              onClick={() => {
                setManagementEdit(false);
                setTradeChanged(false);
                setChallengesChanged(false);
                setContractorChanged(false);
                setNotesChanged(false);
                setTradeText(activeProjectActivity?.tradeOffs);
                setChallengesText(activeProjectActivity?.challenges);
                setContractorText(
                  activeProjectActivity?.contractorDeliverables
                );
                setRecommendationsText(activeProjectActivity?.recommendations);
                setActivityChangeState(false);
              }}
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: managementEdit ? "pointer" : "default",
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                editActivityTradeOffs(
                  activeProjectActivity.activityId,
                  activeProjectActivity.projectId,
                  tradeText
                );
                editActivityChallenges(
                  activeProjectActivity.activityId,
                  activeProjectActivity.projectId,
                  challengesText
                );
                editActivityContDel(
                  activeProjectActivity.activityId,
                  activeProjectActivity.projectId,
                  contractorText
                );
                editActivityRecommendations(
                  activeProjectActivity.activityId,
                  activeProjectActivity.projectId,
                  recommendationsText
                );
                setManagementEdit(false);
                setTradeChanged(false);
                setChallengesChanged(false);
                setContractorChanged(false);
                setNotesChanged(false);
                setActivityChangeState(false);
              }}
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: managementEdit ? "pointer" : "default",
              }}
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "30vh" }}>
          <p className="noActivityText">Select an Activity Box</p>
          <p className="noActivityText">to View More Details</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  activityProductsInputs: state.projects.activityProductsInputs,
  activityProductsOutputs: state.projects.activityProductsOutputs,
  activityReferences: state.projects.activityReferences,
  activityDIDS: state.projects.activityDIDS,
  activityTools: state.projects.activityTools,
  editCanvas: state.projects.editCanvas,
  initialRisks: state.projects.initialRisks,
  activityRisksChanged: state.projects.activityRisksChanged,
  initialProductsInputs: state.projects.initialProductsInputs,
  initialProductsOutputs: state.projects.initialProductsOutputs,
  activityProductsChanged: state.projects.activityProductsChanged,
  initialReferences: state.projects.initialReferences,
  activityReferencesChanged: state.projects.activityReferencesChanged,
  initialDids: state.projects.initialDids,
  activityDidsChanged: state.projects.activityDidsChanged,
  initialTools: state.projects.initialTools,
  activityToolsChanged: state.projects.activityToolsChanged,
  userSearch: state.data.userSearch,
  activityFoundIndex: state.data.activityFoundIndex,
  activityFoundView: state.data.activityFoundView,
  navSearch: state.data.navSearch,
  activeProject: state.projects.activeProject,
});

export default connect(mapStateToProps, {
  setActivityLibrary,
  setActivityIDList,
  getActivityProductInputsData,
  getActivityProductOutputsData,
  getActivityReferenceData,
  getActivityDidsData,
  getActivityToolsData,
  setActivityChangeState,
  editActivityProductInputsList,
  editActivityProductOutputsList,
  editActivityReferenceList,
  editActivityDIDSList,
  editActivityHSIRisks,
  setInitialRisks,
  setActivityRisksChanged,
  setInitialProductsInputs,
  setInitialProductsOutputs,
  setActivityProductsChanged,
  setInitialReferences,
  setActivityReferencesChanged,
  setInitialDids,
  setActivityDidsChanged,
  setInitialTools,
  setActivityToolsChanged,
  editActivityToolsList,
  handleProductInputsOccurr,
  handleProductOutputsOccurr,
  handleRefOccurr,
  handleDidsOccurr,
  handleToolsOccurr,
})(MenuTabContent) as any;
