import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./didsModal.css";
import {
  addNewDIDs,
  deleteDIDs,
  getDIDSLibrary,
  updateDIDs,
} from "../../redux/actions/Data";
import { editActivityDIDSList } from "../../redux/actions/Activities";
import {
  setActivityIDList,
  setActivityDidsChanged,
} from "../../redux/actions/Projects";
import { didsSort } from "../../lib/helpers/columnFilters";
import { ReactComponent as Editsvg } from "../../lib/assets/img/Edit.svg";

const DIDSModal: React.FC<{
  closeDIDSM: any;
  didsM: any;
  didsLibrary: any;
  getDIDSLibrary: () => void;
  style: any;
  addNewDIDs: (documentID: string, name: string, link: string) => void;
  updateDIDs: (
    documentID: string,
    name: string,
    link: string,
    didsID: string
  ) => void;
  deleteDIDs: (id: string) => void;
  activityLibraryEdit: boolean;
  editActivityDIDSList: (activityId: string, list: any) => void;
  activeProjectActivity: any;
  setActivityIDList: (itemID: any) => void;
  activityIDList: any;
  setActivityDidsChanged: (state: any) => void;
  activityDidsChanged: boolean;
  initialDids: any;
  navSearch: string;
  detailsDID: any;
  setDetailsDID: any;
  user: any;
}> = ({
  closeDIDSM,
  didsM,
  didsLibrary,
  getDIDSLibrary,
  style,
  addNewDIDs,
  updateDIDs,
  deleteDIDs,
  activityLibraryEdit,
  editActivityDIDSList,
  activeProjectActivity,
  setActivityIDList,
  activityIDList,
  setActivityDidsChanged,
  activityDidsChanged,
  initialDids,
  navSearch,
  detailsDID,
  setDetailsDID,
  user,
}) => {
  const [newDIDSModal, setNewDIDSModal] = useState(false) as any;
  const [didsSearch, setDidsSearch] = useState("") as any;
  const [searchFiltered, setSearchFiltered] = useState([]) as any;
  const [newDocumentID, setNewDocumentID] = useState("") as any;
  const [newName, setNewName] = useState("") as any;
  const [newLink, setNewLink] = useState("") as any;
  const [documentIDError, setDocumentIDError] = useState(false) as any;
  const [nameError, setNameError] = useState(false) as any;
  const [linkError, setLinkError] = useState(false) as any;
  const [deleteOption, setDeleteOption] = useState(false) as any;
  const [focusId, setFocusId] = useState("") as any;
  const [focusOccurr, setFocusOccurr] = useState([]) as any;
  const [documentIDAscending, setDocumentIDAscending] = useState(0) as any;
  const [nameAscending, setNameAscending] = useState(1) as any;
  const [linkAscending, setLinkAscending] = useState(0) as any;
  const [idList, setIDList] = useState([]) as any;
  const [deleteModal, setDeleteModal] = useState(false) as any;
  const [currentSort, setCurrentSort] = useState("document") as any;
  const [dateCreated, setDateCreated] = useState() as any;

  useEffect(() => {
    getDIDSLibrary();
  }, []);

  useEffect(() => {
    setDidsSearch(navSearch);
    if (detailsDID.length > 0) {
      setDidsSearch(detailsDID);
    }
  }, [navSearch, detailsDID]);

  useEffect(() => {
    let didsList = didsLibrary.filter((x) => !x.hasOwnProperty("init_id"));

    if (didsSearch !== "" && didsLibrary?.length > 0) {
      didsList = didsList.filter(
        (item: any) =>
          item?.documentID?.toLowerCase().indexOf(didsSearch.toLowerCase()) >
            -1 ||
          item?.name?.toLowerCase().indexOf(didsSearch.toLowerCase()) > -1
      );
    }

    didsList = didsSort(
      didsList,
      documentIDAscending,
      nameAscending,
      linkAscending
    );

    setSearchFiltered(didsList);
  }, [
    didsSearch,
    didsLibrary,
    documentIDAscending,
    nameAscending,
    linkAscending,
  ]);

  const documentIDColumn = () => {
    setCurrentSort(currentSort !== "document" ? "document" : currentSort);
    setNameAscending(0);
    setLinkAscending(0);
    documentIDAscending === 1
      ? setDocumentIDAscending(2)
      : setDocumentIDAscending(1);
  };

  const nameColumn = () => {
    setCurrentSort(currentSort !== "name" ? "name" : currentSort);
    setDocumentIDAscending(0);
    setLinkAscending(0);
    nameAscending === 1 ? setNameAscending(2) : setNameAscending(1);
  };

  const linkColumn = () => {
    setCurrentSort(currentSort !== "link" ? "link" : currentSort);
    setDocumentIDAscending(0);
    setNameAscending(0);
    linkAscending === 1 ? setLinkAscending(2) : setLinkAscending(1);
  };

  const cancelDids = () => {
    setNewDIDSModal(false);
    setDocumentIDError(false);
    setNameError(false);
    setLinkError(false);
    setFocusId("");
    setNewDocumentID("");
    setNewName("");
    setNewLink("");
    setDateCreated();
  };

  const saveDIDs = () => {
    if (
      newDocumentID.trim() !== "" &&
      newName.trim() !== "" &&
      newLink.trim() !== ""
    ) {
      if (focusId === "") {
        setDocumentIDAscending(documentIDAscending);
        setNameAscending(nameAscending);
        setLinkAscending(linkAscending);
        addNewDIDs(newDocumentID, newName, newLink);
        setNewDIDSModal(false);
        setFocusId("");
        setFocusOccurr([]);
        setNewDocumentID("");
        setNewName("");
        setNewLink("");
        setDateCreated();
        setDocumentIDError(false);
        setNameError(false);
        setLinkError(false);
      } else if (focusId !== "") {
        setDocumentIDAscending(documentIDAscending);
        setNameAscending(nameAscending);
        setLinkAscending(linkAscending);
        updateDIDs(newDocumentID, newName, newLink, focusId);
        setNewDIDSModal(false);
        setFocusId("");
        setFocusOccurr([]);
        setNewDocumentID("");
        setNewName("");
        setNewLink("");
        setDateCreated();
        setDocumentIDError(false);
        setNameError(false);
        setLinkError(false);
      }
    } else {
      if (newDocumentID.trim() === "") {
        setDocumentIDError(true);
      } else {
        setDocumentIDError(false);
      }
      if (newName.trim() === "") {
        setNameError(true);
      } else {
        setNameError(false);
      }
      if (newLink.trim() === "") {
        setLinkError(true);
      } else {
        setLinkError(false);
      }
    }
  };

  const deleteDIDSAction = () => {
    deleteDIDs(focusId);
    setDeleteOption(false);
    setNewDIDSModal(false);
    setFocusId("");
    setFocusOccurr([]);
    setNewDocumentID("");
    setNewName("");
    setNewLink("");
    setDateCreated();
  };

  const closingModal = () => {
    closeDIDSM();
    setDidsSearch("");
    setActivityIDList("delete");
    setDetailsDID("");
    setDateCreated();
  };

  const closeMini = () => {
    setDeleteOption(false);
    setFocusId("");
    setFocusOccurr([]);
    setNewDocumentID("");
    setNewName("");
    setNewLink("");
    setDateCreated();
    cancelDids();
  };

  const handleCheckBox = (selection) => {
    setActivityIDList(selection);
    if (activityIDList.some((obj) => obj.didsID === selection.didsID)) {
      setIDList(
        activityIDList.filter((item: any) => item.didsID !== selection.didsID)
      );
    } else {
      let newArray = activityIDList;
      newArray.push(selection);
      setIDList(newArray);
    }
  };

  const cancelDelete = () => {
    closeMini();
    setDeleteModal(false);
  };

  const deleteItem = () => {
    deleteDIDSAction();
    setDeleteModal(false);
  };

  const cancelButton = () => {
    closeDIDSM();
    setDidsSearch("");
    setActivityIDList("delete");
    setDetailsDID("");
  };

  const checkDidsChanges = (closeType: string) => {
    if (initialDids.length === 0) {
      if (activityIDList.length > 0) {
        if (closeType === "save") {
          setActivityDidsChanged(true);
          editActivityDIDSList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else if (closeType === "exit") {
          showChangesToast();
          setActivityDidsChanged(true);
        } else {
          setActivityDidsChanged(false);
          cancelButton();
        }
      } else {
        if (closeType === "save") {
          setActivityDidsChanged(true);
          editActivityDIDSList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else {
          setActivityDidsChanged(false);
          cancelButton();
        }
      }
    } else {
      if (activityIDList.length === initialDids.length) {
        if (activityIDList.sort().join(",") === initialDids.sort().join(",")) {
          if (closeType === "save") {
            setActivityDidsChanged(true);
            editActivityDIDSList(
              activeProjectActivity.activityId,
              activityIDList
            );
            cancelButton();
          } else if (closeType === "exit") {
            setActivityDidsChanged(false);
            cancelButton();
          } else {
            setActivityDidsChanged(false);
            cancelButton();
          }
        } else {
          showChangesToast();
          setActivityDidsChanged(true);
        }
      } else {
        if (closeType === "save") {
          setActivityDidsChanged(true);
          editActivityDIDSList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else if (closeType === "exit") {
          showChangesToast();
          setActivityDidsChanged(true);
        } else {
          setActivityDidsChanged(false);
          cancelButton();
        }
      }
    }
  };

  return (
    <div style={{ visibility: didsM ? "initial" : "hidden" }}>
      <div className="modal-container-dids">
        <section className="projectModals-dids">
          <i
            className="fa fa-times"
            onClick={() => {
              activityLibraryEdit ? checkDidsChanges("exit") : closingModal();
            }}
            style={{
              cursor: "pointer",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          ></i>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "-20px",
            }}
          >
            <p>DIDs Library</p>
            <input
              className="didsSearchBox"
              value={didsSearch}
              onChange={(e) => setDidsSearch(e.target.value)}
              style={{
                width: "130px",
                height: "20px",
                alignSelf: "center",
                marginRight: "40px",
                padding: "2px",
                border: "1px solid black",
                paddingLeft: "20px",
                borderRadius: "8px",
              }}
              placeholder="Search DIDs"
            />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              onClick={() => setNewDIDSModal(true)}
              style={{
                marginTop: "-5px",
                paddingBottom: "2px",
                fontSize: "13px",
                color: style.colors.primaryBlue,
                cursor: "pointer",
              }}
            >
              + Add New DID
            </p>

            <div
              style={{
                display: activityLibraryEdit ? "flex" : "none",
                gap: 10,
                marginLeft: "auto",
              }}
            >
              <button
                onClick={cancelButton}
                style={{
                  backgroundColor: "grey",
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => checkDidsChanges("save")}
                style={{
                  backgroundColor: style.colors.primaryBlue,
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Save
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "6px",
              borderTop: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            <div
              onClick={documentIDColumn}
              style={{
                // width: "192px",
                width: "7vw",
                minWidth: "91px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont">
                {documentIDAscending === 0
                  ? "Doucument ID"
                  : documentIDAscending === 1
                  ? "Doucument ID ↓"
                  : "Doucument ID ↑"}
              </p>
            </div>
            <div
              onClick={nameColumn}
              style={{
                // width: "518px",
                width: "23vw",
                minWidth: "302px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "1px solid white",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont">
                {nameAscending === 0
                  ? "Name"
                  : nameAscending === 1
                  ? "Name ↓"
                  : "Name ↑"}
              </p>
            </div>
            <div
              onClick={linkColumn}
              style={{
                // width: "172px",
                width: "27vw",
                minWidth: "351px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid black",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont" style={{ marginLeft: "15px" }}>
                {linkAscending === 0
                  ? "Link"
                  : nameAscending === 1
                  ? "Link ↓"
                  : "Link ↑"}
              </p>
            </div>
          </div>
          <div
            className="modal-body"
            style={{
              border: "1px solid black",
              paddingTop: ".75px",
            }}
          >
            {searchFiltered?.map((item: any, index: number) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                }}
              >
                <div
                  style={{
                    // width: "192px",
                    width: "7vw",
                    minWidth: "91px",
                    borderLeft: "none",
                    borderRight: "none",
                    color: "black",
                    // height: "29px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="overFlowTextDIDS responsiveFont">
                    {item.documentID}
                  </p>
                </div>
                <div
                  style={{
                    // width: "518px",
                    width: "23vw",
                    minWidth: "302px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    color: "black",
                    // height: "29px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="overFlowTextDIDS responsiveFont">{item.name}</p>
                </div>
                <div
                  style={{
                    // width: "153px",
                    width: "26vw",
                    minWidth: "335px",
                    borderLeft: "none",
                    borderRight: "none",
                    color: "black",
                    // height: "29px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="overFlowTextDIDS responsiveFont linkText"
                    style={{ cursor: "pointer", paddingLeft: "5px" }}
                    onClick={() => window.open(item.links, "_blank")}
                  >
                    {item.links}
                  </p>
                  {activityLibraryEdit ? (
                    <input
                      onClick={() => handleCheckBox(item)}
                      type="checkbox"
                      checked={
                        activityIDList.some((obj) => obj.didsID === item.didsID)
                          ? true
                          : false
                      }
                      id="didcheck"
                      name="didcheck"
                      value="didcheck"
                    />
                  ) : (
                    <Editsvg
                      className="modalPencil"
                      onClick={() => {
                        setDeleteOption(true);
                        setNewDIDSModal(true);
                        setFocusId(item.didsID);
                        setFocusOccurr(item.occurrences);
                        setNewDocumentID(item.documentID);
                        setNewName(item.name);
                        setNewLink(item.links);
                        setDateCreated(item.dateAdded);
                      }}
                      fill={style.colors.darkBlue}
                      height="2vh"
                      width="2vw"
                      style={{
                        marginLeft: "auto",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>

      <div
        className="modal-container-dids"
        style={{ visibility: newDIDSModal ? "initial" : "hidden" }}
      >
        {/* <div style={{ maxHeight: "420px" }}> */}
        <section className="projectModals-dids">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          >
            <i
              className="fa fa-times"
              onClick={closeMini}
              style={{
                cursor: "pointer",
                alignSelf: "center",
              }}
            ></i>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                // height: "70px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: documentIDError ? "red" : "black",
                }}
              >
                Document ID*:
              </p>
            </div>
            <input
              readOnly={
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                // marginTop: "-20px",
              }}
              value={newDocumentID}
              onChange={(e) => setNewDocumentID(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                // height: "70px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: nameError ? "red" : "black",
                }}
              >
                Document Name*:
              </p>
            </div>
            <input
              readOnly={
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                // marginTop: "-20px",
              }}
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                // height: "70px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: linkError ? "red" : "black",
                }}
              >
                Link*:
              </p>
            </div>
            <input
              readOnly={
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                // marginTop: "-20px",
              }}
              value={newLink}
              onChange={(e) => setNewLink(e.target.value)}
            />
          </div>

          <div
            style={{
              display: focusId !== 0 ? "flex" : "none",
              flexDirection: "row",
            }}
          >
            <div>
              <p style={{ fontWeight: "bold" }}>Occurrences in Pathways</p>
              {focusOccurr?.length > 0 ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "29px",
                      borderTop: "1px solid black",
                      borderLeft: "1px solid black",
                    }}
                  >
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "209px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p className="responsiveFont">Pathway</p>
                    </div>
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "100px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        borderLeft: "1px solid white",
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p className="responsiveFont">Phase</p>
                    </div>
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "107px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        borderLeft: "1px solid white",
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRight: "1px solid black",
                      }}
                    >
                      <p className="responsiveFont">Domain</p>
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid black",
                      paddingTop: ".75px",
                    }}
                  >
                    {focusOccurr?.map((occur, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor:
                            index % 2 === 0 ? style.colors.lightBlue : "white",
                        }}
                      >
                        <div
                          style={{
                            width: "209px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p className="responsiveFont">{occur.pathwayName}</p>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p className="responsiveFont">{occur.phaseName}</p>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p className="responsiveFont">{occur.domainName}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p
                  style={{
                    width: "400px",
                  }}
                >
                  No occurrences since the DID is currently not linked to any
                  activities.
                </p>
              )}
            </div>
          </div>

          <div
            style={{
              display:
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? "flex"
                    : "none"
                  : "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 20,
              marginTop: "10px",
              paddingBottom: focusOccurr?.length > 0 ? "20px" : 0,
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: deleteOption ? "pointer" : "default",
                opacity: deleteOption ? 1 : 0,
              }}
              onClick={() => {
                if (deleteOption) setDeleteModal(true);
              }}
            >
              Delete
            </button>
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={cancelDids}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={saveDIDs}
            >
              Save
            </button>
          </div>
        </section>
        {/* </div> */}
      </div>

      <div
        className="delete-modal-container-dids"
        style={{ visibility: deleteModal ? "initial" : "hidden" }}
      >
        <section className="projectModals-dids">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          >
            <i
              className="fa fa-times"
              onClick={cancelDelete}
              style={{
                cursor: "pointer",
                alignSelf: "center",
              }}
            ></i>
          </div>

          <p style={{ paddingBottom: "1px", textAlign: "center" }}>
            You are about to delete:
          </p>

          <div style={{ paddingBottom: "2px", textAlign: "center" }}>
            <p>{newDocumentID} from the DIDs Library</p>
            <p style={{ paddingBottom: "2px" }}>
              Are you sure you would like to continue?
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 20,
              marginTop: "10px",
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={cancelDelete}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={deleteItem}
            >
              Delete
            </button>
          </div>
        </section>
      </div>
    </div>
  );

  function showChangesToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar6");
    if (element[0]) {
      element[0].className = "snackbar6 show";
      setTimeout(function () {
        element[0].className = "snackbar6";
      }, 5000);
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  didsLibrary: state.data.didsLibrary,
  style: state.local.style,
  activityLibraryEdit: state.projects.activityLibraryEdit,
  activeProjectActivity: state.projects.activeProjectActivity,
  activityIDList: state.projects.activityIDList,
  activityDidsChanged: state.projects.activityDidsChanged,
  initialDids: state.projects.initialDids,
  navSearch: state.data.navSearch,
  user: state.user.user,
});

export default connect(mapStateToProps, {
  getDIDSLibrary,
  addNewDIDs,
  updateDIDs,
  deleteDIDs,
  editActivityDIDSList,
  setActivityIDList,
  setActivityDidsChanged,
})(DIDSModal) as any;
