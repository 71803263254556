import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./Report.css";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { setNavSearch, setFilterBox } from "../../redux/actions/Data";

const ReportTables: React.FC<{
  style: any;
  userReportObject: any;
  tableView: any;
  setTableView: any;
  activityView: any;
  setActivityView: any;
  completedView: any;
  setCompletedView: any;
  referenceView: any;
  setReferenceView: any;
}> = ({
  style,
  userReportObject,
  tableView,
  setTableView,
  activityView,
  setActivityView,
  completedView,
  setCompletedView,
  referenceView,
  setReferenceView,
}) => {
  const riskSection = () => {
    return (
      <div
        style={{
          display: !tableView ? "none" : "block",
          overflowY: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "60px",
            marginTop: "10px",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            // width: "98vw",
          }}
        >
          <div
            style={{
              width: "9vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Risk</p>
          </div>
          <div
            style={{
              width: "9vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity No.</p>
          </div>
          <div
            style={{
              width: "48.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity</p>
          </div>
          <div
            style={{
              width: "10.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Phase</p>
          </div>
          <div
            style={{
              width: "10.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Domain</p>
          </div>
          <div
            style={{
              width: "10.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "1px solid black",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Due Date</p>
          </div>
        </div>
        <div
          className="modal-Reportbody"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {userReportObject.reportData?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                // marginLeft: "60px",
                backgroundColor:
                  index % 2 === 0 ? style.colors.lightBlue : "white",
              }}
            >
              <div
                style={{
                  width: "9vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.hsiRiskLvl}</p>
              </div>
              <div
                style={{
                  width: "9vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.uniID}</p>
              </div>
              <div
                style={{
                  width: "48.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
              >
                <p className="overFlowTextActivity responsiveReportText">
                  {item.summary}
                </p>
              </div>
              <div
                style={{
                  width: "10.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.phaseName}</p>
              </div>
              <div
                style={{
                  width: "10.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.domainName}</p>
              </div>
              <div
                style={{
                  width: "10.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">
                  {item.dueDate === null ||
                  item.dueDate === "" ||
                  item.dueDate === 0
                    ? ""
                    : new Date(item.dueDate).toLocaleDateString("en-US")}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const statusSection = () => {
    return (
      <div
        style={{
          display: !tableView ? "none" : "block",
          overflowY: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "60px",
            marginTop: "10px",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            style={{
              width: "9vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Status</p>
          </div>
          <div
            style={{
              width: "9vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Date Status Updated</p>
          </div>
          <div
            style={{
              width: "9vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity No.</p>
          </div>
          <div
            style={{
              width: "31.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Phase</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Domain</p>
          </div>
          <div
            style={{
              width: "9vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Overdue</p>
          </div>
          <div
            style={{
              width: "10.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1.5px solid white",
              borderRight: "1px solid black",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Due Date</p>
          </div>
        </div>
        <div
          className="modal-Reportbody"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {userReportObject.reportData?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                // marginLeft: "60px",
                backgroundColor:
                  index % 2 === 0 ? style.colors.lightBlue : "white",
              }}
            >
              <div
                style={{
                  width: "9vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.activityStatus}</p>
              </div>
              <div
                style={{
                  width: "9vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">
                  {item.statusUpdatedDate === null ||
                  item.statusUpdatedDate === ""
                    ? ""
                    : new Date(item.statusUpdatedDate).toLocaleDateString(
                        "en-US"
                      )}
                </p>
              </div>
              <div
                style={{
                  width: "9vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.uniID}</p>
              </div>
              <div
                style={{
                  width: "31.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextActivity responsiveReportText"
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  {item.summary}
                </p>
              </div>
              <div
                style={{
                  width: "10vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.phaseName}</p>
              </div>
              <div
                style={{
                  width: "10vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.domainName}</p>
              </div>
              <div
                style={{
                  width: "9vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">-</p>
              </div>
              <div
                style={{
                  width: "10.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">
                  {item.dueDate === null || item.dueDate === ""
                    ? ""
                    : new Date(item.dueDate).toLocaleDateString("en-US")}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const activitySection = () => {
    return (
      <div
        style={{
          display: !tableView ? "none" : "block",
          overflowY: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "60px",
            marginTop: "10px",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Risk</p>
          </div>
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Status</p>
          </div>
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Date Status Updated</p>
          </div>
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity No.</p>
          </div>
          <div
            style={{
              width: "24.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity</p>
          </div>
          <div
            style={{
              width: "3vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">SME</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Phase</p>
          </div>
          <div
            style={{
              width: "7vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Domain</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Overdue</p>
          </div>
          <div
            style={{
              width: "10.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1.5px solid white",
              borderRight: "1px solid black",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Due Date</p>
          </div>
        </div>
        <div
          className="modal-Reportbody"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {userReportObject.reportData?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                // marginLeft: "60px",
                // backgroundColor: index % 2 === 0 ? style.colors.lightBlue : "white",
              }}
            >
              <div
                style={{
                  width: "8vw",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.hsiRiskLvl}</p>
              </div>
              <div
                style={{
                  width: "8vw",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.activityStatus}</p>
              </div>
              <div
                style={{
                  width: "8vw",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">
                  {item.statusUpdatedDate === null ||
                  item.statusUpdatedDate === ""
                    ? ""
                    : new Date(item.statusUpdatedDate).toLocaleDateString(
                        "en-US"
                      )}
                </p>
              </div>
              <div
                style={{
                  width: "8vw",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.uniID}</p>
              </div>
              <div
                style={{
                  width: "24.5vw",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextActivity responsiveReportText"
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  {item.summary}
                </p>
              </div>
              <div
                style={{
                  width: "3vw",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">
                  {item.smeInteraction ? "Y" : "N"}
                </p>
              </div>
              <div
                style={{
                  width: "10vw",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.phaseName}</p>
              </div>
              <div
                style={{
                  width: "7vw",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.domainName}</p>
              </div>
              <div
                style={{
                  width: "10vw",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">-</p>
              </div>
              <div
                style={{
                  width: "10.5vw",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">
                  {item.dueDate === null || item.dueDate === ""
                    ? ""
                    : new Date(item.dueDate).toLocaleDateString("en-US")}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const productSection = () => {
    return (
      <div
        style={{
          display: !tableView ? "none" : "block",
          overflowY: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "60px",
            marginTop: "10px",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Product Name</p>
          </div>
          <div
            style={{
              width: "5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Type</p>
          </div>
          <div
            style={{
              width: "7vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity No.</p>
          </div>
          <div
            style={{
              width: "23.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity</p>
          </div>
          <div
            style={{
              width: "5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">SME</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Phase</p>
          </div>
          <div
            style={{
              width: "7vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Domain</p>
          </div>
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Risk</p>
          </div>
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Status</p>
          </div>
          <div
            style={{
              width: "6vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Overdue</p>
          </div>
          <div
            style={{
              width: "10.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1.5px solid white",
              borderRight: "1px solid black",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText" style={{ marginLeft: "10px" }}>
              Due Date
            </p>
          </div>
        </div>
        <div
          className="modal-Reportbody"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {userReportObject.reportData?.map((item: any, index: number) =>
            item.hasOwnProperty("reportProductList")
              ? item.reportProductList.map((itemData: any) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginLeft: "60px",
                      backgroundColor:
                        index % 2 === 0 ? style.colors.lightBlue : "white",
                    }}
                  >
                    <div
                      style={{
                        width: "8vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">{itemData.name}</p>
                    </div>
                    <div
                      style={{
                        width: "5vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">{itemData.type}</p>
                    </div>
                    <div
                      style={{
                        width: "7vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">{item.uniID}</p>
                    </div>
                    <div
                      style={{
                        width: "23.5vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        // justifyContent: "center",
                        // alignItems: "center",
                      }}
                    >
                      <p
                        className="overFlowTextActivity responsiveReportText"
                        style={{
                          paddingLeft: "10px",
                        }}
                      >
                        {item.summary}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "5vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">
                        {item.smeInteraction}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "10vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">{item.phaseName}</p>
                    </div>
                    <div
                      style={{
                        width: "7vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">{item.domainName}</p>
                    </div>
                    <div
                      style={{
                        width: "8vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">{item.hsiRiskLvl}</p>
                    </div>
                    <div
                      style={{
                        width: "8vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">
                        {item.activityStatus}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "6vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">-</p>
                    </div>
                    <div
                      style={{
                        width: "10.5vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="responsiveReportText"
                        style={{ marginLeft: "10px" }}
                      >
                        {item.dueDate}
                      </p>
                    </div>
                  </div>
                ))
              : null
          )}
        </div>
      </div>
    );
  };

  const referenceSection = () => {
    return (
      <div
        style={{
          display: !referenceView ? "none" : "block",
          overflowY: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "60px",
            marginTop: "10px",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Reference Name</p>
          </div>
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity No.</p>
          </div>
          <div
            style={{
              width: "23.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity</p>
          </div>
          <div
            style={{
              width: "5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">SME</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Phase</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Domain</p>
          </div>
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Risk</p>
          </div>
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Status</p>
          </div>
          <div
            style={{
              width: "5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Overdue</p>
          </div>
          <div
            style={{
              width: "10.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1.5px solid white",
              borderRight: "1px solid black",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText" style={{ marginLeft: "10px" }}>
              Due Date
            </p>
          </div>
        </div>
        <div
          className="modal-Reportbody"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {userReportObject.reportData?.map((item: any, index: number) =>
            item.hasOwnProperty("reportReferenceList")
              ? item.reportReferenceList.map((itemData: any) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginLeft: "60px",
                      backgroundColor:
                        index % 2 === 0 ? style.colors.lightBlue : "white",
                    }}
                  >
                    <div
                      style={{
                        width: "10vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">
                        {itemData.shortName}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "8vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">{0}</p>
                    </div>
                    <div
                      style={{
                        width: "23.5vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        // justifyContent: "center",
                        // alignItems: "center",
                      }}
                    >
                      <p
                        className="overFlowTextActivity responsiveReportText"
                        style={{
                          paddingLeft: "10px",
                        }}
                      >
                        {item.summary}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "5vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">
                        {item.smeInteraction}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "10vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">{item.phaseName}</p>
                    </div>
                    <div
                      style={{
                        width: "10vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">{item.domainName}</p>
                    </div>
                    <div
                      style={{
                        width: "8vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">{item.hsiRiskLvl}</p>
                    </div>
                    <div
                      style={{
                        width: "8vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">
                        {item.activityStatus}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "5vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="responsiveReportText">-</p>
                    </div>
                    <div
                      style={{
                        width: "10.5vw",
                        // backgroundColor:
                        //   index % 2 === 0 ? style.colors.lightBlue : "white",
                        color: "black",
                        borderLeft:
                          index % 2 === 0
                            ? `1px solid ${style.colors.lightBlue}`
                            : "1px solid white",
                        borderRight: "none",
                        minHeight: "4vh",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="responsiveReportText"
                        style={{ marginLeft: "10px" }}
                      >
                        {item.dueDate}
                      </p>
                    </div>
                  </div>
                ))
              : null
          )}
        </div>
      </div>
    );
  };

  const relatedSection = () => {
    return (
      <div
        style={{
          display: !tableView ? "none" : "block",
          overflowY: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "60px",
            marginTop: "10px",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Item</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Related By</p>
          </div>
          <div
            style={{
              width: "14vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Short Name</p>
          </div>
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Level Of Ownership</p>
          </div>
          <div
            style={{
              width: "6vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity No.</p>
          </div>
          <div
            style={{
              width: "34.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Phase</p>
          </div>
          <div
            style={{
              width: "8.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1.5px solid white",
              borderRight: "1px solid black",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Domain</p>
          </div>
        </div>
        <div
          className="modal-Reportbody"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {userReportObject.reportData?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                // marginLeft: "60px",
                backgroundColor:
                  index % 2 === 0 ? style.colors.lightBlue : "white",
              }}
            >
              <div
                style={{
                  width: "8vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">
                  {item?.reportProductList?.length > 0
                    ? "Product"
                    : item?.reportReferenceList?.length > 0
                    ? "Reference"
                    : "Activity"}
                </p>
              </div>
              <div
                style={{
                  width: "10vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">-</p>
              </div>
              <div
                style={{
                  width: "14vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">
                  {item?.reportProductList?.length > 0
                    ? item?.reportProductList[0]?.name
                    : item?.reportReferenceList?.length > 0
                    ? item?.reportReferenceList[0]?.shortName
                    : "-"}
                </p>
              </div>
              <div
                style={{
                  width: "8vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">
                  {item?.reportProductList?.length > 0
                    ? item?.reportProductList[0]?.contributorOwner
                    : "-"}
                </p>
              </div>
              <div
                style={{
                  width: "6vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.uniID}</p>
              </div>
              <div
                style={{
                  width: "34.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextActivity responsiveReportText"
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  {item.summary}
                </p>
              </div>
              <div
                style={{
                  width: "10vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.phaseName}</p>
              </div>
              <div
                style={{
                  width: "8.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="responsiveReportText"
                  style={{ marginLeft: "10px" }}
                >
                  {item.domainName}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const contentActivitySection = () => {
    return (
      <div
        style={{
          display: !activityView ? "none" : "block",
          overflowY: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "60px",
            marginTop: "10px",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Usage</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Date</p>
          </div>
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity No.</p>
          </div>
          <div
            style={{
              width: "48.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Phase</p>
          </div>
          <div
            style={{
              width: "10.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1.5px solid white",
              borderRight: "1px solid black",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Domain</p>
          </div>
        </div>
        <div
          className="modal-Reportbody"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {userReportObject.reportData?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                // marginLeft: "60px",
                backgroundColor:
                  index % 2 === 0 ? style.colors.lightBlue : "white",
              }}
            >
              <div
                style={{
                  width: "10vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">Usage</p>
              </div>
              <div
                style={{
                  width: "10vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">Date</p>
              </div>
              <div
                style={{
                  width: "8vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{0}</p>
              </div>
              <div
                style={{
                  width: "48.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextActivity responsiveReportText"
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  {item.summary}
                </p>
              </div>
              <div
                style={{
                  width: "10vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.phaseName}</p>
              </div>
              <div
                style={{
                  width: "10.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.domainName}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const activityDetailsSection = () => {
    return (
      <div
        style={{
          display: !activityView ? "none" : "block",
          overflowY: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "70px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "60px",
            marginTop: "10px",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Date Status Updated</p>
          </div>
          <div
            style={{
              width: "8vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity No.</p>
          </div>
          <div
            style={{
              width: "49.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activity</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Phase</p>
          </div>
          <div
            style={{
              width: "10vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Domain</p>
          </div>
          <div
            style={{
              width: "10.5vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1.5px solid white",
              borderRight: "1px solid black",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Overdue</p>
          </div>
        </div>
        <div
          className="modal-Reportbody"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {userReportObject.reportData?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                // marginLeft: "60px",
                backgroundColor:
                  index % 2 === 0 ? style.colors.lightBlue : "white",
              }}
            >
              <div
                style={{
                  width: "10vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">-</p>
              </div>
              <div
                style={{
                  width: "8vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.uniID}</p>
              </div>
              <div
                style={{
                  width: "49.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextActivity responsiveReportText"
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  {item.summary}
                </p>
              </div>
              <div
                style={{
                  width: "10vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">-</p>
              </div>
              <div
                style={{
                  width: "10vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">-</p>
              </div>
              <div
                style={{
                  width: "10.5vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">-</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const completedProductsSection = () => {
    return (
      <div
        style={{
          display: !completedView ? "none" : "block",
          overflowY: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "60px",
            marginTop: "10px",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            style={{
              width: "20vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Date Completed</p>
          </div>
          <div
            style={{
              width: "78vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "1px solid black",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Products</p>
          </div>
        </div>
        <div
          className="modal-Reportbody"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {userReportObject.reportData?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                // marginLeft: "60px",
                backgroundColor:
                  index % 2 === 0 ? style.colors.lightBlue : "white",
              }}
            >
              <div
                style={{
                  width: "20vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">-</p>
              </div>
              <div
                style={{
                  width: "78vw",
                  // backgroundColor:
                  //   index % 2 === 0 ? style.colors.lightBlue : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">-</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const activityTableSetup = () => {
    return (
      <>
        <div
          className="reportRowHeaders"
          style={
            {
              // backgroundColor: style.colors.primaryBlue,
              // width: "94vw",
              // height: "35px",
              // display: "flex",
              // marginLeft: "60px",
            }
          }
        >
          <BsCaretDownFill
            className="responsiveReportText"
            onClick={() => setActivityView(!activityView)}
            style={{
              color: style.colors.white,
              // marginTop: "9px",
              // fontSize: "1.65vh",
              marginLeft: "10px",
              cursor: "pointer",
              transform: !activityView ? "rotate(270deg)" : "rotate(0deg)",
            }}
          />
          <p
            className="responsiveReportText"
            style={{
              color: style.colors.white,
              // marginTop: "10px",
              paddingLeft: "40px",
              // fontSize: "1.65vh",
            }}
          >
            Activities Table
          </p>
          <p
            className="responsiveReportText"
            style={{
              color: style.colors.white,
              // marginTop: "10px",
              marginLeft: "auto",
              paddingRight: "20px",
              // fontSize: "1.65vh",
            }}
          >
            {`${userReportObject?.reportData?.length} Item(s)`}
          </p>
        </div>
        {contentActivitySection()}
      </>
    );
  };

  const activityDetailsSetup = () => {
    return (
      <>
        <div
          className="reportRowHeaders"
          style={{
            // backgroundColor: style.colors.primaryBlue,
            // width: "94vw",
            // height: "35px",
            // display: "flex",
            // marginLeft: "60px",
            marginLeft: "20px",
            marginTop: "10px",
          }}
        >
          <BsCaretDownFill
            className="responsiveReportText"
            onClick={() => setActivityView(!activityView)}
            style={{
              color: style.colors.white,
              // marginTop: "9px",
              // fontSize: "1.65vh",
              marginLeft: "10px",
              cursor: "pointer",
              transform: !activityView ? "rotate(270deg)" : "rotate(0deg)",
            }}
          />
          <p
            className="responsiveReportText"
            style={{
              color: style.colors.white,
              // marginTop: "10px",
              paddingLeft: "40px",
              // fontSize: "1.65vh",
            }}
          >
            Activities Details
          </p>
          <p
            className="responsiveReportText"
            style={{
              color: style.colors.white,
              // marginTop: "10px",
              marginLeft: "auto",
              paddingRight: "20px",
              // fontSize: "1.65vh",
            }}
          >
            {`${userReportObject?.reportData?.length} Item(s)`}
          </p>
        </div>
        {activityDetailsSection()}
      </>
    );
  };

  const completedSetup = () => {
    return (
      <>
        <div
          className="reportRowHeaders"
          style={{
            // backgroundColor: style.colors.primaryBlue,
            // width: "94vw",
            // height: "35px",
            // display: "flex",
            // marginLeft: "60px",
            marginLeft: "20px",
            marginTop: "10px",
          }}
        >
          <BsCaretDownFill
            className="responsiveReportText"
            onClick={() => setCompletedView(!completedView)}
            style={{
              color: style.colors.white,
              // marginTop: "9px",
              // fontSize: "1.65vh",
              marginLeft: "10px",
              cursor: "pointer",
              transform: !completedView ? "rotate(270deg)" : "rotate(0deg)",
            }}
          />
          <p
            className="responsiveReportText"
            style={{
              color: style.colors.white,
              // marginTop: "10px",
              paddingLeft: "40px",
              // fontSize: "1.65vh",
            }}
          >
            Completed Products
          </p>
          <p
            className="responsiveReportText"
            style={{
              color: style.colors.white,
              // marginTop: "10px",
              marginLeft: "auto",
              paddingRight: "20px",
              // fontSize: "1.65vh",
            }}
          >
            {`${userReportObject?.reportData?.length} Item(s)`}
          </p>
        </div>
        {completedProductsSection()}
      </>
    );
  };

  return (
    <div className="reportTableSection">
      {userReportObject.userReportType === "Content Report" ? (
        <>{activityTableSetup()}</>
      ) : null}

      <div
        className="reportRowHeaders"
        style={{
          // backgroundColor: style.colors.primaryBlue,
          // width: "94vw",
          // height: "35px",
          // display: "flex",
          marginLeft: "20px",
          // paddingLeft: "60px",
          marginTop:
            userReportObject.userReportType === "Related Items Report"
              ? "10px"
              : "0px",
        }}
      >
        <BsCaretDownFill
          className="responsiveReportText"
          onClick={() => setTableView(!tableView)}
          style={{
            color: style.colors.white,
            // marginTop: "9px",
            // fontSize: "1.65vh",
            marginLeft: "10px",
            cursor: "pointer",
            transform: !tableView ? "rotate(270deg)" : "rotate(0deg)",
          }}
        />
        <p
          className="responsiveReportText"
          style={{
            color: style.colors.white,
            // marginTop: "10px",
            paddingLeft: "40px",
            // fontSize: "1.65vh",
          }}
        >
          {userReportObject.userReportType === "Status Report"
            ? "Status Table"
            : userReportObject.userReportType === "Custom Activities Report"
            ? "Activities Summary Table"
            : userReportObject.userReportType === "Custom Libraries Report"
            ? "Products Table"
            : userReportObject.userReportType === "Related Items Report"
            ? "Related Items Table"
            : userReportObject.userReportType === "Content Report"
            ? "Activities Table"
            : "Risk Table"}
        </p>
        <p
          className="responsiveReportText"
          id="reportDataLength"
          style={{
            color: style.colors.white,
            // marginTop: "10px",
            marginLeft: "auto",
            paddingRight: "20px",
            // fontSize: "1.65vh",
          }}
        >
          {`${userReportObject?.reportData?.length} Item(s)`}
        </p>
      </div>
      <div style={{ paddingBottom: "70px" }}>
        {userReportObject.userReportType === "Risk Report"
          ? riskSection()
          : userReportObject.userReportType === "Status Report"
          ? statusSection()
          : userReportObject.userReportType === "Custom Activities Report"
          ? activitySection()
          : userReportObject.userReportType === "Custom Libraries Report"
          ? productSection()
          : userReportObject.userReportType === "Related Items Report"
          ? relatedSection()
          : userReportObject.userReportType === "Content Report"
          ? productSection()
          : null}
      </div>
      {userReportObject.userReportType === "Custom Activities Report" ? (
        <>
          {completedSetup()}
          {activityDetailsSetup()}
        </>
      ) : null}

      {userReportObject.userReportType === "Custom Libraries Report" ||
      userReportObject.userReportType === "Content Report" ? (
        <>
          <div
            className="reportRowHeaders"
            style={{
              // backgroundColor: style.colors.primaryBlue,
              // width: "94vw",
              // height: "35px",
              // display: "flex",
              // marginLeft: "60px",
              marginLeft: "20px",
              marginTop: "10px",
            }}
          >
            <BsCaretDownFill
              className="responsiveReportText"
              onClick={() => setReferenceView(!referenceView)}
              style={{
                color: style.colors.white,
                // marginTop: "9px",
                // fontSize: "1.65vh",
                marginLeft: "10px",
                cursor: "pointer",
                transform: !referenceView ? "rotate(270deg)" : "rotate(0deg)",
              }}
            />
            <p
              className="responsiveReportText"
              style={{
                color: style.colors.white,
                // marginTop: "10px",
                paddingLeft: "40px",
                // fontSize: "1.65vh",
              }}
            >
              References Table
            </p>
            <p
              className="responsiveReportText"
              style={{
                color: style.colors.white,
                marginLeft: "auto",
                paddingRight: "20px",
              }}
            >
              {`${userReportObject?.reportData?.length} Item(s)`}
            </p>
          </div>
          {referenceSection()}
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  userReportObject: state.projects.projectToReport,
});

export default connect(mapStateToProps, {
  setNavSearch,
  setFilterBox,
})(ReportTables) as any;
