import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../lib/assets/img/hsifWhite.png";
import microsoft from "../../lib/assets/img/microsoft.png";
import config from "../../config.json";
import "./Login.css";
import { delay } from "../../lib/helpers/time";
import { authLoading, getUser } from "../../redux/actions/User";
import { login } from "../../redux/actions/Auth";
import { setActiveProject, updateRecent } from "../../redux/actions/Projects";

const Login: React.FC<{
  user: any;
  style: any;
  authLoading: (loading: boolean) => void;
  auth_loading: boolean;
  setActiveProject: (project: any) => void;
  updateRecent: (oid: string, projectId: string) => void;
}> = ({
  user,
  style,
  authLoading,
  auth_loading,
  setActiveProject,
  updateRecent,
}) => {
  const url = `${config.api}auth/login`;
  let navigate = useNavigate();
  const removeFlash = async () => {
    authLoading(true);
    await delay(2000);
    if (user.openProjectOnLogin) {
      setActiveProject(user.openProjectOnLogin);
      updateRecent(user.oid, user.openProjectOnLogin.projectId);
      navigate("/project");
    } else {
      navigate("/dashboard");
    }
  };
  useEffect(() => {
    if (user) removeFlash();
    return () => authLoading(false);
  }, [user]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: style.colors.darkBlue,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={logo} alt="logo" width="6%" height="auto" />

      <h2 className="mainTitle" style={{ color: style.colors.white }}>
        HSI Framework
      </h2>
      <div
        style={{
          width: "20vw",
          // height: "5vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {auth_loading ? (
          <div className="lds-dual-ring" />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <a href={url}>
              <button
                onClick={() => {}}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <img
                  // style={{ height: "4.5vh", width: "11vw" }}
                  src={microsoft}
                  // style={{ margin: "10px 0px" }}
                />
              </button>
            </a>
            <a
              href="https://forms.microsoft.com/r/jZRDLap0NQ"
              target="_blank"
              style={{
                textDecoration: "underline",
                textAlign: "center",
                color: "white",
                marginTop: "10px",
              }}
            >
              Request an Account
            </a>
          </div>
        )}
      </div>

      {/* {!auth_loading ? (
        <p style={{ color: "white", textAlign: "center" }}>
          All users will need to register for HSIF access, including those with
          NPS accounts.
        </p>
      ) : null} */}

      <div
        style={{
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "center",
          // alignItems: "center",
          marginTop: "3vh",
          border: "1px solid white",
          padding: "5px",
          width: "90%",
          // height: "40%"
        }}
      >
        <p
          className="DODTitle"
          style={{
            color: style.colors.white,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          DOD Notice & Consent
        </p>
        <p
          className="DODText"
          style={{
            color: style.colors.white,
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "10px",
            marginBottom: 0,
          }}
        >
          ​ You are accessing a U.S. Government (USG) Information System (IS)
          that is provided for USG-authorized use only.
        </p>
        <p
          className="DODText"
          style={{
            color: style.colors.white,
            textAlign: "center",
            fontWeight: "bold",
            marginTop: 0,
            paddingBottom: "10px",
          }}
        >
          By using this IS (which includes any device attached to this IS), you
          consent to the following conditions:
        </p>
        <ul className="loginList">
          <li className="loginListItem">
            The USG routinely intercepts and monitors communications on this IS
            for purposes including, but not limited to, penetration testing,
            COMSEC monitoring, network operations and defense, personnel
            misconduct (PM), law enforcement (LE), and counterintelligence (CI)
            investigations.
          </li>
          <li className="loginListItem">
            At any time, the USG may inspect and seize data stored on this IS.
          </li>
          <li className="loginListItem">
            Communications using, or data stored on, this IS are not private,
            are subject to routine monitoring, interception, and search, and may
            be disclosed or used for any USG authorized purpose.
          </li>
          <li className="loginListItem">
            This IS includes security measures (e.g., authentication and access
            controls) to protect USG interests--not for your personal benefit or
            privacy.
          </li>
          <li className="loginListItem">
            Any communications occurring on or data stored on this information
            system, or any device attached to this information system, may be
            disclosed or used for any U.S. Government-authorized purpose.
          </li>
          <li className="loginListItem">
            Notwithstanding the above, using this IS does not constitute consent
            to PM, LE or CI investigative searching or monitoring of the content
            of privileged communications, or work product, related to personal
            representation or services by attorneys, psychotherapists, or
            clergy, and their assistants. Such communications and work product
            are private and confidential. See User Agreement for details.
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  style: state.local.style,
  auth_loading: state.user.auth_loading,
  usersProjectList: state.projects.usersProjectList,
  recent: state.projects.recent,
});

export default connect(mapStateToProps, {
  authLoading,
  getUser,
  setActiveProject,
  updateRecent,
})(Login) as any;
