import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./navbar.css";
import { logout } from "../../redux/actions/Auth";
import { getUser, dowloadPdf, dowloadManual } from "../../redux/actions/User";
import logo from "../../lib/assets/img/hsifWhite.png";
import { Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  getUserSearch,
  handleFoundView,
  setFilterBox,
  setNavSearch,
} from "../../redux/actions/Data";
import {
  editProjectCanvas,
  addReportList,
  setProjectToReport,
  setActivityLibrary,
  setCollabSelectionState,
  setCollabType,
  clearCollabItems,
  setActivityDeleteModal,
  setClickedItem,
  exportProject,
  getNotifs,
  viewNotifs,
  setActiveProject,
  updateRecent,
} from "../../redux/actions/Projects";
import { deleteActivity } from "../../redux/actions/Activities";
import { ReactComponent as LibrariesImg } from "../../lib/assets/img/LibrariesOption3.svg";
import { ReactComponent as ReportsImg } from "../../lib/assets/img/ReportsOption2.svg";
import { ReactComponent as HelpImg } from "../../lib/assets/img/Help.svg";
import { ReactComponent as ProfileImg } from "../../lib/assets/img/ProfileOption1.svg";
import { ReactComponent as SharedImg } from "../../lib/assets/img/SharedWithMe.svg";
import { ReactComponent as PlusImg } from "../../lib/assets/img/plusSign.svg";
import { ReactComponent as EditImg } from "../../lib/assets/img/Edit.svg";
import { ReactComponent as NotificationImg } from "../../lib/assets/img/Notifications.svg";
import { ReactComponent as DeleteImg } from "../../lib/assets/img/Deleted.svg";
import { ReactComponent as FilesImg } from "../../lib/assets/img/File.svg";
import { ReactComponent as SideMenuImg } from "../../lib/assets/img/BugerMenu.svg";
import { useLocation } from "react-router-dom";
import EditMode from "../../screens/Project/EditMode";
import { useReactFlow } from "reactflow";

const Navbar: React.FC<{
  clickFeedback: any;
  settingMenu: any;
  helpMenu: any;
  handleSettings: any;
  handleHelp: any;
  closePopups: any;
  isSticky: any;
  logout: () => void;
  getUser: () => void;
  projectPage: any;
  screenName: any;
  setProjectArrow: any;
  setXPosition: any;
  setYPosition: any;
  style: any;
  openAcronymM: any;
  openProductM: any;
  openReferenceM: any;
  openDIDSM: any;
  handleReportClick: any;
  getUserSearch: (searchText: string) => void;
  userSearch: any;
  navSearch: string;
  setNavSearch: (text: string) => void;
  filterBox: boolean;
  setFilterBox: (box: boolean) => void;
  editCanvas: any;
  editProjectCanvas: (edit: boolean) => void;
  activeProject: any;
  user: any;
  activeProjectActivity: any;
  reportingList: any;
  addReportList: (project: any, userID: string) => void;
  setProjectToReport: (project: any) => void;
  openToolsM: any;
  setActivityLibrary: (state: boolean) => void;
  openActivityM: any;
  setCollabSelectionState: (state: boolean) => void;
  collabSelection: boolean;
  setCollabType: (state: string) => void;
  clearCollabItems: () => void;
  deleteActivity: (pId: string, aId: string) => void;
  setActivityDeleteModal: (state: any) => void;
  acronymsList: any;
  editPermissions: boolean;
  activeProjectActivityChanges: any;
  itemMenu: any;
  setItemMenu: any;
  sideMenu: any;
  setSideMenu: any;
  handleFoundView: (state: boolean) => void;
  setClickedItem: (activeItem: any) => void;
  openNewProjectM: any;
  openNewSaveAsProjectM: any;
  openProjectM: any;
  setActiveProject: (project: any) => void;
  setShareProjectPM: (value: any) => void;
}> = ({
  clickFeedback,
  settingMenu,
  helpMenu,
  handleSettings,
  handleHelp,
  closePopups,
  isSticky,
  logout,
  getUser,
  projectPage,
  screenName,
  setProjectArrow,
  setXPosition,
  setYPosition,
  style,
  openAcronymM,
  openProductM,
  openReferenceM,
  openDIDSM,
  handleReportClick,
  getUserSearch,
  userSearch,
  navSearch,
  setNavSearch,
  filterBox,
  setFilterBox,
  editCanvas,
  editProjectCanvas,
  activeProject,
  user,
  activeProjectActivity,
  reportingList,
  addReportList,
  setProjectToReport,
  openToolsM,
  setActivityLibrary,
  openActivityM,
  setCollabSelectionState,
  collabSelection,
  setCollabType,
  clearCollabItems,
  deleteActivity,
  setActivityDeleteModal,
  acronymsList,
  editPermissions,
  activeProjectActivityChanges,
  itemMenu,
  setItemMenu,
  sideMenu,
  setSideMenu,
  handleFoundView,
  setClickedItem,
  openNewProjectM,
  openNewSaveAsProjectM,
  openProjectM,
  setActiveProject,
  setShareProjectPM,
}) => {
  const [foundAcronym, setFoundAcronym] = useState({}) as any;
  const location = useLocation();
  const [notifs, setNotifs] = useState({ length: 0, data: [] });
  const locationPath = location.pathname;
  const { setCenter } = useReactFlow();
  const navigate = useNavigate();

  const setEditCanvasState = () => {
    if (editPermissions) {
      if (editCanvas && activeProjectActivityChanges) {
        showChangesToast();
      } else {
        editProjectCanvas(!editCanvas);
      }
    } else {
      showPermissionsToast();
    }
  };

  useEffect(() => {
    const goFind = async () => {
      const notifData = await getNotifs();
      if (notifData?.length)
        setNotifs({
          length: notifData?.filter(
            (n) => n.hasOwnProperty("seen") && n.seen === false
          ).length,
          data: notifData,
        });
    };
    goFind();
  }, []);

  const seenNotifs = () => {
    viewNotifs();
    setNotifs((prevState: any) => ({
      length: 0,
      data: prevState.data,
    }));
  };

  const openAcronym = () => {
    openAcronymM();
    setActivityLibrary(false);
    // setLibraryHover();
  };

  const openProduct = () => {
    openProductM();
    setActivityLibrary(false);
    // setLibraryHover();
  };

  const openReference = () => {
    openReferenceM();
    setActivityLibrary(false);
    // setLibraryHover();
  };

  const openDIDS = () => {
    openDIDSM();
    setActivityLibrary(false);
    // setLibraryHover();
  };

  const openTools = () => {
    openToolsM();
    setActivityLibrary(false);
    // setLibraryHover();
  };

  const handleReport = () => {
    // setProjectToReport(null);
    handleReportClick();
    setActivityLibrary(false);
    // setLibraryHover();
  };

  const handleReportProject = () => {
    if (
      !reportingList.find((item) => item.projectId === activeProject.projectId)
    ) {
      addReportList(activeProject, user.oid);
    }
    setProjectToReport(activeProject);
    handleReport();
    if (
      !reportingList.find((item) => item.projectId === activeProject.projectId)
    ) {
      addReportList(activeProject, user.oid);
    }
    setProjectToReport(activeProject);
    // setEllipsisClick({});
  };

  const globalHandler = () => {
    showGlobalCToast();
    setCollabSelectionState(true);
    setCollabType("Global");
  };

  const localHandler = () => {
    showLocalCToast();
    setCollabSelectionState(true);
    setCollabType("Local");
  };

  const cancelCollab = () => {
    setCollabSelectionState(false);
    setCollabType("");
    clearCollabItems();
  };

  const openProjectView = (new_project: any) => {
    editProjectCanvas(false);
    editProjectCanvas(false);
    setActiveProject(new_project.project);
    updateRecent(new_project.projectId);
    navigate("/project");
  };

  return (
    <div>
      <div className="navbar">
        <Link
          style={{ cursor: "pointer" }}
          to="/dashboard"
          onClick={closePopups}
        >
          <img
            style={{ position: "absolute", left: 7, top: 6.5 }}
            src={logo}
            alt="logo"
            width="38"
            height="35"
          />
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Link
            to="/dashboard"
            style={{ color: style.colors.white }}
            // onClick={closePopups}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "10px",
                // paddingTop: "3px"
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                HSI Framework
              </span>
              {screenName === "" ? null : (
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  :
                </span>
              )}

              {activeProject === null && projectPage ? (
                <div className="lds-dual-ring-navbar" />
              ) : (
                <span style={{ fontSize: "18px", marginLeft: "5px" }}>
                  {screenName}
                </span>
              )}
            </div>
          </Link>
          <div style={{ marginLeft: "auto" }}>
            <div className="dropdown">
              <input
                className="searchBox"
                value={navSearch}
                placeholder="Search"
                type="text"
                style={{
                  paddingLeft: "20px",
                  paddingRight: "9px",
                  paddingTop: "9px",
                  paddingBottom: "9px",
                  fontSize: "12px",
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  getUserSearch(e.target.value.replace(/\s{2,}/g, " "));
                  setNavSearch(e.target.value.replace(/\s{2,}/g, " "));
                  setFilterBox(e.target.value.length > 0 ? true : false);
                  setFoundAcronym(
                    acronymsList.find(
                      (x) =>
                        x.acronymName.toLowerCase() ===
                        e.target.value.toLowerCase()
                    )
                      ? acronymsList.find(
                          (x) =>
                            x.acronymName.toLowerCase() ===
                            e.target.value.toLowerCase()
                        )
                      : {}
                  );
                }}
                onClick={() => setFilterBox(true)}
              />
              <div
                className="search-dropdown-content"
                style={{
                  marginTop: "6px",
                  display: navSearch.length > 0 && filterBox ? "block" : "none",
                }}
                onMouseOver={() => setFilterBox(true)}
                onMouseOut={() => setFilterBox(false)}
              >
                {foundAcronym.hasOwnProperty("acronymName") ? (
                  <div style={{ borderBottom: "1px solid grey" }}>
                    <a
                      className="search-dropdown-content-hover"
                      href="javascript:;"
                      style={{ display: "flex", flexDirection: "column" }}
                      onClick={() => {
                        openAcronymM();
                        setFilterBox(false);
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {foundAcronym.acronymName}
                      </span>
                      <span
                        style={{
                          marginLeft: "15px",
                          color: "orange",
                          fontSize: "12px",
                        }}
                      >
                        {foundAcronym.definition}
                      </span>
                    </a>
                  </div>
                ) : null}
                <a
                  href="javascript:;"
                  style={{ display: "flex", flexDirection: "row" }}
                  className={
                    locationPath === "/project"
                      ? "search-dropdown-content-hover"
                      : "search-dropdown-content-nohover"
                  }
                  onClick={() => {
                    if (userSearch.hasOwnProperty("activitiesFound")) {
                      if (userSearch.activitiesFound.length > 0) {
                        let zoom = 1.8;
                        handleFoundView(true);
                        setClickedItem(userSearch.activitiesFound[0]);
                        setItemMenu(true);
                        setCenter(
                          // node?.position?.x === 120 || node?.position?.x === 310 ? 0 :
                          userSearch.activitiesFound[0].position?.x + 350,
                          userSearch.activitiesFound[0].position?.y + 150,
                          { zoom, duration: 1000 }
                        );
                      }
                    }
                  }}
                  title={
                    locationPath === "/project"
                      ? ""
                      : "Select Project to Search"
                  }
                >
                  <span
                    style={{
                      color: locationPath === "/project" ? "black" : "grey",
                      fontSize: "12px",
                    }}
                  >
                    in
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      color:
                        locationPath === "/project"
                          ? style.colors.primaryBlue
                          : "grey",
                      fontSize: "12px",
                    }}
                  >
                    Project Activities
                  </span>
                  {locationPath === "/project" ? (
                    <span style={{ marginLeft: "auto", fontSize: "12px" }}>{`(${
                      userSearch?.activityInstances > 0
                        ? userSearch?.activityInstances
                        : 0
                    })`}</span>
                  ) : null}
                </a>
                <a
                  className="search-dropdown-content-hover"
                  href="javascript:;"
                  style={{ display: "flex", flexDirection: "row" }}
                  onClick={() => {
                    if (
                      userSearch?.acronyms &&
                      userSearch?.acronyms.length > 0
                    ) {
                      openAcronymM();
                      setFilterBox(false);
                    }
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    in
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      color: style.colors.primaryBlue,
                      fontSize: "12px",
                    }}
                  >
                    Acronym Library
                  </span>
                  <span style={{ marginLeft: "auto", fontSize: "12px" }}>{`(${
                    userSearch?.acronyms ? userSearch?.acronyms.length : 0
                  })`}</span>
                </a>
                <a
                  className="search-dropdown-content-hover"
                  href="javascript:;"
                  style={{ display: "flex", flexDirection: "row" }}
                  onClick={() => {
                    if (
                      userSearch?.products &&
                      userSearch?.products.length > 0
                    ) {
                      openProductM();
                      setFilterBox(false);
                    }
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    in
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      color: style.colors.primaryBlue,
                      fontSize: "12px",
                    }}
                  >
                    Product Library
                  </span>
                  <span style={{ marginLeft: "auto", fontSize: "12px" }}>{`(${
                    userSearch?.products ? userSearch?.products.length : 0
                  })`}</span>
                </a>
                <a
                  className="search-dropdown-content-hover"
                  href="javascript:;"
                  style={{ display: "flex", flexDirection: "row" }}
                  onClick={() => {
                    if (
                      userSearch?.references &&
                      userSearch?.references.length > 0
                    ) {
                      openReferenceM();
                      setFilterBox(false);
                    }
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    in
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      color: style.colors.primaryBlue,
                      fontSize: "12px",
                    }}
                  >
                    Reference Library
                  </span>
                  <span style={{ marginLeft: "auto", fontSize: "12px" }}>{`(${
                    userSearch?.references ? userSearch?.references.length : 0
                  })`}</span>
                </a>
                <a
                  className="search-dropdown-content-hover"
                  href="javascript:;"
                  style={{ display: "flex", flexDirection: "row" }}
                  onClick={() => {
                    if (userSearch?.dids && userSearch?.dids.length > 0) {
                      openDIDSM();
                      setFilterBox(false);
                    }
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    in
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      color: style.colors.primaryBlue,
                      fontSize: "12px",
                    }}
                  >
                    DIDs Library
                  </span>
                  <span style={{ marginLeft: "auto", fontSize: "12px" }}>{`(${
                    userSearch?.dids ? userSearch?.dids.length : 0
                  })`}</span>
                </a>
                <a
                  className="search-dropdown-content-hover"
                  href="javascript:;"
                  style={{ display: "flex", flexDirection: "row" }}
                  onClick={() => {
                    if (userSearch?.tools && userSearch?.tools.length > 0) {
                      openToolsM();
                      setFilterBox(false);
                    }
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    in
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      color: style.colors.primaryBlue,
                      fontSize: "12px",
                    }}
                  >
                    Tools Library
                  </span>
                  <span style={{ marginLeft: "auto", fontSize: "12px" }}>{`(${
                    userSearch?.tools ? userSearch?.tools.length : 0
                  })`}</span>
                </a>
              </div>
            </div>
            {/* {projectPage ? ( */}
            <div className="dropdown smallMenu">
              <button className="dropbtn" style={{ paddingTop: "16px" }}>
                <GiHamburgerMenu fill="white" />
              </button>
              <div className="smallMenu-content smallMenuPopup">
                <a href="javascript:;" style={{ fontSize: "12px" }}>
                  0 Notifications
                </a>
                <a
                  href="javascript:;"
                  onClick={openAcronym}
                  style={{ fontSize: "12px" }}
                >
                  Acronym Library
                </a>
                <a
                  href="javascript:;"
                  onClick={openProduct}
                  style={{ fontSize: "12px" }}
                >
                  Product Library
                </a>
                <a
                  href="javascript:;"
                  onClick={openReference}
                  style={{ fontSize: "12px" }}
                >
                  Reference Library
                </a>
                <a
                  href="javascript:;"
                  onClick={openDIDS}
                  style={{ fontSize: "12px" }}
                >
                  DIDs Library
                </a>
                <a
                  href="javascript:;"
                  onClick={openTools}
                  style={{ fontSize: "12px" }}
                >
                  Tools Library
                </a>
                <a
                  href="javascript:;"
                  onClick={handleReport}
                  style={{ fontSize: "12px" }}
                >
                  Reports Page
                </a>
                <a
                  href="javascript:;"
                  onClick={dowloadPdf}
                  style={{ fontSize: "12px" }}
                >
                  Quick Reference Guide
                </a>
                <a
                  href="javascript:;"
                  onClick={dowloadManual}
                  style={{ fontSize: "12px" }}
                >
                  User Training Manual
                </a>
                <a
                  href="javascript:;"
                  onClick={clickFeedback}
                  style={{ fontSize: "12px" }}
                >
                  Submit Feedback
                </a>
                <a href="javascript:;" style={{ fontSize: "12px" }}>
                  Reset Password
                </a>
                <a href="javascript:;" style={{ fontSize: "12px" }}>
                  Edit Account
                </a>
                <a
                  href="javascript:;"
                  onClick={logout}
                  style={{ fontSize: "12px" }}
                >
                  Logout
                </a>
              </div>
            </div>
            {/* {projectPage ? ( */}
            <div className="dropdown hideMenus">
              <button
                className="columnStack"
                style={{
                  flexDirection: "column",
                  position: "relative",
                }}
                onMouseLeave={() => seenNotifs()}
              >
                <NotificationImg className="navbarIcons" />
                <span className="navbarColumnText">Notifications</span>
                {notifs.length ? (
                  <div
                    style={{
                      flexDirection: "column",
                      position: "absolute",
                      width: "18px",
                      height: "18px",
                      borderRadius: "5px",
                      backgroundColor: "red",
                      alignItems: "center",
                      justifyContent: "center",
                      right: "12px",
                    }}
                  >
                    <p style={{ fontSize: "10px", lineHeight: "0px" }}>1</p>
                  </div>
                ) : null}
              </button>
              {notifs?.length ? (
                <div className="notifications-content notificationsPopup">
                  <div className="tools-content toolsPopup">
                    {notifs.data?.map((notifItem: any) => (
                      <a
                        href="javascript:;"
                        onClick={() => openProjectView(notifItem)}
                        style={{ fontSize: "12px" }}
                      >
                        {notifItem.msg}
                      </a>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
            {/* ) : null} */}
            <div className="dropdown hideMenus">
              <button className="columnStack">
                <LibrariesImg
                  className="navbarIcons"
                  style={{ marginTop: "7px" }}
                />
                <span className="navbarColumnText" style={{ marginTop: "3px" }}>
                  Libraries
                </span>
              </button>
              <div className="tools-content toolsPopup">
                <a
                  href="javascript:;"
                  onClick={openAcronym}
                  style={{ fontSize: "12px" }}
                >
                  Acronym Library
                </a>
                <a
                  href="javascript:;"
                  onClick={openProduct}
                  style={{ fontSize: "12px" }}
                >
                  Product Library
                </a>
                <a
                  href="javascript:;"
                  onClick={openReference}
                  style={{ fontSize: "12px" }}
                >
                  Reference Library
                </a>
                <a
                  href="javascript:;"
                  onClick={openDIDS}
                  style={{ fontSize: "12px" }}
                >
                  DIDs Library
                </a>
                <a
                  href="javascript:;"
                  onClick={openTools}
                  style={{ fontSize: "12px" }}
                >
                  Tools Library
                </a>
              </div>
            </div>
            <div className="dropdown hideMenus">
              <button className="columnStack" title="Reports">
                <ReportsImg onClick={handleReport} className="navbarIcons" />
                <span className="navbarColumnText" style={{ marginTop: "6px" }}>
                  Reports
                </span>
              </button>
            </div>
            <div className="dropdown hideMenus">
              <button className="columnStack">
                <HelpImg className="navbarIcons" />
                <span className="navbarColumnText">Help</span>
              </button>
              <div className="help-content helpPopup">
                <a
                  href="javascript:;"
                  onClick={dowloadPdf}
                  style={{ fontSize: "12px" }}
                >
                  Quick Reference Guide
                </a>
                <a
                  href="javascript:;"
                  onClick={dowloadManual}
                  style={{ fontSize: "12px" }}
                >
                  User Training Manual
                </a>
                <a
                  href="javascript:;"
                  onClick={clickFeedback}
                  style={{ fontSize: "12px" }}
                >
                  Submit Feedback
                </a>
              </div>
            </div>
            <div className="dropdown hideMenus">
              <button className="columnStackProfile">
                <ProfileImg className="navbarIcons" />
                <span className="navbarColumnText">Profile</span>
              </button>
              <div className="settings-content settingPopup">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://passwordreset.microsoftonline.com/"
                  style={{ fontSize: "12px" }}
                >
                  Reset Password
                </a>
                <a href="javascript:;" style={{ fontSize: "12px" }}>
                  Edit Account
                </a>
                <a
                  href="javascript:;"
                  onClick={logout}
                  style={{ fontSize: "12px" }}
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {projectPage ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#404040",
            // height: "49px",
            position: "relative",
          }}
        >
          {editCanvas ? <EditMode /> : null}

          <div className="dropdown">
            <button className="dropbtntools">
              <FilesImg
                fill="white"
                className="toolSectionIcons"
                height="22px"
                width="22px"
              />
              <span className="toolSectionColumnText">File</span>
            </button>
            <div className="files-content filesPopup">
              <a href="javascript:;" onClick={openNewProjectM}>
                New Project
              </a>
              <a
                href="javascript:;"
                onClick={openProjectM}
                style={{ borderBottom: "1px solid grey" }}
              >
                Open
              </a>
              <a href="javascript:;">Save</a>
              <a href="javascript:;" onClick={openNewSaveAsProjectM}>
                Save As
              </a>
              <a
                onClick={() => exportProject(activeProject.projectId)}
                href="javascript:;"
              >
                Export
              </a>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtntools" onClick={handleReportProject}>
              <ReportsImg
                fill="white"
                className="toolSectionIcons"
                height="22px"
                width="22px"
              />
              <span className="toolSectionColumnText">Run Report</span>
            </button>
          </div>
          <div className="dropdown">
            <button
              className="dropbtntools"
              onClick={() => setShareProjectPM(activeProject?.projectId)}
            >
              <SharedImg
                fill="white"
                className="toolSectionIcons"
                height="22px"
                width="22px"
              />
              <span className="toolSectionColumnText">Share</span>
            </button>
          </div>
          <div className="dropdown" style={{ marginTop: "2px" }}>
            <button className="dropbtntools">
              <label className="switch toolSectionIcons">
                <input
                  type="checkbox"
                  checked={editCanvas}
                  onClick={setEditCanvasState}
                />
                <span className="slider round">
                  <span
                    style={{
                      position: "absolute",
                      top: 5,
                      left: editCanvas ? 7 : 23,
                      fontSize: "11px",
                      // color: editCanvas ? "white" : "black",
                      transition: "0.25s",
                      zIndex: 8,
                    }}
                  >
                    {editCanvas ? "ON" : "OFF"}
                  </span>
                </span>
              </label>
              <span className="toolSectionColumnText">Edit Lock</span>
            </button>
          </div>
          <div className="dropdown">
            <button
              className={collabSelection ? "dropbtntoolsX" : "dropbtntools"}
            >
              {collabSelection ? (
                <>
                  <PlusImg
                    fill="white"
                    className="toolSectionIconsX"
                    height="22px"
                    width="22px"
                    onClick={cancelCollab}
                  />
                  <span className="toolSectionColumnTextX">Cancel</span>
                </>
              ) : (
                <>
                  <PlusImg
                    fill={editCanvas ? "white" : "grey"}
                    className="toolSectionIcons"
                    height="22px"
                    width="22px"
                  />
                  <span
                    className="toolSectionColumnText"
                    style={{ color: editCanvas ? "white" : "grey" }}
                  >
                    Add Feature
                  </span>
                </>
              )}
            </button>
            {collabSelection ? null : editCanvas ? (
              <div className="addFeature-content addFeaturePopup">
                <a href="javascript:;" onClick={localHandler}>
                  Local Collaboration
                </a>
                <a
                  href="javascript:;"
                  onClick={globalHandler}
                  style={{ borderBottom: "1px solid grey" }}
                >
                  Global Collaboration
                </a>
                <a href="javascript:;" onClick={openActivityM}>
                  Activity Box
                </a>
              </div>
            ) : null}
          </div>
          <div className="dropdown hideMenus">
            <button
              className={collabSelection ? "dropbtntoolsX" : "dropbtntools"}
              style={{ cursor: activeProjectActivity ? "pointer" : "default" }}
              onClick={() => {
                if (activeProjectActivity && editCanvas)
                  setActivityDeleteModal(true);
                // deleteActivity(
                //   activeProjectActivity.projectId,
                //   activeProjectActivity.activityId
                // );
              }}
            >
              <DeleteImg
                fill={
                  activeProjectActivity
                    ? editCanvas
                      ? "white"
                      : "grey"
                    : "grey"
                }
                className="toolSectionIcons"
                height="22px"
                width="22px"
                style={{
                  cursor: activeProjectActivity
                    ? editCanvas
                      ? "pointer"
                      : "default"
                    : "default",
                }}
              />
              <span
                className="toolSectionColumnText"
                style={{
                  color: activeProjectActivity
                    ? editCanvas
                      ? "white"
                      : "grey"
                    : "grey",
                }}
              >
                Delete
              </span>
            </button>
          </div>
          <div style={{ marginLeft: "auto", paddingRight: "10px" }}>
            <SideMenuImg
              style={{ marginTop: "11px", cursor: "pointer" }}
              onClick={() => setItemMenu(!itemMenu)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );

  function showGlobalCToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar3");
    if (element[0]) {
      element[0].className = "snackbar3 show";
      setTimeout(function () {
        element[0].className = "snackbar3";
      }, 5000);
      return;
    }
  }

  function showLocalCToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar4");
    if (element[0]) {
      element[0].className = "snackbar4 show";
      setTimeout(function () {
        element[0].className = "snackbar4";
      }, 5000);
      return;
    }
  }

  function showChangesToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar6");
    if (element[0]) {
      element[0].className = "snackbar6 show";
      setTimeout(function () {
        element[0].className = "snackbar6";
      }, 5000);
      return;
    }
  }

  function showPermissionsToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar7");
    if (element[0]) {
      element[0].className = "snackbar7 show";
      setTimeout(function () {
        element[0].className = "snackbar7";
      }, 5000);
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  userSearch: state.data.userSearch,
  editCanvas: state.projects.editCanvas,
  filterBox: state.data.filterBox,
  navSearch: state.data.navSearch,
  activeProject: state.projects.activeProject,
  user: state.user.user,
  activeProjectActivity: state.projects.activeProjectActivity,
  reportingList: state.projects.reportingList,
  collabSelection: state.projects.collabSelection,
  acronymsList: state.data.acronyms,
  editPermissions: state.projects.editPermissions,
  activeProjectActivityChanges: state.projects.activeProjectActivityChanges,
});

export default connect(mapStateToProps, {
  logout,
  getUser,
  getUserSearch,
  editProjectCanvas,
  setFilterBox,
  setNavSearch,
  addReportList,
  setProjectToReport,
  setActivityLibrary,
  setCollabSelectionState,
  setCollabType,
  clearCollabItems,
  deleteActivity,
  setActivityDeleteModal,
  handleFoundView,
  setClickedItem,
  setActiveProject,
})(Navbar) as any;
