import {
  GET_ACRONYMS,
  GET_PRODUCT_LIBRARY,
  GET_REFERENCE_LIBRARY,
  GET_USER_SEARCH,
  ADD_NEW_ACRONYM,
  DELETE_ACRONYM,
  UPDATE_ACRONYM,
  ADD_NEW_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  ADD_NEW_REFERENCE,
  UPDATE_REFERENCE,
  DELETE_REFERENCE,
  SET_NAV_SEARCH,
  SET_FILTER_BOX,
  GET_DIDS_LIBRARY,
  ADD_NEW_DIDS,
  UPDATE_DIDS,
  DELETE_DIDS,
  ADD_FEEDBACK,
  GET_FEEDBACKS,
  GET_TOOLS,
  HANDLE_FOUND_INDEX,
  HANDLE_FOUND_VIEW,
  RESET_ACTIVITY_SEARCH,
  GET_ALL_USERS,
  HANDLE_PRODUCT_INPUTS_OCCURR,
  HANDLE_PRODUCT_OUTPUTS_OCCURR,
  HANDLE_REF_OCCURR,
  HANDLE_DIDS_OCCURR,
  HANDLE_TOOLS_OCCURR,
} from "../actions/types";

const initialState = {
  acronyms: [],
  productLibrary: [],
  referenceLibrary: [],
  didsLibrary: [],
  feedbackLibrary: [],
  toolsLibrary: [],
  allUsers: [],
  userSearch: null,
  navSearch: "",
  filterBox: false,
  activityFoundIndex: 0,
  activityFoundView: false,
};

const userReducer = (state = initialState as any, action: any) => {
  switch (action.type) {
    case GET_ACRONYMS:
      return {
        ...state,
        acronyms: action.payload,
      };
    case ADD_NEW_ACRONYM:
      return {
        ...state,
        acronyms: action.payload,
      };
    case DELETE_ACRONYM:
      return {
        ...state,
        acronyms: action.payload,
      };
    case UPDATE_ACRONYM:
      return {
        ...state,
        acronyms: action.payload,
      };
    case GET_PRODUCT_LIBRARY:
      return {
        ...state,
        productLibrary: action.payload,
      };
    case ADD_NEW_PRODUCT:
      return {
        ...state,
        productLibrary: action.payload,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        productLibrary: action.payload,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        productLibrary: action.payload,
      };
    case GET_REFERENCE_LIBRARY:
      return {
        ...state,
        referenceLibrary: action.payload,
      };
    case ADD_NEW_REFERENCE:
      return {
        ...state,
        referenceLibrary: action.payload,
      };
    case UPDATE_REFERENCE:
      return {
        ...state,
        referenceLibrary: action.payload,
      };
    case DELETE_REFERENCE:
      return {
        ...state,
        referenceLibrary: action.payload,
      };
    case GET_USER_SEARCH:
      return {
        ...state,
        userSearch: action.payload,
      };
    case SET_NAV_SEARCH:
      return {
        ...state,
        navSearch: action.payload,
      };
    case SET_FILTER_BOX:
      return {
        ...state,
        filterBox: action.payload,
      };
    case GET_DIDS_LIBRARY:
      return {
        ...state,
        didsLibrary: action.payload,
      };
    case ADD_NEW_DIDS:
      return {
        ...state,
        didsLibrary: action.payload,
      };
    case UPDATE_DIDS:
      return {
        ...state,
        didsLibrary: action.payload,
      };
    case DELETE_DIDS:
      return {
        ...state,
        didsLibrary: action.payload,
      };
    case ADD_FEEDBACK:
      return {
        ...state,
        feedbackLibrary: action.payload,
      };
    case GET_FEEDBACKS:
      return {
        ...state,
        feedbackLibrary: action.payload,
      };
    case GET_TOOLS:
      return {
        ...state,
        toolsLibrary: action.payload,
      };
    case HANDLE_FOUND_INDEX:
      return {
        ...state,
        activityFoundIndex: action.payload,
      };
    case HANDLE_FOUND_VIEW:
      return {
        ...state,
        activityFoundView: action.payload,
      };
    case RESET_ACTIVITY_SEARCH:
      return {
        ...state,
        userSearch: null,
        navSearch: "",
        activityFoundIndex: 0,
        activityFoundView: false,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case HANDLE_PRODUCT_INPUTS_OCCURR:
      return {
        ...state,
        productLibrary: state.productLibrary
          .filter((x) => x.type !== "Input")
          .concat(action.payload),
      };
    case HANDLE_PRODUCT_OUTPUTS_OCCURR:
      return {
        ...state,
        productLibrary: state.productLibrary
          .filter((x) => x.type !== "Output")
          .concat(action.payload),
      };
    case HANDLE_REF_OCCURR:
      return {
        ...state,
        referenceLibrary: action.payload,
      };
    case HANDLE_DIDS_OCCURR:
      return {
        ...state,
        didsLibrary: action.payload,
      };
    case HANDLE_TOOLS_OCCURR:
      return {
        ...state,
        toolsLibrary: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
