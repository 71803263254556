import {
  LOGOUT,
  CREATE_PROJECT,
  GET_RECENT_PROJECTS,
  GET_HSI_FRAMEWORK_MODELS,
  GET_MY_PROJECTS,
  GET_SHARED_WITH_ME,
  GET_FAVORITES,
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  GET_DELETED,
  SET_ACTIVE_PROJECT,
  EDIT_PROJECT,
  SAVE_EDIT_PROJECT,
  SAVE_AS,
  SAVE_AS_NEW,
  DELETE_PROJECT,
  SAVE_DELETE_PROJECT,
  SET_CLICKED_ITEM,
  EDIT_PROJECT_CANVAS,
  SET_RIGHT_CLICKED_ITEM,
  EDIT_LIBRARIES_HSI_RISK,
  SET_PROJECT_ACTIVITY_STATUS,
  SET_PROJECT_ACTIVITY_DUE_DATE,
  EDIT_ACTIVITY_TITLE,
  EDIT_ACTIVITY_DESCRIPTION,
  EDIT_ACTIVITY_SME,
  EDIT_ACTIVITY_TRADEOFFS,
  EDIT_ACTIVITY_COMMENTS,
  EDIT_ACTIVITY_ROI,
  EDIT_ACTIVITY_CONTDEL,
  EDIT_ACTIVITY_CHALLENGES,
  EDIT_ACTIVITY_RECOMMENDATIONS,
  EDIT_ACTIVITY_OTHER,
  EDIT_ACTIVITY_HSIRISKS,
  REMOVE_REPORT_LIST,
  ADD_REPORT_LIST,
  GET_REPORT_LIST,
  EDIT_REPORT_CATEGORIES,
  RESET_REPORT_CATEGORIES,
  SET_REPORT_TYPE,
  SET_PROJECT_TO_REPORT,
  SET_CLICKED_PROJECT_ID,
  CLEAR_RISK_DATES,
  GET_RISK_REPORT,
  SET_POPUP_VALUES,
  RECOVER_DELETE_PROJECT,
  SET_ITEM_MENU,
  DELETE_ACTIVITY,
  SET_RIGHTCLICK_MENU,
  SET_SUMMARY_MENU,
  SET_ACTIVITY_LIBRARY,
  SET_ACTIVITY_ID_LIST,
  EDIT_ACTIVITY_PRODUCT_INPUTS_LIST,
  EDIT_ACTIVITY_PRODUCT_OUTPUTS_LIST,
  EDIT_ACTIVITY_REFERENCE_LIST,
  EDIT_ACTIVITY_DIDS_LIST,
  EDIT_ACTIVITY_TOOLS_LIST,
  EDIT_ACTIVITY_PRODUCT_INPUTS_DATA,
  EDIT_ACTIVITY_PRODUCT_OUTPUTS_DATA,
  EDIT_ACTIVITY_REFERENCE_DATA,
  EDIT_ACTIVITY_DIDS_DATA,
  EDIT_ACTIVITY_TOOLS_DATA,
  CLEAR_DATA_LISTS,
  GET_STATUS_REPORT,
  PERM_DELETE_PROJECT,
  GET_CUSTOM_ACTIVITY_REPORT,
  GET_CUSTOM_LIBRARY_REPORT,
  GET_RELATED_ITEMS_REPORT,
  GET_CONTENT_REPORT,
  SET_COLLAB_SELECTION_STATE,
  SET_COLLAB_TYPE,
  SET_COLLAB_SOURCE,
  SET_COLLAB_TARGET,
  CLEAR_COLLAB_ITEMS,
  SET_ACTIVITY_CHANGE_STATE,
  SET_ACTIVITY_DELETE_MODAL,
  SET_ACTIVITY_RISKS_CHANGED,
  SET_ACTIVITY_PRODUCTS_CHANGED,
  SET_ACTIVITY_REFERENCES_CHANGED,
  SET_ACTIVITY_DIDS_CHANGED,
  SET_ACTIVITY_TOOLS_CHANGED,
  SET_ACTIVITY_INITIAL_RISKS,
  SET_ACTIVITY_INITIAL_PRODUCTS_INPUTS,
  SET_ACTIVITY_INITIAL_PRODUCTS_OUTPUTS,
  SET_ACTIVITY_INITIAL_REFERENCES,
  SET_ACTIVITY_INITIAL_DIDS,
  SET_ACTIVITY_INITIAL_TOOLS,
  SET_PROJECT_PERMISSIONS,
  HANDLE_PAN_POSITION,
  GET_ALL_PROJECTS,
  HANDLE_OPEN_STARTUP,
  NEW_PROJECT_INCOMING,
  GET_USER_PROJECT_LIST,
  SHARED_WITH_ME,
  REMOVE_ME_FROM_PROJECT,
} from "../actions/types";

const initialState = {
  activeProject: null,
  activeProjectActivity: null,
  activeProjectPhase: null,
  activeProjectActivityChanges: false,
  activityRisksChanged: false,
  initialRisks: [],
  activityProductsChanged: false,
  initialProductsInputs: [],
  initialProductsOutputs: [],
  activityReferencesChanged: false,
  initialReferences: [],
  activityDidsChanged: false,
  initialDids: [],
  activityToolsChanged: false,
  initialTools: [],
  popupScreen_x: null,
  popupScreen_y: null,
  editProject: null,
  editCanvas: false,
  editLibrariesHSI: false,
  rightClickedItem: null,
  saveAs: null,
  deleteProject: null,
  recent: [],
  hsiFrameworkModels: [],
  myProjects: [],
  sharedWithMe: [],
  favorites: [],
  deleted: [],
  allProjects: [],
  reportingList: [],
  projectToReport: {
    selectedProject: null,
    phaseReportList: [],
    domainReportList: [],
    riskReportList: [],
    activityStatusList: [],
    activityNotesList: [],
    contentList: [],
    hsiManagementList: [],
    userReportType: "",
    riskStartDate: "",
    riskEndDate: "",
    statusStartDate: "",
    statusEndDate: "",
    updateStartDate: "",
    updateEndDate: "",
    addedStartDate: "",
    addedEndDate: "",
    modifiedStartDate: "",
    modifiedEndDate: "",
    deletedStartDate: "",
    deletedEndDate: "",
    reportData: null,
  },
  clickedProjectId: "",
  itemMenu: false,
  rightClick_x: null,
  rightClick_y: null,
  summaryMenu: false,
  activityLibraryEdit: false,
  collabSourceData: null,
  collabTargetData: null,
  collabSelection: false,
  collabType: "",
  activityIDList: [],
  activityProductsInputs: [],
  activityProductsOutputs: [],
  activityReferences: [],
  activityDIDS: [],
  activityTools: [],
  actDelModal: false,
  editPermissions: false,
  panPosition: {
    x: 0,
    y: 0,
  },
  newProjectIncoming: false,
  usersProjectList: [],
  lastLoginTimeStamp: null,
};

const userReducer = (state = initialState as any, action: any) => {
  switch (action.type) {
    case SET_ACTIVE_PROJECT:
      return {
        ...state,
        activeProject: action.payload,
      };
    case CREATE_PROJECT:
      return {
        ...state,
        recent: [action.payload, ...state.recent],
        myProjects: [action.payload, ...state.myProjects],
        activeProject: action.payload,
      };
    case GET_RECENT_PROJECTS:
      return {
        ...state,
        recent: action.payload,
      };
    case GET_HSI_FRAMEWORK_MODELS:
      return {
        ...state,
        hsiFrameworkModels: action.payload,
      };
    case GET_MY_PROJECTS:
      return {
        ...state,
        myProjects: action.payload,
      };
    case SHARED_WITH_ME:
      return {
        ...state,
        sharedWithMe: action.payload,
      };
    case GET_FAVORITES:
      return {
        ...state,
        favorites: action.payload,
      };
    case ADD_FAVORITE:
      return {
        ...state,
        favorites: [action.payload, ...state.favorites],
      };
    case REMOVE_FAVORITE:
      return {
        ...state,
        favorites: [
          ...state.favorites?.filter(
            (project: any) => project.projectId !== action.payload.projectId
          ),
        ],
      };
    case GET_DELETED:
      return {
        ...state,
        deleted: action.payload,
      };
    case EDIT_PROJECT:
      return {
        ...state,
        editProject: action.payload,
      };
    case SAVE_EDIT_PROJECT:
      return {
        ...state,
        recent: [
          ...state.recent?.map((project: any) => {
            if (project.projectId === action.payload.projectId) {
              return action.payload;
            } else {
              return project;
            }
          }),
        ],
        hsiFrameworkModels: [
          ...state.hsiFrameworkModels?.map((project: any) => {
            if (project.projectId === action.payload.projectId) {
              return action.payload;
            } else {
              return project;
            }
          }),
        ],
        myProjects: [
          ...state.myProjects?.map((project: any) => {
            if (project.projectId === action.payload.projectId) {
              return action.payload;
            } else {
              return project;
            }
          }),
        ],
        sharedWithMe: [
          ...state.sharedWithMe?.map((project: any) => {
            if (project.projectId === action.payload.projectId) {
              return action.payload;
            } else {
              return project;
            }
          }),
        ],
        favorites: [
          ...state.favorites?.map((project: any) => {
            if (project.projectId === action.payload.projectId) {
              return action.payload;
            } else {
              return project;
            }
          }),
        ],
        deleted: [
          ...state.deleted?.map((project: any) => {
            if (project.projectId === action.payload.projectId) {
              return action.payload;
            } else {
              return project;
            }
          }),
        ],
      };
    case SAVE_AS_NEW:
      return {
        ...state,
        activeProject: action.payload,
        recent: [action.payload, ...state.recent],
        myProjects: [action.payload, ...state.myProjects],
      };
    case SAVE_AS:
      return {
        ...state,
        saveAs: action.payload,
      };
    case SAVE_DELETE_PROJECT:
      return {
        ...state,
        recent: action.payload.recentList,
        myProjects: action.payload.myProjectsList,
        sharedWithMe: action.payload.sharedWithMeList,
        favorites: action.payload.favoritesList,
        deleted: action.payload.deletedList,
      };
    case RECOVER_DELETE_PROJECT:
      return {
        ...state,
        recent: action.payload.recentList,
        myProjects: action.payload.myProjectsList,
        deleted: action.payload.deletedList,
      };
    case DELETE_PROJECT:
      return {
        ...state,
        deleteProject: action.payload,
      };
    case SET_CLICKED_ITEM:
      return {
        ...state,
        activeProjectActivity:
          action.payload.menuType === "activity"
            ? action.payload.activeItem
            : null,
        activeProjectPhase:
          action.payload.menuType === "phase"
            ? action.payload.activeItem
            : null,
      };
    case LOGOUT:
      return initialState;
    case EDIT_PROJECT_CANVAS:
      return {
        ...state,
        editCanvas: action.payload,
      };
    case EDIT_LIBRARIES_HSI_RISK:
      return {
        ...state,
        editLibrariesHSI: action.payload,
      };
    case SET_RIGHT_CLICKED_ITEM:
      return {
        ...state,
        rightClickedItem: action.payload,
      };
    case SET_PROJECT_ACTIVITY_STATUS:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          activityStatus: action.payload.activityStatus,
        },
      };
    case SET_PROJECT_ACTIVITY_DUE_DATE:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          dueDate: action.payload.dueDate,
        },
      };
    case EDIT_ACTIVITY_TITLE:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          summary: action.payload.summary,
        },
      };
    case EDIT_ACTIVITY_DESCRIPTION:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          description: action.payload.description,
        },
      };
    case EDIT_ACTIVITY_SME:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          smeInteraction: action.payload.smeInteraction,
        },
      };
    case EDIT_ACTIVITY_TRADEOFFS:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          tradeOffs: action.payload.tradeOffs,
        },
      };
    case EDIT_ACTIVITY_COMMENTS:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          comments: action.payload.comments,
        },
      };
    case EDIT_ACTIVITY_ROI:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          returnOnInvestment: action.payload.returnOnInvestment,
        },
      };
    case EDIT_ACTIVITY_CONTDEL:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          contractorDeliverables: action.payload.contractorDeliverables,
        },
      };
    case EDIT_ACTIVITY_CHALLENGES:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          challenges: action.payload.challenges,
        },
      };
    case EDIT_ACTIVITY_RECOMMENDATIONS:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          recommendations: action.payload.recommendations,
        },
      };
    case EDIT_ACTIVITY_OTHER:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          other: action.payload.other,
        },
      };
    case EDIT_ACTIVITY_HSIRISKS:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          hsiRisk: action.payload.hsiRisk,
        },
      };
    case REMOVE_REPORT_LIST:
      return {
        ...state,
        reportingList: action.payload.reportsList,
        projectToReport: {
          ...state.projectToReport,
          selectedProject:
            state.projectToReport.selectedProject &&
            action.payload.project.projectId ===
              state.projectToReport.selectedProject.projectId
              ? null
              : state.projectToReport.selectedProject,
        },
      };
    case ADD_REPORT_LIST:
      return {
        ...state,
        reportingList: action.payload,
      };
    case GET_REPORT_LIST:
      return {
        ...state,
        reportingList: action.payload,
      };
    case EDIT_REPORT_CATEGORIES:
      return {
        ...state,
        projectToReport: {
          selectedProject: action.payload.userReportSelected,
          phaseReportList: action.payload.phase,
          domainReportList: action.payload.domain,
          riskReportList: action.payload.risk,
          activityStatusList: action.payload.activity,
          activityNotesList: action.payload.notes,
          contentList: action.payload.content,
          hsiManagementList: action.payload.management,
          riskStartDate: action.payload.riskStartDate,
          riskEndDate: action.payload.riskEndDate,
          statusStartDate: action.payload.statusStartDate,
          statusEndDate: action.payload.statusEndDate,
          updateStartDate: action.payload.updateStartDate,
          updateEndDate: action.payload.updateEndDate,
          addedStartDate: action.payload.addedStartDate,
          addedEndDate: action.payload.addedEndDate,
          modifiedStartDate: action.payload.modifiedStartDate,
          modifiedEndDate: action.payload.modifiedEndDate,
          deletedStartDate: action.payload.deletedStartDate,
          deletedEndDate: action.payload.deletedEndDate,
          userReportType: action.payload.reportType,
        },
      };
    case RESET_REPORT_CATEGORIES:
      return {
        ...state,
        projectToReport: {
          ...state.projectToReport,
          phaseReportList: [],
          domainReportList: [],
          riskReportList: [],
          activityStatusList: [],
          activityNotesList: [],
          contentList: [],
          hsiManagementList: [],
          riskStartDate: "",
          riskEndDate: "",
          statusStartDate: "",
          statusEndDate: "",
          updateStartDate: "",
          updateEndDate: "",
          addedStartDate: "",
          addedEndDate: "",
          modifiedStartDate: "",
          modifiedEndDate: "",
          deletedStartDate: "",
          deletedEndDate: "",
        },
      };
    case SET_REPORT_TYPE:
      return {
        ...state,
        projectToReport: {
          ...state.projectToReport,
          userReportType: action.payload,
        },
      };
    case SET_PROJECT_TO_REPORT:
      return {
        ...state,
        projectToReport: {
          ...state.projectToReport,
          selectedProject:
            action.payload !== null
              ? state.projectToReport.selectedProject === null ||
                action.payload.projectId !==
                  state.projectToReport.selectedProject.projectId
                ? action.payload
                : null
              : null,
        },
      };
    case SET_CLICKED_PROJECT_ID:
      return {
        ...state,
        clickedProjectId: action.payload,
      };
    case CLEAR_RISK_DATES:
      return {
        ...state,
        projectToReport: {
          ...state.projectToReport,
          riskStartDate: "",
          riskEndDate: "",
        },
      };
    case GET_RISK_REPORT:
      return {
        ...state,
        projectToReport: {
          ...state.projectToReport,
          reportData: action.payload,
        },
      };
    case GET_STATUS_REPORT:
      return {
        ...state,
        projectToReport: {
          ...state.projectToReport,
          reportData: action.payload,
        },
      };
    case GET_CUSTOM_ACTIVITY_REPORT:
      return {
        ...state,
        projectToReport: {
          ...state.projectToReport,
          reportData: action.payload,
        },
      };
    case GET_CUSTOM_LIBRARY_REPORT:
      return {
        ...state,
        projectToReport: {
          ...state.projectToReport,
          reportData: action.payload,
        },
      };
    case GET_RELATED_ITEMS_REPORT:
      return {
        ...state,
        projectToReport: {
          ...state.projectToReport,
          reportData: action.payload,
        },
      };
    case GET_CONTENT_REPORT:
      return {
        ...state,
        projectToReport: {
          ...state.projectToReport,
          reportData: action.payload,
        },
      };
    case SET_POPUP_VALUES:
      return {
        ...state,
        popupScreen_x: action.payload.x,
        popupScreen_y: action.payload.y,
      };
    case SET_ITEM_MENU:
      return {
        ...state,
        itemMenu: action.payload,
      };
    case DELETE_ACTIVITY:
      return {
        ...state,
        itemMenu: false,
      };
    case SET_RIGHTCLICK_MENU:
      return {
        ...state,
        rightClick_x: action.payload.x,
        rightClick_y: action.payload.y,
      };
    case SET_SUMMARY_MENU:
      return {
        ...state,
        summaryMenu: action.payload,
      };
    case SET_ACTIVITY_LIBRARY:
      return {
        ...state,
        activityLibraryEdit: action.payload,
      };
    case SET_ACTIVITY_ID_LIST:
      return {
        ...state,
        activityIDList: action.payload,
      };
    case EDIT_ACTIVITY_PRODUCT_INPUTS_LIST:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          inputProductList: action.payload.inputProductList,
        },
      };
    case EDIT_ACTIVITY_PRODUCT_OUTPUTS_LIST:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          outputProductList: action.payload.outputProductList,
        },
      };
    case EDIT_ACTIVITY_REFERENCE_LIST:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          referenceList: action.payload.referenceList,
        },
      };
    case EDIT_ACTIVITY_DIDS_LIST:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          didsList: action.payload.didsList,
        },
      };
    case EDIT_ACTIVITY_TOOLS_LIST:
      return {
        ...state,
        activeProjectActivity: {
          ...state.activeProjectActivity,
          toolsList: action.payload.toolsList,
        },
      };
    case EDIT_ACTIVITY_PRODUCT_INPUTS_DATA:
      return {
        ...state,
        activityProductsInputs: action.payload.filter(
          (x) => !x.hasOwnProperty("init_id")
        ),
      };
    case EDIT_ACTIVITY_PRODUCT_OUTPUTS_DATA:
      return {
        ...state,
        activityProductsOutputs: action.payload.filter(
          (x) => !x.hasOwnProperty("init_id")
        ),
      };
    case EDIT_ACTIVITY_REFERENCE_DATA:
      return {
        ...state,
        activityReferences: action.payload,
      };
    case EDIT_ACTIVITY_DIDS_DATA:
      return {
        ...state,
        activityDIDS: action.payload,
      };
    case EDIT_ACTIVITY_TOOLS_DATA:
      return {
        ...state,
        activityTools: action.payload.filter(
          (x) => !x.hasOwnProperty("init_id")
        ),
      };
    case CLEAR_DATA_LISTS:
      return {
        ...state,
        activityProductsInputs: [],
        activityProductsOutputs: [],
        activityReferences: [],
        activityDIDS: [],
        activityTools: [],
      };
    case PERM_DELETE_PROJECT:
      return {
        ...state,
        deleted: action.payload.deletedList,
      };
    case SET_COLLAB_SELECTION_STATE:
      return {
        ...state,
        collabSelection: action.payload,
      };
    case SET_COLLAB_TYPE:
      return {
        ...state,
        collabType: action.payload,
      };
    case SET_COLLAB_SOURCE:
      return {
        ...state,
        collabSourceData: action.payload,
      };
    case SET_COLLAB_TARGET:
      return {
        ...state,
        collabTargetData: action.payload,
      };
    case CLEAR_COLLAB_ITEMS:
      return {
        ...state,
        collabSourceData: action.payload,
        collabTargetData: action.payload,
      };
    case SET_ACTIVITY_CHANGE_STATE:
      return {
        ...state,
        activeProjectActivityChanges: action.payload,
      };
    case SET_ACTIVITY_DELETE_MODAL:
      return {
        ...state,
        actDelModal: action.payload,
      };
    case SET_ACTIVITY_RISKS_CHANGED:
      return {
        ...state,
        activityRisksChanged: action.payload,
      };
    case SET_ACTIVITY_PRODUCTS_CHANGED:
      return {
        ...state,
        activityProductsChanged: action.payload,
      };
    case SET_ACTIVITY_REFERENCES_CHANGED:
      return {
        ...state,
        activityReferencesChanged: action.payload,
      };
    case SET_ACTIVITY_DIDS_CHANGED:
      return {
        ...state,
        activityDidsChanged: action.payload,
      };
    case SET_ACTIVITY_TOOLS_CHANGED:
      return {
        ...state,
        activityToolsChanged: action.payload,
      };
    case SET_ACTIVITY_INITIAL_RISKS:
      return {
        ...state,
        initialRisks: action.payload,
      };
    case SET_ACTIVITY_INITIAL_PRODUCTS_INPUTS:
      return {
        ...state,
        initialProductsInputs: action.payload,
      };
    case SET_ACTIVITY_INITIAL_PRODUCTS_OUTPUTS:
      return {
        ...state,
        initialProductsOutputs: action.payload,
      };
    case SET_ACTIVITY_INITIAL_REFERENCES:
      return {
        ...state,
        initialReferences: action.payload,
      };
    case SET_ACTIVITY_INITIAL_DIDS:
      return {
        ...state,
        initialDids: action.payload,
      };
    case SET_ACTIVITY_INITIAL_TOOLS:
      return {
        ...state,
        initialTools: action.payload,
      };
    case SET_PROJECT_PERMISSIONS:
      return {
        ...state,
        editPermissions: action.payload,
      };
    case HANDLE_PAN_POSITION:
      return {
        ...state,
        panPosition: {
          x: action.payload.x,
          y: action.payload.y,
        },
      };
    case GET_ALL_PROJECTS:
      return {
        ...state,
        allProjects: action.payload,
      };
    case HANDLE_OPEN_STARTUP:
      return {
        ...state,
        usersProjectList: action.payload,
        // recent: state.recent?.map((obj: any) => {
        //   if (obj.projectId === action.payload.projectId) {
        //     return {
        //       ...obj,
        //       open_upon_login: action.payload.open_upon_login,
        //     };
        //   } else {
        //     return obj;
        //   }
        // }),
        // myProjects: state.myProjects?.map((obj: any) => {
        //   if (obj.projectId === action.payload.projectId) {
        //     return {
        //       ...obj,
        //       open_upon_login: action.payload.open_upon_login,
        //     };
        //   } else {
        //     return obj;
        //   }
        // }),
      };
    case GET_USER_PROJECT_LIST:
      return {
        ...state,
        usersProjectList: action.payload,
      };
    case NEW_PROJECT_INCOMING:
      return {
        ...state,
        newProjectIncoming: action.payload,
      };
    case REMOVE_ME_FROM_PROJECT:
      return {
        ...state,
        newProjectIncoming: action.payload,
        recent: state.recent?.filter(
          (obj: any) => obj.projectId !== action.payload
        ),
        sharedWithMe: state.sharedWithMe?.filter(
          (obj: any) => obj.projectId !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default userReducer;
