import { customAlphabet } from "nanoid/async";
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 24);

const getId = async () => {
  try {
    const id = await nanoid();
    return id;
  } catch (error) {
    console.log(error);
  }
};

export { getId };
