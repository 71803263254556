import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./projectReportModal.css";
import Modal from "react-modal";
import { addReportList } from "../../redux/actions/Projects";

const ProjectReportModal: React.FC<{
  closeAPRM: any;
  aprm: any;
  style: any;
  recent: any;
  framework: any;
  myProjects: any;
  sharedWithMe: any;
  favorites: any;
  deleted: any;
  addReportList: (project: any, userID: string) => void;
  user: any;
  reportingList: any;
}> = ({
  closeAPRM,
  aprm,
  style,
  recent,
  framework,
  myProjects,
  sharedWithMe,
  favorites,
  deleted,
  addReportList,
  user,
  reportingList,
}) => {
  const customStyles = {
    content: {
      top: "40%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
  };
  const [activeTab, setActiveTab] = useState("recent") as any;
  const [projectList, setProjectList] = useState(recent) as any;
  const [selectedProject, setSelectedProject] = useState({}) as any;

  const setRecent = () => {
    setActiveTab("recent");
  };

  const setFramework = () => {
    setActiveTab("framework");
  };

  const setPersonal = () => {
    setActiveTab("personal");
  };

  const setShared = () => {
    setActiveTab("shared");
  };

  const setFavorites = () => {
    setActiveTab("favorites");
  };

  const setDeleted = () => {
    setActiveTab("deleted");
  };

  const setAll = () => {
    setActiveTab("all");
  };

  const cancelButton = () => {
    setSelectedProject({});
    closeAPRM();
    setRecent();
  };

  const saveButton = () => {
    if (
      reportingList.find((item) => item.projectId === selectedProject.projectId)
    ) {
      showReportToast();
    } else {
      addReportList(selectedProject, user.oid);
      closeAPRM();
      setSelectedProject({});
      setRecent();
    }
  };

  useEffect(() => {
    if (activeTab === "recent") setProjectList(recent);
    if (activeTab === "framework") setProjectList(framework);
    if (activeTab === "personal") setProjectList(myProjects);
    if (activeTab === "shared") setProjectList(sharedWithMe);
    if (activeTab === "favorites") setProjectList(favorites);
    if (activeTab === "deleted") setProjectList(deleted);
    if (activeTab === "all") {
      let allList = [
        ...recent,
        ...framework,
        ...myProjects,
        ...sharedWithMe,
        ...favorites,
        ...deleted,
      ];

      const ids = allList.map((o) => o.projectId);
      const filtered = allList.filter(
        ({ projectId }, index) => !ids.includes(projectId, index + 1)
      );
      setProjectList(filtered);
    }
  }, [
    activeTab,
    deleted,
    favorites,
    framework,
    myProjects,
    recent,
    sharedWithMe,
  ]);

  return (
    <div>
      <Modal isOpen={aprm} onRequestClose={closeAPRM} style={customStyles}>
        <i
          className="fa fa-times"
          onClick={closeAPRM}
          style={{
            cursor: "pointer",
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-10px",
          }}
        ></i>
        <div style={{ display: "flex", height: "38vh" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "14vw",
              borderRight: "1px solid grey",
              overflowY: "auto",
            }}
          >
            <p
              style={{
                borderLeft:
                  activeTab === "recent" ? "2px solid blue" : "2px solid grey",
                paddingLeft: "10%",
                cursor: "pointer",
              }}
              onClick={setRecent}
            >
              Recent
            </p>
            <p
              style={{
                borderLeft:
                  activeTab === "framework"
                    ? "2px solid blue"
                    : "2px solid grey",
                paddingLeft: "10%",
                cursor: "pointer",
              }}
              onClick={setFramework}
            >
              HSI Framework Models
            </p>
            <p
              style={{
                borderLeft:
                  activeTab === "personal"
                    ? "2px solid blue"
                    : "2px solid grey",
                paddingLeft: "10%",
                cursor: "pointer",
              }}
              onClick={setPersonal}
            >
              My Projects
            </p>
            <p
              style={{
                borderLeft:
                  activeTab === "shared" ? "2px solid blue" : "2px solid grey",
                paddingLeft: "10%",
                cursor: "pointer",
              }}
              onClick={setShared}
            >
              Shared With Me
            </p>
            <p
              style={{
                borderLeft:
                  activeTab === "favorites"
                    ? "2px solid blue"
                    : "2px solid grey",
                paddingLeft: "10%",
                cursor: "pointer",
              }}
              onClick={setFavorites}
            >
              Favorites
            </p>
            <p
              style={{
                borderLeft:
                  activeTab === "deleted" ? "2px solid blue" : "2px solid grey",
                paddingLeft: "10%",
                cursor: "pointer",
              }}
              onClick={setDeleted}
            >
              Deleted
            </p>
            <p
              style={{
                borderLeft:
                  activeTab === "all" ? "2px solid blue" : "2px solid grey",
                paddingLeft: "10%",
                cursor: "pointer",
              }}
              onClick={setAll}
            >
              All
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "40vw",
              overflowY: "auto",
            }}
          >
            {projectList.length > 0
              ? projectList.map((item: any) => {
                  return (
                    <div
                      onClick={() => {
                        if (
                          selectedProject &&
                          selectedProject.projectId === item.projectId
                        ) {
                          setSelectedProject({});
                        } else {
                          setSelectedProject(item);
                        }
                      }}
                      style={{
                        borderBottom: "1px solid grey",
                        cursor: "pointer",
                        backgroundColor:
                          selectedProject &&
                          selectedProject?.projectId === item.projectId
                            ? "lightblue"
                            : "transparent",
                      }}
                    >
                      <p
                        style={{
                          marginLeft: "1vw",
                        }}
                      >
                        {item.name}
                      </p>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "5%" }}>
          <button
            onClick={cancelButton}
            style={{
              backgroundColor: "grey",
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              marginLeft: "auto",
              marginRight: "4%",
            }}
          >
            Cancel
          </button>
          <button
            onClick={saveButton}
            style={{
              backgroundColor: style.colors.primaryBlue,
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  );

  function showReportToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar2");
    if (element[0]) {
      element[0].className = "snackbar2 show";
      setTimeout(function () {
        element[0].className = "snackbar2";
      }, 5000);
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  recent: state.projects.recent,
  framework: state.projects.hsiFrameworkModels,
  myProjects: state.projects.myProjects,
  sharedWithMe: state.projects.sharedWithMe,
  favorites: state.projects.favorites,
  deleted: state.projects.deleted,
  user: state.user.user,
  reportingList: state.projects.reportingList,
});

export default connect(mapStateToProps, { addReportList })(
  ProjectReportModal
) as any;
