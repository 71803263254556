import "./App.css";
import { Provider } from "react-redux";
import store from "./redux/index";
import Navigation from "./screens/Navigation";
import Init from "./screens/init";
import Snackbar from "./components/snackbar/snackbar";

const App = () => {
  return (
    <Provider store={store}>
      <Navigation />
      <Init />
      <Snackbar
        idName={"snackbar"}
        cName={"snackbar"}
        itemText={"Feedback Submitted Successfully"}
      />
      <Snackbar
        idName={"snackbar2"}
        cName={"snackbar2"}
        itemText={"Project Already Exists"}
      />
      <Snackbar
        idName={"snackbar3"}
        cName={"snackbar3"}
        itemText={"Select Activty"}
      />
      <Snackbar
        idName={"snackbar4"}
        cName={"snackbar4"}
        itemText={"Select Activties"}
      />
      <Snackbar
        idName={"snackbar5"}
        cName={"snackbar5"}
        itemText={"Local Collaboration Already Exists"}
      />
      <Snackbar
        idName={"snackbar6"}
        cName={"snackbar6"}
        itemText={"Please Cancel or Save changes"}
      />
      <Snackbar
        idName={"snackbar7"}
        cName={"snackbar7"}
        itemText={"User Does Not Have Permissions To Edit"}
      />
    </Provider>
  );
};

export default App;
