//User
export const AUTH_LOADING = "AUTH_LOADING";
export const TEST = "TEST";
export const GET_USER = "GET_USER";
export const LOGOUT = "LOGOUT";

//Projects
export const CREATE_PROJECT = "CREATE_PROJECT";
export const GET_RECENT_PROJECTS = "GET_RECENT_PROJECTS";
export const GET_HSI_FRAMEWORK_MODELS = "GET_HSI_FRAMEWORK_MODELS";
export const GET_MY_PROJECTS = "GET_MY_PROJECTS";
export const GET_SHARED_WITH_ME = "GET_SHARED_WITH_ME";
export const GET_FAVORITES = "GET_FAVORITES";
export const ADD_FAVORITE = "ADD_FAVORITE";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";
export const GET_DELETED = "GET_DELETED";
export const UPDATE_RECENT = "UPDATE_RECENT";
export const SET_ACTIVE_PROJECT = "SET_ACTIVE_PROJECT";
export const SET_CLICKED_ITEM = "SET_CLICKED_ITEM";
export const EDIT_PROJECT_CANVAS = "EDIT_PROJECT_CANVAS";
export const SET_RIGHT_CLICKED_ITEM = "SET_RIGHT_CLICKED_ITEM";
export const EDIT_LIBRARIES_HSI_RISK = "EDIT_LIBRARIES_HSI_RISK";
export const REMOVE_REPORT_LIST = "REMOVE_REPORT_LIST";
export const ADD_REPORT_LIST = "ADD_REPORT_LIST";
export const GET_REPORT_LIST = "GET_REPORT_LIST";
export const EDIT_REPORT_CATEGORIES = "EDIT_REPORT_CATEGORIES";
export const RESET_REPORT_CATEGORIES = "RESET_REPORT_CATEGORIES";
export const SET_REPORT_TYPE = "SET_REPORT_TYPE";
export const SET_PROJECT_TO_REPORT = "SET_PROJECT_TO_REPORT";
export const SET_CLICKED_PROJECT_ID = "SET_CLICKED_PROJECT_ID";
export const CLEAR_RISK_DATES = "CLEAR_RISK_DATES";
export const GET_RISK_REPORT = "GET_RISK_REPORT";
export const SET_POPUP_VALUES = "SET_POPUP_VALUES";
export const RECOVER_DELETE_PROJECT = "RECOVER_DELETE_PROJECT";
export const SET_ITEM_MENU = "SET_ITEM_MENU";
export const SET_RIGHTCLICK_MENU = "SET_RIGHTCLICK_MENU";
export const DELETE_COLLABORATION_OPTION = "DELETE_COLLABORATION_OPTION";
export const SET_SUMMARY_MENU = "SET_SUMMARY_MENU";
export const SET_ACTIVITY_LIBRARY = "SET_ACTIVITY_LIBRARY";
export const SET_ACTIVITY_ID_LIST = "SET_ACTIVITY_ID_LIST";
export const CLEAR_DATA_LISTS = "CLEAR_DATA_LISTS";
export const GET_STATUS_REPORT = "GET_STATUS_REPORT";
export const PERM_DELETE_PROJECT = "PERM_DELETE_PROJECT";
export const GET_CUSTOM_ACTIVITY_REPORT = "GET_CUSTOM_ACTIVITY_REPORT";
export const GET_CUSTOM_LIBRARY_REPORT = "GET_CUSTOM_LIBRARY_REPORT";
export const GET_RELATED_ITEMS_REPORT = "GET_RELATED_ITEMS_REPORT";
export const GET_CONTENT_REPORT = "GET_CONTENT_REPORT";
export const SET_COLLAB_SELECTION_STATE = "SET_COLLAB_SELECTION_STATE";
export const SET_COLLAB_TYPE = "SET_COLLAB_TYPE";
export const SET_COLLAB_SOURCE = "SET_COLLAB_SOURCE";
export const SET_COLLAB_TARGET = "SET_COLLAB_TARGET";
export const CLEAR_COLLAB_ITEMS = "CLEAR_COLLAB_ITEMS";
export const SET_ACTIVITY_CHANGE_STATE = "SET_ACTIVITY_CHANGE_STATE";
export const SET_ACTIVITY_RISKS_CHANGED = "SET_ACTIVITY_RISKS_CHANGED";
export const SET_ACTIVITY_PRODUCTS_CHANGED = "SET_ACTIVITY_PRODUCTS_CHANGED";
export const SET_ACTIVITY_REFERENCES_CHANGED =
  "SET_ACTIVITY_REFERENCES_CHANGED";
export const SET_ACTIVITY_DIDS_CHANGED = "SET_ACTIVITY_DIDS_CHANGED";
export const SET_ACTIVITY_TOOLS_CHANGED = "SET_ACTIVITY_TOOLS_CHANGED";
export const SET_ACTIVITY_INITIAL_RISKS = "SET_ACTIVITY_INITIAL_RISKS";
export const SET_ACTIVITY_INITIAL_PRODUCTS_INPUTS =
  "SET_ACTIVITY_INITIAL_PRODUCTS_INPUTS";
export const SET_ACTIVITY_INITIAL_PRODUCTS_OUTPUTS =
  "SET_ACTIVITY_INITIAL_PRODUCTS_OUTPUTS";
export const SET_ACTIVITY_INITIAL_REFERENCES =
  "SET_ACTIVITY_INITIAL_REFERENCES";
export const SET_ACTIVITY_INITIAL_DIDS = "SET_ACTIVITY_INITIAL_DIDS";
export const SET_ACTIVITY_INITIAL_TOOLS = "SET_ACTIVITY_INITIAL_TOOLS";
export const SET_PROJECT_PERMISSIONS = "SET_PROJECT_PERMISSIONS";
export const HANDLE_PAN_POSITION = "HANDLE_PAN_POSITION";
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const HANDLE_OPEN_STARTUP = "HANDLE_OPEN_STARTUP";
export const NEW_PROJECT_INCOMING = "NEW_PROJECT_INCOMING";
export const GET_USER_PROJECT_LIST = "GET_USER_PROJECT_LIST";
export const REMOVE_ME_FROM_PROJECT = "REMOVE_ME_FROM_PROJECT";

//Activities
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const GET_COLLABORATIONS = "GET_COLLABORATIONS";
export const ADD_COLLABORATION = "ADD_COLLABORATION";
export const DELETE_COLLABORATION = "DELETE_COLLABORATION";
export const NEW_ACTIVITIES = "NEW_ACTIVITIES";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const SET_PROJECT_ACTIVITY_STATUS = "SET_PROJECT_ACTIVITY_STATUS";
export const SET_PROJECT_ACTIVITY_DUE_DATE = "SET_PROJECT_ACTIVITY_DUE_DATE";
export const EDIT_ACTIVITY_TITLE = "EDIT_ACTIVITY_TITLE";
export const EDIT_ACTIVITY_DESCRIPTION = "EDIT_ACTIVITY_DESCRIPTION";
export const EDIT_ACTIVITY_SME = "EDIT_ACTIVITY_SME";
export const EDIT_ACTIVITY_TRADEOFFS = "EDIT_ACTIVITY_TRADEOFFS";
export const EDIT_ACTIVITY_COMMENTS = "EDIT_ACTIVITY_COMMENTS";
export const EDIT_ACTIVITY_ROI = "EDIT_ACTIVITY_ROI";
export const EDIT_ACTIVITY_CONTDEL = "EDIT_ACTIVITY_CONTDEL";
export const EDIT_ACTIVITY_CHALLENGES = "EDIT_ACTIVITY_CHALLENGES";
export const EDIT_ACTIVITY_RECOMMENDATIONS = "EDIT_ACTIVITY_RECOMMENDATIONS";
export const EDIT_ACTIVITY_OTHER = "EDIT_ACTIVITY_OTHER";
export const EDIT_ACTIVITY_HSIRISKS = "EDIT_ACTIVITY_HSIRISKS";
export const EDIT_ACTIVITY_PRODUCT_INPUTS_LIST =
  "EDIT_ACTIVITY_PRODUCT_INPUTS_LIST";
export const EDIT_ACTIVITY_PRODUCT_OUTPUTS_LIST =
  "EDIT_ACTIVITY_PRODUCT_OUTPUTS_LIST";
export const EDIT_ACTIVITY_REFERENCE_LIST = "EDIT_ACTIVITY_REFERENCE_LIST";
export const EDIT_ACTIVITY_DIDS_LIST = "EDIT_ACTIVITY_DIDS_LIST";
export const EDIT_ACTIVITY_TOOLS_LIST = "EDIT_ACTIVITY_TOOLS_LIST";
export const EDIT_ACTIVITY_PRODUCT_INPUTS_DATA =
  "EDIT_ACTIVITY_PRODUCT_INPUTS_DATA";
export const EDIT_ACTIVITY_PRODUCT_OUTPUTS_DATA =
  "EDIT_ACTIVITY_PRODUCT_OUTPUTS_DATA";
export const EDIT_ACTIVITY_REFERENCE_DATA = "EDIT_ACTIVITY_REFERENCE_DATA";
export const EDIT_ACTIVITY_DIDS_DATA = "EDIT_ACTIVITY_DIDS_DATA";
export const EDIT_ACTIVITY_TOOLS_DATA = "EDIT_ACTIVITY_TOOLS_DATA";
export const UPDATE_COLLAB_LINE_TYPE = "UPDATE_COLLAB_LINE_TYPE";
export const SET_ACTIVITY_DELETE_MODAL = "SET_ACTIVITY_DELETE_MODAL";
export const FIX_ACTIVITY_LIBRARY_LISTS = "FIX_ACTIVITY_LIBRARY_LISTS";
export const CLEAR_ACTIVITIES = "CLEAR_ACTIVITIES";
export const GET_DATA_LOADING = "GET_DATA_LOADING";

//Save and edit project
export const EDIT_PROJECT = "SET_EDIT_PROJECT";
export const SAVE_EDIT_PROJECT = "SAVE_EDIT_PROJECT";

//Save as - copy project
export const SAVE_AS = "SAVE_AS";
export const SAVE_AS_NEW = "SAVE_AS_NEW";

//Save as - copy project
export const DELETE_PROJECT = "DELETE_PROJECT";
export const SAVE_DELETE_PROJECT = "SAVE_DELETE_PROJECT";

//Data
export const GET_ACRONYMS = "GET_ACRONYMS";
export const GET_PRODUCT_LIBRARY = "GET_PRODUCT_LIBRARY";
export const GET_REFERENCE_LIBRARY = "GET_REFERENCE_LIBRARY";
export const GET_USER_SEARCH = "GET_USER_SEARCH";
export const ADD_NEW_ACRONYM = "ADD_NEW_ACRONYM";
export const DELETE_ACRONYM = "DELETE_ACRONYM";
export const UPDATE_ACRONYM = "UPDATE_ACRONYM";
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const ADD_NEW_REFERENCE = "ADD_NEW_REFERENCE";
export const UPDATE_REFERENCE = "UPDATE_REFERENCE";
export const DELETE_REFERENCE = "DELETE_REFERENCE";
export const SET_NAV_SEARCH = "SET_NAV_SEARCH";
export const SET_FILTER_BOX = "SET_FILTER_BOX";
export const GET_DIDS_LIBRARY = "GET_DIDS_LIBRARY";
export const ADD_NEW_DIDS = "ADD_NEW_DIDS";
export const UPDATE_DIDS = "UPDATE_DIDS";
export const DELETE_DIDS = "DELETE_DIDS";
export const ADD_FEEDBACK = "ADD_FEEDBACK";
export const GET_FEEDBACKS = "GET_FEEDBACKS";
export const GET_USER_TYPE = "GET_USER_TYPE";
export const GET_TOOLS = "GET_TOOLS";
export const HANDLE_FOUND_INDEX = "HANDLE_FOUND_INDEX";
export const HANDLE_FOUND_VIEW = "HANDLE_FOUND_VIEW";
export const RESET_ACTIVITY_SEARCH = "RESET_ACTIVITY_SEARCH";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const HANDLE_PRODUCT_INPUTS_OCCURR = "HANDLE_PRODUCT_INPUTS_OCCURR";
export const HANDLE_PRODUCT_OUTPUTS_OCCURR = "HANDLE_PRODUCT_OUTPUTS_OCCURR";
export const HANDLE_REF_OCCURR = "HANDLE_REF_OCCURR";
export const HANDLE_DIDS_OCCURR = "HANDLE_DIDS_OCCURR";
export const HANDLE_TOOLS_OCCURR = "HANDLE_TOOLS_OCCURR";
export const SHARED_WITH_ME = "SHARED_WITH_ME";
