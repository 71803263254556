import Project from "./Project/Project";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Layout from "./Layout";
import Login from "./Login/Login";
import MyProjects from "./Dashboard/myProjects";
import Recent from "./Dashboard/recent";

import React, { useState } from "react";
import StepOne from "./Reports/StepOne";
import StepTwo from "./Reports/StepTwo";
import Report from "./Reports/Report";

const PrivateRoute: React.FC<{
  user: any;
  children: any;
}> = ({ user, children }) => {
  return user ? children : <Navigate to="/" />;
};

const Navigation: React.FC<{
  user: any;
}> = ({ user }) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute user={user}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/project"
          element={
            <PrivateRoute user={user}>
              <Project />
            </PrivateRoute>
          }
        />
        <Route
          path="/stepone"
          element={
            <PrivateRoute user={user}>
              <StepOne />
            </PrivateRoute>
          }
        />
        <Route
          path="/steptwo"
          element={
            <PrivateRoute user={user}>
              <StepTwo />
            </PrivateRoute>
          }
        />
        <Route
          path="/report"
          element={
            <PrivateRoute user={user}>
              <Report />
            </PrivateRoute>
          }
        />
        <Route index element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, {})(Navigation) as any;
