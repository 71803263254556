import React from "react";
import "./snackbar.css";

const Snackbar: React.FC<{
  idName: string;
  cName: string;
  itemText: string;
}> = ({ idName, cName, itemText }) => {
  return (
    <div id={idName} className={cName}>
      {itemText}
    </div>
  );
};

export default Snackbar;
