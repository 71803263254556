import React from "react";
import { connect } from "react-redux";

const EditMode: React.FC<{}> = ({}) => {
  return (
    <div
      style={{
        backgroundColor: "lightyellow",
        height: "30px",
        width: "200px",
        position: "absolute",
        top: 0,
        left: "45%",
        bottom: 0,
        right: 0,
        zIndex: 9,
        border: "2px solid black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span
        style={{
          textAlign: "center",
          fontSize: "16px",
        }}
      >
        You Are Currently Editing
      </span>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(EditMode) as any;
