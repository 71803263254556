import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import "./newProjectModal.css";
import Modal from "react-modal";
import { searchUsers } from "../../redux/actions/User";
import { MdClose } from "react-icons/md";
import { createProject, shareProject } from "../../redux/actions/Projects";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    width: "28rem",
  },
};

const ShareProjectModal: React.FC<{
  closeShareProjectM: any;
  shareProjectM: any;
  style: any;
  createProject: (FormData: any) => void;
}> = ({ closeShareProjectM, shareProjectM, createProject, style }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    modelId: "1",
  });
  const [searchResults, setSearchResults] = useState([]);
  const [shareList, setShareList] = useState([]) as any;
  const [searchValue, setSearchValue] = useState("");

  const [dropDownValue, setDropDownValue] = useState("Can View");

  const goSearch = useCallback(
    async (value) => {
      const res = await searchUsers(value);
      if (res?.length)
        setSearchResults(
          res?.filter(
            (item: any) =>
              !shareList.some((person: any) => person.oid === item.oid)
          )
        );
    },
    [shareList]
  );

  useEffect(() => {
    if (searchValue) goSearch(searchValue);
    else setSearchResults([]);
  }, [searchValue]);

  const createNewProject = () => {
    if (formData.name && formData.modelId) createProject(formData);
    closeShareProjectM();
  };

  const changeDropdown = (e) => {
    setDropDownValue((prevState) => e.target.value);
  };

  return (
    <div
      style={{
        visibility: shareProjectM ? "initial" : "hidden",
      }}
    >
      <div className="modal-container-share">
        <section className="projectModals-share">
          <p
            style={{
              paddingBottom: "1px",
              fontWeight: "bold",
              marginBottom: "1vh",
            }}
          >
            Share Project With Others
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderBottom: "1px solid grey",
              marginBottom: ".7rem",
              paddingBottom: ".7rem",
            }}
          >
            {shareList.map((item: any) => (
              <div
                key={item.oid || item.user_name || item.unique_name}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: ".5rem",
                  borderRadius: ".2rem",
                  backgroundColor: style.colors.primaryBlue,
                  border: "none",
                  textAlign: "start",
                  marginBottom: ".7rem",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: "normal" }}>{item.name}</span>
                  <span style={{ fontWeight: "normal" }}>
                    {item.username || item.unique_name}
                  </span>
                </div>
                <button
                  onClick={() => {
                    setShareList((prevState: any) => [
                      ...prevState.filter(
                        (person: any) => person.oid !== item.oid
                      ),
                    ]);
                    setSearchResults([]);
                    setSearchValue("");
                  }}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    paddingLeft: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <MdClose />
                </button>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <span style={{ fontWeight: "normal" }}>First Name Last Name</span>
              <span style={{ fontWeight: "normal" }}>username</span>
              <input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                style={{
                  marginTop: "1rem",
                  width: "90%",
                  padding: ".15rem",
                }}
                placeholder="search user"
                list="search"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <span style={{ marginBottom: ".7rem" }}>{dropDownValue}</span>
              <select
                name="base"
                className="base"
                style={{ width: "150px", height: "25px" }}
                onChange={changeDropdown}
              >
                <option value="Can View">Can View</option>
                <option value="Can Edit">Can Edit</option>
                <option value="Is Owner">Is Owner</option>
              </select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1rem",
              height: "10rem",
            }}
          >
            {searchResults?.map((item: any) => (
              <button
                onClick={() => {
                  setShareList((prevState: any) => [
                    { ...item, permission: dropDownValue },
                    ...prevState.filter(
                      (person: any) => person.oid !== item.oid
                    ),
                  ]);
                  setSearchResults([]);
                  setSearchValue("");
                  setDropDownValue("Can View");
                }}
                key={item.oid || item.user_name || item.unique_name}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: ".5rem",
                  borderRadius: ".2rem",
                  backgroundColor: style.colors.primaryBlue,
                  border: "none",
                  textAlign: "start",
                  marginBottom: ".2rem",
                }}
              >
                <span style={{ fontWeight: "normal" }}>{item.name}</span>
                <span style={{ fontWeight: "normal" }}>
                  {item.username || item.unique_name}
                </span>
              </button>
            ))}
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", height: "7rem" }}
          ></div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 20,
              marginTop: "10px",
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={closeShareProjectM}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                shareProject(shareList, shareProjectM);
                setShareList([]);
              }}
            >
              Save
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({ style: state.local.style });

export default connect(mapStateToProps, { createProject })(
  ShareProjectModal
) as any;
