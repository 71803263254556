import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateRecent,
  setActiveProject,
  addFavorite,
  editProject,
  saveAs,
  saveDeleteProject,
  setPopupValues,
  recoverDeleteProject,
  addReportList,
  setProjectToReport,
  permDeleteProject,
  handleOpenStartup,
  exportProject,
  removeMeFromProject,
} from "../../../redux/actions/Projects";

const Popup: React.FC<{
  setActiveProject: (project: any) => void;
  updateRecent: (projectId: string) => void;
  addFavorite: (projectId: string) => void;
  editProject: (project: any) => void;
  saveAs: (project: any) => void;
  saveDeleteProject: (project: any) => void;
  activeProject: any;
  style: any;
  sideOption: any;
  popupScreen_x: any;
  popupScreen_y: any;
  setPopupValues: (x: any, y: any) => void;
  highlightProject: any;
  recoverDeleteProject: (project: any) => void;
  ellipsisClick: any;
  setEllipsisClick: any;
  reportingList: any;
  user: any;
  addReportList: (project: any, userID: string) => void;
  setProjectToReport: (project: object) => void;
  permDeleteProject: (projectId: string) => void;
  setShareProjectM: (value: any) => void;
  handleOpenStartup: (projectId: string) => void;
  usersProjectList: any;
  removeMeFromProject: (projectId: string) => void;
}> = ({
  setActiveProject,
  updateRecent,
  addFavorite,
  editProject,
  saveAs,
  saveDeleteProject,
  activeProject,
  style,
  sideOption,
  popupScreen_x,
  popupScreen_y,
  setPopupValues,
  highlightProject,
  recoverDeleteProject,
  ellipsisClick,
  setEllipsisClick,
  reportingList,
  user,
  addReportList,
  setProjectToReport,
  permDeleteProject,
  setShareProjectM,
  handleOpenStartup,
  usersProjectList,
  removeMeFromProject,
}) => {
  // const [projectClicked, setProjectClicked] = useState(false) as any;
  // const [projectMenu, setProjectMenu] = useState(false) as any;

  const navigate = useNavigate();

  // const openProject = () => {
  //   updateRecent(ellipsisClick.projectId);
  //   navigate("/project");
  //   setEllipsisClick({});
  // };

  // useEffect(() => {
  //   if(ellipsisClick){

  //   }
  // }, [])

  const addFavoriteProject = () => {
    addFavorite(ellipsisClick.projectId);
    setActiveProject(null);
    setEllipsisClick({});
    setPopupValues(null, null);
  };

  const editNameTitle = () => {
    editProject(ellipsisClick);
    setActiveProject(null);
    setEllipsisClick({});
    setPopupValues(null, null);
  };

  const saveAsNew = () => {
    saveAs(ellipsisClick);
    setActiveProject(null);
    setEllipsisClick({});
    setPopupValues(null, null);
  };

  const goDelete = () => {
    saveDeleteProject(ellipsisClick);
    setActiveProject(null);
    setEllipsisClick({});
    setPopupValues(null, null);
  };

  const goRecover = () => {
    recoverDeleteProject(ellipsisClick);
    setActiveProject(null);
    setEllipsisClick({});
    setPopupValues(null, null);
  };

  const handleReportClick = () => {
    if (
      !reportingList.find((item) => item.projectId === ellipsisClick.projectId)
    ) {
      addReportList(ellipsisClick, user.oid);
    }
    setProjectToReport(ellipsisClick);
    setPopupValues(null, null);
    navigate("/stepone");
    // setEllipsisClick({});
  };

  const goPermanently = () => {
    permDeleteProject(ellipsisClick.projectId);
    setActiveProject(null);
    setEllipsisClick({});
    setPopupValues(null, null);
  };

  const openStartUpClick = () => {
    handleOpenStartup(ellipsisClick.projectId);
    // setEllipsisClick({
    //   ...ellipsisClick,
    //   open_upon_login: !ellipsisClick.open_upon_login,
    // });
  };

  const default_project_ids = [
    "wihqi631etml3e0i83pwbh50",
    "ddzggs33kgy0lcufh5y5r06c",
    "fd0pctpxfka5g2lceky5v1pd",
    "tqfd3n246mstuwgqqnbsn3rc",
    "9frwcq3y4rcnerwksulke6qr",
    "anhmqnq63o45ecqqdwx1fdnh",
  ];

  return (
    <>
      {popupScreen_x && popupScreen_y ? (
        <div
          // onMouseOver={() => {
          //   setProjectMenu(true);
          // }}
          onMouseLeave={() => {
            setActiveProject(null);
            setPopupValues(null, null);
            if (highlightProject) {
              setActiveProject(highlightProject);
            }
          }}
          style={{
            position: "absolute",
            backgroundColor: style.colors.white,
            border: "1px solid grey",
            left: popupScreen_x,
            top: popupScreen_y,
            zIndex: 3,
            boxShadow: `0 4px 8px 0 ${style.colors.grey_3}`,
          }}
        >
          {sideOption === "Deleted" ? (
            <ul
              style={{
                listStyle: "none",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              <li className="projectListItems" onClick={goRecover}>
                <span>Recover</span>
              </li>
              {user?.userRole?.includes("Admin") ? (
                <li className="projectListItems" onClick={goPermanently}>
                  <span>Permanently Delete</span>
                </li>
              ) : (
                <li className="projectListItems">
                  <span style={{ color: style.colors.disabledText }}>
                    Permanently Delete
                  </span>
                </li>
              )}
            </ul>
          ) : (
            <ul
              style={{
                listStyle: "none",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              <li className="projectListItems" onClick={openStartUpClick}>
                <span>
                  {usersProjectList.some(
                    (obj) =>
                      obj.projectId === ellipsisClick.projectId &&
                      obj.open_upon_login === true
                  )
                    ? "Open Upon Startup ✔"
                    : "Open Upon Startup"}
                </span>
              </li>
              <li
                className="projectListItems"
                onClick={editNameTitle}
                style={{ borderBottom: "1px solid grey" }}
              >
                <span>Edit Name/Description</span>
              </li>
              <li className="projectListItems" onClick={handleReportClick}>
                <span>Run Report</span>
              </li>
              <li className="projectListItems" onClick={saveAsNew}>
                <span>Save As</span>
              </li>
              <li
                className="projectListItems"
                onClick={() => exportProject(ellipsisClick.projectId)}
              >
                <span>Export</span>
              </li>
              <li
                className="projectListItems"
                style={{ borderBottom: "1px solid grey" }}
                onClick={() => setShareProjectM(ellipsisClick.projectId)}
              >
                <span>Share</span>
              </li>
              {!default_project_ids.includes(ellipsisClick.projectId) &&
              (sideOption === "Shared With Me" ||
                usersProjectList.some(
                  (obj) =>
                    obj.projectId === ellipsisClick.projectId &&
                    obj.permission !== "owner"
                )) ? (
                <li
                  className="projectListItems"
                  onClick={() => {
                    removeMeFromProject(ellipsisClick.projectId);
                    setActiveProject(null);
                    setPopupValues(null, null);
                    if (highlightProject) {
                      setActiveProject(highlightProject);
                    }
                  }}
                >
                  <span>Remove Me From Project</span>
                </li>
              ) : null}
              <li
                className="projectListItems"
                onClick={goDelete}
                style={{
                  display: default_project_ids.includes(ellipsisClick.projectId)
                    ? "none"
                    : usersProjectList.some(
                        (obj) =>
                          obj.projectId === ellipsisClick.projectId &&
                          obj.permission !== "owner"
                      )
                    ? "none"
                    : "block",
                }}
              >
                <span>Delete</span>
              </li>
            </ul>
          )}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  activeProject: state.projects.activeProject,
  popupScreen_x: state.projects.popupScreen_x,
  popupScreen_y: state.projects.popupScreen_y,
  style: state.local.style,
  reportingList: state.projects.reportingList,
  user: state.user.user,
  usersProjectList: state.projects.usersProjectList,
});

export default connect(mapStateToProps, {
  setActiveProject,
  updateRecent,
  addFavorite,
  editProject,
  saveAs,
  saveDeleteProject,
  setPopupValues,
  recoverDeleteProject,
  addReportList,
  setProjectToReport,
  permDeleteProject,
  handleOpenStartup,
  removeMeFromProject,
})(Popup) as any;
