import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./Report.css";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { setNavSearch, setFilterBox } from "../../redux/actions/Data";

const SummaryTables: React.FC<{
  style: any;
  userReportObject: any;
  summaryView: any;
  setSummaryView: any;
}> = ({ style, userReportObject, summaryView, setSummaryView }) => {
  const summarySection = () => {
    return (
      <div
        style={{
          display: !summaryView ? "none" : "block",
          overflowY: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "60px",
            marginTop: "10px",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            style={{
              width: "20vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Usage</p>
          </div>
          <div
            style={{
              width: "20vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Activities</p>
          </div>
          <div
            style={{
              width: "20vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Products</p>
          </div>
          <div
            style={{
              width: "20vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "none",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">References</p>
          </div>
          <div
            style={{
              width: "20vw",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1.5px solid white",
              borderRight: "1px solid black",
              height: "3vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsiveReportText">Total</p>
          </div>
        </div>
        <div
          className="modal-Reportbody"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {userReportObject.reportData?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                // marginLeft: "60px",
                backgroundColor:
                  index % 2 === 0 ? style.colors.lightBlue : "white",
              }}
            >
              <div
                style={{
                  width: "20vw",
                  // backgroundColor: index % 2 === 0 ? "lightblue" : "white",
                  color: "black",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">test</p>
              </div>
              <div
                style={{
                  width: "20vw",
                  // backgroundColor: index % 2 === 0 ? "lightblue" : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText">{item.uniID}</p>
              </div>
              <div
                style={{
                  width: "20vw",
                  // backgroundColor: index % 2 === 0 ? "lightblue" : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText"></p>
              </div>
              <div
                style={{
                  width: "20vw",
                  // backgroundColor: index % 2 === 0 ? "lightblue" : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="responsiveReportText"></p>
              </div>
              <div
                style={{
                  width: "20vw",
                  // backgroundColor: index % 2 === 0 ? "lightblue" : "white",
                  color: "black",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight: "none",
                  minHeight: "4vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="responsiveReportText"
                  style={{ marginLeft: "10px" }}
                ></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        className="reportRowHeaders"
        style={{
          // backgroundColor: style.colors.primaryBlue,
          // width: "94vw",
          // height: "35px",
          // display: "flex",
          // marginLeft: "60px",
          marginLeft: "20px",
        }}
      >
        <BsCaretDownFill
          className="responsiveReportText"
          onClick={() => setSummaryView(!summaryView)}
          style={{
            color: style.colors.white,
            // marginTop: "9px",
            // fontSize: "1.65vh",
            marginLeft: "10px",
            cursor: "pointer",
            transform: !summaryView ? "rotate(270deg)" : "rotate(0deg)",
          }}
        />
        <p
          className="responsiveReportText"
          style={{
            color: style.colors.white,
            // marginTop: "10px",
            paddingLeft: "40px",
            // fontSize: "1.65vh",
          }}
        >
          {"Summary Table"}
        </p>
      </div>
      {summarySection()}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  userReportObject: state.projects.projectToReport,
});

export default connect(mapStateToProps, {
  setNavSearch,
  setFilterBox,
})(SummaryTables) as any;
