import {
  GET_ACTIVITIES,
  GET_COLLABORATIONS,
  ADD_COLLABORATION,
  SET_ACTIVE_PROJECT,
  DELETE_COLLABORATION,
  NEW_ACTIVITIES,
  DELETE_ACTIVITY,
  SET_PROJECT_ACTIVITY_STATUS,
  SET_PROJECT_ACTIVITY_DUE_DATE,
  EDIT_ACTIVITY_TITLE,
  EDIT_ACTIVITY_DESCRIPTION,
  EDIT_ACTIVITY_SME,
  EDIT_ACTIVITY_TRADEOFFS,
  EDIT_ACTIVITY_COMMENTS,
  EDIT_ACTIVITY_ROI,
  EDIT_ACTIVITY_CONTDEL,
  EDIT_ACTIVITY_CHALLENGES,
  EDIT_ACTIVITY_RECOMMENDATIONS,
  EDIT_ACTIVITY_OTHER,
  EDIT_ACTIVITY_HSIRISKS,
  DELETE_COLLABORATION_OPTION,
  EDIT_ACTIVITY_PRODUCT_INPUTS_LIST,
  EDIT_ACTIVITY_PRODUCT_OUTPUTS_LIST,
  EDIT_ACTIVITY_REFERENCE_LIST,
  EDIT_ACTIVITY_DIDS_LIST,
  EDIT_ACTIVITY_TOOLS_LIST,
  UPDATE_COLLAB_LINE_TYPE,
  FIX_ACTIVITY_LIBRARY_LISTS,
  CLEAR_ACTIVITIES,
  GET_DATA_LOADING,
} from "../actions/types";

const initialState = {
  activities: [],
  collaborations: [],
  loadingActivities: false,
};

const userReducer = (state = initialState as any, action: any) => {
  switch (action.type) {
    case GET_ACTIVITIES:
      return {
        ...state,
        activities: action.payload,
      };
    case GET_DATA_LOADING:
      return {
        ...state,
        loadingActivities: true,
        // activities: state.activities,
      };
    case NEW_ACTIVITIES:
      return {
        ...state,
        loadingActivities: false,
        // activities: state.activities,
      };
    case DELETE_ACTIVITY:
      return {
        ...state,
        activities: [
          ...state.activities?.filter(
            (activity: any) => activity.activityId !== action.payload.activityId
          ),
        ],
        collaborations: action.payload.collaborations,
      };
    case GET_COLLABORATIONS:
      return {
        ...state,
        collaborations: action.payload,
      };
    case DELETE_COLLABORATION_OPTION:
      return {
        ...state,
        collaborations: action.payload,
      };
    case SET_ACTIVE_PROJECT:
      return {
        ...state,
        activities: [],
        collaborations: [],
      };
    case SET_PROJECT_ACTIVITY_STATUS:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              activityStatus: action.payload.activityStatus,
            };
          } else {
            return obj;
          }
        }),
      };
    case SET_PROJECT_ACTIVITY_DUE_DATE:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              dueDate: action.payload.dueDate,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_TITLE:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              summary: action.payload.summary,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_DESCRIPTION:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              description: action.payload.description,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_SME:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              smeInteraction: action.payload.smeInteraction,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_TRADEOFFS:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              tradeOffs: action.payload.tradeOffs,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_COMMENTS:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              comments: action.payload.comments,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_ROI:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              returnOnInvestment: action.payload.returnOnInvestment,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_CONTDEL:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              contractorDeliverables: action.payload.contractorDeliverables,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_CHALLENGES:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              challenges: action.payload.challenges,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_RECOMMENDATIONS:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              recommendations: action.payload.recommendations,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_OTHER:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              other: action.payload.other,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_HSIRISKS:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              hsiRisk: action.payload.hsiRisk,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_PRODUCT_INPUTS_LIST:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              inputProductList: action.payload.inputProductList,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_PRODUCT_OUTPUTS_LIST:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              outputProductList: action.payload.outputProductList,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_REFERENCE_LIST:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              referenceList: action.payload.referenceList,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_DIDS_LIST:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              didsList: action.payload.didsList,
            };
          } else {
            return obj;
          }
        }),
      };
    case EDIT_ACTIVITY_TOOLS_LIST:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              toolsList: action.payload.toolsList,
            };
          } else {
            return obj;
          }
        }),
      };
    case UPDATE_COLLAB_LINE_TYPE:
      return {
        ...state,
        collaborations: action.payload,
      };
    case FIX_ACTIVITY_LIBRARY_LISTS:
      return {
        ...state,
        activities: state.activities?.map((obj: any) => {
          if (obj.id === action.payload.activityId) {
            return {
              ...obj,
              didsList: action.payload.didsList,
              referenceList: action.payload.referenceList,
            };
          } else {
            return obj;
          }
        }),
      };
    case CLEAR_ACTIVITIES:
      return {
        ...state,
        activities: [],
      };
    default:
      return state;
  }
};

export default userReducer;
