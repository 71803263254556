import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./saveAsProjectModal.css";
import Modal from "react-modal";

import { createProject, saveAs, saveAsNew } from "../../redux/actions/Projects";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
  },
};

const SaveAsProjectModal: React.FC<{
  project: any;
  saveAs: (project: any) => void;
  saveAsNew: (formData: any) => void;
  style: any;
  hsiFrameworkModels: any;
  myProjects: any;
}> = ({
  project,
  saveAs,
  saveAsNew,
  style,
  hsiFrameworkModels,
  myProjects,
}) => {
  const nameCountCheck = (copyName) => {
    let currentProjects = myProjects.concat(hsiFrameworkModels);

    const uniqueIds = new Set();
    const uniqueObjects: any = [];

    for (const obj of currentProjects) {
      if (!uniqueIds.has(obj.projectId)) {
        uniqueIds.add(obj.projectId);
        uniqueObjects.push(obj);
      }
    }

    let occurrenceCount = 1;
    let newName = copyName;

    while (uniqueObjects.some((obj) => obj.name === newName)) {
      occurrenceCount++;
      newName = `${copyName} (${occurrenceCount})`;
    }

    return newName;
  };
  const [formData, setFormData] = useState({
    name: project?.name,
    description: project?.description,
    modelId: project?.modelId,
  });

  const modelNames = [
    "Urgent Capability Acquisition Pathway",
    "Middle Tier Acquisition Pathway",
    "Major Capability Acquisition Pathway",
    "Software Acquisition Pathway",
    "Defense Business Systems Pathway",
    "Acquisition of Services Pathway",
  ];

  useEffect(() => {
    if (project?.hasOwnProperty("projectId")) {
      setFormData({
        name: nameCountCheck(
          project?.name
            .replace(/\((\d+)\)/g, "")
            .replace(/\s+/g, " ")
            .trimEnd()
        ),
        description: project?.description,
        modelId: project?.modelId,
      });
    }
  }, [project]);

  const changeName = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const changeDescription = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
  };

  const closeUp = () => {
    saveAs(null);
  };

  const saveUp = () => {
    saveAs(null);
    saveAsNew({
      projectId: project?.projectId,
      name: formData.name,
      description: formData.description,
    });
  };

  return (
    <Modal
      isOpen={project?.hasOwnProperty("projectId")}
      onRequestClose={closeUp}
      style={customStyles}
    >
      <p style={{ paddingBottom: "1px", fontWeight: "bold" }}>Project name</p>
      <input
        style={{ width: "600px" }}
        onChange={changeName}
        value={formData.name}
      />

      <div style={{ paddingBottom: "2px" }}>
        <p style={{ paddingBottom: "2px", fontWeight: "bold" }}>
          Project Description
        </p>
        <textarea
          className="newPMInputs"
          onChange={changeDescription}
          value={formData.description}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <p style={{ paddingRight: "15px", fontWeight: "bold" }}>
          Pathway Template:
        </p>
        <p>{modelNames[formData.modelId - 1]}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: 20,
          marginTop: "10px",
        }}
      >
        <button
          style={{
            backgroundColor: "grey",
            width: "100px",
            height: "30px",
            color: style.colors.white,
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={closeUp}
        >
          Cancel
        </button>
        <button
          style={{
            backgroundColor: style.colors.primaryBlue,
            width: "100px",
            height: "30px",
            color: style.colors.white,
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={saveUp}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  project: state.projects.saveAs,
  style: state.local.style,
  hsiFrameworkModels: state.projects.hsiFrameworkModels,
  myProjects: state.projects.myProjects,
});

export default connect(mapStateToProps, {
  createProject,
  saveAs,
  saveAsNew,
})(SaveAsProjectModal) as any;
