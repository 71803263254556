import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setActiveProject,
  getReportList,
  setProjectToReport,
  setUserReportType,
  getUsersProjectList,
} from "../../redux/actions/Projects";
import { setNavSearch, setFilterBox } from "../../redux/actions/Data";

//Components
import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import Bottombar from "../../components/bottombar/bottombar";
import "./Dashboard.css";
import FeedbackModal from "../../components/feedbackModal/feedbackModal";
import NewProjectModal from "../../components/newProjectModal/newProjectModal";
import ImportProjectModal from "../../components/ImportProjectModal/ImportProjectModal";
import AcronymModal from "../../components/acronymModal/acronymModal";
import ProductLibraryModal from "../../components/productLibraryModal/productLibraryModal";
import ReferenceLibraryModal from "../../components/referenceLibraryModal/referenceLibraryModal";
import DeleteModal from "../../components/deleteModal/deleteModal";

//Modals
import EditProjectModal from "../../components/editProjectModal";
import SaveAsProjectModal from "../../components/saveAsProjectModal";
import Popup from "./popup";
import ShareProjectModal from "../../components/shareProjectModal";

//Sections
import Recent from "./recent";
import MyProjects from "./myProjects";
import HsiModels from "./hsiModels";
import Favorites from "./favorites";
import Deleted from "./deleted";
import AllProjects from "./allProjects";
import UserAccounts from "./accounts";
import Feedback from "./feedback";
import SharedWithMe from "./sharedWithMe";
import DidsModal from "../../components/didsModal/didsModal";
import { getUserType } from "../../redux/actions/User";
import ToolsModal from "../../components/toolsModal/toolsModal";
import { setNewProjectIncoming } from "../../redux/actions/Projects";
import { ReactFlowProvider } from "reactflow";

const Dashboard: React.FC<{
  user: any;
  setActiveProject: (project: any) => void;
  setNavSearch: (text: string) => void;
  setFilterBox: (box: boolean) => void;
  navSearch: string;
  getReportList: (userID: string) => void;
  getUserType: () => void;
  setProjectToReport: (project: any) => void;
  setUserReportType: (report: string) => void;
  activeProject: any;
  setNewProjectIncoming: (incoming: boolean) => void;
  newProjectIncoming: boolean;
  getUsersProjectList: () => void;
  usersProjectList: any;
  recentProjects: any;
}> = ({
  user,
  setActiveProject,
  setNavSearch,
  setFilterBox,
  navSearch,
  getReportList,
  getUserType,
  setProjectToReport,
  setUserReportType,
  activeProject,
  setNewProjectIncoming,
  newProjectIncoming,
  getUsersProjectList,
  usersProjectList,
  recentProjects,
}) => {
  const [sideOption, setSideOption] = useState("Recent") as any;
  const [feedback, setFeedback] = useState(false) as any;
  const [settingMenu, setSettingMenu] = useState(false) as any;
  const [helpMenu, setHelpMenu] = useState(false) as any;
  const [newProjectM, setNewProjectM] = useState(false) as any;
  const [shareProjectM, setShareProjectM] = useState(false) as any;
  const [importProjectM, setImportProjectM] = useState(false) as any;
  const [acronymM, setAcronymM] = useState(false) as any;
  const [productM, setProductM] = useState(false) as any;
  const [toolsM, setToolsM] = useState(false) as any;
  const [referenceM, setReferenceM] = useState(false) as any;
  const [didsM, setDidsM] = useState(false) as any;
  const [highlightProject, setHighlightProject] = useState(null) as any;

  const [ellipsisClick, setEllipsisClick] = useState({}) as any;

  const navigate = useNavigate();

  useEffect(() => {
    setActiveProject(null);
    getReportList(user.oid);
    setNavSearch("");
    getUsersProjectList();
  }, []);

  //todo
  // useEffect(() => {
  //   if (usersProjectList) {
  //     usersProjectList.filter((item) => {
  //       if (item.open_upon_login === true && item.deleted === false) {
  //         setActiveProject(
  //           recentProjects.filter(
  //             (recent) => recent.projectId === item.projectId
  //           )
  //         );
  //         navigate("/project");
  //       }
  //     });
  //   }
  // }, [usersProjectList]);

  // useEffect(() => {
  //   if (newProjectIncoming && activeProject) {
  //     if (activeProject.hasOwnProperty("projectId")) {
  //       console.log("activeProject", activeProject);
  //       navigate("/project");
  //       setNewProjectIncoming(false);
  //     }
  //   }
  // }, [activeProject, newProjectIncoming]);

  const handleReportClick = () => {
    navigate("/stepone");
    setProjectToReport(null);
    setUserReportType("");
  };

  const clickFeedback = () => {
    setFeedback(!feedback);
  };

  const closeFeedback = () => {
    setFeedback(false);
  };

  const closePopups = () => {
    setSettingMenu(false);
    setHelpMenu(false);
  };

  const handleSettings = () => {
    setSettingMenu(!settingMenu);
    setHelpMenu(false);
  };

  const handleHelp = () => {
    setHelpMenu(!helpMenu);
    setSettingMenu(false);
  };

  const openNewProjectM = () => {
    setNewProjectM(true);
  };

  const closeNewProjectM = () => {
    setNewProjectM(false);
  };

  const openImportProjectM = () => {
    setImportProjectM(true);
  };

  const closeImportProjectM = () => {
    setImportProjectM(false);
  };

  //Acronym Modal
  const openAcronymM = () => {
    setAcronymM(true);
  };
  const closeAcronymM = () => {
    setNavSearch("");
    setAcronymM(false);
  };

  //Product Modal
  const openProductM = () => {
    setProductM(true);
  };
  const closeProductM = () => {
    setProductM(false);
    setFilterBox(navSearch.length > 0 ? true : false);
    setNavSearch("");
  };

  //Reference Modal
  const openReferenceM = () => {
    setReferenceM(true);
  };
  const closeReferenceM = () => {
    setReferenceM(false);
    setFilterBox(navSearch.length > 0 ? true : false);
    setNavSearch("");
  };

  //DIDS Modal
  const openDIDSM = () => {
    setDidsM(true);
  };
  const closeDIDSM = () => {
    setDidsM(false);
  };

  //Tools Modal
  const openToolsM = () => {
    setToolsM(true);
  };
  const closeToolsM = () => {
    setNavSearch("");
    setToolsM(false);
  };

  return (
    <div className="main">
      <ReactFlowProvider>
        <Navbar
          clickFeedback={clickFeedback}
          settingMenu={settingMenu}
          helpMenu={helpMenu}
          handleSettings={handleSettings}
          handleHelp={handleHelp}
          closePopups={closePopups}
          screenName={``}
          openAcronymM={openAcronymM}
          openProductM={openProductM}
          openReferenceM={openReferenceM}
          openDIDSM={openDIDSM}
          handleReportClick={handleReportClick}
          openToolsM={openToolsM}
        />
      </ReactFlowProvider>
      <div className="box" onClick={closePopups}>
        <div className="leftSide">
          <Sidebar
            sideOption={sideOption}
            setSideOption={setSideOption}
            openNewProjectM={openNewProjectM}
            openImportProjectM={openImportProjectM}
            openAcronymM={openAcronymM}
            openProductM={openProductM}
            openReferenceM={openReferenceM}
            handleReportClick={handleReportClick}
            setHighlightProject={setHighlightProject}
          />
        </div>
        <div
          className="header1"
          style={{ overflowY: "auto", paddingBottom: "50px" }}
        >
          {sideOption === "Recent" ? (
            <Recent
              highlightProject={highlightProject}
              setHighlightProject={setHighlightProject}
              setEllipsisClick={setEllipsisClick}
            />
          ) : sideOption === "HSI Framework Models" ? (
            <HsiModels
              highlightProject={highlightProject}
              setHighlightProject={setHighlightProject}
              setEllipsisClick={setEllipsisClick}
            />
          ) : sideOption === "My Projects" ? (
            <MyProjects
              highlightProject={highlightProject}
              setHighlightProject={setHighlightProject}
              setEllipsisClick={setEllipsisClick}
            />
          ) : sideOption === "Shared With Me" ? (
            <SharedWithMe
              highlightProject={highlightProject}
              setHighlightProject={setHighlightProject}
              setEllipsisClick={setEllipsisClick}
            />
          ) : sideOption === "Favorites" ? (
            <Favorites
              highlightProject={highlightProject}
              setHighlightProject={setHighlightProject}
              setEllipsisClick={setEllipsisClick}
            />
          ) : sideOption === "Deleted" ? (
            <Deleted
              highlightProject={highlightProject}
              setHighlightProject={setHighlightProject}
              setEllipsisClick={setEllipsisClick}
            />
          ) : sideOption === "All Projects" ? (
            <AllProjects sideOption={sideOption} />
          ) : sideOption === "User Accounts" ? (
            <UserAccounts />
          ) : sideOption === "Feedback" ? (
            <Feedback sideOption={sideOption} />
          ) : null}
        </div>
      </div>
      <FeedbackModal closeFeedback={closeFeedback} feedback={feedback} />
      <NewProjectModal
        closeNewProjectM={closeNewProjectM}
        newProjectM={newProjectM}
      />
      <ShareProjectModal
        closeShareProjectM={() => setShareProjectM(false)}
        shareProjectM={shareProjectM}
      />
      <EditProjectModal />
      <SaveAsProjectModal />
      <ImportProjectModal
        closeImportProjectM={closeImportProjectM}
        importProjectM={importProjectM}
      />
      <AcronymModal closeAcronymM={closeAcronymM} acronymM={acronymM} />
      <ProductLibraryModal closeProductM={closeProductM} productM={productM} />
      <ReferenceLibraryModal
        closeReferenceM={closeReferenceM}
        referenceM={referenceM}
      />
      <DidsModal closeDIDSM={closeDIDSM} didsM={didsM} />
      <ToolsModal closeToolsM={closeToolsM} toolsM={toolsM} />
      <Popup
        sideOption={sideOption}
        highlightProject={highlightProject}
        ellipsisClick={ellipsisClick}
        setEllipsisClick={setEllipsisClick}
        setShareProjectM={setShareProjectM}
      />
      <DeleteModal />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  projects: state.projects.projects,
  navSearch: state.data.navSearch,
  activeProject: state.projects.activeProject,
  newProjectIncoming: state.projects.newProjectIncoming,
  usersProjectList: state.projects.usersProjectList,
  recentProjects: state.projects.recent,
});

export default connect(mapStateToProps, {
  setActiveProject,
  setNavSearch,
  setFilterBox,
  getReportList,
  getUserType,
  setProjectToReport,
  setUserReportType,
  setNewProjectIncoming,
  getUsersProjectList,
})(Dashboard) as any;
