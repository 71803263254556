import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./toolsModal.css";
import { getTools } from "../../redux/actions/Data";
import {
  setActivityIDList,
  setActivityToolsChanged,
} from "../../redux/actions/Projects";
import { editActivityToolsList } from "../../redux/actions/Activities";
import { toolSort } from "../../lib/helpers/columnFilters";

const ToolsModal: React.FC<{
  closeToolsM: any;
  toolsM: any;
  toolsLibrary: any;
  getTools: () => void;
  style: any;
  navSearch: string;
  setActivityIDList: (itemID: any) => void;
  activityIDList: any;
  activityLibraryEdit: boolean;
  setActivityToolsChanged: (state: any) => void;
  initialTools: any;
  activeProjectActivity: any;
  editActivityToolsList: (activityId: string, list: any) => void;
  detailsTool: any;
  setDetailsTool: any;
}> = ({
  closeToolsM,
  toolsM,
  toolsLibrary,
  getTools,
  style,
  navSearch,
  setActivityIDList,
  activityIDList,
  activityLibraryEdit,
  setActivityToolsChanged,
  initialTools,
  activeProjectActivity,
  editActivityToolsList,
  detailsTool,
  setDetailsTool,
}) => {
  const [toolSearch, setToolSearch] = useState("") as any;
  const [searchFiltered, setSearchFiltered] = useState([]) as any;
  const [nameAscending, setNameAscending] = useState(0) as any;
  const [meaningAscending, setMeaningAscending] = useState(0) as any;
  const [dateAscending, setDateAscending] = useState(0) as any;
  const [idList, setIDList] = useState([]) as any;
  const [currentSort, setCurrentSort] = useState("name") as any;

  useEffect(() => {
    getTools();
  }, []);

  useEffect(() => {
    setToolSearch(navSearch);
    if (detailsTool.length > 0) {
      setToolSearch(detailsTool);
    }
  }, [navSearch, detailsTool]);

  useEffect(() => {
    let toolsList = toolsLibrary.filter((x) => !x.hasOwnProperty("init_id"));

    if (toolSearch !== "" && toolsLibrary?.length > 0) {
      toolsList = toolsList.filter(
        (item: any) =>
          item?.name?.toLowerCase().indexOf(toolSearch.toLowerCase()) > -1 ||
          item?.description?.toLowerCase().indexOf(toolSearch.toLowerCase()) >
            -1
      );
    }

    toolsList = toolSort(
      toolsList,
      nameAscending,
      meaningAscending,
      dateAscending
    );

    setSearchFiltered(toolsList);
  }, [
    toolSearch,
    toolsLibrary,
    nameAscending,
    meaningAscending,
    dateAscending,
  ]);

  const nameColumn = () => {
    setCurrentSort(currentSort !== "acronym" ? "acronym" : currentSort);
    setMeaningAscending(0);
    nameAscending === 1 ? setNameAscending(2) : setNameAscending(1);
  };

  const meaningColumn = () => {
    setCurrentSort(currentSort !== "meaining" ? "meaining" : currentSort);
    setNameAscending(0);
    meaningAscending === 1 ? setMeaningAscending(2) : setMeaningAscending(1);
  };

  const closingModal = () => {
    closeToolsM();
    setToolSearch("");
    setDetailsTool("");
  };

  const handleCheckBox = (selection) => {
    setActivityIDList(selection);
    if (activityIDList.some((obj) => obj.toolId === selection.toolId)) {
      setIDList(
        activityIDList.filter((item: any) => item.toolId !== selection.toolId)
      );
    } else {
      let newArray = activityIDList;
      newArray.push(selection);
      setIDList(newArray);
    }
  };

  // const cancelAcronym = () => {
  //   setNewToolsModal(false);
  //   setToolError(false);
  //   setMeaningError(false);
  //   setNewToolName("");
  //   setNewMeaning("");
  // };

  // const saveAcronym = () => {
  //   if (newToolName !== "" && newMeaning !== "") {
  //     if (focusId === 0) {
  //       setNameAscending(0);
  //       setMeaningAscending(0);
  //       setDateAscending(0);
  //       addNewAcronym(newToolName, newMeaning);
  //       setNewToolsModal(false);
  //       setFocusId(0);
  //       setNewToolName("");
  //       setNewMeaning("");
  //       setToolError(false);
  //       setMeaningError(false);
  //     } else if (focusId !== 0) {
  //       setNameAscending(0);
  //       setMeaningAscending(0);
  //       setDateAscending(0);
  //       updateAcronym(focusId, newToolName, newMeaning);
  //       setNewToolsModal(false);
  //       setFocusId(0);
  //       setNewToolName("");
  //       setNewMeaning("");
  //       setToolError(false);
  //       setMeaningError(false);
  //     }
  //   } else {
  //     if (newToolName === "") {
  //       setToolError(true);
  //     } else {
  //       setToolError(false);
  //     }
  //     if (newMeaning === "") {
  //       setMeaningError(true);
  //     } else {
  //       setMeaningError(false);
  //     }
  //   }
  // };

  // const deleteAcronymAction = () => {
  //   deleteAcronym(focusId);
  //   setNewToolsModal(false);
  //   setFocusId(0);
  //   setNewToolName("");
  //   setNewMeaning("");
  //   setToolError(false);
  //   setMeaningError(false);
  // };

  // const closeMini = () => {
  //   setDeleteOption(false);
  //   setFocusId(0);
  //   setNewToolName("");
  //   setNewMeaning("");
  //   cancelAcronym();
  // };

  const cancelButton = () => {
    closeToolsM();
    setToolSearch("");
    setActivityIDList("delete");
    setDetailsTool("");
  };

  const checkToolsChanges = (closeType: string) => {
    if (initialTools.length === 0) {
      if (activityIDList.length > 0) {
        if (closeType === "save") {
          setActivityToolsChanged(true);
          editActivityToolsList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else if (closeType === "exit") {
          showChangesToast();
          setActivityToolsChanged(true);
        } else {
          setActivityToolsChanged(false);
          cancelButton();
        }
      } else {
        if (closeType === "save") {
          setActivityToolsChanged(true);
          editActivityToolsList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else {
          setActivityToolsChanged(false);
          cancelButton();
        }
      }
    } else {
      if (activityIDList.length === initialTools.length) {
        if (activityIDList.sort().join(",") === initialTools.sort().join(",")) {
          if (closeType === "save") {
            setActivityToolsChanged(true);
            editActivityToolsList(
              activeProjectActivity.activityId,
              activityIDList
            );
            cancelButton();
          } else if (closeType === "exit") {
            setActivityToolsChanged(false);
            cancelButton();
          } else {
            setActivityToolsChanged(false);
            cancelButton();
          }
        } else {
          showChangesToast();
          setActivityToolsChanged(true);
        }
      } else {
        if (closeType === "save") {
          setActivityToolsChanged(true);
          editActivityToolsList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else if (closeType === "exit") {
          showChangesToast();
          setActivityToolsChanged(true);
        } else {
          setActivityToolsChanged(false);
          cancelButton();
        }
      }
    }
  };

  return (
    <div style={{ visibility: toolsM ? "initial" : "hidden" }}>
      <div className="modal-container-tools">
        <section className="projectModals-tools">
          <i
            className="fa fa-times"
            onClick={() => {
              activityLibraryEdit ? checkToolsChanges("exit") : closingModal();
            }}
            style={{
              cursor: "pointer",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          ></i>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "-20px",
            }}
          >
            <p>Tools Library</p>
            <input
              className="toolSearchBox"
              value={toolSearch}
              onChange={(e) => setToolSearch(e.target.value)}
              style={{
                width: "160px",
                height: "20px",
                alignSelf: "center",
                marginRight: "40px",
                padding: "2px",
                border: "1px solid black",
                paddingLeft: "20px",
                borderRadius: "8px",
              }}
              placeholder="Search Tools"
            />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              onClick={() =>
                window.open(
                  "https://wss.apan.org/osd/HSI-BOKM/Lists/tblHSI_TTAMs_full/AllItems.aspx",
                  "_blank"
                )
              }
              style={{
                marginTop: "-5px",
                paddingBottom: "2px",
                fontSize: "13px",
                color: style.colors.primaryBlue,
                cursor: "pointer",
              }}
            >
              Link to Tools List in APAN
            </p>
            <div
              style={{
                display: activityLibraryEdit ? "flex" : "none",
                gap: 10,
                marginLeft: "auto",
              }}
            >
              <button
                onClick={cancelButton}
                style={{
                  backgroundColor: "grey",
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => checkToolsChanges("save")}
                style={{
                  backgroundColor: style.colors.primaryBlue,
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Save
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "6px",
              borderTop: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            <div
              onClick={nameColumn}
              style={{
                // width: "192px",
                width: "15vw",
                minWidth: "106px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont">
                {nameAscending === 0
                  ? "Name"
                  : nameAscending === 1
                  ? "Name ↓"
                  : "Name ↑"}
              </p>
            </div>
            <div
              onClick={meaningColumn}
              style={{
                // width: "518px",
                width: "35vw",
                minWidth: "288px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "1px solid white",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont">
                {meaningAscending === 0
                  ? "Description"
                  : meaningAscending === 1
                  ? "Description ↓"
                  : "Description ↑"}
              </p>
            </div>
            <div
              // onClick={dateColumn}
              style={{
                // width: "202px",
                width: "13vw",
                minWidth: "133px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                height: "29px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                borderRight: "none",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont" style={{ marginLeft: "15px" }}>
                {dateAscending === 0
                  ? "HSI Activity(ies)"
                  : dateAscending === 1
                  ? "HSI Activity(ies) ↓"
                  : "HSI Activity(ies) ↑"}
              </p>
            </div>
          </div>
          <div
            className="modal-body"
            style={{
              border: "1px solid black",
              paddingTop: ".75px",
            }}
          >
            {searchFiltered?.map((item: any, index: number) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                  minWidth: "139px",
                }}
              >
                <div
                  style={{
                    // width: "192px",
                    width: "15vw",
                    minWidth: "106px",
                    borderLeft: "none",
                    borderRight: "none",
                    color: "black",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="overFlowTextAcronym responsiveFont">
                    {item.name}
                  </p>
                </div>
                <div
                  style={{
                    // width: "518px",
                    width: "35vw",
                    minWidth: "288px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    color: "black",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="overFlowTextTools responsiveFont">
                    {item.description}
                  </p>
                </div>
                <div
                  style={{
                    // width: "153px",
                    width: "11vw",
                    minWidth: "117px",
                    borderLeft: "none",
                    borderRight: "none",
                    color: "black",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p className="overFlowTextTools responsiveFont">
                    {item.activities}
                  </p>
                  {activityLibraryEdit ? (
                    <input
                      onClick={() => handleCheckBox(item)}
                      type="checkbox"
                      checked={
                        activityIDList.some((obj) => obj.toolId === item.toolId)
                          ? true
                          : false
                      }
                      id="productcheck"
                      name="productcheck"
                      value="productcheck"
                      style={{ marginLeft: "auto" }}
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );

  function showChangesToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar6");
    if (element[0]) {
      element[0].className = "snackbar6 show";
      setTimeout(function () {
        element[0].className = "snackbar6";
      }, 5000);
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  toolsLibrary: state.data.toolsLibrary,
  style: state.local.style,
  navSearch: state.data.navSearch,
  activityIDList: state.projects.activityIDList,
  activityLibraryEdit: state.projects.activityLibraryEdit,
  initialTools: state.projects.initialTools,
  activeProjectActivity: state.projects.activeProjectActivity,
});

export default connect(mapStateToProps, {
  getTools,
  setActivityIDList,
  editActivityToolsList,
  setActivityToolsChanged,
})(ToolsModal) as any;
