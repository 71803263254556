import React, { useState } from "react";
import { connect } from "react-redux";
import "./ImportProjectModal.css";
import Modal from "react-modal";
import { uploadProject } from "../../redux/actions/Projects";

const ImportProjectModal: React.FC<{
  closeImportProjectM: any;
  importProjectM: any;
  style: any;
  uploadProject: (file: any, modelId: string) => void;
}> = ({ closeImportProjectM, importProjectM, style, uploadProject }) => {
  const [loadingModal, setLoadingModal] = useState(false) as any;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    modelId: "0",
    file: null,
  });
  const [errors, setErrors] = useState(false);
  const changeModel = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      modelId: e.target.value,
    }));
  };
  const upload = (e: any) => {
    try {
      if (!formData.modelId || formData.modelId === "0") return setErrors(true);
      setFormData((prevState) => ({ ...prevState, file: e }));
    } catch (error) {}
  };
  const importProject = async () => {
    try {
      setLoading(true);
      setLoadingModal(true);
      if (!formData.modelId || formData.modelId === "0") return setErrors(true);
      await uploadProject(formData.file, formData.modelId);
    } catch (error) {}
    setLoading(false);
    setLoadingModal(false);
    closeImportProjectM();
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
  };

  const customStyles2 = {
    content: {
      top: "75%",
      left: "70%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
    overlay: {
      background: "none",
    },
  };

  return (
    <div>
      <Modal
        isOpen={importProjectM}
        onRequestClose={closeImportProjectM}
        style={customStyles}
      >
        <p style={{ paddingBottom: "2px" }}>Import project:</p>
        <select
          name="base"
          className="base"
          style={{
            width: "260px",
            height: "25px",
            borderColor: errors ? "red" : "black",
          }}
          onChange={changeModel}
        >
          <option value="0">Select Model</option>
          <option value="1">Urgent Capability Acquisition Pathway</option>
          <option value="2">Middle Tier Acquisition Pathway</option>
          <option value="3">Major Capability Acquisition Pathway</option>
          <option value="4">Software Acquisition Pathway</option>
          <option value="5">Defense Business Systems Pathway</option>
          <option value="6">Acquisition of Services Pathway</option>
        </select>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "30px",
          }}
        >
          <input
            style={{ width: "440px" }}
            placeholder="Project Name"
            type="file"
            onChange={upload}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 20,
            marginTop: "50px",
            width: "500px",
          }}
        >
          <button
            style={{
              backgroundColor: "grey",
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={closeImportProjectM}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: style.colors.primaryBlue,
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={importProject}
          >
            {loading ? (
              <div
                style={{ marginBottom: "5px", marginRight: "5px" }}
                className="lds-dual-ring2"
              />
            ) : (
              "Upload"
            )}
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={loadingModal}
        onRequestClose={() => setLoadingModal(false)}
        style={customStyles2}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "-20px",
          }}
        >
          <p style={{ paddingBottom: "0px" }}>Importing Project...</p>
          <i
            className="fa fa-times"
            onClick={() => setLoadingModal(false)}
            style={{
              cursor: "pointer",
              alignSelf: "center",
            }}
          ></i>
        </div>

        <input
          style={{ width: "440px", height: "20px", cursor: "default" }}
          readOnly
        />

        <p style={{ marginBottom: "-20px" }}>
          This may take several minutes. Clck the 'X' to hide this window
        </p>
        <p style={{}}>while your project is importing</p>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({ style: state.local.style });

export default connect(mapStateToProps, {
  uploadProject,
})(ImportProjectModal) as any;
