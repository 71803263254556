import { combineReducers } from "redux";

//Reducers
import local from "./local";
import user from "./user";
import projects from "./projects";
import data from "./data";
import activities from "./activities";

export default combineReducers({
  local,
  user,
  projects,
  data,
  activities,
});
