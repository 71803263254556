import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import Table from "./Table";
import Card from "../Card";
import { getFeedbacks } from "../../../redux/actions/Data";

const Feedback: React.FC<{
  getFeedbacks: () => void;
  sideOption: string;
  feedbackLibrary: any;
}> = ({ getFeedbacks, sideOption, feedbackLibrary }) => {
  const [feedbackList, setFeedbackList] = useState([]) as any;

  useEffect(() => {
    getFeedbacks();
    let feedbackList = feedbackLibrary.filter(
      (x) => !x.hasOwnProperty("init_id")
    );

    // if (acronymSearch !== "" && acronyms?.length > 0) {
    //   acronymList = acronymList.filter(
    //     (item: any) =>
    //       item.acronymName.toLowerCase().indexOf(acronymSearch.toLowerCase()) >
    //         -1 ||
    //       item.definition.toLowerCase().indexOf(acronymSearch.toLowerCase()) >
    //         -1
    //   );
    // }

    // acronymList = acronymSort(acronymList, acronymAscending, dateAscending);

    setFeedbackList(feedbackList);
  }, [feedbackLibrary.length]);

  return (
    <>
      <div
        className="subTitle"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p style={{ paddingLeft: "25px", fontWeight: "bold", fontSize: "16px" }}>Feedback</p>
      </div>

      <div className="header2">
        <Table
          projectList={feedbackList}
          // handleClick={() => console.log("CLicked")}
          // setProjectClicked={() => console.log("CLicked")}
          // openDeleteM={() => console.log("CLicked")}
          adminFolderState={true}
          sideOption={sideOption}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  feedbackLibrary: state.data.feedbackLibrary,
});

export default connect(mapStateToProps, { getFeedbacks })(Feedback) as any;
