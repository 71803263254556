import axios from "axios";
import config from "../../config.json";
import { LOGOUT } from "./types";

const login = async () => {
  try {
    const url = `${config.api}auth/login`;
    const res = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const logout = () => async (dispatch: any) => {
  try {
    const url = `${config.api}auth/logout`;
    axios.get(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: LOGOUT,
      payload: null,
    });
  } catch (error) {
    console.log(error);
  }
};

export { logout, login };
