import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Navbar from "../../components/navbar/navbar";
import "./StepOne.css";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import FeedbackModal from "../../components/feedbackModal/feedbackModal";
import NewProjectModal from "../../components/newProjectModal/newProjectModal";
import AcronymModal from "../../components/acronymModal/acronymModal";
import ProductLibraryModal from "../../components/productLibraryModal/productLibraryModal";
import ReferenceLibraryModal from "../../components/referenceLibraryModal/referenceLibraryModal";
import DidsModal from "../../components/didsModal/didsModal";
import { setNavSearch, setFilterBox } from "../../redux/actions/Data";
import {
  getDeleted,
  getFavorites,
  getHsiFrameworkModels,
  getMyProjects,
  getRecentProjects,
  removeReportList,
  setUserReportType,
  getReportList,
  setProjectToReport,
  editReportCategories,
  resetReportCategories,
} from "../../redux/actions/Projects";
import ProjectReportModal from "../../components/projectReportModal/projectReportModal";
import { ReactComponent as Editsvg } from "../../lib/assets/img/Edit.svg";
import { ReactFlowProvider } from "reactflow";
import ToolsModal from "../../components/toolsModal/toolsModal";

const StepOne: React.FC<{
  style: any;
  setNavSearch: (text: string) => void;
  setFilterBox: (box: boolean) => void;
  user: any;
  navSearch: string;
  getDeleted: () => void;
  getFavorites: () => void;
  getHsiFrameworkModels: () => void;
  getMyProjects: () => void;
  getRecentProjects: () => void;
  removeReportList: (project: any, userID: string) => void;
  reportingList: any;
  setUserReportType: (report: string) => void;
  userReportType: string;
  getReportList: (userID: string) => void;
  setProjectToReport: (project: any) => void;
  userReportSelected: any;
  editReportCategories: (reportSettings: object) => void;
  userReport: any;
  resetReportCategories: () => void;
  recent_projects: any;
}> = ({
  style,
  setNavSearch,
  setFilterBox,
  user,
  navSearch,
  getDeleted,
  getFavorites,
  getHsiFrameworkModels,
  getMyProjects,
  getRecentProjects,
  removeReportList,
  reportingList,
  setUserReportType,
  userReportType,
  getReportList,
  setProjectToReport,
  userReportSelected,
  userReport,
  resetReportCategories,
  recent_projects,
}) => {
  const navigate = useNavigate();
  const [reportType, setReportType] = useState(userReportType) as any;
  const [feedback, setFeedback] = useState(false) as any;
  const [settingMenu, setSettingMenu] = useState(false) as any;
  const [helpMenu, setHelpMenu] = useState(false) as any;
  const [newProjectM, setNewProjectM] = useState(false) as any;
  const [acronymM, setAcronymM] = useState(false) as any;
  const [productM, setProductM] = useState(false) as any;
  const [referenceM, setReferenceM] = useState(false) as any;
  const [didsM, setDidsM] = useState(false) as any;
  const [toolsM, setToolsM] = useState(false) as any;
  const [aprm, setAPRMM] = useState(false) as any;
  const [projectError, setProjectError] = useState(false) as any;
  const [reportError, setReportError] = useState(false) as any;

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const resetCategories = () => {
    editReportCategories({
      userReportSelected: userReportSelected,
      phase: [],
      domain: [],
      risk: [],
      activity: [],
      notes: [],
      content: [],
      management: [],
      riskStartDate: "",
      riskEndDate: "",
      statusStartDate: "",
      statusEndDate: "",
      updateStartDate: "",
      updateEndDate: "",
      addedStartDate: "",
      addedEndDate: "",
      modifiedStartDate: "",
      modifiedEndDate: "",
      deletedStartDate: "",
      deletedEndDate: "",
      reportType: userReportType,
    });
  };

  const typeRiskReport = () => {
    if (reportType === "Risk Report") {
      setReportType("");
    } else setReportType("Risk Report");
  };

  const typeStatusReport = () => {
    if (reportType === "Status Report") {
      setReportType("");
    } else setReportType("Status Report");
  };

  const typeActivitiesReport = () => {
    if (reportType === "Custom Activities Report") {
      setReportType("");
    } else setReportType("Custom Activities Report");
  };

  const typeLibrariesReport = () => {
    if (reportType === "Custom Libraries Report") {
      setReportType("");
    } else setReportType("Custom Libraries Report");
  };

  const typeRelatedReport = () => {
    if (reportType === "Related Items Report") {
      setReportType("");
    } else setReportType("Related Items Report");
  };

  const typeContentReport = () => {
    if (reportType === "Content Report") {
      setReportType("");
    } else setReportType("Content Report");
  };

  const clickFeedback = () => {
    setFeedback(!feedback);
  };

  const closeFeedback = () => {
    setFeedback(false);
  };

  const closePopups = () => {
    setSettingMenu(false);
    setHelpMenu(false);
  };

  const handleSettings = () => {
    setSettingMenu(!settingMenu);
    setHelpMenu(false);
  };

  const handleHelp = () => {
    setHelpMenu(!helpMenu);
    setSettingMenu(false);
  };

  const openNewProjectM = () => {
    setNewProjectM(true);
  };

  const closeNewProjectM = () => {
    setNewProjectM(false);
  };

  //Acronym Modal
  const openAcronymM = () => {
    setAcronymM(true);
  };
  const closeAcronymM = () => {
    setNavSearch("");
    setAcronymM(false);
  };

  //Product Modal
  const openProductM = () => {
    setProductM(true);
  };
  const closeProductM = () => {
    setProductM(false);
    setFilterBox(navSearch.length > 0 ? true : false);
    setNavSearch("");
  };

  //Reference Modal
  const openReferenceM = () => {
    setReferenceM(true);
  };
  const closeReferenceM = () => {
    setReferenceM(false);
    setFilterBox(navSearch.length > 0 ? true : false);
    setNavSearch("");
  };

  //DIDS Modal
  const openDIDSM = () => {
    setDidsM(true);
  };
  const closeDIDSM = () => {
    setDidsM(false);
  };

  //Tools Modal
  const openToolsM = () => {
    setToolsM(true);
  };
  const closeToolsM = () => {
    setNavSearch("");
    setToolsM(false);
  };

  //Add Project Report Modal
  const openAPRM = () => {
    setAPRMM(true);
  };
  const closeAPRM = () => {
    setAPRMM(false);
  };

  const nextButton = () => {
    resetReportCategories();
    if (userReportSelected === null) {
      setProjectError(true);
    }
    if (reportType === "") {
      setReportError(true);
    }
    if (userReportSelected !== null && reportType !== "") {
      setProjectError(false);
      setReportError(true);
      setUserReportType(reportType);
      navigate("/steptwo");
    }
  };

  const cancelButton = () => {
    setUserReportType("");
    setReportType("");
    navigate(-1);
  };

  useEffect(() => {
    getDeleted();
    getFavorites();
    getHsiFrameworkModels();
    getMyProjects();
    getRecentProjects();
    setNavSearch("");
  }, []);

  useEffect(() => {
    getReportList(user.oid);
  }, [reportingList.length]);

  // useEffect(() => {
  //   window.onpopstate = function () {
  //     setProjectToReport(null);
  //   };
  // }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: style.colors.white,
      }}
    >
      <ReactFlowProvider>
        <Navbar
          clickFeedback={clickFeedback}
          settingMenu={settingMenu}
          helpMenu={helpMenu}
          handleSettings={handleSettings}
          handleHelp={handleHelp}
          closePopups={closePopups}
          screenName={`Reports`}
          openAcronymM={openAcronymM}
          openProductM={openProductM}
          openReferenceM={openReferenceM}
          openDIDSM={openDIDSM}
          openToolsM={openToolsM}
        />
      </ReactFlowProvider>
      <div
        style={{
          backgroundColor: style.colors.primaryBlue,
          height: "40px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            color: style.colors.white,
            marginLeft: "40px",
            marginTop: "11px",
          }}
        >
          {"Step 1: Select Project and Report Type"}
        </p>
        <p
          style={{
            color: style.colors.white,
            marginRight: "40px",
            marginTop: "11px",
          }}
        >
          Step 1 of 2
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          // paddingLeft: "20px",
          // paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // height: "45%",
            // marginTop: "-16px",
            // borderBottom: "2px solid grey",
            // overflowY: "auto",
          }}
        >
          <p
            style={{
              color: projectError ? "red" : "black",
              paddingTop: "10px",
              // paddingLeft: "40px",
            }}
          >
            {"Select which project you would like to create a report for."}
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                width: "2vw",
                minWidth: "50px",
                backgroundColor: style.colors.primaryBlue,
                color: "black",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
            <div
              // onClick={acronymMeaningColumn}
              style={{
                // width: "518px",
                width: "27vw",
                minWidth: "288px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p
                className="responsiveFont responsiveFontHeader"
                style={{ fontWeight: "bold", marginRight: "2vw" }}
              >
                Project
              </p>
            </div>
            <div
              // onClick={acronymColumn}
              style={{
                // width: "192px",
                width: "10vw",
                minWidth: "106px",
                backgroundColor: style.colors.primaryBlue,
                borderLeft: "1px solid white",
                borderRight: "1px solid white",
                color: style.colors.white,
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p
                className="responsiveFont responsiveFontHeader"
                style={{ fontWeight: "bold" }}
              >
                Project Owner
              </p>
            </div>
            <div
              // onClick={dateColumn}
              style={{
                // width: "172px",
                width: "9vw",
                minWidth: "133px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "none",
                cursor: "pointer",
              }}
            >
              <p
                className="responsiveFont responsiveFontHeader"
                style={{ fontWeight: "bold" }}
              >
                Date Created
              </p>
            </div>
          </div>
          <div
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {recent_projects?.map((item: any, index: number) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: "2vw",
                    minWidth: "50px",
                    backgroundColor:
                      index % 2 === 0 ? style.colors.lightBlue : "white",
                    color: "black",
                    height: "29px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    onClick={() => {
                      setProjectToReport(item);
                    }}
                    type="checkbox"
                    checked={
                      userReportSelected?.projectId === item?.projectId
                        ? true
                        : false
                    }
                    id="didcheck"
                    name="didcheck"
                    value="didcheck"
                  />
                </div>
                <div
                  style={{
                    // width: "518px",
                    width: "27vw",
                    minWidth: "288px",
                    backgroundColor:
                      index % 2 === 0 ? style.colors.lightBlue : "white",
                    color: "black",
                    height: "29px",
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="overFlowTextAcronym responsiveFont"
                    title={item.name}
                  >
                    {item.name}
                  </p>
                </div>
                <div
                  style={{
                    // width: "192px",
                    width: "10vw",
                    minWidth: "106px",
                    backgroundColor:
                      index % 2 === 0 ? style.colors.lightBlue : "white",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    color: "black",
                    height: "29px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="overFlowTextAcronym responsiveFont"
                    // title={item.acronymName}
                  >
                    test
                    {/* {item.acronymName} */}
                  </p>
                </div>
                <div
                  style={{
                    // width: "153px",
                    width: "9vw",
                    minWidth: "133px",
                    backgroundColor:
                      index % 2 === 0 ? style.colors.lightBlue : "white",
                    borderLeft: "none",
                    borderRight: "none",
                    color: "black",
                    height: "29px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="responsiveFont">
                    {new Date(item.createdDate).getDate() < 10 ? "0" : ""}
                    {new Date(item.createdDate).getDate() +
                      " " +
                      months[new Date(item.createdDate).getMonth()] +
                      " " +
                      new Date(item.createdDate).getFullYear()}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* <div
            style={{ marginLeft: "45px", display: "flex", flexWrap: "wrap" }}
          >
            {reportingList.map((item: any) => {
              return (
                <div
                  style={{
                    height: "30px",
                    border: "1px solid grey",
                    borderRadius: "8px",
                    marginTop: "10px",
                    display: "flex",
                    marginRight: "20px",
                    backgroundColor:
                      userReportSelected !== null &&
                      userReportSelected.projectId === item.projectId
                        ? style.colors.primaryBlue
                        : "transparent",
                    cursor: "pointer",
                  }}
                >
                  <div
                    onClick={() => {
                      setProjectToReport(item);
                    }}
                    style={{
                      // width: "70%",
                      paddingRight: "20px",
                    }}
                  >
                    <p
                      style={{
                        color:
                          userReportSelected !== null &&
                          userReportSelected.projectId === item.projectId
                            ? "white"
                            : "black",
                        marginLeft: "10px",
                        marginTop: "6.5px",
                      }}
                    >
                      {item.name}
                    </p>
                  </div>

                  <div
                    onClick={() => removeReportList(item, user.oid)}
                    style={
                      {
                        // width: "25%",
                      }
                    }
                  >
                    <MdClose
                      // onClick={() => removeReportList(item, user.oid)}
                      style={{
                        color:
                          userReportSelected !== null &&
                          userReportSelected.projectId === item.projectId
                            ? "white"
                            : "grey",
                        fontSize: 20,
                        marginTop: "5px",
                        cursor: "pointer",
                        zIndex: 9,
                      }}
                    />
                  </div>
                  <div
                    onClick={() => setProjectToReport(item)}
                    style={{
                      // width: "5%",
                      paddingRight: "10px",
                    }}
                  ></div>
                </div>
              );
            })}
          </div> */}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p
            style={{
              color: reportError ? "red" : "black",
              paddingTop: "10px",
              paddingLeft: "40px",
            }}
          >
            Select the type of report you would like to create.
          </p>

          <div
            style={{
              paddingLeft: "40px",
              paddingRight: "6%",
              marginTop: "5px",
              // display: "flex",
              // justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <input
                className="reportRadios"
                type="radio"
                id="report1"
                name="report"
                value="Risk"
                checked={reportType === "Risk Report" ? true : false}
                onClick={typeRiskReport}
              />
              <p onClick={typeRiskReport} style={{ cursor: "default" }}>
                Risk Report
              </p>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <input
                className="reportRadios"
                type="radio"
                id="report2"
                name="report"
                value="Status Report"
                checked={reportType === "Status Report" ? true : false}
                // style={{ marginLeft: "110px" }}
                onClick={typeStatusReport}
              />
              <p onClick={typeStatusReport} style={{ cursor: "default" }}>
                Status Report
              </p>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <input
                className="reportRadios"
                type="radio"
                id="report3"
                name="report"
                value="Activities"
                checked={
                  reportType === "Custom Activities Report" ? true : false
                }
                // style={{ marginLeft: "110px" }}
                onClick={typeActivitiesReport}
              />
              <p onClick={typeActivitiesReport} style={{ cursor: "default" }}>
                Custom Activities Report
              </p>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <input
                className="reportRadios"
                type="radio"
                id="report4"
                name="report"
                value="Libraries"
                checked={
                  reportType === "Custom Libraries Report" ? true : false
                }
                // style={{ marginLeft: "110px" }}
                onClick={typeLibrariesReport}
              />
              <p onClick={typeLibrariesReport} style={{ cursor: "default" }}>
                Custom Libraries Report
              </p>
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <input
                className="reportRadios"
                type="radio"
                id="report5"
                name="report"
                value="Related"
                checked={reportType === "Related Items Report" ? true : false}
                // style={{ marginLeft: "110px" }}
                onClick={typeRelatedReport}
              />
              <p onClick={typeRelatedReport} style={{ cursor: "default" }}>
                Related Items Report
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            <input
              className="reportRadios"
              type="radio"
              id="report5"
              name="report"
              value="Content"
              checked={reportType === "Content Report" ? true : false}
              // style={{ marginLeft: "45px" }}
              onClick={typeContentReport}
            />
            <p onClick={typeContentReport} style={{ cursor: "default" }}>
              Content Report
            </p>
          </div>
          <div style={{ display: "flex", paddingRight: "6%" }}>
            <div
              className="reportOneButtons"
              style={{
                // marginTop: "18vh",
                marginLeft: "auto",
              }}
            >
              <button
                onClick={cancelButton}
                style={{
                  backgroundColor: "grey",
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  marginRight: "30px",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                onClick={nextButton}
                style={{
                  backgroundColor: style.colors.primaryBlue,
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <FeedbackModal closeFeedback={closeFeedback} feedback={feedback} />
      <NewProjectModal
        closeNewProjectM={closeNewProjectM}
        newProjectM={newProjectM}
      />
      <AcronymModal closeAcronymM={closeAcronymM} acronymM={acronymM} />
      <ProductLibraryModal closeProductM={closeProductM} productM={productM} />
      <ReferenceLibraryModal
        closeReferenceM={closeReferenceM}
        referenceM={referenceM}
      />
      <DidsModal closeDIDSM={closeDIDSM} didsM={didsM} />
      <ToolsModal closeToolsM={closeToolsM} toolsM={toolsM} />
      <ProjectReportModal closeAPRM={closeAPRM} aprm={aprm} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  user: state.user.user,
  navSearch: state.data.navSearch,
  reportingList: state.projects.reportingList,
  userReportType: state.projects.projectToReport.userReportType,
  userReportSelected: state.projects.projectToReport.selectedProject,
  userReport: state.projects.projectToReport,
  recent_projects: state.projects.recent,
});

export default connect(mapStateToProps, {
  setNavSearch,
  setFilterBox,
  getDeleted,
  getFavorites,
  getHsiFrameworkModels,
  getMyProjects,
  getRecentProjects,
  removeReportList,
  setUserReportType,
  getReportList,
  setProjectToReport,
  editReportCategories,
  resetReportCategories,
})(StepOne) as any;
