import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./acronymModal.css";
import Modal from "react-modal";
import {
  getAcronyms,
  addNewAcronym,
  deleteAcronym,
  updateAcronym,
} from "../../redux/actions/Data";
import { acronymSort } from "../../lib/helpers/columnFilters";
import { ReactComponent as Editsvg } from "../../lib/assets/img/Edit.svg";
import DeleteLibraryItemModal from "../deleteLibraryItemModal/deleteLibraryItemModal";

const AcronymModal: React.FC<{
  closeAcronymM: any;
  acronymM: any;
  acronyms: any;
  getAcronyms: () => void;
  style: any;
  addNewAcronym: (acronym: string, meaning: string) => void;
  deleteAcronym: (id: number) => void;
  updateAcronym: (id: number, acronym: string, meaning: string) => void;
  navSearch: string;
  user: any;
}> = ({
  closeAcronymM,
  acronymM,
  acronyms,
  getAcronyms,
  style,
  addNewAcronym,
  deleteAcronym,
  updateAcronym,
  navSearch,
  user,
}) => {
  const [newAcronymModal, setNewAcronymModal] = useState(false) as any;
  const [acronymSearch, setAcronymSearch] = useState("") as any;
  const [searchFiltered, setSearchFiltered] = useState([]) as any;
  const [newAcronym, setNewAcronym] = useState("") as any;
  const [newMeaning, setNewMeaning] = useState("") as any;
  const [acronymError, setAcronymError] = useState(false) as any;
  const [meaningError, setMeaningError] = useState(false) as any;
  const [deleteOption, setDeleteOption] = useState(false) as any;
  const [focusId, setFocusId] = useState(0) as any;
  const [acronymAscending, setAcronymAscending] = useState(1) as any;
  const [meaningAscending, setMeaningAscending] = useState(0) as any;
  const [dateAscending, setDateAscending] = useState(0) as any;
  const [deleteModal, setDeleteModal] = useState(false) as any;
  const [currentSort, setCurrentSort] = useState("acronym") as any;
  const [dateCreated, setDateCreated] = useState() as any;

  const customStyles = {
    content: {
      top: "40%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
      // padding: "25px",
    },
  };

  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
    overlay: {
      background: "none",
    },
  };

  useEffect(() => {
    getAcronyms();
  }, [getAcronyms]);

  useEffect(() => {
    setAcronymSearch(navSearch);
  }, [navSearch]);

  useEffect(() => {
    let acronymList = acronyms.filter((x) => !x.hasOwnProperty("init_id"));

    if (acronymSearch !== "" && acronyms?.length > 0) {
      acronymList = acronymList.filter(
        (item: any) =>
          item?.acronymName
            ?.toLowerCase()
            .indexOf(acronymSearch.toLowerCase()) > -1 ||
          item?.definition?.toLowerCase().indexOf(acronymSearch.toLowerCase()) >
            -1
      );
    }

    acronymList = acronymSort(
      acronymList,
      acronymAscending,
      meaningAscending,
      dateAscending
    );

    setSearchFiltered(acronymList);
  }, [
    acronymSearch,
    acronyms,
    acronymAscending,
    meaningAscending,
    dateAscending,
  ]);

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const acronymColumn = () => {
    setCurrentSort(currentSort !== "acronym" ? "acronym" : currentSort);
    setMeaningAscending(0);
    setDateAscending(0);
    acronymAscending === 1 ? setAcronymAscending(2) : setAcronymAscending(1);
  };

  const acronymMeaningColumn = () => {
    setCurrentSort(currentSort !== "meaning" ? "meaning" : currentSort);
    setAcronymAscending(0);
    setDateAscending(0);
    meaningAscending === 1 ? setMeaningAscending(2) : setMeaningAscending(1);
  };

  const dateColumn = () => {
    setCurrentSort(currentSort !== "date" ? "date" : currentSort);
    setAcronymAscending(0);
    setMeaningAscending(0);
    dateAscending === 1 ? setDateAscending(2) : setDateAscending(1);
  };

  const closingModal = () => {
    closeAcronymM();
    setAcronymSearch("");
  };

  const cancelAcronym = () => {
    setNewAcronymModal(false);
    setAcronymError(false);
    setMeaningError(false);
    setNewAcronym("");
    setNewMeaning("");
    setDateCreated();
  };

  const saveAcronym = () => {
    if (newAcronym.trim() !== "" && newMeaning.trim() !== "") {
      if (focusId === 0) {
        setAcronymAscending(acronymAscending);
        setMeaningAscending(meaningAscending);
        setDateAscending(dateAscending);
        addNewAcronym(newAcronym, newMeaning);
        setNewAcronymModal(false);
        setFocusId(0);
        setNewAcronym("");
        setNewMeaning("");
        setDateCreated();
        setAcronymError(false);
        setMeaningError(false);
      } else if (focusId !== 0) {
        setAcronymAscending(acronymAscending);
        setMeaningAscending(meaningAscending);
        setDateAscending(dateAscending);
        updateAcronym(focusId, newAcronym, newMeaning);
        setNewAcronymModal(false);
        setFocusId(0);
        setNewAcronym("");
        setNewMeaning("");
        setDateCreated();
        setAcronymError(false);
        setMeaningError(false);
      }
    } else {
      if (newAcronym.trim() === "") {
        setAcronymError(true);
      } else {
        setAcronymError(false);
      }
      if (newMeaning.trim() === "") {
        setMeaningError(true);
      } else {
        setMeaningError(false);
      }
    }
  };

  const deleteAcronymAction = () => {
    deleteAcronym(focusId);
    setNewAcronymModal(false);
    setFocusId(0);
    setNewAcronym("");
    setNewMeaning("");
    setAcronymError(false);
    setMeaningError(false);
    setDateCreated();
  };

  const closeMini = () => {
    setDeleteOption(false);
    setFocusId(0);
    setNewAcronym("");
    setNewMeaning("");
    cancelAcronym();
    setDateCreated();
  };

  return (
    <div>
      <Modal
        isOpen={acronymM}
        onRequestClose={closingModal}
        style={customStyles}
      >
        <i
          className="fa fa-times"
          onClick={closingModal}
          style={{
            cursor: "pointer",
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-10px",
          }}
        ></i>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "-20px",
          }}
        >
          <p>Acronym Library</p>
          <input
            className="acronymSearchBox"
            value={acronymSearch}
            onChange={(e) => setAcronymSearch(e.target.value)}
            style={{
              width: "160px",
              height: "20px",
              alignSelf: "center",
              marginRight: "40px",
              padding: "2px",
              border: "1px solid black",
              paddingLeft: "20px",
              borderRadius: "8px",
            }}
            placeholder="Search Acronyms"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <p
            onClick={() => setNewAcronymModal(true)}
            style={{
              marginTop: "-5px",
              paddingBottom: "2px",
              fontSize: "13px",
              color: style.colors.primaryBlue,
              cursor: "pointer",
            }}
          >
            + Add New Acronym
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            // borderRight: "1px solid black",
          }}
        >
          <div
            onClick={acronymColumn}
            style={{
              // width: "192px",
              width: "10vw",
              minWidth: "106px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {acronymAscending === 0
                ? "Acronym"
                : acronymAscending === 1
                ? "Acronym ↓"
                : "Acronym ↑"}
            </p>
          </div>
          <div
            onClick={acronymMeaningColumn}
            style={{
              // width: "518px",
              width: "27vw",
              minWidth: "288px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              // borderRight: "1px solid white",
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {meaningAscending === 0
                ? "Meaning"
                : meaningAscending === 1
                ? "Meaning ↓"
                : "Meaning ↑"}
            </p>
          </div>
          <div
            onClick={dateColumn}
            style={{
              // width: "172px",
              width: "9vw",
              minWidth: "140px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "29px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              borderLeft: "1px solid white",
              borderRight: "1px solid black",
            }}
          >
            <p className="responsiveFont" style={{ marginLeft: "15px" }}>
              {dateAscending === 0
                ? "Date Added"
                : dateAscending === 1
                ? "Date Added ↓"
                : "Date Added ↑"}
            </p>
          </div>
        </div>
        <div
          className="modal-body-acronym"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {searchFiltered?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor:
                  index % 2 === 0 ? style.colors.lightBlue : "white",
              }}
            >
              <div
                style={{
                  // width: "192px",
                  width: "10vw",
                  minWidth: "106px",
                  borderLeft: "none",
                  borderRight: "none",
                  color: "black",
                  // height: "29px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextAcronym responsiveFont"
                  title={item.acronymName}
                >
                  {item.acronymName}
                </p>
              </div>
              <div
                style={{
                  // width: "518px",
                  width: "27vw",
                  minWidth: "288px",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  color: "black",
                  // height: "29px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextAcronym responsiveFont"
                  title={item.definition}
                >
                  {item.definition}
                </p>
              </div>
              <div
                style={{
                  // width: "153px",
                  width: "8vw",
                  minWidth: "117px",
                  borderLeft: "none",
                  borderRight: "none",
                  color: "black",
                  // height: "29px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p className="responsiveFont" style={{ marginLeft: "16px" }}>
                  {new Date(item.dateAdded * 1000).getDate() < 10 ? "0" : ""}
                  {new Date(item.dateAdded * 1000).getDate() +
                    " " +
                    months[new Date(item.dateAdded * 1000).getMonth()] +
                    " " +
                    new Date(item.dateAdded * 1000).getFullYear()}
                </p>
                <Editsvg
                  className="modalPencil"
                  onClick={() => {
                    setDeleteOption(true);
                    setNewAcronymModal(true);
                    setFocusId(item.acronymId);
                    setNewAcronym(item.acronymName);
                    setNewMeaning(item.definition);
                    setDateCreated(item.dateAdded);
                  }}
                  fill={style.colors.darkBlue}
                  height="1.5vh"
                  width="1.5vw"
                  style={{
                    marginLeft: "auto",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </Modal>

      <Modal
        isOpen={newAcronymModal}
        onRequestClose={closeMini}
        style={customStyles2}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-10px",
          }}
        >
          <i
            className="fa fa-times"
            onClick={closeMini}
            style={{
              cursor: "pointer",
              alignSelf: "center",
            }}
          ></i>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "150px",
              borderRight: "2px solid lightgrey",
              justifyContent: "flex-end",
              height: "70px",
            }}
          >
            <p
              style={{
                paddingRight: "10px",
                color: acronymError ? "red" : "black",
              }}
            >
              Acronym*:
            </p>
          </div>
          <input
            readOnly={
              dateCreated === 1695251789
                ? user?.userRole?.includes("Admin")
                  ? false
                  : true
                : false
            }
            value={newAcronym}
            onChange={(e) => setNewAcronym(e.target.value)}
            style={{
              width: "240px",
              height: "20px",
              cursor: "default",
              alignSelf: "center",
              marginLeft: "10px",
              marginTop: "-20px",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "150px",
              borderRight: "2px solid lightgrey",
              justifyContent: "flex-end",
              height: "70px",
            }}
          >
            <p
              style={{
                paddingRight: "10px",
                color: meaningError ? "red" : "black",
              }}
            >
              Meaning*:
            </p>
          </div>
          <input
            readOnly={
              dateCreated === 1695251789
                ? user?.userRole?.includes("Admin")
                  ? false
                  : true
                : false
            }
            value={newMeaning}
            onChange={(e) => setNewMeaning(e.target.value)}
            style={{
              width: "240px",
              height: "20px",
              cursor: "default",
              alignSelf: "center",
              marginLeft: "10px",
              marginTop: "-20px",
            }}
          />
        </div>

        <div
          style={{
            display:
              dateCreated === 1695251789
                ? user?.userRole?.includes("Admin")
                  ? "flex"
                  : "none"
                : "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 20,
            marginTop: "10px",
          }}
        >
          <button
            style={{
              backgroundColor: "grey",
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: deleteOption ? "pointer" : "default",
              opacity: deleteOption ? 1 : 0,
            }}
            onClick={() => {
              if (deleteOption) setDeleteModal(true);
            }}
          >
            Delete
          </button>
          <button
            style={{
              backgroundColor: "grey",
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={cancelAcronym}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: style.colors.primaryBlue,
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={saveAcronym}
          >
            Save
          </button>
        </div>
      </Modal>

      <DeleteLibraryItemModal
        itemTitle={newMeaning}
        itemLibrary="Acronym"
        itemCancel={cancelAcronym}
        itemDelete={deleteAcronymAction}
        deleteM={deleteModal}
        setDeleteModal={setDeleteModal}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  acronyms: state.data.acronyms,
  style: state.local.style,
  navSearch: state.data.navSearch,
  user: state.user.user,
});

export default connect(mapStateToProps, {
  getAcronyms,
  addNewAcronym,
  deleteAcronym,
  updateAcronym,
})(AcronymModal) as any;
