import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { getUser } from "../../redux/actions/User";
import { initIntercepters } from "../../lib/helpers/intercepter";

const Init: React.FC<{ getUser: () => void }> = ({ getUser }) => {
  useEffect(() => {
    initIntercepters();
    getUser();
  }, []);
  return null;
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, { getUser })(Init) as any;
