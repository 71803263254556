import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./activityDeleteModal.css";
import { deleteActivity } from "../../redux/actions/Activities";
import { setActivityDeleteModal } from "../../redux/actions/Projects";
const ActivityDeleteModal: React.FC<{
  activeProjectActivity: any;
  style: any;
  actDelModal: any;
  setActivityDeleteModal: (state: any) => void;
  deleteActivity: (pId: string, aId: string) => void;
}> = ({
  activeProjectActivity,
  style,
  actDelModal,
  setActivityDeleteModal,
  deleteActivity,
}) => {
  return (
    <div
      className="actDel-modal-container"
      style={{ visibility: actDelModal ? "initial" : "hidden" }}
    >
      <section className="actDelModals">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-10px",
          }}
        >
          <i
            className="fa fa-times"
            onClick={() => setActivityDeleteModal(false)}
            style={{
              cursor: "pointer",
              alignSelf: "center",
            }}
          ></i>
        </div>

        <p style={{ paddingBottom: "1px" }}>Do you want to delete activity: </p>
        <div style={{ paddingBottom: "2px" }}>
          <p>{activeProjectActivity?.summary}</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 20,
            marginTop: "10px",
          }}
        >
          <button
            style={{
              backgroundColor: "grey",
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => setActivityDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: style.colors.primaryBlue,
              width: "100px",
              height: "30px",
              color: style.colors.white,
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => {
              setActivityDeleteModal(false);
              deleteActivity(
                activeProjectActivity?.projectId,
                activeProjectActivity?.activityId
              );
            }}
          >
            Confirm
          </button>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  activeProjectActivity: state.projects.activeProjectActivity,
  style: state.local.style,
  actDelModal: state.projects.actDelModal,
});

export default connect(mapStateToProps, {
  setActivityDeleteModal,
  deleteActivity,
})(ActivityDeleteModal) as any;
