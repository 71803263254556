import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import "./ItemMenu.css";
import { MdClose } from "react-icons/md";
import {
  BsTriangleFill,
  BsCircle,
  BsCircleHalf,
  BsCircleFill,
  BsCalendar3,
  BsFlagFill,
} from "react-icons/bs";
import { RiArrowDownCircleFill } from "react-icons/ri";
import { BiCheck } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  clearDataLists,
  editLibrariesHSIRisk,
  handlePanPosition,
  setClickedItem,
} from "../../../redux/actions/Projects";
import {
  setProjectActivityStatus,
  setProjectActivityDueDate,
  editActivityTitle,
  editActivityDescription,
  editActivitySMEInteraction,
  editActivityTradeOffs,
  editActivityContDel,
  editActivityChallenges,
  editActivityRecommendations,
  fixActivityLibraryLists,
} from "../../../redux/actions/Activities";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MenuTabs from "./MenuTabs";
import MenuTabContent from "./MenuTabContent";
import { ReactComponent as RiskLevelLowImg } from "../../../lib/assets/img/RiskLevelLow.svg";
import { ReactComponent as RiskLevelMediumImg } from "../../../lib/assets/img/RiskLevelMedium.svg";
import { ReactComponent as RiskLevelHighImg } from "../../../lib/assets/img/RiskLevelHigh.svg";
import { AiOutlineLeft } from "react-icons/ai";
import {
  handleFoundIndex,
  resetActivtySearch,
} from "../../../redux/actions/Data";
import { useReactFlow } from "reactflow";

const ItemMenu: React.FC<{
  itemMenu: any;
  setItemMenu: any;
  activeProjectActivity: any;
  editLibrariesHSIRisk: (edit: boolean) => void;
  setProjectActivityStatus: (activityId: string, status: string) => void;
  setProjectActivityDueDate: (activityId: string, date: any) => void;
  editActivityTitle: (
    activityId: string,
    projectId: string,
    text: string
  ) => void;
  editActivityDescription: (
    activityId: string,
    projectId: string,
    text: string
  ) => void;
  editActivitySMEInteraction: (
    activityId: string,
    projectId: string,
    interaction: boolean
  ) => void;
  editActivityTradeOffs: (
    activityId: string,
    projectId: string,
    text: string
  ) => void;
  editActivityContDel: (
    activityId: string,
    projectId: string,
    text: string
  ) => void;
  editActivityChallenges: (
    activityId: string,
    projectId: string,
    text: string
  ) => void;
  editActivityRecommendations: (
    activityId: string,
    projectId: string,
    text: string
  ) => void;
  openProductM: any;
  openReferenceM: any;
  openDIDSM: any;
  openToolsM: any;
  clearDataLists: () => void;
  setClickedItem: (activeItem: any) => void;
  style: any;
  editCanvas: boolean;
  userSearch: any;
  activityFoundIndex: number;
  activityFoundView: boolean;
  handleFoundIndex: (clickType: string) => void;
  resetActivtySearch: () => void;
  handlePanPosition: (positioning: any) => void;
  fixActivityLibraryLists: (activity: any) => void;
  setDetailsProduct: any;
  setDetailsReference: any;
  setDetailsDID: any;
  setDetailsTool: any;
}> = ({
  itemMenu,
  setItemMenu,
  activeProjectActivity,
  editLibrariesHSIRisk,
  setProjectActivityStatus,
  setProjectActivityDueDate,
  editActivityTitle,
  editActivityDescription,
  editActivitySMEInteraction,
  editActivityTradeOffs,
  editActivityContDel,
  editActivityChallenges,
  editActivityRecommendations,
  openProductM,
  openReferenceM,
  openDIDSM,
  openToolsM,
  clearDataLists,
  setClickedItem,
  style,
  editCanvas,
  userSearch,
  activityFoundIndex,
  activityFoundView,
  handleFoundIndex,
  resetActivtySearch,
  handlePanPosition,
  fixActivityLibraryLists,
  setDetailsProduct,
  setDetailsReference,
  setDetailsDID,
  setDetailsTool,
}) => {
  const datePickerRef = useRef<
    DatePicker & { setOpen: (open: boolean) => void }
  >(null);

  const [tabState, setTabState] = useState("details") as any;
  const [detailsEdit, setDetailsEdit] = useState(false) as any;
  const [librariesEdit, setLibrariesEdit] = useState(false) as any;
  const [managementEdit, setManagementEdit] = useState(false) as any;
  const [titleText, setTitleText] = useState("") as any;
  const [descriptionText, setDescriptionText] = useState("") as any;
  const [tradeText, setTradeText] = useState("") as any;
  const [contractorText, setContractorText] = useState("") as any;
  const [challengesText, setChallengesText] = useState("") as any;
  const [recommendationsText, setRecommendationsText] = useState("") as any;
  const [hsiRiskColor, setHsiRiskColor] = useState("") as any;
  const { setCenter } = useReactFlow();

  useEffect(() => {
    if (editCanvas === false) {
      setDetailsEdit(false);
      setLibrariesEdit(false);
      setManagementEdit(false);
    }
  }, [editCanvas]);

  useEffect(() => {
    if (activeProjectActivity?.hsiRisk.length > 0) {
      let colorLvl = 1;
      activeProjectActivity?.hsiRisk.map((item: any) => {
        let currentLvl = 1;
        if (item.riskLvl === "High") {
          currentLvl = 3;
        } else if (item.riskLvl === "Medium") {
          currentLvl = 2;
        }

        if (currentLvl > colorLvl) {
          colorLvl = currentLvl;
        }
      });

      if (colorLvl === 3) {
        setHsiRiskColor("red");
      } else if (colorLvl === 2) {
        setHsiRiskColor("orange");
      } else {
        setHsiRiskColor("yellow");
      }
    }
  }, [activeProjectActivity?.hsiRisk?.length]);

  useEffect(() => {
    if (activeProjectActivity) {
      setTitleText(activeProjectActivity.summary);
      setManagementData();
    }
  }, [activeProjectActivity?.summary?.length]);

  useEffect(() => {
    if (activeProjectActivity) {
      setDescriptionText(activeProjectActivity.description);
      setManagementData();
    }
  }, [activeProjectActivity?.description?.length]);

  const setManagementData = () => {
    setTradeText(activeProjectActivity.tradeOffs);
    setChallengesText(activeProjectActivity.challenges);
    setContractorText(activeProjectActivity.contractorDeliverables);
    setRecommendationsText(activeProjectActivity.recommendations);
  };

  useEffect(() => {
    if (activityFoundView) {
      if (userSearch.hasOwnProperty("activitiesFound")) {
        setTabState(userSearch.activitiesFound[activityFoundIndex].tabMenu);
      }
    }
  }, [activityFoundView, activeProjectActivity]);

  // useEffect(() => {
  //   if (activeProjectActivity) {
  //     if (
  //       activeProjectActivity?.references.length > 0 &&
  //       !activeProjectActivity.hasOwnProperty('didsList')
  //     ) {
  //       fixActivityLibraryLists(activeProjectActivity);
  //     }
  //   }
  // }, [activeProjectActivity]);

  const handleActivityFinder = (direction: string) => {
    let newIndex = 0;
    let zoom = 1.8;
    if (userSearch.activitiesFound.length > 1) {
      if (direction === "left") {
        if (activityFoundIndex === 0) {
          newIndex = userSearch.activitiesFound.length - 1;
        } else {
          newIndex = activityFoundIndex - 1;
        }
      } else {
        if (activityFoundIndex + 1 === userSearch.activitiesFound.length) {
          newIndex = 0;
        } else {
          newIndex = activityFoundIndex + 1;
        }
      }
    }
    setClickedItem(userSearch.activitiesFound[newIndex]);
    handlePanPosition({
      x: userSearch.activitiesFound[newIndex].position?.x,
      y: userSearch.activitiesFound[newIndex].position?.y,
    });
    setCenter(
      // node?.position?.x === 120 || node?.position?.x === 310 ? 0 :
      userSearch.activitiesFound[newIndex].position?.x + 350,
      userSearch.activitiesFound[newIndex].position?.y + 150,
      { zoom, duration: 1000 }
    );
  };

  return (
    <div
      id="myitemSidenav"
      className="itemsidenav"
      style={{
        width: "35vw",
        right: itemMenu ? 0 : `-35vw`,
        zIndex: 8,
        borderLeft: itemMenu ? "2px solid black" : "none",
        borderTop: itemMenu ? "2px solid black" : "none",
        // marginTop: "50px",
        // height: "90.20vh",
        // background: style.colors.disabledText
      }}
    >
      <div
        style={{
          display: activityFoundView ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "space-between",
          background: style.colors.lightBlue,
          paddingRight: "10px",
          paddingLeft: "10px",
        }}
      >
        <AiOutlineLeft
          fill="black"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleActivityFinder("left");
            handleFoundIndex("left");
          }}
        />
        <span className="unSelect" unselectable="on">{`${
          activityFoundIndex + 1
        } of ${
          userSearch
            ? userSearch.hasOwnProperty("activitiesFound")
              ? userSearch.activitiesFound.length
              : 0
            : 0
        } results`}</span>
        <AiOutlineLeft
          fill="black"
          style={{ transform: "rotate(180deg)", cursor: "pointer" }}
          onClick={() => {
            handleActivityFinder("right");
            handleFoundIndex("right");
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          background: style.colors.primaryBlue,
          paddingRight: "10px",
          paddingLeft: "10px",
        }}
      >
        {hsiRiskColor === "red" ? (
          <RiskLevelHighImg
            title="High Risk"
            fill="#C00000"
            style={{
              color: "black",
              marginLeft: "left",
              justifyContent: "center",
              alignSelf: "center",
              paddingRight: "10px",
              opacity: activeProjectActivity?.hsiRisk.length > 0 ? 1 : 0,
            }}
          />
        ) : hsiRiskColor === "orange" ? (
          <RiskLevelMediumImg
            title="Medium Risk"
            fill="#ED7D31"
            style={{
              color: "black",
              marginLeft: "left",
              justifyContent: "center",
              alignSelf: "center",
              paddingRight: "10px",
              opacity: activeProjectActivity?.hsiRisk.length > 0 ? 1 : 0,
            }}
          />
        ) : (
          <RiskLevelLowImg
            title="Low Risk"
            fill="#FFD966"
            style={{
              color: "black",
              marginLeft: "left",
              justifyContent: "center",
              alignSelf: "center",
              paddingRight: "10px",
              opacity: activeProjectActivity?.hsiRisk.length > 0 ? 1 : 0,
            }}
          />
        )}

        <div className="dropdownStatus">
          <button className="dropbtnStatus">
            {activeProjectActivity ? (
              activeProjectActivity.activityStatus === "None" ? (
                <BsFlagFill
                  style={{
                    color: "black",
                    fontSize: 20,
                    cursor: "pointer",
                    marginLeft: "left",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                />
              ) : activeProjectActivity.activityStatus === "Not Started" ? (
                <BsCircle
                  style={{
                    color: "black",
                    fontSize: 20,
                    cursor: "pointer",
                    marginLeft: "left",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                />
              ) : activeProjectActivity.activityStatus === "In Progress" ? (
                <BsCircleHalf
                  style={{
                    color: "black",
                    fontSize: 20,
                    cursor: "pointer",
                    marginLeft: "left",
                    justifyContent: "center",
                    alignSelf: "center",
                    transform: "rotate(180deg)",
                  }}
                />
              ) : (
                <BsCircleFill
                  style={{
                    color: "black",
                    fontSize: 20,
                    cursor: "pointer",
                    marginLeft: "left",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                />
              )
            ) : (
              <BsCircleFill
                style={{
                  color: "black",
                  fontSize: 20,
                  cursor: "default",
                  marginLeft: "left",
                  justifyContent: "center",
                  alignSelf: "center",
                  opacity: 0,
                }}
              />
            )}
          </button>
          <div
            className={
              activeProjectActivity
                ? "activity-content activityPopup"
                : "hideContent"
            }
          >
            <div>
              <p className="activityPopupText">Activity Status</p>
              <p className="activityPopupText">Due Date</p>
              <div style={{ display: "flex" }}>
                <DatePicker
                  selected={
                    activeProjectActivity
                      ? activeProjectActivity.dueDate !== null
                        ? new Date(activeProjectActivity.dueDate)
                        : null
                      : null
                  }
                  onChange={(date) =>
                    setProjectActivityDueDate(
                      activeProjectActivity.activityId,
                      date
                    )
                  }
                  ref={datePickerRef}
                />
                <AiFillCloseCircle
                  onClick={() =>
                    setProjectActivityDueDate(
                      activeProjectActivity.activityId,
                      null
                    )
                  }
                  style={{
                    color: "black",
                    fontSize: 20,
                    cursor: activeProjectActivity
                      ? activeProjectActivity.dueDate !== null
                        ? "pointer"
                        : "default"
                      : "default",
                    opacity: activeProjectActivity
                      ? activeProjectActivity.dueDate !== null
                        ? 1
                        : 0
                      : 0,
                  }}
                />
                <BsCalendar3
                  onClick={() => datePickerRef?.current?.setOpen(true)}
                  style={{
                    color: "black",
                    fontSize: 20,
                    marginLeft: "14px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div
                className="activityPopupOptions"
                onClick={() =>
                  setProjectActivityStatus(
                    activeProjectActivity.activityId,
                    "None"
                  )
                }
                style={{
                  backgroundColor: activeProjectActivity
                    ? activeProjectActivity.activityStatus === "None"
                      ? "#ddd"
                      : "white"
                    : "white",
                }}
              >
                <BsCircle
                  style={{
                    color: "white",
                    fontSize: 20,
                    opacity: 0,
                    marginLeft: "5px",
                  }}
                />
                <p className="activityPopupText">None</p>
                <BiCheck
                  style={{
                    color: "black",
                    fontSize: 20,
                    marginLeft: "auto",
                    marginRight: "15px",
                    opacity: activeProjectActivity
                      ? activeProjectActivity.activityStatus === "None"
                        ? 1
                        : 0
                      : 0,
                  }}
                />
              </div>
              <div
                className="activityPopupOptions"
                onClick={() =>
                  setProjectActivityStatus(
                    activeProjectActivity.activityId,
                    "Not Started"
                  )
                }
                style={{
                  backgroundColor: activeProjectActivity
                    ? activeProjectActivity.activityStatus === "Not Started"
                      ? "#ddd"
                      : "white"
                    : "white",
                }}
              >
                <BsCircle
                  style={{
                    color: "black",
                    fontSize: 20,
                    marginLeft: "5px",
                  }}
                />
                <p className="activityPopupText">Not Started</p>
                <BiCheck
                  style={{
                    color: "black",
                    fontSize: 20,
                    marginLeft: "auto",
                    marginRight: "15px",
                    opacity: activeProjectActivity
                      ? activeProjectActivity.activityStatus === "Not Started"
                        ? 1
                        : 0
                      : 0,
                  }}
                />
              </div>
              <div
                className="activityPopupOptions"
                onClick={() =>
                  setProjectActivityStatus(
                    activeProjectActivity.activityId,
                    "In Progress"
                  )
                }
                style={{
                  backgroundColor: activeProjectActivity
                    ? activeProjectActivity.activityStatus === "In Progress"
                      ? "#ddd"
                      : "white"
                    : "white",
                }}
              >
                <BsCircleHalf
                  style={{
                    color: "black",
                    fontSize: 20,
                    transform: "rotate(180deg)",
                    marginLeft: "5px",
                  }}
                />
                <p className="activityPopupText">In Progress</p>
                <BiCheck
                  style={{
                    color: "black",
                    fontSize: 20,
                    marginLeft: "auto",
                    marginRight: "15px",
                    opacity: activeProjectActivity
                      ? activeProjectActivity.activityStatus === "In Progress"
                        ? 1
                        : 0
                      : 0,
                  }}
                />
              </div>
              <div
                className="activityPopupOptions"
                onClick={() =>
                  setProjectActivityStatus(
                    activeProjectActivity.activityId,
                    "Completed"
                  )
                }
                style={{
                  backgroundColor: activeProjectActivity
                    ? activeProjectActivity.activityStatus === "Completed"
                      ? "#ddd"
                      : "white"
                    : "white",
                }}
              >
                <BsCircleFill
                  style={{
                    color: "black",
                    fontSize: 20,
                    marginLeft: "5px",
                  }}
                />
                <p className="activityPopupText">Completed</p>
                <BiCheck
                  style={{
                    color: "black",
                    fontSize: 20,
                    marginLeft: "auto",
                    marginRight: "15px",
                    opacity: activeProjectActivity
                      ? activeProjectActivity.activityStatus === "Completed"
                        ? 1
                        : 0
                      : 0,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <p style={{ marginLeft: "auto", color: "black", fontWeight: "bold" }}>
          {activeProjectActivity
            ? "Activity " + activeProjectActivity?.uniqueID
            : ""}
        </p>
        <MdClose
          onClick={() => {
            setItemMenu(false);
            setClickedItem(null);
            clearDataLists();
            setTimeout(resetActivtySearch, 500);
          }}
          style={{
            color: "black",
            fontSize: 20,
            cursor: "pointer",
            marginLeft: "auto",
            justifyContent: "center",
            alignSelf: "center",
          }}
        />
      </div>

      <MenuTabs
        tabState={tabState}
        setTabState={setTabState}
        itemMenu={itemMenu}
      />
      {/* <hr
        style={{
          height: "2px",
          color: "black",
          backgroundColor: "black",
          marginTop: "-2px",
        }}
      /> */}
      <MenuTabContent
        activeProjectActivity={activeProjectActivity}
        tabState={tabState}
        setDetailsEdit={setDetailsEdit}
        detailsEdit={detailsEdit}
        titleText={titleText}
        setTitleText={setTitleText}
        descriptionText={descriptionText}
        setDescriptionText={setDescriptionText}
        editActivitySMEInteraction={editActivitySMEInteraction}
        editLibrariesHSIRisk={editLibrariesHSIRisk}
        editActivityTitle={editActivityTitle}
        editActivityDescription={editActivityDescription}
        setLibrariesEdit={setLibrariesEdit}
        librariesEdit={librariesEdit}
        setManagementEdit={setManagementEdit}
        managementEdit={managementEdit}
        tradeText={tradeText}
        setTradeText={setTradeText}
        challengesText={challengesText}
        setChallengesText={setChallengesText}
        contractorText={contractorText}
        setContractorText={setContractorText}
        recommendationsText={recommendationsText}
        setRecommendationsText={setRecommendationsText}
        editActivityTradeOffs={editActivityTradeOffs}
        editActivityChallenges={editActivityChallenges}
        editActivityContDel={editActivityContDel}
        editActivityRecommendations={editActivityRecommendations}
        openProductM={openProductM}
        openReferenceM={openReferenceM}
        openDIDSM={openDIDSM}
        openToolsM={openToolsM}
        setDetailsProduct={setDetailsProduct}
        setDetailsReference={setDetailsReference}
        setDetailsDID={setDetailsDID}
        setDetailsTool={setDetailsTool}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  activeProjectActivity: state.projects.activeProjectActivity,
  style: state.local.style,
  editCanvas: state.projects.editCanvas,
  userSearch: state.data.userSearch,
  activityFoundIndex: state.data.activityFoundIndex,
  activityFoundView: state.data.activityFoundView,
});

export default connect(mapStateToProps, {
  editLibrariesHSIRisk,
  setProjectActivityStatus,
  setProjectActivityDueDate,
  editActivityTitle,
  editActivityDescription,
  editActivitySMEInteraction,
  editActivityTradeOffs,
  editActivityContDel,
  editActivityChallenges,
  editActivityRecommendations,
  clearDataLists,
  setClickedItem,
  handleFoundIndex,
  resetActivtySearch,
  handlePanPosition,
  fixActivityLibraryLists,
})(ItemMenu) as any;
