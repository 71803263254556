import axios from "axios";
import config from "../../config.json";
import {
  CREATE_PROJECT,
  GET_RECENT_PROJECTS,
  GET_MY_PROJECTS,
  UPDATE_RECENT,
  SET_ACTIVE_PROJECT,
  GET_FAVORITES,
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  EDIT_PROJECT,
  SAVE_EDIT_PROJECT,
  SAVE_AS,
  SAVE_AS_NEW,
  DELETE_PROJECT,
  SAVE_DELETE_PROJECT,
  GET_DELETED,
  SET_CLICKED_ITEM,
  GET_HSI_FRAMEWORK_MODELS,
  EDIT_PROJECT_CANVAS,
  SET_RIGHT_CLICKED_ITEM,
  EDIT_LIBRARIES_HSI_RISK,
  REMOVE_REPORT_LIST,
  ADD_REPORT_LIST,
  GET_REPORT_LIST,
  EDIT_REPORT_CATEGORIES,
  RESET_REPORT_CATEGORIES,
  SET_REPORT_TYPE,
  SET_PROJECT_TO_REPORT,
  SET_CLICKED_PROJECT_ID,
  CLEAR_RISK_DATES,
  GET_RISK_REPORT,
  SET_POPUP_VALUES,
  RECOVER_DELETE_PROJECT,
  SET_ITEM_MENU,
  SET_RIGHTCLICK_MENU,
  SET_SUMMARY_MENU,
  SET_ACTIVITY_LIBRARY,
  SET_ACTIVITY_ID_LIST,
  EDIT_ACTIVITY_PRODUCT_INPUTS_DATA,
  EDIT_ACTIVITY_PRODUCT_OUTPUTS_DATA,
  EDIT_ACTIVITY_REFERENCE_DATA,
  EDIT_ACTIVITY_DIDS_DATA,
  EDIT_ACTIVITY_TOOLS_DATA,
  CLEAR_DATA_LISTS,
  GET_STATUS_REPORT,
  PERM_DELETE_PROJECT,
  GET_CUSTOM_ACTIVITY_REPORT,
  GET_CUSTOM_LIBRARY_REPORT,
  GET_RELATED_ITEMS_REPORT,
  GET_CONTENT_REPORT,
  SET_COLLAB_SELECTION_STATE,
  SET_COLLAB_TYPE,
  SET_COLLAB_SOURCE,
  SET_COLLAB_TARGET,
  CLEAR_COLLAB_ITEMS,
  SET_ACTIVITY_CHANGE_STATE,
  SET_ACTIVITY_DELETE_MODAL,
  SET_ACTIVITY_RISKS_CHANGED,
  SET_ACTIVITY_PRODUCTS_CHANGED,
  SET_ACTIVITY_REFERENCES_CHANGED,
  SET_ACTIVITY_DIDS_CHANGED,
  SET_ACTIVITY_TOOLS_CHANGED,
  SET_ACTIVITY_INITIAL_RISKS,
  SET_ACTIVITY_INITIAL_PRODUCTS_INPUTS,
  SET_ACTIVITY_INITIAL_PRODUCTS_OUTPUTS,
  SET_ACTIVITY_INITIAL_REFERENCES,
  SET_ACTIVITY_INITIAL_DIDS,
  SET_ACTIVITY_INITIAL_TOOLS,
  SET_PROJECT_PERMISSIONS,
  HANDLE_PAN_POSITION,
  GET_ALL_PROJECTS,
  HANDLE_OPEN_STARTUP,
  GET_USER_PROJECT_LIST,
  NEW_PROJECT_INCOMING,
  SHARED_WITH_ME,
  REMOVE_ME_FROM_PROJECT,
} from "./types";
import store from "../index";

const createProject = (formData: any) => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/create_project`;
    const body = {
      name: formData.name,
      description: formData.description,
      modelId: formData.modelId,
    };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: CREATE_PROJECT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const getRecentProjects = () => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/get_recent_projects`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_RECENT_PROJECTS,
      payload: res.data.filter((item: any) => item !== null),
    });
  } catch (error) {
    console.log(error);
  }
};

const getMyProjects = () => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/get_my_projects`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_MY_PROJECTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const getHsiFrameworkModels = () => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/get_hsi_framework_models`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_HSI_FRAMEWORK_MODELS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const getFavorites = () => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/get_favorites`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_FAVORITES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const addFavorite = (favoritingProject: any) => async (dispatch: any) => {
  try {
    const body = {
      favoritingProject: favoritingProject,
    };
    const url = `${config.api}projects/add_favorite`;
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: ADD_FAVORITE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const removeFavorite = (favoritingProject: any) => async (dispatch: any) => {
  try {
    const body = { favoritingProject: favoritingProject };
    const url = `${config.api}projects/remove_favorite`;
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: REMOVE_FAVORITE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const updateRecent = (projectId: string) => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/update_recent`;
    const body = { projectId: projectId };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: UPDATE_RECENT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const setActiveProject = (project: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVE_PROJECT,
      payload: project,
    });
  } catch (error) {
    console.log(error);
  }
};

const saveEditProject = (formData: any) => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/save_edit_project`;
    const res = await axios.post(url, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: SAVE_EDIT_PROJECT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const editProject = (project: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: EDIT_PROJECT,
      payload: project,
    });
  } catch (error) {
    console.log(error);
  }
};

const saveAsNew = (formData: any) => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/save_as_new`;
    const res = await axios.post(url, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: SAVE_AS_NEW,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const saveAs = (project: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SAVE_AS,
      payload: project,
    });
  } catch (error) {
    console.log(error);
  }
};

const saveDeleteProject = (deletingProject: any) => async (dispatch: any) => {
  try {
    let state = store.getState();
    let recentList = state.projects.recent;
    let myProjectsList = state.projects.myProjects;
    let sharedWithMeList = state.projects.sharedWithMe;
    let favoritesList = state.projects.favorites;
    let deletedList = state.projects.deleted;

    recentList = recentList.filter(
      (project: any) => project.projectId !== deletingProject.projectId
    );
    myProjectsList = myProjectsList.filter(
      (project: any) => project.projectId !== deletingProject.projectId
    );
    sharedWithMeList = sharedWithMeList.filter(
      (project: any) => project.projectId !== deletingProject.projectId
    );
    favoritesList = favoritesList.filter(
      (project: any) => project.projectId !== deletingProject.projectId
    );

    let newData = {
      recentList: recentList,
      myProjectsList: myProjectsList,
      sharedWithMeList: sharedWithMeList,
      favoritesList: favoritesList,
      deletedList: [deletingProject, ...deletedList],
    };

    const body = {
      deletingProject: deletingProject,
    };
    const url = `${config.api}projects/delete_project`;
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: SAVE_DELETE_PROJECT,
      payload: newData,
    });
  } catch (error) {
    console.log(error);
  }
};

const recoverDeleteProject = (recoverProject: any) => async (dispatch: any) => {
  try {
    let state = store.getState();
    let recentList = state.projects.recent;
    let myProjectsList = state.projects.myProjects;
    let deletedList = state.projects.deleted;

    let newData = {
      recentList: [recoverProject, ...recentList],
      myProjectsList: [recoverProject, ...myProjectsList],
      deletedList: deletedList.filter(
        (project: any) => project.projectId !== recoverProject.projectId
      ),
    };

    const body = { recoverProject: recoverProject };
    const url = `${config.api}projects/recover_project`;
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: RECOVER_DELETE_PROJECT,
      payload: newData,
    });
  } catch (error) {
    console.log(error);
  }
};

const deleteProject = (project: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: DELETE_PROJECT,
      payload: project,
    });
  } catch (error) {
    console.log(error);
  }
};

const getDeleted = () => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/get_deleted`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_DELETED,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const setClickedItem = (activeItem: any) => async (dispatch: any) => {
  try {
    let menuType;

    if (activeItem !== null && activeItem.hasOwnProperty("phaseOrder")) {
      menuType = "activity";
    } else if (
      activeItem !== null &&
      activeItem.height === 65 &&
      activeItem.className?.includes("Title")
    ) {
      menuType = "phase";
    } else {
      menuType = null;
    }

    const data = {
      activeItem: activeItem,
      menuType: menuType,
    };

    dispatch({
      type: SET_CLICKED_ITEM,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

const setRightClickedItem = (righClicktItem: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_RIGHT_CLICKED_ITEM,
      payload: righClicktItem,
    });
  } catch (error) {
    console.log(error);
  }
};

const editProjectCanvas = (edit: boolean) => async (dispatch: any) => {
  try {
    dispatch({
      type: EDIT_PROJECT_CANVAS,
      payload: edit,
    });
  } catch (error) {
    console.log(error);
  }
};

const editLibrariesHSIRisk = (edit: boolean) => async (dispatch: any) => {
  try {
    dispatch({
      type: EDIT_LIBRARIES_HSI_RISK,
      payload: edit,
    });
  } catch (error) {
    console.log(error);
  }
};

const removeReportList =
  (project: any, userID: string) => async (dispatch: any) => {
    try {
      const url = `${config.api}projects/remove_report_list_item`;
      const body = { project: project, userID: userID };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = {
        reportsList: res.data.reportsList,
        project: project,
      };
      dispatch({
        type: REMOVE_REPORT_LIST,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const addReportList =
  (project: any, userID: string) => async (dispatch: any) => {
    try {
      const url = `${config.api}projects/add_report_list_item`;
      const body = { project: project, userID: userID };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: ADD_REPORT_LIST,
        payload: res.data.reportsList,
      });
    } catch (error) {
      console.log(error);
    }
  };

const getReportList = (userID: string) => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/get_report_list`;
    const body = { userID: userID };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_REPORT_LIST,
      payload: res.data.reportsList,
    });
  } catch (error) {
    console.log(error);
  }
};

const editReportCategories =
  (reportSettings: object) => async (dispatch: any) => {
    try {
      dispatch({
        type: EDIT_REPORT_CATEGORIES,
        payload: reportSettings,
      });
    } catch (error) {
      console.log(error);
    }
  };

const resetReportCategories = () => async (dispatch: any) => {
  try {
    dispatch({
      type: RESET_REPORT_CATEGORIES,
      payload: null,
    });
  } catch (error) {
    console.log(error);
  }
};

const setUserReportType = (report: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_REPORT_TYPE,
      payload: report,
    });
  } catch (error) {
    console.log(error);
  }
};

const setProjectToReport = (project: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_PROJECT_TO_REPORT,
      payload: project,
    });
  } catch (error) {
    console.log(error);
  }
};

const setClickedProjectId = (id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_CLICKED_PROJECT_ID,
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};

const clearRiskDates = () => async (dispatch: any) => {
  try {
    dispatch({
      type: CLEAR_RISK_DATES,
      payload: null,
    });
  } catch (error) {
    console.log(error);
  }
};

const getRiskReport =
  (
    phase: any,
    domain: any,
    risk: any,
    startDate: string,
    endDate: string,
    id: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}projects/get_risk_report`;
      const body = {
        phase: phase,
        domain: domain,
        risk: risk,
        startDate: startDate,
        endDate: endDate,
        id: id,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: GET_RISK_REPORT,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const getStatusReport =
  (
    phase: any,
    domain: any,
    status: any,
    startDueDate: string,
    endDueDate: string,
    startUpdateDate: string,
    endUpdateDate: string,
    id: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}projects/get_status_report`;
      const body = {
        phase: phase,
        domain: domain,
        status: status,
        startDueDate: startDueDate,
        endDueDate: endDueDate,
        startUpdateDate: startUpdateDate,
        endUpdateDate: endUpdateDate,
        id: id,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: GET_STATUS_REPORT,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const getCustomActivityReport =
  (
    phase: any,
    domain: any,
    status: any,
    startDueDate: string,
    endDueDate: string,
    startUpdateDate: string,
    endUpdateDate: string,
    notes: any,
    management: any,
    risk: any,
    riskStartDate: string,
    riskEndDate: string,
    id: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}projects/get_custom_activity_report`;
      const body = {
        phase: phase,
        domain: domain,
        status: status,
        startDueDate: startDueDate,
        endDueDate: endDueDate,
        startUpdateDate: startUpdateDate,
        endUpdateDate: endUpdateDate,
        notes: notes,
        management: management,
        risk: risk,
        riskStartDate: riskStartDate,
        riskEndDate: riskEndDate,
        id: id,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: GET_CUSTOM_ACTIVITY_REPORT,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const getCustomLibraryReport =
  (
    phase: any,
    domain: any,
    status: any,
    startDueDate: string,
    endDueDate: string,
    startUpdateDate: string,
    endUpdateDate: string,
    management: any,
    risk: any,
    riskStartDate: string,
    riskEndDate: string,
    id: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}projects/get_custom_library_report`;
      const body = {
        phase: phase,
        domain: domain,
        status: status,
        startDueDate: startDueDate,
        endDueDate: endDueDate,
        startUpdateDate: startUpdateDate,
        endUpdateDate: endUpdateDate,
        management: management,
        risk: risk,
        riskStartDate: riskStartDate,
        riskEndDate: riskEndDate,
        id: id,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: GET_CUSTOM_LIBRARY_REPORT,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const getRelatedItemsReport =
  (phase: any, domain: any, content: any, id: string) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}projects/get_related_items_report`;
      const body = {
        phase: phase,
        domain: domain,
        content: content,
        id: id,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: GET_RELATED_ITEMS_REPORT,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const getContentReport =
  (
    phase: any,
    domain: any,
    content: any,
    addedStartDate: string,
    addedEndDate: string,
    modifiedStartDate: string,
    modifiedEndDate: string,
    deletedStartDate: string,
    deletedEndDate: string,
    id: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}projects/get_content_report`;
      const body = {
        phase: phase,
        domain: domain,
        content: content,
        addedStartDate: addedStartDate,
        addedEndDate: addedEndDate,
        modifiedStartDate: modifiedStartDate,
        modifiedEndDate: modifiedEndDate,
        deletedStartDate: deletedStartDate,
        deletedEndDate: deletedEndDate,
        id: id,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: GET_CONTENT_REPORT,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const setPopupValues = (x: any, y: any) => async (dispatch: any) => {
  const data = {
    x: x,
    y: y,
  };
  try {
    dispatch({
      type: SET_POPUP_VALUES,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

const setItemMenu = (state: boolean) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ITEM_MENU,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setRightClickMenu = (x: any, y: any) => async (dispatch: any) => {
  try {
    const data = {
      x: x,
      y: y,
    };
    dispatch({
      type: SET_RIGHTCLICK_MENU,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

const setSummaryMenu = (state: boolean) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_SUMMARY_MENU,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setActivityLibrary = (state: boolean) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_LIBRARY,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setActivityIDList = (itemID: any) => async (dispatch: any) => {
  try {
    let state = store.getState();
    let itemList = state.projects.activityIDList;
    if (itemID === "delete") {
      itemList = [];
    } else {
      if (itemList.some((obj) => obj._id === itemID._id)) {
        itemList = itemList.filter((ids: any) => ids._id !== itemID._id);
      } else {
        itemList.push(itemID);
      }
    }

    dispatch({
      type: SET_ACTIVITY_ID_LIST,
      payload: itemList,
    });
  } catch (error) {
    console.log(error);
  }
};

const getActivityProductInputsData = (list: any) => async (dispatch: any) => {
  try {
    // const url = `${config.api}projects/edit_activity_product_data`;
    // const body = { list: list };
    // const res = await axios.post(url, body, {
    //   withCredentials: true,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    dispatch({
      type: EDIT_ACTIVITY_PRODUCT_INPUTS_DATA,
      payload: list.length > 0 ? list : [],
    });
  } catch (error) {
    console.log(error);
  }
};

const getActivityProductOutputsData = (list: any) => async (dispatch: any) => {
  try {
    // const url = `${config.api}projects/edit_activity_product_data`;
    // const body = { list: list };
    // const res = await axios.post(url, body, {
    //   withCredentials: true,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    dispatch({
      type: EDIT_ACTIVITY_PRODUCT_OUTPUTS_DATA,
      payload: list.length > 0 ? list : [],
    });
  } catch (error) {
    console.log(error);
  }
};

const getActivityReferenceData = (list: any) => async (dispatch: any) => {
  try {
    // const url = `${config.api}projects/edit_activity_reference_data`;
    // const body = { list: list };
    // const res = await axios.post(url, body, {
    //   withCredentials: true,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    dispatch({
      type: EDIT_ACTIVITY_REFERENCE_DATA,
      payload: list.length > 0 ? list : [],
    });
  } catch (error) {
    console.log(error);
  }
};

const getActivityDidsData = (list: any) => async (dispatch: any) => {
  try {
    // const url = `${config.api}projects/edit_activity_dids_data`;
    // const body = { list: list };
    // const res = await axios.post(url, body, {
    //   withCredentials: true,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    dispatch({
      type: EDIT_ACTIVITY_DIDS_DATA,
      payload: list.length > 0 ? list : [],
    });
  } catch (error) {
    console.log(error);
  }
};

const getActivityToolsData = (list: any) => async (dispatch: any) => {
  try {
    // const url = `${config.api}projects/edit_activity_tools_data`;
    // const body = { list: list };
    // const res = await axios.post(url, body, {
    //   withCredentials: true,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    dispatch({
      type: EDIT_ACTIVITY_TOOLS_DATA,
      payload: list.length > 0 ? list : [],
    });
  } catch (error) {
    console.log(error);
  }
};

const clearDataLists = () => async (dispatch: any) => {
  try {
    dispatch({
      type: CLEAR_DATA_LISTS,
      payload: [],
    });
  } catch (error) {
    console.log(error);
  }
};

const permDeleteProject = (projectId: string) => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/perm_delete_project`;
    const body = { projectId: projectId };

    let state = store.getState();
    let deletedList = state.projects.deleted;

    deletedList = deletedList.filter(
      (project: any) => project.projectId !== projectId
    );

    let newData = {
      deletedList: deletedList,
    };

    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: PERM_DELETE_PROJECT,
      payload: newData,
    });
  } catch (error) {
    console.log(error);
  }
};

const shareProject = async (shareList: any, projectId: string) => {
  try {
    const url = `${config.api}projects/share_project`;
    const res = await axios.post(
      url,
      { shareList, projectId },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const setCollabSelectionState = (state: boolean) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_COLLAB_SELECTION_STATE,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setCollabType = (state: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_COLLAB_TYPE,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setCollabSource = (collab: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_COLLAB_SOURCE,
      payload: collab,
    });
  } catch (error) {
    console.log(error);
  }
};

const setCollabTarget = (collab: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_COLLAB_TARGET,
      payload: collab,
    });
  } catch (error) {
    console.log(error);
  }
};

const clearCollabItems = () => async (dispatch: any) => {
  try {
    dispatch({
      type: CLEAR_COLLAB_ITEMS,
      payload: null,
    });
  } catch (error) {
    console.log(error);
  }
};

const setActivityChangeState = (state: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_CHANGE_STATE,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setActivityDeleteModal = (state: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_DELETE_MODAL,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setActivityRisksChanged = (state: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_RISKS_CHANGED,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setActivityProductsChanged = (state: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_PRODUCTS_CHANGED,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setActivityReferencesChanged = (state: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_REFERENCES_CHANGED,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setActivityDidsChanged = (state: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_DIDS_CHANGED,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setActivityToolsChanged = (state: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_TOOLS_CHANGED,
      payload: state,
    });
  } catch (error) {
    console.log(error);
  }
};

const setInitialRisks = (list: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_INITIAL_RISKS,
      payload: list,
    });
  } catch (error) {
    console.log(error);
  }
};

const setInitialProductsInputs = (list: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_INITIAL_PRODUCTS_INPUTS,
      payload: list,
    });
  } catch (error) {
    console.log(error);
  }
};

const setInitialProductsOutputs = (list: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_INITIAL_PRODUCTS_OUTPUTS,
      payload: list,
    });
  } catch (error) {
    console.log(error);
  }
};

const setInitialReferences = (list: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_INITIAL_REFERENCES,
      payload: list,
    });
  } catch (error) {
    console.log(error);
  }
};

const setInitialDids = (list: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_INITIAL_DIDS,
      payload: list,
    });
  } catch (error) {
    console.log(error);
  }
};

const setInitialTools = (list: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_ACTIVITY_INITIAL_TOOLS,
      payload: list,
    });
  } catch (error) {
    console.log(error);
  }
};

const uploadProject = (e: any, modelId: string) => async (dispatch: any) => {
  try {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("modelId", modelId);
    const url = `${config.api}projects/upload`;
    const res = await axios.post(url, data, {
      withCredentials: true,
    });
    dispatch({
      type: CREATE_PROJECT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const exportProject = async (projectId: string) => {
  try {
    const url = `${config.api}projects/export`;
    const res = await axios.post(
      url,
      { projectId },
      {
        responseType: "arraybuffer",
        withCredentials: true,
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "HSIF.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log(error);
  }
};

const setProjectPermissions = (projectId: any) => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/set_project_permissions`;
    const body = {
      projectId: projectId,
    };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: SET_PROJECT_PERMISSIONS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const handlePanPosition = (positioning: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: HANDLE_PAN_POSITION,
      payload: positioning,
    });
  } catch (error) {
    console.log(error);
  }
};

const getAllProjects = () => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/get_all_projects`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_ALL_PROJECTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const getSharedWithMe = () => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/shared_with_me`;
    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: SHARED_WITH_ME,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const handleOpenStartup = (projectId: any) => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/handle_open_startup`;
    const body = {
      projectId: projectId,
    };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: HANDLE_OPEN_STARTUP,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const getUsersProjectList = () => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/get_user_project_list`;

    const res = await axios.post(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_USER_PROJECT_LIST,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const getNotifs = async () => {
  try {
    const url = `${config.api}projects/get_notifs`;
    const body = {};
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const viewNotifs = async () => {
  try {
    const url = `${config.api}projects/view_notifs`;
    const body = {};
    await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const setNewProjectIncoming = (incoming: boolean) => async (dispatch: any) => {
  try {
    dispatch({
      type: NEW_PROJECT_INCOMING,
      payload: incoming,
    });
  } catch (error) {
    console.log(error);
  }
};

const removeMeFromProject = (projectId) => async (dispatch: any) => {
  try {
    const url = `${config.api}projects/remove_me_from_project`;
    const body = {
      projectId: projectId,
    };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: REMOVE_ME_FROM_PROJECT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export {
  uploadProject,
  shareProject,
  getFavorites,
  createProject,
  getRecentProjects,
  getMyProjects,
  updateRecent,
  setActiveProject,
  addFavorite,
  removeFavorite,
  editProject,
  saveEditProject,
  saveAs,
  saveAsNew,
  deleteProject,
  saveDeleteProject,
  recoverDeleteProject,
  getDeleted,
  setClickedItem,
  getHsiFrameworkModels,
  setRightClickedItem,
  editProjectCanvas,
  editLibrariesHSIRisk,
  removeReportList,
  addReportList,
  getReportList,
  editReportCategories,
  resetReportCategories,
  setUserReportType,
  setProjectToReport,
  setClickedProjectId,
  clearRiskDates,
  getRiskReport,
  setPopupValues,
  setItemMenu,
  setRightClickMenu,
  setSummaryMenu,
  setActivityLibrary,
  setActivityIDList,
  getActivityProductInputsData,
  getActivityProductOutputsData,
  getActivityReferenceData,
  getActivityDidsData,
  getActivityToolsData,
  clearDataLists,
  getStatusReport,
  permDeleteProject,
  getCustomActivityReport,
  getCustomLibraryReport,
  getRelatedItemsReport,
  getContentReport,
  setCollabSelectionState,
  setCollabType,
  setCollabSource,
  setCollabTarget,
  clearCollabItems,
  setActivityChangeState,
  setActivityDeleteModal,
  setActivityRisksChanged,
  setActivityProductsChanged,
  setActivityReferencesChanged,
  setActivityDidsChanged,
  setActivityToolsChanged,
  setInitialRisks,
  setInitialProductsInputs,
  setInitialProductsOutputs,
  setInitialReferences,
  setInitialDids,
  setInitialTools,
  setProjectPermissions,
  handlePanPosition,
  getAllProjects,
  handleOpenStartup,
  getUsersProjectList,
  setNewProjectIncoming,
  exportProject,
  getNotifs,
  viewNotifs,
  getSharedWithMe,
  removeMeFromProject,
};
