import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import "./Project.css";
import { ReactFlowProvider } from "reactflow";
import OverviewFlowcopy from "./overflow/OverviewFlowcopy";
import SideMenu from "./sideMenu/SideMenu";
import ItemMenu from "./itemMenu/ItemMenu";
import {
  getActivities,
  getCollaborations,
  newActivities,
} from "../../redux/actions/Activities";
import {
  editProjectCanvas,
  editLibrariesHSIRisk,
  setItemMenu,
  setProjectPermissions,
  setProjectToReport,
  saveAs,
} from "../../redux/actions/Projects";
import {
  phases1,
  phases2,
  phases3,
  phases4,
  phases5,
  phases6,
} from "./nodes/phases";
import {
  domains1,
  domains2,
  domains3,
  domains4,
  domains5,
  domains6,
} from "./nodes/domains";
import { rows1, rows2, rows3, rows4, rows5, rows6 } from "./nodes/rows";
import FeedbackModal from "../../components/feedbackPModal/feedbackModal";
import AcronymModal from "../../components/acronymPModal/acronymModal";
import ProductLibraryModal from "../../components/productLibraryPModal/productLibraryModal";
import ReferenceLibraryModal from "../../components/referenceLibraryPModal/referenceLibraryModal";
import RightClickMenu from "./rightClickMenu/RightClickMenu";
import NewActivityModal from "../../components/newActivityModal/newActivityModal";
import DidsModal from "../../components/didsPModal/didsModal";
import NotesRiskPModal from "../../components/notesRiskPModal/notesRiskPModal";
import { setNavSearch } from "../../redux/actions/Data";
import SideMenuButton from "./itemMenu/SideMenuButton";
import EditMode from "./EditMode";
import { rowAdjustment } from "../../lib/helpers/customCanvasStyles";
import RightClickActivityMenu from "./rightClickActivityMenu/RightClickActivityMenu";
import SummaryModal from "../../components/summaryModal/SummaryModal";
import ToolsModal from "../../components/toolsPModal/toolsModal";
import CollabModal from "../../components/collabModal/collabModal";
import ActivityDeleteModal from "../../components/activityDeleteModal/activityDeleteModal";
import SaveChangesModal from "../../components/saveChangesModal/saveChangesModal";
import NewProjectModal from "../../components/newProjectPModal/newProjectModal";
import SaveAsProjectPModal from "../../components/saveAsProjectPModal";
import OpenProjectModal from "../../components/openProjectModal/openProjectModal";
import ShareProjectPModal from "../../components/shareProjectPModal";

const Project: React.FC<{
  activeProject: any;
  getActivities: (projectId: string) => void;
  getCollaborations: (projectId: string) => void;
  style: any;
  newActivities: (
    projectId: string,
    description: string,
    summary: string,
    phase: string,
    domain: string,
    order: string,
    modelId: string
  ) => void;
  editProjectCanvas: (edit: boolean) => void;
  editCanvas: any;
  rightClickedItem: any;
  activeProjectActivity: any;
  editLibrariesHSIRisk: (edit: boolean) => void;
  editLibrariesHSI: boolean;
  setNavSearch: (text: string) => void;
  itemMenu: boolean;
  setItemMenu: (state: boolean) => void;
  setProjectPermissions: (projectId: string) => void;
  setProjectToReport: (project: any) => void;
  saveAs: (project: any) => void;
}> = ({
  activeProject,
  getActivities,
  getCollaborations,
  style,
  newActivities,
  editProjectCanvas,
  editCanvas,
  rightClickedItem,
  activeProjectActivity,
  editLibrariesHSIRisk,
  editLibrariesHSI,
  setNavSearch,
  itemMenu,
  setItemMenu,
  setProjectPermissions,
  setProjectToReport,
  saveAs,
}) => {
  const activeProjectRef = useRef("");
  const [sideMenu, setSideMenu] = useState(false) as any;
  const [settingMenu, setSettingMenu] = useState(false) as any;
  const [feedback, setFeedback] = useState(false) as any;
  const [helpMenu, setHelpMenu] = useState(false) as any;
  const [projectArrow, setProjectArrow] = useState(false) as any;
  const [xPosition, setXPosition] = useState(0) as any;
  const [yPosition, setYPosition] = useState(0) as any;
  const [acronymM, setAcronymM] = useState(false) as any;
  const [productM, setProductM] = useState(false) as any;
  const [referenceM, setReferenceM] = useState(false) as any;
  const [didsM, setDidsM] = useState(false) as any;
  const [toolsM, setToolsM] = useState(false) as any;
  const [newProjectP, setNewProjectP] = useState(false) as any;
  const [saveAsProjectP, setSaveAsProjectP] = useState(false) as any;
  const [openAProjectM, setOpenAProjectM] = useState(false) as any;
  const [shareProjectPM, setShareProjectPM] = useState(false) as any;
  const [defaultData, setDefaultData] = useState(false) as any;
  const [show, setShow] = useState(false);
  const [points, setPoints] = useState({ x: 0, y: 0 });
  const [menuActivty, setMenuActivty] = useState(false) as any;
  const [collabMenu, setCollabMenu] = useState(false) as any;
  const [menuReset, setMenuReset] = useState(false) as any;
  // const [editCanvasState, setEditCanvasState] = useState(false) as any;
  const [newActivitySubmit, setNewActivitySubmit] = useState(false) as any;
  const [newDescription, setNewDescription] = useState("") as any;
  const [newSummary, setNewSummary] = useState("") as any;
  const [newPhase, setNewPhase] = useState("") as any;
  const [newDomain, setNewDomain] = useState("") as any;
  const [newOrder, setNewOrder] = useState("") as any;
  const [detailsProduct, setDetailsProduct] = useState("") as any;
  const [detailsReference, setDetailsReference] = useState("") as any;
  const [detailsDID, setDetailsDID] = useState("") as any;
  const [detailsTool, setDetailsTool] = useState("") as any;

  const navigate = useNavigate();

  useEffect(() => {
    setNavSearch("");
    const handleClick = () => setShow(false);
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  useEffect(() => {
    if (
      activeProject?.hasOwnProperty("projectId") &&
      activeProject.projectId !== activeProjectRef.current
    ) {
      activeProjectRef.current = activeProject.projectId;
      getActivities(activeProject?.projectId);
      getCollaborations(activeProject?.projectId);
      setProjectPermissions(activeProject?.projectId);
    }
  }, [activeProject]);

  const clickFeedback = () => {
    setFeedback(!feedback);
  };

  const closeFeedback = () => {
    setFeedback(false);
  };

  const handleSettings = () => {
    setSettingMenu(!settingMenu);
    setHelpMenu(false);
  };

  const handleHelp = () => {
    setHelpMenu(!helpMenu);
    setSettingMenu(false);
  };

  const closePopups = () => {
    setDefaultData(true);
    setSettingMenu(false);
    setHelpMenu(false);
  };

  function handleReportClick() {
    navigate("/stepone");
    setProjectToReport(null);
  }

  //Acronym Modal
  const openAcronymM = () => {
    setAcronymM(true);
  };
  const closeAcronymM = () => {
    setAcronymM(false);
    setNavSearch("");
  };

  //Product Modal
  const openProductM = () => {
    setProductM(true);
  };
  const closeProductM = () => {
    setProductM(false);
    setNavSearch("");
  };

  //Reference Modal
  const openReferenceM = () => {
    setReferenceM(true);
  };
  const closeReferenceM = () => {
    setReferenceM(false);
    setNavSearch("");
  };

  //Dids Modal
  const openDidsM = () => {
    setDidsM(true);
  };
  const closeDidsM = () => {
    setDidsM(false);
    setNavSearch("");
  };

  //Tools Modal
  const openToolsM = () => {
    setToolsM(true);
  };
  const closeToolsM = () => {
    setToolsM(false);
    setNavSearch("");
  };

  //Activity Modal
  const openActivityM = () => {
    setMenuActivty(true);
    setShow(false);
  };
  const closeActivityM = () => {
    setMenuActivty(false);
  };

  //Global Modal
  const openCollabM = () => {
    setCollabMenu(true);
    setShow(false);
  };
  const closeCollabM = () => {
    setCollabMenu(false);
  };

  const closeNotesHSI = () => {
    editLibrariesHSIRisk(false);
  };

  //New Project Modal
  const openNewProjectM = () => {
    setNewProjectP(true);
  };
  const closeNewProjectM = () => {
    setNewProjectP(false);
    setNavSearch("");
  };

  //New Save as Project Modal
  const openNewSaveAsProjectM = () => {
    setSaveAsProjectP(true);
    saveAs(activeProject);
  };
  const closeNewSaveAsProjectM = () => {
    setSaveAsProjectP(false);
    setNavSearch("");
  };

  //open a Project Modal
  const openProjectM = () => {
    setOpenAProjectM(true);
  };
  const closeProjectM = () => {
    setOpenAProjectM(false);
    setNavSearch("");
  };

  //open Share Project Modal
  // const openShareProjectM = () => {
  //   setShareProjectPM(true);
  // };
  const closeShareProjectM = () => {
    setShareProjectPM(false);
    setNavSearch("");
  };

  const resetNewActvityForm = () => {
    setNewActivitySubmit(false);
    setNewDescription("");
    setNewSummary("");
    setNewPhase("");
    setNewDomain("");
    setNewOrder("");
  };

  const clearMenu = () => {
    setShow(false);
    setPoints({ x: 0, y: 0 });
  };

  useEffect(() => {
    if (rightClickedItem !== null) {
      setShow(false);
    }
  }, [rightClickedItem]);

  useEffect(() => {
    if (newActivitySubmit) {
      newActivities(
        activeProject.projectId,
        newDescription,
        newSummary,
        newPhase,
        newDomain,
        newOrder,
        activeProject.modelId
      );
    }
    setNewActivitySubmit(false);
  }, [newActivitySubmit]);

  //Revisit
  // useEffect(() => {
  // window.onpopstate = function () {
  //   if (editCanvas) {
  //     window.history.go(1);
  //     // setChangesModal(true);
  //   } else {
  //     window.history.go(-1);
  //   }
  // };
  // window.onbeforeunload = function () {
  //   alert("Browser back button is clicked.");
  //   setChangesModal(true);
  //   console.log("backkk");
  // };
  // window.onpopstate = (e) => {
  //   if (editCanvas) {
  //     e.preventDefault();
  //     alert("HEY");
  //   }
  // };
  // window.addEventListener("beforeunload", handleUnload);
  // return () => {
  //   window.removeEventListener("beforeunload", handleUnload);
  // };
  // }, []);

  return (
    <div
      style={{
        backgroundColor: style.colors.white,
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        setShow(true);
        if (editCanvas) setPoints({ x: e.pageX, y: e.pageY });
      }}
    >
      <ReactFlowProvider>
        <Navbar
          clickFeedback={clickFeedback}
          projectPage={true}
          handleSettings={handleSettings}
          settingMenu={settingMenu}
          handleHelp={handleHelp}
          helpMenu={helpMenu}
          closePopups={closePopups}
          screenName={`${activeProject?.name}`}
          setProjectArrow={setProjectArrow}
          setXPosition={setXPosition}
          setYPosition={setYPosition}
          openAcronymM={openAcronymM}
          openProductM={openProductM}
          openReferenceM={openReferenceM}
          openDIDSM={openDidsM}
          handleReportClick={handleReportClick}
          openToolsM={openToolsM}
          openActivityM={openActivityM}
          setItemMenu={setItemMenu}
          itemMenu={itemMenu}
          sideMenu={sideMenu}
          setSideMenu={setSideMenu}
          openNewProjectM={openNewProjectM}
          openNewSaveAsProjectM={openNewSaveAsProjectM}
          openProjectM={openProjectM}
          setShareProjectPM={setShareProjectPM}
        />
        {/* {editCanvas ? <EditMode /> : null} */}

        <FeedbackModal closeFeedback={closeFeedback} feedback={feedback} />
        <AcronymModal closeAcronymM={closeAcronymM} acronymM={acronymM} />
        <ProductLibraryModal
          closeProductM={closeProductM}
          productM={productM}
          detailsProduct={detailsProduct}
          setDetailsProduct={setDetailsProduct}
        />
        <ReferenceLibraryModal
          closeReferenceM={closeReferenceM}
          referenceM={referenceM}
          detailsReference={detailsReference}
          setDetailsReference={setDetailsReference}
        />
        <DidsModal
          closeDIDSM={closeDidsM}
          didsM={didsM}
          detailsDID={detailsDID}
          setDetailsDID={setDetailsDID}
        />
        <ToolsModal
          closeToolsM={closeToolsM}
          toolsM={toolsM}
          detailsTool={detailsTool}
          setDetailsTool={setDetailsTool}
        />
        <NewProjectModal
          newProjectP={newProjectP}
          closeNewProjectM={closeNewProjectM}
        />
        <SaveAsProjectPModal
          saveAsProjectP={saveAsProjectP}
          closeNewSaveAsProjectM={closeNewSaveAsProjectM}
        />
        <OpenProjectModal
          openAProjectM={openAProjectM}
          closeProjectM={closeProjectM}
        />
        <ShareProjectPModal
          shareProjectM={shareProjectPM}
          closeShareProjectM={closeShareProjectM}
        />
        {/* <SideMenu setSideMenu={setSideMenu} sideMenu={sideMenu} /> */}
        {/* <SideMenuButton
        setItemMenu={setItemMenu}
        itemMenu={itemMenu}
        sideMenu={sideMenu}
        setSideMenu={setSideMenu}
        itemType="item"
      />
      <SideMenuButton
        setItemMenu={setItemMenu}
        itemMenu={itemMenu}
        sideMenu={sideMenu}
        setSideMenu={setSideMenu}
        itemType="title"
      /> */}
        {/* <ItemMenu
        setItemMenu={setItemMenu}
        itemMenu={itemMenu}
        openProductM={openProductM}
        openReferenceM={openReferenceM}
        openDIDSM={openDidsM}
        openToolsM={openToolsM}
      /> */}
        {itemMenu && activeProjectActivity ? (
          <NotesRiskPModal
            closeNotesHSI={closeNotesHSI}
            notesM={editLibrariesHSI}
          />
        ) : null}
        <SummaryModal />
        {/* <SaveChangesModal
        changesModal={changesModal}
        setChangesModal={setChangesModal}
      /> */}
        <NewActivityModal
          closeActivityM={closeActivityM}
          activityM={menuActivty}
          projectPhase={
            activeProject?.modelId === "6"
              ? phases6
              : activeProject?.modelId === "5"
              ? phases5
              : activeProject?.modelId === "4"
              ? phases4
              : activeProject?.modelId === "3"
              ? phases3
              : activeProject?.modelId === "2"
              ? phases2
              : phases1
          }
          projectDomain={
            activeProject?.modelId === "6"
              ? domains6
              : activeProject?.modelId === "5"
              ? domains5
              : activeProject?.modelId === "4"
              ? domains4
              : activeProject?.modelId === "3"
              ? domains3
              : activeProject?.modelId === "2"
              ? domains2
              : domains1
          }
          activeProject={activeProject}
          setNewDescription={setNewDescription}
          setNewSummary={setNewSummary}
          setNewPhase={setNewPhase}
          setNewDomain={setNewDomain}
          setNewOrder={setNewOrder}
          setNewActivitySubmit={setNewActivitySubmit}
          resetNewActvityForm={resetNewActvityForm}
          itemMenu={itemMenu}
          openProductM={openProductM}
          openReferenceM={openReferenceM}
          openDIDSM={openDidsM}
          openToolsM={openToolsM}
        />
        {/* <CollabModal closeCollabM={closeCollabM} collabM={collabMenu} /> */}
        {show && editCanvas && rightClickedItem === null ? (
          <RightClickMenu
            topPoint={points.y}
            leftPoint={points.x}
            openActivityM={openActivityM}
            clearMenu={clearMenu}
          />
        ) : null}
        <RightClickActivityMenu />
        <div
          style={{
            // height: "94.25vh",
            height: "100vh",
            width: "100vw",
            zIndex: 0,
            // borderBottom: "3px solid red",
          }}
        >
          <ActivityDeleteModal />

          <SideMenu setSideMenu={setSideMenu} sideMenu={sideMenu} />
          <ItemMenu
            setItemMenu={setItemMenu}
            itemMenu={itemMenu}
            openProductM={openProductM}
            openReferenceM={openReferenceM}
            openDIDSM={openDidsM}
            openToolsM={openToolsM}
            setDetailsProduct={setDetailsProduct}
            setDetailsReference={setDetailsReference}
            setDetailsDID={setDetailsDID}
            setDetailsTool={setDetailsTool}
          />
          <OverviewFlowcopy
            setSideMenu={setSideMenu}
            sideMenu={sideMenu}
            setItemMenu={setItemMenu}
            itemMenu={itemMenu}
            projectPhase={
              activeProject?.modelId === "6"
                ? phases6
                : activeProject?.modelId === "5"
                ? phases5
                : activeProject?.modelId === "4"
                ? phases4
                : activeProject?.modelId === "3"
                ? phases3
                : activeProject?.modelId === "2"
                ? phases2
                : phases1
            }
            projectDomain={
              activeProject?.modelId === "6"
                ? domains6
                : activeProject?.modelId === "5"
                ? domains5
                : activeProject?.modelId === "4"
                ? domains4
                : activeProject?.modelId === "3"
                ? domains3
                : activeProject?.modelId === "2"
                ? domains2
                : domains1
            }
            projectRows={
              activeProject?.modelId === "6"
                ? rows6
                : activeProject?.modelId === "5"
                ? rows5
                : activeProject?.modelId === "4"
                ? rows4
                : activeProject?.modelId === "3"
                ? rows3
                : activeProject?.modelId === "2"
                ? rows2
                : rows1
            }
            openCollabM={openCollabM}
            collabMenu={collabMenu}
            closeCollabM={closeCollabM}
          />
          {/* <OverviewFlow
          setSideMenu={setSideMenu}
          sideMenu={sideMenu}
          setItemMenu={setItemMenu}
          itemMenu={itemMenu}
          projectPhase={
            activeProject?.modelId === "6"
              ? phases6
              : activeProject?.modelId === "5"
              ? phases5
              : activeProject?.modelId === "4"
              ? phases4
              : activeProject?.modelId === "3"
              ? phases3
              : activeProject?.modelId === "2"
              ? phases2
              : phases1
          }
          projectDomain={
            activeProject?.modelId === "6"
              ? domains6
              : activeProject?.modelId === "5"
              ? domains5
              : activeProject?.modelId === "4"
              ? domains4
              : activeProject?.modelId === "3"
              ? domains3
              : activeProject?.modelId === "2"
              ? domains2
              : domains1
          }
          projectRows={
            activeProject?.modelId === "6"
              ? rows6
              : activeProject?.modelId === "5"
              ? rows5
              : activeProject?.modelId === "4"
              ? rows4
              : activeProject?.modelId === "3"
              ? rows3
              : activeProject?.modelId === "2"
              ? rows2
              : rows1
          }
          openCollabM={openCollabM}
          collabMenu={collabMenu}
          closeCollabM={closeCollabM}
        /> */}
        </div>

        {setZoomIn()}
        {setZoomOut()}
        {setFitView()}
        {setLock()}
        {hideBrandLogo()}
        {gobacklisten()}
      </ReactFlowProvider>
    </div>
  );

  function setZoomIn() {
    const element = document.querySelectorAll<HTMLElement>(
      ".react-flow__controls-zoomin"
    );
    if (element[0]) {
      element[0].title = "Zoom In";
      return;
    }
  }
  function setZoomOut() {
    const element = document.querySelectorAll<HTMLElement>(
      ".react-flow__controls-zoomout"
    );
    if (element[0]) {
      element[0].title = "Zoom Out";
      return;
    }
  }
  function setFitView() {
    const element = document.querySelectorAll<HTMLElement>(
      ".react-flow__controls-fitview"
    );
    if (element[0]) {
      element[0].style.display = "none";
      // element[0].title = "Auto Fit";
      return;
    }
  }
  function setLock() {
    const element = document.querySelectorAll<HTMLElement>(
      ".react-flow__controls-interactive"
    );
    if (element[0]) {
      element[0].style.display = "none";
      // element[0].title = "Toggle Interactivity";
      return;
    }
  }
  function hideBrandLogo() {
    const element = document.querySelectorAll<HTMLElement>(
      ".react-flow__attribution"
    );
    if (element[0]) {
      element[0].style.display = "none";
      return;
    }
  }
  function gobacklisten() {
    window.onbeforeunload = function (event) {
      event.returnValue = "Write something clever here..";
    };
  }
};

const mapStateToProps = (state: any) => ({
  activeProject: state.projects.activeProject,
  style: state.local.style,
  editCanvas: state.projects.editCanvas,
  rightClickedItem: state.projects.rightClickedItem,
  activeProjectActivity: state.projects.activeProjectActivity,
  editLibrariesHSI: state.projects.editLibrariesHSI,
  itemMenu: state.projects.itemMenu,
});

export default connect(mapStateToProps, {
  getActivities,
  getCollaborations,
  newActivities,
  editProjectCanvas,
  editLibrariesHSIRisk,
  setNavSearch,
  setItemMenu,
  setProjectPermissions,
  setProjectToReport,
  saveAs,
})(Project) as any;
