import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./openProjectModal.css";
import {
  getRecentProjects,
  getHsiFrameworkModels,
  getMyProjects,
  getFavorites,
  setActiveProject,
  setNewProjectIncoming,
} from "../../redux/actions/Projects";
import { clearActivities } from "../../redux/actions/Activities";
import { projectSort } from "../../lib/helpers/columnFilters";
import { useNavigate } from "react-router-dom";

const OpenProjectModal: React.FC<{
  closeProjectM: any;
  openAProjectM: any;
  style: any;
  getRecentProjects: () => void;
  getHsiFrameworkModels: () => void;
  getMyProjects: () => void;
  getFavorites: () => void;
  recent_projects: any;
  hsiFrameworkModels: any;
  myProjects: any;
  favorites: any;
  activeProject: any;
  setActiveProject: (project: any) => void;
  clearActivities: () => void;
  setNewProjectIncoming: (incoming: boolean) => void;
}> = ({
  closeProjectM,
  openAProjectM,
  style,
  getRecentProjects,
  getHsiFrameworkModels,
  getMyProjects,
  getFavorites,
  recent_projects,
  hsiFrameworkModels,
  myProjects,
  favorites,
  activeProject,
  setActiveProject,
  clearActivities,
  setNewProjectIncoming,
}) => {
  const [projectSearch, setProjectSearch] = useState("") as any;
  const [searchFiltered, setSearchFiltered] = useState([]) as any;
  const [projectAscending, setProjectAscending] = useState(1) as any;
  const [fileTypeValue, setFileTypeValue] = useState("Recent") as any;
  const [currentSort, setCurrentSort] = useState("project") as any;
  const [focusList, setFocusList] = useState(recent_projects) as any;
  const navigate = useNavigate();

  useEffect(() => {
    if (openAProjectM) {
      getRecentProjects();
      getHsiFrameworkModels();
      getMyProjects();
      getFavorites();
    }
    setSearchFiltered(
      recent_projects.filter((x) => x?.projectId !== activeProject?.projectId)
    );
  }, [openAProjectM]);

  useEffect(() => {
    if (openAProjectM) {
      let projectList = focusList.filter(
        (x) => x.projectId !== activeProject.projectId
      );

      if (projectSearch !== "" && focusList?.length > 0) {
        projectList = projectList.filter(
          (item: any) =>
            item.name.toLowerCase().indexOf(projectSearch.toLowerCase()) > -1
        );
      }

      projectList = projectSort(projectList, projectAscending);

      setSearchFiltered(projectList);
    }
  }, [projectSearch, focusList, projectAscending]);

  const fileTypeHandler = (event) => {
    const value = event.target.value;
    setFileTypeValue(value);
    setFocusList(
      value === "Recent"
        ? recent_projects
        : value === "AAF Pathway Templates"
        ? hsiFrameworkModels
        : value === "My Projects"
        ? myProjects
        : favorites
    );
  };

  const acronymColumn = () => {
    setCurrentSort(currentSort !== "project" ? "project" : currentSort);
    projectAscending === 1 ? setProjectAscending(2) : setProjectAscending(1);
  };

  const closingModal = () => {
    closeProjectM();
    setProjectSearch("");
  };

  const handleOpenProjects = (project) => {
    clearActivities();
    setProjectSearch("");
    closeProjectM();
    setActiveProject(project);
    // setNewProjectIncoming(true);
    // navigate("/project");
  };

  return (
    <div style={{ visibility: openAProjectM ? "initial" : "hidden" }}>
      <div className="modal-container-acronym">
        <section className="projectModals-acronym">
          <i
            className="fa fa-times"
            onClick={closingModal}
            style={{
              cursor: "pointer",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          ></i>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "-20px",
            }}
          >
            <p>Projects</p>
            <input
              className="acronymSearchBox"
              value={projectSearch}
              onChange={(e) => setProjectSearch(e.target.value)}
              style={{
                width: "160px",
                height: "20px",
                alignSelf: "center",
                marginRight: "40px",
                padding: "2px",
                border: "1px solid black",
                paddingLeft: "20px",
                borderRadius: "8px",
              }}
              placeholder="Search Projects"
            />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <select
              value={fileTypeValue}
              onChange={fileTypeHandler}
              style={{
                width: "250px",
                height: "25px",
                cursor: "default",
                alignSelf: "center",
                marginTop: "-6px",
              }}
            >
              <option selected value="Recent">
                Recent
              </option>
              <option selected value="AAF Pathway Templates">
                AAF Pathway Templates
              </option>
              <option selected value="My Projects">
                My Projects
              </option>
              {/* <option selected value="Shared With Me">
                Shared With Me
              </option> */}
              <option selected value="Favorites">
                Favorites
              </option>
            </select>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "6px",
              borderTop: "1px solid black",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
            }}
          >
            <div
              onClick={acronymColumn}
              style={{
                // width: "192px",
                width: "35vw",
                minWidth: "576px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont">
                {projectAscending === 0
                  ? "Project"
                  : projectAscending === 1
                  ? "Project ↓"
                  : "Project ↑"}
              </p>
            </div>
          </div>
          <div
            className="modal-body"
            style={{
              border: "1px solid black",
              paddingTop: ".75px",
            }}
          >
            {searchFiltered?.map((item: any, index: number) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                }}
              >
                <div
                  style={{
                    // width: "192px",
                    width: "34vw",
                    minWidth: "576px",
                    borderLeft: "none",
                    borderRight: "none",
                    color: "black",
                    // height: "29px",
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <p
                    className="overFlowTextProject responsiveFont"
                    style={{ paddingLeft: "10px", cursor: "pointer" }}
                    onClick={() => handleOpenProjects(item)}
                  >
                    {item.name}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  recent_projects: state.projects.recent,
  hsiFrameworkModels: state.projects.hsiFrameworkModels,
  myProjects: state.projects.myProjects,
  favorites: state.projects.favorites,
  activeProject: state.projects.activeProject,
});

export default connect(mapStateToProps, {
  getRecentProjects,
  getHsiFrameworkModels,
  getMyProjects,
  getFavorites,
  setActiveProject,
  clearActivities,
  setNewProjectIncoming,
})(OpenProjectModal) as any;
