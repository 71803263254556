import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import projectIMG from "../../lib/assets/img/projectExample.png";
import { BiCheckboxChecked, BiCheckbox } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  updateRecent,
  setActiveProject,
  addFavorite,
  editProject,
  saveAs,
  deleteProject,
  // setClickedProjectId,
  setPopupValues,
  removeFavorite,
  editProjectCanvas,
} from "../../redux/actions/Projects";
import { setNavSearch } from "../../redux/actions/Data";
import "./Card.css";

const Card: React.FC<{
  index: number;
  item: any;
  style: any;
  bottom: any;
  setActiveProject: (project: any) => void;
  setNavSearch: (text: string) => void;
  activeProject: any;
  clickedProjectId: string;
  setClickedProjectId: (id: string) => void;
  allItems: any;
  highlightProject: any;
  setHighlightProject: any;
  setPopupValues: (x: any, y: any) => void;
  setEllipsisClick: any;
  addFavorite: (project: any) => void;
  removeFavorite: (project: any) => void;
  favorites: any;
  sideOption: any;
  editProjectCanvas: (edit: boolean) => void;
}> = ({
  index,
  item,
  bottom,
  style,
  setActiveProject,
  setNavSearch,
  activeProject,
  // clickedProjectId,
  // setClickedProjectId,
  // allItems,
  highlightProject,
  setHighlightProject,
  setPopupValues,
  setEllipsisClick,
  addFavorite,
  favorites,
  removeFavorite,
  sideOption,
  editProjectCanvas,
}) => {
  const [projectClicked, setProjectClicked] = useState(false) as any;
  const [projectHover, setProjectHover] = useState(false) as any;
  const [projectMenu, setProjectMenu] = useState(false) as any;
  const [isFavorite, setisFavorite] = useState(false) as any;
  const [loading, setLoading] = useState(false) as any;
  const [starColor, setStarColor] = useState(style.colors.grey5) as any;

  const navigate = useNavigate();

  const handleClickedProjectM = () => {
    setProjectMenu(!projectMenu);
  };

  const openProject = () => {
    editProjectCanvas(false);
    editProjectCanvas(false);
    setActiveProject(item);
    updateRecent(item.projectId);
    navigate("/project");
  };

  const singleClickProject = () => {
    // console.log("click before", highlightProject);
    // console.log("projectClicked", projectClicked);

    if (projectClicked) {
      setHighlightProject(null);
      setActiveProject(null);
      setProjectClicked(false);
      setProjectMenu(false);
      // setClickedProjectId("");
    } else {
      setHighlightProject(item);
      setActiveProject(item);
      setProjectClicked(true);
      setProjectMenu(true);
      // setClickedProjectId(item.projectId);
    }

    // console.log("click after", highlightProject);
  };

  // const focusProject = () => {
  //   if (clickedProjectId.length > 0) {
  //     if (clickedProjectId === item?.projectId) setActiveProject(item);
  //     else {
  //       allItems.map((item) => {
  //         if (item.projectId === clickedProjectId) {
  //           setActiveProject(item);
  //         }
  //       });
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (activeProject === null) {
  //     if (clickedProjectId === item?.projectId) {
  //       setActiveProject(item);
  //     }
  //   }
  // }, [activeProject]);

  // useEffect(() => {
  //   if (activeProject) {
  //     if (activeProject?.projectId === item?.projectId) {
  //       setProjectClicked(true);
  //       // console.log("ids", activeProject.projectId === item.projectId);
  //     } else {
  //       setProjectClicked(false);
  //       setProjectMenu(false);
  //     }
  //   } else {
  //     setProjectClicked(false);
  //     setProjectMenu(false);
  //   }
  // }, [activeProject]);

  // useEffect(() => {
  //   if (favorites.length > 0) {
  //     if (
  //       favorites.filter((fav) => fav.projectId === item.projectId).length > 0
  //     ) {
  //       setStarColor(style.colors.primaryBlue);
  //       setisFavorite(true);
  //     } else {
  //       setStarColor(style.colors.grey5);
  //       setisFavorite(false);
  //     }
  //   }
  // }, [favorites, sideOption]);

  const handleFavoriteClick = (item) => {
    if (favorites.length > 0) {
      if (favorites.some((fav) => fav.projectId === item.projectId)) {
        removeFavorite(item);
      } else {
        addFavorite(item);
      }
    } else {
      addFavorite(item);
    }
  };

  return (
    <div
      className="dashboardCards"
      key={index}
      onMouseOver={() => {
        setActiveProject(item);
        setProjectHover(item.projectId);
        setStarColor(style.colors.textWhite);
      }}
      onMouseOut={() => {
        //
        setProjectHover(false);
        setStarColor(
          isFavorite ? style.colors.primaryBlue : style.colors.grey5
        );
        // focusProject();
        if (!projectMenu) {
          setProjectClicked(false);
          setActiveProject(null);
        }
        // console.log("hover away", highlightProject)
        if (highlightProject) {
          setActiveProject(highlightProject);
        }
      }}
      onDoubleClick={openProject}
      // onMouseLeave={() => }
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "20px",
        marginTop: "20px",
        borderRadius: "8px",
        backgroundColor: projectHover
          ? style.colors.primaryBlue
          : highlightProject
          ? highlightProject.projectId === item.projectId
            ? style.colors.primaryBlue
            : style.colors.grey2
          : style.colors.grey2,
        boxShadow: `0 4px 8px 0 ${style.colors.grey2}`,
        marginBottom: "5vh",
      }}
    >
      <img
        className="dashboardCardsImg"
        onClick={singleClickProject}
        // onMouseOver={() => setActiveProject(item)}
        // onMouseOut={() => {
        //   if (!projectMenu) setActiveProject(null);
        // }}
        src={projectIMG}
        alt="logo"
        style={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          cursor: "pointer",
        }}
      />
      <div className="center">
        <div onClick={singleClickProject}>
          <span
            className="overFlowCardText"
            style={{
              color: projectHover
                ? "white"
                : highlightProject
                ? highlightProject.projectId === item.projectId
                  ? "white"
                  : "black"
                : "black",
              fontFamily: "OpenSans-Regular",
              cursor: "pointer",
            }}
          >
            {item.name}
          </span>
        </div>
        <i
          onClick={(event) => {
            setPopupValues(event.clientX - 10, event.clientY - 10);
            handleClickedProjectM();
            setNavSearch("");
            setProjectHover(item.projectId);
            setEllipsisClick(item);
            setStarColor(style.colors.textWhite);
          }}
          style={
            projectHover
              ? {
                  color: style.colors.textWhite,
                  fontSize: "1vw",
                  marginLeft: "auto",
                }
              : highlightProject
              ? highlightProject.projectId === item.projectId
                ? {
                    color: style.colors.textWhite,
                    fontSize: "1vw",
                    marginLeft: "auto",
                  }
                : {
                    color: style.colors.grey2,
                    fontSize: "1vw",
                    marginLeft: "auto",
                  }
              : {
                  color: style.colors.grey2,
                  fontSize: "1vw",
                  marginLeft: "auto",
                }
          }
          className="fa fa-ellipsis-v"
        />
      </div>
      <div className="center2">
        <BiCheckboxChecked
          style={{
            color: projectHover
              ? style.colors.textWhite
              : highlightProject
              ? highlightProject.projectId === item.projectId
                ? style.colors.textWhite
                : "transparent"
              : "transparent",
          }}
        />
        <p
          className="dashboardCardsOpenTXT"
          style={{
            color: projectHover
              ? style.colors.textWhite
              : highlightProject
              ? highlightProject.projectId === item.projectId
                ? style.colors.textWhite
                : "transparent"
              : "transparent",
            fontFamily: "OpenSans-Regular",
            cursor: "pointer",
          }}
          onClick={openProject}
        >
          Open
        </p>
        <i
          onClick={() => handleFavoriteClick(item)}
          className="fa-solid fa-star dashboardCardsStar"
          style={{
            color: favorites.some((obj) => obj.projectId === item.projectId)
              ? projectHover === item.projectId
                ? style.colors.textWhite
                : style.colors.primaryBlue
              : style.colors.grey5,
            marginLeft: "auto",
            // zIndex: 9,
          }}
        ></i>
        {/* <AiFillStar  /> */}
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: "3vh",
          paddingLeft: ".7vw",
          paddingRight: ".3vw",
        }}
      >
        <div onClick={singleClickProject}>
          <p
            className="overFlowCardText"
            style={{
              fontSize: ".7vw",
              color: projectHover
                ? "white"
                : highlightProject
                ? highlightProject.projectId === item.projectId
                  ? "white"
                  : "black"
                : "black",
              fontFamily: "OpenSans-Regular",
              cursor: "pointer",
            }}
          >
            {item.name}
          </p>
        </div>
        <div
          style={{
            width: "1vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={(event) => {
            setPopupValues(event.clientX - 10, event.clientY - 10);
            handleClickedProjectM();
            setNavSearch("");
            setProjectHover(true);
            setEllipsisClick(item);
          }}
        >
          <i
            style={
              projectHover
                ? {
                    color: style.colors.textWhite,
                    fontSize: "1vw",
                  }
                : highlightProject
                ? highlightProject.projectId === item.projectId
                  ? {
                      color: style.colors.textWhite,
                      fontSize: "1vw",
                    }
                  : { display: "none" }
                : { display: "none" }
            }
            className="fa fa-ellipsis-v"
          />
        </div>
      </div>
      <div
        style={{
          marginTop: "2vh",
          height: "3vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: ".7vw",
          paddingRight: ".4vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <BiCheckboxChecked
            style={{
              color: projectHover
                ? style.colors.textWhite
                : highlightProject
                ? highlightProject.projectId === item.projectId
                  ? style.colors.textWhite
                  : "transparent"
                : "transparent",
            }}
          />
          <p
            style={{
              fontSize: ".7vw",
              color: projectHover
                ? style.colors.textWhite
                : highlightProject
                ? highlightProject.projectId === item.projectId
                  ? style.colors.textWhite
                  : "transparent"
                : "transparent",
              fontFamily: "OpenSans-Regular",
              cursor: "pointer",
            }}
            onClick={openProject}
          >
            Open
          </p>
        </div>
        <AiFillStar
          style={{
            color: projectHover
              ? style.colors.textWhite
              : highlightProject
              ? highlightProject.projectId === item.projectId
                ? style.colors.textWhite
                : style.colors.grey5
              : style.colors.grey5,
          }}
        />
      </div> */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  activeProject: state.projects.activeProject,
  clickedProjectId: state.projects.clickedProjectId,
  favorites: state.projects.favorites,
});

export default connect(mapStateToProps, {
  setActiveProject,
  updateRecent,
  addFavorite,
  editProject,
  saveAs,
  deleteProject,
  setNavSearch,
  // setClickedProjectId,
  setPopupValues,
  removeFavorite,
  editProjectCanvas,
})(Card) as any;
