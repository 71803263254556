import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { convertTimeNumeric } from "../../lib/helpers/convertTime";
import {
  updateRecent,
  setActiveProject,
  addFavorite,
  editProject,
  saveAs,
  deleteProject,
  addReportList,
  setProjectToReport,
  editProjectCanvas,
  removeFavorite,
} from "../../redux/actions/Projects";
import "./Table.css";
import { useNavigate } from "react-router-dom";

const Table: React.FC<{
  projectList: any;
  handleClick: any;
  // setProjectClicked: any;
  openDeleteM: any;
  setSearchText: any;
  style: any;
  activeProject: any;
  setActiveProject: (project: any) => void;
  updateRecent: (projectId: string) => void;
  addFavorite: (projectId: string) => void;
  editProject: (project: any) => void;
  saveAs: (project: any) => void;
  deleteProject: (project: any) => void;
  adminFolderState: boolean;
  sideOption: string;
  reportingList: any;
  addReportList: (project: any, userID: string) => void;
  user: any;
  setProjectToReport: (project: object) => void;
  editProjectCanvas: (edit: boolean) => void;
  favorites: any;
  removeFavorite: (project: any) => void;
}> = ({
  projectList,
  handleClick,
  // setProjectClicked,
  openDeleteM,
  setSearchText,
  style,
  activeProject,
  setActiveProject,
  updateRecent,
  addFavorite,
  editProject,
  saveAs,
  deleteProject,
  adminFolderState,
  sideOption,
  reportingList,
  addReportList,
  user,
  setProjectToReport,
  editProjectCanvas,
  favorites,
  removeFavorite,
}) => {
  const [tableRowMenu, setTableRowMenu] = useState(false) as any;
  const [projectHover, setProjectHover] = useState() as any;
  const [xPosition, setXPosition] = useState(0) as any;
  const [yPosition, setYPosition] = useState(0) as any;
  const [viewOption, setViewOption] = useState(
    sideOption === "Feedback" ? "current" : "all"
  ) as any;
  const navigate = useNavigate();

  const openMenu = () => {
    setTableRowMenu(true);
  };

  const openProject = () => {
    editProjectCanvas(false);
    updateRecent(activeProject.projectId);
    navigate("/project");
  };

  const addFavoriteProject = () => {
    addFavorite(activeProject.projectId);
    setActiveProject(null);
  };

  const editNameTitle = () => {
    editProject(activeProject);
    setActiveProject(null);
  };

  const saveAsNew = () => {
    saveAs(activeProject);
    setActiveProject(null);
  };

  const goDelete = () => {
    deleteProject(activeProject);
    setActiveProject(null);
  };

  const handleReportClick = () => {
    if (
      !reportingList.find((item) => item.projectId === activeProject.projectId)
    ) {
      addReportList(activeProject, user.oid);
    }
    setProjectToReport(activeProject);
    navigate("/stepone");
  };

  const handleFavoriteClick = (item) => {
    if (favorites.length > 0) {
      if (favorites.some((fav) => fav.projectId === item.projectId)) {
        removeFavorite(item);
      } else {
        addFavorite(item);
      }
    } else {
      addFavorite(item);
    }
  };

  return (
    <div>
      <select
        value={viewOption}
        name="allProjectOption"
        id="allProjectOption"
        style={{
          marginLeft: "4vw",
          width: "80px",
          height: "25px",
          marginTop: "20px",
          opacity: adminFolderState ? 1 : 0,
          cursor: adminFolderState ? "pointer" : "default",
        }}
        onChange={(e) => setViewOption(e.target.value)}
      >
        {sideOption === "Feedback" ? (
          <>
            <option value="current">Current</option>
            <option value="archived">Archived</option>
          </>
        ) : (
          <>
            <option value="all">All</option>
            <option value="current">Current</option>
            <option value="deleted">Deleted</option>
          </>
        )}
      </select>
      <div
        style={{
          marginLeft: "4vw",
          marginTop: "40px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="tableHeaders"
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "1.5px solid black",
            height: "5.5vh",
          }}
        >
          <div
            style={{
              width: "41vw",
            }}
          >
            <p
              className="responsiveTableFont"
              style={{ paddingLeft: "20px", fontSize: "0.825vw" }}
            >
              Project
            </p>
          </div>
          <div
            style={{
              width: "13vw",
            }}
          >
            <p className="responsiveTableFont" style={{ fontSize: "0.825vw" }}>
              Last Modified
            </p>
          </div>
          <div
            style={{
              width: "13vw",
            }}
          >
            <p className="responsiveTableFont" style={{ fontSize: "0.825vw" }}>
              Date Created
            </p>
          </div>
          <div
            style={{
              width: "1vw",
            }}
          ></div>
        </div>
        <div
          style={{
            overflowY: "auto",
            maxHeight: "45vh",
          }}
        >
          {projectList
            .sort((a: any, b: any) => Number(a.modelId) - Number(b.modelId))
            ?.map((item: any, index: number) => (
              <div
                key={index}
                onDoubleClick={() => {
                  setActiveProject(item);
                  updateRecent(item.projectId);
                  navigate("/project");
                }}
                onMouseOver={() => {
                  setActiveProject(item);
                  setProjectHover(item.projectId);
                  // setProjectClicked(item.name);
                }}
                onMouseOut={() => {
                  // setStarColor(isFavorite ? style.colors.primaryBlue : style.colors.grey5);
                  if (!tableRowMenu) {
                    setTableRowMenu(false);
                    setActiveProject(null);
                    setProjectHover(false);
                  }
                }}
                className="tableRowData"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "5.5vh",
                  // backgroundColor: style.colors.primaryBlue
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "41vw",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="responsiveTableFont"
                    style={{ paddingLeft: "20px" }}
                  >
                    {item.name}
                  </p>
                  <i
                    onClick={() => handleFavoriteClick(item)}
                    className="fa-solid fa-star"
                    style={{
                      color: favorites.some(
                        (obj) => obj.projectId === item.projectId
                      )
                        ? projectHover === item.projectId
                          ? style.colors.textWhite
                          : style.colors.primaryBlue
                        : style.colors.grey5,
                      paddingLeft: "5px",
                      fontSize: "0.8vw",
                      marginTop: "-1px",
                      cursor: "pointer",
                      // zIndex: 9,
                    }}
                  ></i>
                </div>
                <div
                  style={{
                    width: "13vw",
                  }}
                >
                  <p className="responsiveTableFont">
                    {convertTimeNumeric(item.modifiedDate)}
                  </p>
                </div>
                <div
                  style={{
                    width: "14vw",
                    display: "flex",
                  }}
                >
                  <p className="responsiveTableFont">
                    {convertTimeNumeric(item.createdDate)}
                  </p>
                  <i
                    onClick={(event) => {
                      openMenu();
                      setActiveProject({
                        ...item,
                      });
                      setXPosition(event.clientX - 180);
                      setYPosition(event.clientY);
                      // setSearchText("");
                    }}
                    style={{
                      alignSelf: "center",
                      cursor: "pointer",
                      marginLeft: "auto",
                      marginRight: "20px"
                    }}
                    className="fa fa-ellipsis-v rowIcon"
                  ></i>
                </div>
                {tableRowMenu && projectHover === item.projectId ? (
                  <div
                    onMouseLeave={() => {
                      setTableRowMenu(false);
                      setActiveProject(null);
                    }}
                    style={{
                      position: "absolute",
                      backgroundColor: style.colors.white,
                      border: "1px solid grey",
                      left: xPosition,
                      top: yPosition,
                      zIndex: 3,
                    }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <li className="TableRowMenuItems" onClick={openProject}>
                        <span>Open Upon Startup</span>
                      </li>
                      <li
                        className="TableRowMenuItems"
                        onClick={editNameTitle}
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <span>Edit Name/Description</span>
                      </li>
                      <li
                        className="TableRowMenuItems"
                        onClick={handleReportClick}
                      >
                        <span>Reports</span>
                      </li>
                      <li className="TableRowMenuItems" onClick={saveAsNew}>
                        <span>Save As</span>
                      </li>
                      <li className="TableRowMenuItems">
                        <span>Export</span>
                      </li>
                      <li
                        className="TableRowMenuItems"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <span>Share</span>
                      </li>
                      <li
                        className="TableRowMenuItems"
                        // onClick={goDelete}
                      >
                        <span>Delete</span>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  activeProject: state.projects.activeProject,
  style: state.local.style,
  reportingList: state.projects.reportingList,
  user: state.user.user,
  favorites: state.projects.favorites,
});

export default connect(mapStateToProps, {
  setActiveProject,
  updateRecent,
  addFavorite,
  editProject,
  saveAs,
  deleteProject,
  addReportList,
  setProjectToReport,
  editProjectCanvas,
  removeFavorite,
})(Table) as any;
