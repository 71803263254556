import React, {
  useRef,
  useEffect,
  useState,
  Fragment,
  useCallback,
  memo,
} from "react";
import { connect } from "react-redux";
// import { Handle, Position } from "react-flow-renderer";
import { Handle, NodeProps, Position } from "reactflow";

import "./CustomNode.css";
import {
  BsTriangleFill,
  BsCircle,
  BsCircleHalf,
  BsCircleFill,
  BsFlagFill,
} from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import {
  setRightClickedItem,
  setClickedItem,
  setItemMenu,
  setRightClickMenu,
} from "../../../redux/actions/Projects";
import { deleteActivity } from "../../../redux/actions/Activities";
import { ReactComponent as RiskLevelLowImg } from "../../../lib/assets/img/RiskLevelLow.svg";
import { ReactComponent as RiskLevelMediumImg } from "../../../lib/assets/img/RiskLevelMedium.svg";
import { ReactComponent as RiskLevelHighImg } from "../../../lib/assets/img/RiskLevelHigh.svg";

const handleStyle = { left: 10 };

const CustomNode: React.FC<{
  data: any;
  setRightClickedItem: (righClicktItem: any) => void;
  activeProjectActivity: any;
  activities: any;
  deleteActivity: (pId: string, aId: string) => void;
  rightClickedItem: any;
  setClickedItem: (activeItem: any) => void;
  setItemMenu: (state: boolean) => void;
  setRightClickMenu: (x: any, y: any) => void;
  editCanvas: any;
  collabSourceData: any;
  collabTargetData: any;
  userSearch: any;
  activityFoundIndex: number;
  activityFoundView: boolean;
  style: any;
  navSearch: any;
}> = ({
  data,
  setRightClickedItem,
  activeProjectActivity,
  activities,
  deleteActivity,
  rightClickedItem,
  setClickedItem,
  setItemMenu,
  setRightClickMenu,
  editCanvas,
  collabSourceData,
  collabTargetData,
  userSearch,
  activityFoundIndex,
  activityFoundView,
  style,
  navSearch,
}) => {
  // const [points, setPoints] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const [activityData, setActivityData]: any = useState();
  const [hsiRiskColor, setHsiRiskColor] = useState("") as any;

  useEffect(() => {
    activities.map((item: any) => {
      if (item.activityId === data.activityId) {
        setActivityData(item);
      }
    });

    // console.log("data", data);
  }, [activities]);

  useEffect(() => {
    if (activityData?.hsiRisk.length > 0) {
      let colorLvl = 1;
      activityData?.hsiRisk.map((item: any) => {
        let currentLvl = 1;
        if (item.riskLvl === "High") {
          currentLvl = 3;
        } else if (item.riskLvl === "Medium") {
          currentLvl = 2;
        }

        if (currentLvl > colorLvl) {
          colorLvl = currentLvl;
        }
      });

      if (colorLvl === 3) {
        setHsiRiskColor("red");
      } else if (colorLvl === 2) {
        setHsiRiskColor("orange");
      } else {
        setHsiRiskColor("yellow");
      }
    }
  }, [activityData]);

  const highlightSummary = (
    searchString: string,
    content: string,
    highlightColor: any,
    isActivity: boolean
  ) => {
    const highlightedContent = content.replace(
      new RegExp(searchString, "gi"),
      (matchedString) =>
        isActivity
          ? `<span className="summaryText" style="background-color: ${highlightColor}; font-size: 10px">${matchedString}</span>`
          : `<span className="diamond-centered-text" style="background-color: ${highlightColor}; font-size: 10px">${matchedString}</span>`
    );

    return (
      <p
        className={isActivity ? "summaryText" : "diamond-centered-text"}
        dangerouslySetInnerHTML={{ __html: highlightedContent }}
      />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {data?.setrMilestones.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "162px",
            // marginLeft: "-50px",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <div className="diamond-shape"></div>
          {activityFoundView ? (
            userSearch.hasOwnProperty("activitiesFound") ? (
              userSearch.activitiesFound.some(
                (obj) =>
                  obj.activityIdD === data.activityIdD &&
                  obj.summary === data.summary &&
                  obj.itemFound === "summary"
              ) ? (
                highlightSummary(
                  navSearch,
                  activityData?.summary,
                  "yellow",
                  false
                )
              ) : (
                <p className="diamond-centered-text">{activityData?.summary}</p>
              )
            ) : (
              <p className="diamond-centered-text">{activityData?.summary}</p>
            )
          ) : (
            <p className="diamond-centered-text">{activityData?.summary}</p>
          )}
        </div>
      ) : (
        <div
          style={{
            border:
              activeProjectActivity?.activityId === data.activityId ||
              collabSourceData?.activityId === data.activityId ||
              collabTargetData?.activityId === data.activityId ||
              data?.domainName === "STM"
                ? "1px solid black"
                : "1px solid lightgray",
          }}
          className={
            data?.domainName === "STM"
              ? "text-updater-node-STM"
              : "text-updater-node"
          }
          onContextMenu={(e) => {
            e.preventDefault();
            // console.log("data", data);
            let activitySearch = activities.filter(
              (item: any) => item.activityId === data.activityId
            );
            setRightClickedItem(activitySearch[0]);
            // console.log(e.pageX);
            // console.log(e.pageY);
            setShow(true);
            if (editCanvas) {
              setRightClickMenu(e.pageX, e.pageY);
              if (activeProjectActivity === null) {
                setClickedItem(activityData);
              }
            }
            // setPoints({ x: e.pageX, y: e.pageY });
          }}
        >
          {/* {data?.phaseOrder === 1 ? null : (
          <Handle type="target" position={Position.Left} />
        )} */}
          {data?.position?.y === 68 || data?.position?.y === 168 ? null : (
            <Handle type="target" position={Position.Top} />
          )}
          <div>
            <div
              style={{
                display:
                  /* activityData?.domainName === "STM" ? "none" : */ "flex",
                flexDirection: "row",
              }}
            >
              {/* <label htmlFor="text">Risk:</label> */}
              {hsiRiskColor === "red" ? (
                <RiskLevelHighImg
                  title="High Risk"
                  height="12px"
                  width="12px"
                  fill="#C00000"
                  style={{ opacity: activityData?.hsiRisk?.length > 0 ? 1 : 0 }}
                />
              ) : hsiRiskColor === "orange" ? (
                <RiskLevelMediumImg
                  title="Medium Risk"
                  height="12px"
                  width="12px"
                  fill="#ED7D31"
                  style={{ opacity: activityData?.hsiRisk?.length > 0 ? 1 : 0 }}
                />
              ) : (
                <RiskLevelLowImg
                  title="Low Risk"
                  height="12px"
                  width="12px"
                  fill="#FFD966"
                  style={{ opacity: activityData?.hsiRisk?.length > 0 ? 1 : 0 }}
                />
              )}
              {/* <label htmlFor="text" >
            Status:
          </label> */}
              {activityData?.activityStatus === "None" ? (
                <BsFlagFill
                  fontSize={10}
                  style={{
                    color: "black",
                    marginLeft: "auto",
                  }}
                />
              ) : activityData?.activityStatus === "Not Started" ? (
                <BsCircle
                  fontSize={10}
                  style={{
                    color: "black",
                    marginLeft: "auto",
                  }}
                />
              ) : activityData?.activityStatus === "In Progress" ? (
                <BsCircleHalf
                  fontSize={10}
                  style={{
                    color: "black",
                    marginLeft: "auto",
                    transform: "rotate(180deg)",
                  }}
                />
              ) : (
                <BsCircleFill
                  fontSize={10}
                  style={{
                    color: "black",
                    marginLeft: "auto",
                  }}
                />
              )}
              {/* <BsCircle fontSize={8} style={{ marginLeft: "auto" }} /> */}
            </div>
            {activityFoundView ? (
              userSearch.hasOwnProperty("activitiesFound") ? (
                userSearch.activitiesFound.some(
                  (obj) =>
                    obj.activityIdD === data.activityIdD &&
                    obj.summary === data.summary &&
                    obj.itemFound === "summary"
                ) ? (
                  highlightSummary(
                    navSearch,
                    activityData?.summary,
                    "yellow",
                    true
                  )
                ) : (
                  <p className="summaryText">{activityData?.summary}</p>
                )
              ) : (
                <p className="summaryText">{activityData?.summary}</p>
              )
            ) : data?.domainName === "STM" ? (
              <p className="summaryText-STM">{activityData?.summary}</p>
            ) : (
              <p className="summaryText">{activityData?.summary}</p>
            )}

            <div style={{ display: "flex", flexDirection: "row" }}>
              <label htmlFor="text" style={{ fontSize: "10px" }}>
                {/* data?.domainName === "STM" ? "" : */ data.uniqueID}
              </label>
              {/* <label htmlFor="text" style={{ marginLeft: "auto" }}>
            SME:
          </label> */}
              {activityData?.smeInteraction ? (
                <FaUser fontSize={10} style={{ marginLeft: "auto" }} />
              ) : null}
            </div>
          </div>
          {data?.position?.y === 1725 || data?.domainName === "STM" ? null : (
            <Handle type="source" position={Position.Bottom} />
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  activeProjectActivity: state.projects.activeProjectActivity,
  activities: state.activities.activities,
  rightClickedItem: state.projects.rightClickedItem,
  editCanvas: state.projects.editCanvas,
  collabSourceData: state.projects.collabSourceData,
  collabTargetData: state.projects.collabTargetData,
  userSearch: state.data.userSearch,
  activityFoundIndex: state.data.activityFoundIndex,
  activityFoundView: state.data.activityFoundView,
  style: state.local.style,
  navSearch: state.data.navSearch,
});

export default connect(mapStateToProps, {
  setRightClickedItem,
  deleteActivity,
  setClickedItem,
  setItemMenu,
  setRightClickMenu,
})(CustomNode) as any;
