import {
  GET_USER_TYPE,
  GET_USER,
  LOGOUT,
  AUTH_LOADING,
} from "../actions/types";

const initialState = {
  user: null,
  user_type: "",
  auth_loading: false,
};

const userReducer = (state = initialState as any, action: any) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case GET_USER_TYPE:
      return {
        ...state,
        user_type: action.payload,
      };
    case AUTH_LOADING:
      return {
        ...state,
        auth_loading: action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
