import React, { useState } from "react";
import { connect } from "react-redux";
import "./newActivityModal.css";

const NewActivityModal: React.FC<{
  closeActivityM: any;
  activityM: any;
  style: any;
  projectPhase: any;
  projectDomain: any;
  activeProject: any;
  setNewDescription: any;
  setNewSummary: any;
  setNewPhase: any;
  setNewDomain: any;
  setNewOrder: any;
  setNewActivitySubmit: any;
  resetNewActvityForm: any;
  newDescription: any;
  newSummary: any;
}> = ({
  closeActivityM,
  activityM,
  style,
  projectPhase,
  projectDomain,
  activeProject,
  setNewDescription,
  setNewSummary,
  setNewPhase,
  setNewDomain,
  setNewOrder,
  setNewActivitySubmit,
  resetNewActvityForm,
  newDescription,
  newSummary,
}) => {
  var order = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
  ];

  const [phaseValue, setPhaseValue] = useState(0) as any;
  const [domainValue, setDomainValue] = useState(0) as any;
  const [orderValue, setOrderValue] = useState(0) as any;
  const [description, setDescription] = useState("") as any;
  const [summary, setSummary] = useState("") as any;

  const descriptionHandler = (event) => {
    const value = event.target.value;
    setNewDescription(value);
    setDescription(value);
  };

  const summaryHandler = (event) => {
    const value = event.target.value;
    setNewSummary(value);
    setSummary(value);
  };

  const phaseHandler = (event) => {
    const value = event.target.value;
    setPhaseValue(value);
    // setSelectedPhase(projectPhase[Number(value)]);
    setDomainValue(0);
    // domainSet(0);
    setNewPhase(event.target.options[Number(value)].label);
  };

  const domainHandler = (event) => {
    const value = event.target.value;
    setDomainValue(value);
    // domainSet(value);
    setNewDomain(event.target.options[Number(value)].label);
  };

  const orderHandler = (event) => {
    const value = event.target.value;
    setOrderValue(value);
    setNewOrder(Number(value));
  };

  const handleReset = () => {
    resetNewActvityForm();
    setPhaseValue(0);
    setDomainValue(0);
    setOrderValue(0);
    setDescription("");
    setSummary("");
  };
  const handleClose = () => {
    handleReset();
    closeActivityM();
  };

  return (
    <div style={{ visibility: activityM ? "initial" : "hidden" }}>
      <div className="modal-container">
        <section className="modal">
          <i
            className="fa fa-times"
            onClick={handleClose}
            style={{
              cursor: "pointer",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          ></i>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "70px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Title*:</p>
            </div>
            <input
              value={summary}
              onChange={summaryHandler}
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "-20px",
              }}
              placeholder="Title..."
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                // height: "70px",
              }}
            >
              <p style={{ paddingRight: "10px", marginTop: "-15px" }}>
                Description*:
              </p>
            </div>
            <textarea
              value={description}
              onChange={descriptionHandler}
              style={{
                width: "240px",
                height: "120px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "-20px",
                resize: "none",
                fontFamily: "Arial",
              }}
              placeholder="Description..."
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "70px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Phase*:</p>
            </div>
            <select
              value={phaseValue}
              onChange={phaseHandler}
              style={{
                width: "250px",
                height: "25px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "-20px",
              }}
            >
              <option hidden disabled selected value={0}>
                Select Phase
              </option>
              {projectPhase?.map((item: any, index: number) => (
                <option key={index} value={index + 1}>
                  {item.data.label}
                </option>
              ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "70px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Domain*:</p>
            </div>
            <select
              value={domainValue}
              onChange={domainHandler}
              style={{
                width: "250px",
                height: "25px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "-20px",
              }}
            >
              <option hidden disabled selected value={0}>
                Select Domain
              </option>
              {activeProject
                ? activeProject?.modelId === "3"
                  ? Number(phaseValue) === 1
                    ? projectDomain
                        .slice(3, 14)
                        ?.map((item: any, index: number) => (
                          <option key={index} value={index + 1}>
                            {index === 0 ? item.data.label : item.domainName}
                          </option>
                        ))
                    : projectDomain
                        .slice(15, 24)
                        ?.map((item: any, index: number) => (
                          <option key={index} value={index + 1}>
                            {item.data.label}
                          </option>
                        ))
                  : projectDomain
                      .slice(0, 10)
                      ?.map((item: any, index: number) => (
                        <option key={index} value={index + 1}>
                          {item.data.label}
                        </option>
                      ))
                : null}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "70px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Order*:</p>
            </div>
            <select
              value={orderValue}
              onChange={orderHandler}
              style={{
                width: "250px",
                height: "25px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "-20px",
              }}
            >
              <option hidden disabled selected value={0}>
                Select Order
              </option>
              {order?.map((item: any, index: number) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 20,
              marginTop: "10px",
            }}
          >
            <button
              onClick={handleClose}
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setNewActivitySubmit(true);
                closeActivityM();
                setPhaseValue(0);
                setDomainValue(0);
                setOrderValue(0);
                setDescription("");
                setSummary("");
              }}
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Save
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
});

export default connect(mapStateToProps, {})(NewActivityModal) as any;
