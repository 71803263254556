import axios from "axios";
import config from "../../config.json";
import {
  GET_ACTIVITIES,
  GET_COLLABORATIONS,
  ADD_COLLABORATION,
  DELETE_COLLABORATION,
  NEW_ACTIVITIES,
  DELETE_ACTIVITY,
  SET_PROJECT_ACTIVITY_STATUS,
  SET_PROJECT_ACTIVITY_DUE_DATE,
  EDIT_ACTIVITY_TITLE,
  EDIT_ACTIVITY_DESCRIPTION,
  EDIT_ACTIVITY_SME,
  EDIT_ACTIVITY_TRADEOFFS,
  EDIT_ACTIVITY_COMMENTS,
  EDIT_ACTIVITY_ROI,
  EDIT_ACTIVITY_CONTDEL,
  EDIT_ACTIVITY_CHALLENGES,
  EDIT_ACTIVITY_RECOMMENDATIONS,
  EDIT_ACTIVITY_OTHER,
  EDIT_ACTIVITY_HSIRISKS,
  DELETE_COLLABORATION_OPTION,
  EDIT_ACTIVITY_PRODUCT_INPUTS_LIST,
  EDIT_ACTIVITY_PRODUCT_OUTPUTS_LIST,
  EDIT_ACTIVITY_REFERENCE_LIST,
  EDIT_ACTIVITY_DIDS_LIST,
  EDIT_ACTIVITY_TOOLS_LIST,
  UPDATE_COLLAB_LINE_TYPE,
  FIX_ACTIVITY_LIBRARY_LISTS,
  CLEAR_ACTIVITIES,
  GET_DATA_LOADING,
} from "./types";
import store from "../index";

const addCollaboration =
  (projectId: string, collaboration: any) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/add_collaboration`;
      const body = { projectId: projectId, collaboration: collaboration };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: ADD_COLLABORATION,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const deleteCollaboration = (sourceId: string) => async (dispatch: any) => {
  try {
    const url = `${config.api}activities/delete_collaboration`;
    const body = { sourceId: sourceId };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: DELETE_COLLABORATION,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const deleteCollaborationOption =
  (projectId: string, activityId: string) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/delete_collaboration_option`;
      const body = { projectId: projectId, activityId: activityId };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: DELETE_COLLABORATION_OPTION,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const getActivities = (projectId: string) => async (dispatch: any) => {
  try {
    const url = `${config.api}activities/get_activities`;
    const body = { projectId: projectId };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_ACTIVITIES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const newActivities =
  (
    projectId: string,
    description: string,
    summary: string,
    phase: string,
    domain: string,
    order: string,
    modelId: string
  ) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}activities/new_activities`;
      const body = {
        projectId: projectId,
        description: description,
        summary: summary,
        phase: phase,
        domain: domain,
        order: order,
        modelId: modelId,
      };

      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: GET_DATA_LOADING,
        payload: null,
      });

      dispatch({
        type: NEW_ACTIVITIES,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const deleteActivity =
  (projectId: string, activityId: string) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/delete_activity`;
      const body = { projectId: projectId, activityId: activityId };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: DELETE_ACTIVITY,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const getCollaborations = (projectId: string) => async (dispatch: any) => {
  try {
    const url = `${config.api}activities/get_collaborations`;
    const body = { projectId: projectId };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: GET_COLLABORATIONS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const setProjectActivityStatus =
  (activityId: string, status: string) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/set_project_activity_status`;
      const body = { activityId: activityId, status: status };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("res.data", res.data);
      dispatch({
        type: SET_PROJECT_ACTIVITY_STATUS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const setProjectActivityDueDate =
  (activityId: string, date: any) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/set_project_activity_due_date`;
      const body = { activityId: activityId, date: date };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("res.data", res.data);
      dispatch({
        type: SET_PROJECT_ACTIVITY_DUE_DATE,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityTitle =
  (activityId: string, projectId: string, text: string) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_title`;
      const body = { activityId: activityId, projectId: projectId, text: text };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_TITLE,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityDescription =
  (activityId: string, projectId: string, text: string) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_description`;
      const body = { activityId: activityId, projectId: projectId, text: text };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_DESCRIPTION,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivitySMEInteraction =
  (activityId: string, projectId: string, interaction: boolean) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_smeInteraction`;
      const body = {
        activityId: activityId,
        projectId: projectId,
        interaction: interaction,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_SME,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityTradeOffs =
  (activityId: string, projectId: string, text: string) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_tradeoffs`;
      const body = { activityId: activityId, projectId: projectId, text: text };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_TRADEOFFS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityComments =
  (activityId: string, text: string) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_comments`;
      const body = { activityId: activityId, text: text };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_COMMENTS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityROI =
  (activityId: string, text: string) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_roi`;
      const body = { activityId: activityId, text: text };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_ROI,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityContDel =
  (activityId: string, projectId: string, text: string) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_contdel`;
      const body = { activityId: activityId, projectId: projectId, text: text };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_CONTDEL,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityChallenges =
  (activityId: string, projectId: string, text: string) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_challenges`;
      const body = { activityId: activityId, projectId: projectId, text: text };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_CHALLENGES,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityRecommendations =
  (activityId: string, projectId: string, text: string) =>
  async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_recommendations`;
      const body = { activityId: activityId, projectId: projectId, text: text };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_RECOMMENDATIONS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityOther =
  (activityId: string, text: string) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_other`;
      const body = { activityId: activityId, text: text };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_OTHER,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityHSIRisks =
  (activityId: string, risks: any) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_hsirisks`;
      const body = { activityId: activityId, risks: risks };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_HSIRISKS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityProductInputsList =
  (activityId: string, list: any) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_product_inputs_list`;
      const body = { activityId: activityId, list: list };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_PRODUCT_INPUTS_LIST,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityProductOutputsList =
  (activityId: string, list: any) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_product_outputs_list`;
      const body = { activityId: activityId, list: list };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_PRODUCT_OUTPUTS_LIST,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityReferenceList =
  (activityId: string, list: any) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_reference_list`;
      const body = { activityId: activityId, list: list };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_REFERENCE_LIST,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityDIDSList =
  (activityId: string, list: any) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_dids_list`;
      const body = { activityId: activityId, list: list };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_DIDS_LIST,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const editActivityToolsList =
  (activityId: string, list: any) => async (dispatch: any) => {
    try {
      const url = `${config.api}activities/edit_activity_tools_list`;
      const body = { activityId: activityId, list: list };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: EDIT_ACTIVITY_TOOLS_LIST,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const updateCollabLineType =
  (collabSourceData: any, collabTargetData: any) => async (dispatch: any) => {
    try {
      const url = `${config.api}projects/update_collab_line_data`;
      const body = {
        collabSourceData: collabSourceData,
        collabTargetData: collabTargetData,
      };
      const res = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: UPDATE_COLLAB_LINE_TYPE,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

const fixActivityLibraryLists = (activity: any) => async (dispatch: any) => {
  try {
    const url = `${config.api}activities/fix_activity_library_lists`;
    const body = { activity: activity };
    const res = await axios.post(url, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: FIX_ACTIVITY_LIBRARY_LISTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const clearActivities = () => async (dispatch: any) => {
  try {
    dispatch({
      type: CLEAR_ACTIVITIES,
      payload: null,
    });
  } catch (error) {
    console.log(error);
  }
};

export {
  getActivities,
  getCollaborations,
  addCollaboration,
  deleteCollaboration,
  newActivities,
  deleteActivity,
  setProjectActivityStatus,
  setProjectActivityDueDate,
  editActivityTitle,
  editActivityDescription,
  editActivitySMEInteraction,
  editActivityTradeOffs,
  editActivityComments,
  editActivityROI,
  editActivityContDel,
  editActivityChallenges,
  editActivityRecommendations,
  editActivityOther,
  editActivityHSIRisks,
  deleteCollaborationOption,
  editActivityProductInputsList,
  editActivityProductOutputsList,
  editActivityReferenceList,
  editActivityDIDSList,
  editActivityToolsList,
  updateCollabLineType,
  fixActivityLibraryLists,
  clearActivities,
};
