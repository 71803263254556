const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const convertTimeNumeric = (timeString: Date) => {
  try {
    return new Date(timeString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  } catch (error) {
    console.log(error);
  }
};

const convertTimeString = (timeString: Date) => {
  try {
    let numDate = new Date(timeString).getDate().toString();
    const month = months[new Date(timeString).getMonth()];
    const year = new Date(timeString).getFullYear().toString();

    if (Number(numDate) < 10) {
      numDate = "0" + numDate;
    }

    const timeConvert = (month + " " + numDate + " " + year).toString();

    return timeConvert;
  } catch (error) {
    console.log(error);
  }
};

export { convertTimeNumeric, convertTimeString };
