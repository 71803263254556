import React, {
  useRef,
  useEffect,
  useState,
  Fragment,
  useCallback,
  useMemo,
} from "react";
import ReactFlow, {
  addEdge,
  FitViewOptions,
  applyNodeChanges,
  applyEdgeChanges,
  Node,
  Edge,
  Background,
  NodeChange,
  EdgeChange,
  Connection,
  MiniMap,
  useNodesState,
  useEdgesState,
  updateEdge,
  ReactFlowProvider,
  Controls,
  useReactFlow,
} from "reactflow";
import { connect } from "react-redux";
import "reactflow/dist/style.css";
import "./OverviewFlow.css";

import CustomNodecopy from "./CustomNodecopy";

import {
  addCollaboration,
  deleteCollaboration,
  getActivities,
} from "../../../redux/actions/Activities";
import {
  setClickedItem,
  setRightClickMenu,
  setRightClickedItem,
  clearDataLists,
  setCollabSource,
  setCollabTarget,
  setActivityChangeState,
  handlePanPosition,
} from "../../../redux/actions/Projects";
import { delay } from "../../../lib/helpers/time";
import CollabModal from "../../../components/collabModal/collabModal";
import SaveChangesModal from "../../../components/saveChangesModal/saveChangesModal";

const edgeOptions = {
  animated: false,
  style: {
    stroke: "black",
    innerWidth: "20px",
    outerWidth: "20px",
  },
};

const connectionLineStyle = { stroke: "black" };

const nodeTypes = {
  custom: CustomNodecopy,
};

const OverviewFlow: React.FC<{
  setSideMenu: any;
  sideMenu: any;
  setItemMenu: any;
  activities: any;
  collaborations: any;
  activeProject: any;
  addCollaboration: (projectId: string, collaboration: any) => void;
  setClickedItem: (activeItem: any) => void;
  projectPhase: any;
  projectDomain: any;
  projectRows: any;
  defaultData: any;
  deleteCollaboration: (sourceId: string) => void;
  activeProjectActivity: any;
  itemMenu: any;
  setRightClickMenu: (x: any, y: any) => void;
  setRightClickedItem: (righClicktItem: any) => void;
  clearDataLists: () => void;
  collabSourceData: any;
  collabTargetData: any;
  collabSelection: boolean;
  collabType: string;
  setCollabSource: (collab: any) => void;
  setCollabTarget: (collab: any) => void;
  openCollabM: any;
  collabMenu: any;
  closeCollabM: any;
  activeProjectActivityChanges: any;
  openProductM: any;
  openReferenceM: any;
  openDidsM: any;
  openToolsM: any;
  editCanvas: any;
  setActivityChangeState: (state: boolean) => void;
  handlePanPosition: (positioning: any) => void;
  panPosition: any;
  getActivities: (projectId: string) => void;
  loadingActivities: any;
}> = ({
  setSideMenu,
  sideMenu,
  setItemMenu,
  activities,
  collaborations,
  activeProject,
  addCollaboration,
  setClickedItem,
  projectPhase,
  projectDomain,
  projectRows,
  defaultData,
  deleteCollaboration,
  activeProjectActivity,
  itemMenu,
  setRightClickMenu,
  setRightClickedItem,
  clearDataLists,
  collabSourceData,
  collabTargetData,
  collabSelection,
  collabType,
  setCollabSource,
  setCollabTarget,
  openCollabM,
  collabMenu,
  closeCollabM,
  activeProjectActivityChanges,
  openProductM,
  openReferenceM,
  openDidsM,
  openToolsM,
  editCanvas,
  setActivityChangeState,
  handlePanPosition,
  panPosition,
  getActivities,
  loadingActivities,
}) => {
  const [nodes, setNodes] = useState<Node[]>([
    ...projectPhase,
    ...projectDomain,
    ...projectRows,
  ]);

  // useEffect(() => {
  //   if (activities.length && collaborations.length) {
  //     collaborations.map((collab: any) => {
  //       const found = activities.find(
  //         (act: any) => act.activityId === collab.source
  //       );
  //       console.log("found");
  //     });
  //   }
  // }, [activities, collaborations]);

  const edgeUpdateSuccessful = useRef(true);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [hidden, setHidden] = useState(false);
  const [hidden2, setHidden2] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [clickedDomain, setClickedDomain]: any = useState(null);
  const [initialNodes, setInitialNodes]: any = useState([]);
  const [changesModal, setChangesModal] = useState(false) as any;
  const { setViewport, setCenter } = useReactFlow();
  const [itemPosition, setItemPosition] = useState({ x: 0, y: 0 });
  let screenH = window.innerHeight;
  let screenW = window.innerWidth;

  // const handleTransform = useCallback(() => {
  //   if (activeProjectActivity) {
  //     if (activeProjectActivity?.hasOwnProperty("position")) {
  //       console.log(activeProjectActivity);
  //       console.log(activeProjectActivity.position);

  //       setViewport(
  //         {
  //           x: activeProjectActivity.position.x,
  //           y: activeProjectActivity.position.y,
  //           zoom: 1,
  //         },
  //         { duration: 800 }
  //       );
  //     }
  //   } else {
  //     console.log(panPosition);
  //     setViewport(
  //       {
  //         x: panPosition?.x,
  //         y: panPosition?.y,
  //         zoom: 1,
  //       },
  //       { duration: 800 }
  //     );
  //   }
  // }, [setViewport]);

  // useEffect(() => {
  //   if (panPosition.x !== 0) handleTransform();
  // }, [panPosition]);

  // let idCheckList: any = [];

  // Original node creation
  // useEffect(() => {
  //   if (activities.length) {
  //     setNodes([...phases, ...domains, ...rows, ...activities]);
  //   }
  // }, [activities.length]);

  // useEffect(() => {
  //   let x = 0;
  //   activities.map((item) => {
  //     if (item.projectId === activeProject.projectId) {
  //       x++;
  //     }
  //   });
  //   console.log("act count", x);
  // }, []);

  // const nodeTypes = useMemo(
  //   () => ({
  //     CustomNode: CustomNode,
  //   }),
  //   []
  // );

  useEffect(() => {
    if (clickedDomain !== null) {
      collapsingItems();
    }
  }, [clickedDomain]);

  //Custom node creation
  useEffect(() => {
    if (loadingActivities) getActivities(activeProject?.projectId);
    // console.log("overflow");
    if (activities?.length) {
      activities?.map((item: any) => {
        const nodeData = {
          ...item,
          style: {
            ...item.style,
            // borderRadius: "0px",
            // border: "1px solid lightgray",
            // boxShadow: "0 4px 8px 0 lightgray",
          },
          // type: "custom",
        };
        initialNodes.push(nodeData);
      });
      setNodes([
        ...projectPhase,
        ...projectDomain,
        ...projectRows,
        ...initialNodes,
      ]);
    }
    setInitialNodes([]);
  }, [activities, activities?.length, loadingActivities]);

  useEffect(() => {
    console.log("collab");
    if (collaborations?.length) {
      setEdges([...collaborations]);
    }
  }, [collaborations, collaborations?.length]);

  // useEffect(() => {
  //   window.onpopstate = function () {
  //     if (editCanvas) {
  //       window.history.go(1);
  //       // setChangesModal(true);
  //     } else {
  //       window.history.go(-1);
  //     }
  //   };
  // }, [editCanvas])

  const hide = (hidden: boolean, node: any) => (nodeOrEdge) => {
    if (
      nodeOrEdge.phaseName === node.phaseName &&
      nodeOrEdge.domainName === node.domainName &&
      !nodeOrEdge.className
    ) {
      nodeOrEdge.hidden = hidden;
    }

    if (nodeOrEdge?.type === "smoothstep") {
      let sourceItem = nodes.find(
        (activity: any) => nodeOrEdge?.source === activity.activityId
      );

      // console.log("sourceItem", sourceItem);

      let targetItem = nodes.find(
        (activity: any) => nodeOrEdge?.target === activity.activityId
      );

      // console.log("targetItem", targetItem);

      if (sourceItem?.hidden || targetItem?.hidden) {
        nodeOrEdge.hidden = true;
      } else {
        nodeOrEdge.hidden = false;
      }
    }

    if (hidden === true && nodeOrEdge.id === node.className) {
      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height: 60,
      };
    } else if (hidden === false && nodeOrEdge.id === node.className) {
      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height:
          activeProject.modelId === "3" && nodeOrEdge.id.includes("A")
            ? 193
            : activeProject.modelId === "3" &&
              (nodeOrEdge.id.includes("B") ||
                nodeOrEdge.id.includes("C") ||
                nodeOrEdge.id.includes("D") ||
                nodeOrEdge.id.includes("E") ||
                nodeOrEdge.id.includes("F") ||
                nodeOrEdge.id.includes("G") ||
                nodeOrEdge.id.includes("H") ||
                nodeOrEdge.id.includes("I"))
            ? 183
            : (activeProject.modelId !== "3" && nodeOrEdge.id.includes("A")) ||
              nodeOrEdge.id.includes("J")
            ? 193
            : activeProject.modelId !== "3" &&
              (nodeOrEdge.id.includes("B") ||
                nodeOrEdge.id.includes("C") ||
                nodeOrEdge.id.includes("D") ||
                nodeOrEdge.id.includes("E") ||
                nodeOrEdge.id.includes("F") ||
                nodeOrEdge.id.includes("G") ||
                nodeOrEdge.id.includes("H") ||
                nodeOrEdge.id.includes("I"))
            ? 183
            : 193,
      };
    }

    return nodeOrEdge;
  };

  const hide2 = (hidden: boolean, node: any) => (nodeOrEdge) => {
    if (
      node.id === "group1" &&
      nodeOrEdge.phaseName === node.phaseName &&
      (nodeOrEdge.domainName === "DP-Prep" ||
        nodeOrEdge.domainName === "DP-CP&A" ||
        nodeOrEdge.domainName === "DP-CD") &&
      !nodeOrEdge.className
    ) {
      nodeOrEdge.hidden = hidden;
    } else if (
      node.id === "group2" &&
      nodeOrEdge.phaseName === node.phaseName &&
      (nodeOrEdge.domainName === "ST-Plan" ||
        nodeOrEdge.domainName === "ST-Conduct" ||
        nodeOrEdge.domainName === "ST-Document") &&
      !nodeOrEdge.className
    ) {
      nodeOrEdge.hidden = hidden;
    } else if (
      node.id === "group3" &&
      nodeOrEdge.phaseName === node.phaseName &&
      (nodeOrEdge.domainName === "MS-Plan" ||
        nodeOrEdge.domainName === "MS-Conduct" ||
        nodeOrEdge.domainName === "MS-Document" ||
        nodeOrEdge.domainName === "MS-CD") &&
      !nodeOrEdge.className
    ) {
      nodeOrEdge.hidden = hidden;
    }

    if (nodeOrEdge?.type === "smoothstep") {
      let sourceItem = nodes.find(
        (activity: any) => nodeOrEdge?.source === activity.activityId
      );

      // console.log("sourceItem", sourceItem);

      let targetItem = nodes.find(
        (activity: any) => nodeOrEdge?.target === activity.activityId
      );

      // console.log("targetItem", targetItem);

      if (sourceItem?.hidden || targetItem?.hidden) {
        nodeOrEdge.hidden = true;
      } else {
        nodeOrEdge.hidden = false;
      }
    }

    if (
      hidden === true &&
      node.id === "group1" &&
      (nodeOrEdge.rowID === "A1" ||
        nodeOrEdge.rowID === "B1" ||
        nodeOrEdge.rowID === "C1")
    ) {
      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height: 60,
      };
    } else if (
      hidden === false &&
      node.id === "group1" &&
      (nodeOrEdge.rowID === "A1" ||
        nodeOrEdge.rowID === "B1" ||
        nodeOrEdge.rowID === "C1")
    ) {
      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height:
          activeProject.modelId === "3" && nodeOrEdge.id.includes("A")
            ? 193
            : activeProject.modelId === "3" &&
              (nodeOrEdge.id.includes("B") ||
                nodeOrEdge.id.includes("C") ||
                nodeOrEdge.id.includes("D") ||
                nodeOrEdge.id.includes("E") ||
                nodeOrEdge.id.includes("F") ||
                nodeOrEdge.id.includes("G") ||
                nodeOrEdge.id.includes("H") ||
                nodeOrEdge.id.includes("I"))
            ? 183
            : (activeProject.modelId !== "3" && nodeOrEdge.id.includes("A")) ||
              nodeOrEdge.id.includes("J")
            ? 193
            : activeProject.modelId !== "3" &&
              (nodeOrEdge.id.includes("B") ||
                nodeOrEdge.id.includes("C") ||
                nodeOrEdge.id.includes("D") ||
                nodeOrEdge.id.includes("E") ||
                nodeOrEdge.id.includes("F") ||
                nodeOrEdge.id.includes("G") ||
                nodeOrEdge.id.includes("H") ||
                nodeOrEdge.id.includes("I"))
            ? 183
            : 193,
      };
    }

    if (
      hidden === true &&
      node.id === "group2" &&
      (nodeOrEdge.rowID === "D1" ||
        nodeOrEdge.rowID === "E1" ||
        nodeOrEdge.rowID === "F1")
    ) {
      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height: 60,
      };
    } else if (
      hidden === false &&
      node.id === "group2" &&
      (nodeOrEdge.rowID === "D1" ||
        nodeOrEdge.rowID === "E1" ||
        nodeOrEdge.rowID === "F1")
    ) {
      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height:
          activeProject.modelId === "3" && nodeOrEdge.id.includes("A")
            ? 193
            : activeProject.modelId === "3" &&
              (nodeOrEdge.id.includes("B") ||
                nodeOrEdge.id.includes("C") ||
                nodeOrEdge.id.includes("D") ||
                nodeOrEdge.id.includes("E") ||
                nodeOrEdge.id.includes("F") ||
                nodeOrEdge.id.includes("G") ||
                nodeOrEdge.id.includes("H") ||
                nodeOrEdge.id.includes("I"))
            ? 183
            : (activeProject.modelId !== "3" && nodeOrEdge.id.includes("A")) ||
              nodeOrEdge.id.includes("J")
            ? 193
            : activeProject.modelId !== "3" &&
              (nodeOrEdge.id.includes("B") ||
                nodeOrEdge.id.includes("C") ||
                nodeOrEdge.id.includes("D") ||
                nodeOrEdge.id.includes("E") ||
                nodeOrEdge.id.includes("F") ||
                nodeOrEdge.id.includes("G") ||
                nodeOrEdge.id.includes("H") ||
                nodeOrEdge.id.includes("I"))
            ? 183
            : 193,
      };
    }

    if (
      hidden === true &&
      node.id === "group3" &&
      (nodeOrEdge.rowID === "G1" ||
        nodeOrEdge.rowID === "H1" ||
        nodeOrEdge.rowID === "I1" ||
        nodeOrEdge.rowID === "J1")
    ) {
      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height: 60,
      };
    } else if (
      hidden === false &&
      node.id === "group3" &&
      (nodeOrEdge.rowID === "G1" ||
        nodeOrEdge.rowID === "H1" ||
        nodeOrEdge.rowID === "I1" ||
        nodeOrEdge.rowID === "J1")
    ) {
      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height:
          activeProject.modelId === "3" && nodeOrEdge.id.includes("A")
            ? 193
            : activeProject.modelId === "3" &&
              (nodeOrEdge.id.includes("B") ||
                nodeOrEdge.id.includes("C") ||
                nodeOrEdge.id.includes("D") ||
                nodeOrEdge.id.includes("E") ||
                nodeOrEdge.id.includes("F") ||
                nodeOrEdge.id.includes("G") ||
                nodeOrEdge.id.includes("H") ||
                nodeOrEdge.id.includes("I"))
            ? 183
            : (activeProject.modelId !== "3" && nodeOrEdge.id.includes("A")) ||
              nodeOrEdge.id.includes("J")
            ? 193
            : activeProject.modelId !== "3" &&
              (nodeOrEdge.id.includes("B") ||
                nodeOrEdge.id.includes("C") ||
                nodeOrEdge.id.includes("D") ||
                nodeOrEdge.id.includes("E") ||
                nodeOrEdge.id.includes("F") ||
                nodeOrEdge.id.includes("G") ||
                nodeOrEdge.id.includes("H") ||
                nodeOrEdge.id.includes("I"))
            ? 183
            : 193,
      };
    }

    return nodeOrEdge;
  };

  const handleCollapsing = () => (nodeOrEdge) => {
    let domainNum;
    if (clickedDomain.rowID) {
      domainNum = clickedDomain.rowID.charAt(1);
    } else {
      domainNum = "1";
    }

    let rowA = "A" + domainNum;
    let rowB = "B" + domainNum;
    let rowC = "C" + domainNum;
    let rowD = "D" + domainNum;
    let rowE = "E" + domainNum;
    let rowF = "F" + domainNum;
    let rowG = "G" + domainNum;
    let rowH = "H" + domainNum;
    let rowI = "I" + domainNum;
    let rowJ = "J" + domainNum;
    let domainGID;
    if (clickedDomain.id.includes("group")) {
      domainGID = clickedDomain.id;
    }

    var heightA = nodes.find((x: any) => x.rowID === rowA);
    var heightB = nodes.find((x: any) => x.rowID === rowB);
    var heightC = nodes.find((x: any) => x.rowID === rowC);
    var heightD = nodes.find((x: any) => x.rowID === rowD);
    var heightE = nodes.find((x: any) => x.rowID === rowE);
    var heightF = nodes.find((x: any) => x.rowID === rowF);
    var heightG = nodes.find((x: any) => x.rowID === rowG);
    var heightH = nodes.find((x: any) => x.rowID === rowH);
    var heightI = nodes.find((x: any) => x.rowID === rowI);
    var heightJ = nodes.find((x: any) => x.rowID === rowJ);
    var heightG1 = nodes.find((x: any) => x.id === "group1");
    var heightG2 = nodes.find((x: any) => x.id === "group2");
    var heightG3 = nodes.find((x: any) => x.id === "group3");

    if (nodeOrEdge.type === "group" && nodeOrEdge.id === rowA) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y: 164 + Number(heightA?.style?.height),
      };
    }

    if (nodeOrEdge.rowID === rowB) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y: 165 + Number(heightA?.style?.height),
      };
    }

    if (nodeOrEdge.type === "group" && nodeOrEdge.id === rowB) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          164 + Number(heightA?.style?.height) + Number(heightB?.style?.height),
      };
    }

    if (nodeOrEdge.rowID === rowC) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          165 + Number(heightA?.style?.height) + Number(heightB?.style?.height),
      };
    }

    if (nodeOrEdge.type === "group" && nodeOrEdge.id === rowC) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          164 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height),
      };
    }

    if (nodeOrEdge.rowID === rowD) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          165 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height),
      };
    }

    if (nodeOrEdge.type === "group" && nodeOrEdge.id === rowD) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          164 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height),
      };
    }

    if (nodeOrEdge.rowID === rowE) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          165 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height),
      };
    }

    if (nodeOrEdge.type === "group" && nodeOrEdge.id === rowE) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          164 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height),
      };
    }

    if (nodeOrEdge.rowID === rowF) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          165 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height),
      };
    }

    if (nodeOrEdge.type === "group" && nodeOrEdge.id === rowF) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          164 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height) +
          Number(heightF?.style?.height),
      };
    }

    if (nodeOrEdge.rowID === rowG) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          165 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height) +
          Number(heightF?.style?.height),
      };
    }

    if (nodeOrEdge.type === "group" && nodeOrEdge.id === rowG) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          164 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height) +
          Number(heightF?.style?.height) +
          Number(heightG?.style?.height),
      };
    }

    if (nodeOrEdge.rowID === rowH) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          165 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height) +
          Number(heightF?.style?.height) +
          Number(heightG?.style?.height),
      };
    }

    if (nodeOrEdge.type === "group" && nodeOrEdge.id === rowH) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          164 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height) +
          Number(heightF?.style?.height) +
          Number(heightG?.style?.height) +
          Number(heightH?.style?.height),
      };
    }

    if (nodeOrEdge.rowID === rowI) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          165 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height) +
          Number(heightF?.style?.height) +
          Number(heightG?.style?.height) +
          Number(heightH?.style?.height),
      };
    }

    if (nodeOrEdge.type === "group" && nodeOrEdge.id === rowI) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          164 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height) +
          Number(heightF?.style?.height) +
          Number(heightG?.style?.height) +
          Number(heightH?.style?.height) +
          Number(heightI?.style?.height),
      };
    }

    if (nodeOrEdge.rowID === rowJ) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          165 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height) +
          Number(heightF?.style?.height) +
          Number(heightG?.style?.height) +
          Number(heightH?.style?.height) +
          Number(heightI?.style?.height),
      };
    }

    if (nodeOrEdge.type === "group" && nodeOrEdge.id === rowJ) {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          164 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height) +
          Number(heightF?.style?.height) +
          Number(heightG?.style?.height) +
          Number(heightH?.style?.height) +
          Number(heightI?.style?.height) +
          Number(heightJ?.style?.height),
      };

      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height:
          1913 -
          Number(heightA?.style?.height) -
          Number(heightB?.style?.height) -
          Number(heightC?.style?.height) -
          Number(heightD?.style?.height) -
          Number(heightE?.style?.height) -
          Number(heightF?.style?.height) -
          Number(heightG?.style?.height) -
          Number(heightH?.style?.height) -
          Number(heightI?.style?.height) -
          Number(heightJ?.style?.height),
      };
    }

    if (domainNum === "1" && nodeOrEdge.id === "group1") {
      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height:
          100 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height),
      };
    }

    if (domainNum === "1" && nodeOrEdge.id === "group2") {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          165 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height),
      };

      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height:
          100 +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height) +
          Number(heightF?.style?.height),
      };
    }

    if (domainNum === "1" && nodeOrEdge.id === "group3") {
      nodeOrEdge.position = {
        ...nodeOrEdge.position,
        y:
          165 +
          Number(heightA?.style?.height) +
          Number(heightB?.style?.height) +
          Number(heightC?.style?.height) +
          Number(heightD?.style?.height) +
          Number(heightE?.style?.height) +
          Number(heightF?.style?.height),
      };

      nodeOrEdge.style = {
        ...nodeOrEdge.style,
        height:
          100 +
          Number(heightG?.style?.height) +
          Number(heightH?.style?.height) +
          Number(heightI?.style?.height) +
          Number(heightJ?.style?.height),
      };
    }

    if (nodeOrEdge.hasOwnProperty("activityId")) {
      // if (activeProject.modelId !== "3") {
      if (
        nodeOrEdge.domainName === "HFE" ||
        nodeOrEdge.domainName === "DP-CP&A"
      ) {
        nodeOrEdge.position = {
          ...nodeOrEdge.position,
          y: 170 + Number(heightA?.style?.height),
        };
      } else if (
        nodeOrEdge.domainName === "MAN" ||
        nodeOrEdge.domainName === "DP-CD"
      ) {
        nodeOrEdge.position = {
          ...nodeOrEdge.position,
          y:
            170 +
            Number(heightA?.style?.height) +
            Number(heightB?.style?.height),
        };
      } else if (
        nodeOrEdge.domainName === "PER" ||
        nodeOrEdge.domainName === "ST-Plan"
      ) {
        nodeOrEdge.position = {
          ...nodeOrEdge.position,
          y:
            170 +
            Number(heightA?.style?.height) +
            Number(heightB?.style?.height) +
            Number(heightC?.style?.height),
        };
      } else if (
        nodeOrEdge.domainName === "TRA" ||
        nodeOrEdge.domainName === "ST-Conduct"
      ) {
        nodeOrEdge.position = {
          ...nodeOrEdge.position,
          y:
            170 +
            Number(heightA?.style?.height) +
            Number(heightB?.style?.height) +
            Number(heightC?.style?.height) +
            Number(heightD?.style?.height),
        };
      } else if (
        nodeOrEdge.domainName === "SAF" ||
        nodeOrEdge.domainName === "ST-Document"
      ) {
        nodeOrEdge.position = {
          ...nodeOrEdge.position,
          y:
            170 +
            Number(heightA?.style?.height) +
            Number(heightB?.style?.height) +
            Number(heightC?.style?.height) +
            Number(heightD?.style?.height) +
            Number(heightE?.style?.height),
        };
      } else if (
        nodeOrEdge.domainName === "OCC" ||
        nodeOrEdge.domainName === "MS-Plan"
      ) {
        nodeOrEdge.position = {
          ...nodeOrEdge.position,
          y:
            170 +
            Number(heightA?.style?.height) +
            Number(heightB?.style?.height) +
            Number(heightC?.style?.height) +
            Number(heightD?.style?.height) +
            Number(heightE?.style?.height) +
            Number(heightF?.style?.height),
        };
      } else if (
        nodeOrEdge.domainName === "SUR" ||
        nodeOrEdge.domainName === "MS-Conduct"
      ) {
        nodeOrEdge.position = {
          ...nodeOrEdge.position,
          y:
            170 +
            Number(heightA?.style?.height) +
            Number(heightB?.style?.height) +
            Number(heightC?.style?.height) +
            Number(heightD?.style?.height) +
            Number(heightE?.style?.height) +
            Number(heightF?.style?.height) +
            Number(heightG?.style?.height),
        };
      } else if (
        nodeOrEdge.domainName === "HAB" ||
        nodeOrEdge.domainName === "MS-Document"
      ) {
        nodeOrEdge.position = {
          ...nodeOrEdge.position,
          y:
            170 +
            Number(heightA?.style?.height) +
            Number(heightB?.style?.height) +
            Number(heightC?.style?.height) +
            Number(heightD?.style?.height) +
            Number(heightE?.style?.height) +
            Number(heightF?.style?.height) +
            Number(heightG?.style?.height) +
            Number(heightH?.style?.height),
        };
      } else if (
        nodeOrEdge.domainName === "ENV" ||
        nodeOrEdge.domainName === "MS-CD"
      ) {
        nodeOrEdge.position = {
          ...nodeOrEdge.position,
          y:
            170 +
            Number(heightA?.style?.height) +
            Number(heightB?.style?.height) +
            Number(heightC?.style?.height) +
            Number(heightD?.style?.height) +
            Number(heightE?.style?.height) +
            Number(heightF?.style?.height) +
            Number(heightG?.style?.height) +
            Number(heightH?.style?.height) +
            Number(heightI?.style?.height),
        };
      }
      // }
    }

    return nodeOrEdge;
  };

  const hideItems = (hideState: boolean, node: any) => {
    if (node.id.includes("group") && node.phaseName === "PRE-MDD") {
      setNodes((nds) => nds?.map(hide2(hideState, node)));
      setEdges((eds) => eds?.map(hide2(hideState, node)));
    } else {
      setNodes((nds) => nds?.map(hide(hideState, node)));
      setEdges((eds) => eds?.map(hide(hideState, node)));
    }
  };

  const collapsingItems = () => {
    setNodes((nds) => nds?.map(handleCollapsing()));
  };

  const addNode = () => {
    setNodes([
      ...nodes,
      {
        id: "14a",
        type: "output",
        data: { label: "Node 14a" },
        position: { x: 20, y: 15 },
        parentNode: "J1",
        style: { overflow: "clip", maxHeight: "185px" },
        extent: "parent",
      },
    ]);
  };

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds?.filter((e) => e.id !== edge.id));
      deleteCollaboration(edge.source);
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const onConnect = (new_edge) => {
    // console.log("new_edge", new_edge);
    setEdges((prevState) =>
      addEdge(
        {
          ...new_edge,
          type: "smoothstep",
        },
        prevState
      )
    );
    addCollaboration(activeProject.projectId, {
      ...new_edge,
      collaborationType: "PRE-MDD",
      type: "smoothstep",
    });
  };

  const onNodeClick = (_: any, node: Node) => {
    if (
      node.hasOwnProperty("isDivider") ||
      (activeProjectActivity !== null && node.id === activeProjectActivity.id)
    ) {
      setSideMenu(false);
      setItemMenu(false);
      setClickedItem(null);
    } else {
      if (collabSelection) {
        console.log("collabsection");
        if (collabType === "Global") {
          setCollabSource(node);
          openCollabM();
        } else if (collabType === "Local") {
          if (collabSourceData === null && collabTargetData === null) {
            setCollabSource(node);
          } else {
            setCollabTarget(node);
            openCollabM();
          }
        }
      }
      // else {
      //   if (activeProjectActivityChanges) {
      //     console.log("1");
      //     showChangesToast();
      //   }
      // }

      if (node.hasOwnProperty("phaseOrder")) {
        if (activeProjectActivityChanges) {
          showChangesToast();
        } else if (!collabSelection) {
          setItemMenu(true);
          setSideMenu(false);
          if (activeProjectActivityChanges) {
            showChangesToast();
          } else {
            let zoom = 1.8;
            setItemPosition({ x: node?.position?.x, y: node?.position?.y });
            handlePanPosition({ x: node?.position?.x, y: node?.position?.y });
            setClickedItem(node);
            setCenter(
              // node?.position?.x === 120 || node?.position?.x === 310 ? 0 :
              node?.position?.x + 350,
              node?.position?.y + 150,
              { zoom, duration: 1000 }
            );
            // handleTransform();
          }
        }

        // console.log("node", node);
      }
      if (node.height === 65 && node.className?.includes("Title")) {
        if (activeProjectActivityChanges) {
          showChangesToast();
        } else {
          setSideMenu(true);
          setItemMenu(false);
          setClickedItem(node);
        }
      }
      if (
        (node.height === 193 ||
          node.height === 183 ||
          node.height === 183 ||
          node.height === 188) &&
        node.className?.includes("title")
      ) {
        if (activeProjectActivityChanges) {
          showChangesToast();
        } else {
          hideItems(true, node);
          setCollapse(!collapse);
          setClickedDomain(node);
        }
      }
      if (node.height === 60 && node.className?.includes("title")) {
        if (activeProjectActivityChanges) {
          showChangesToast();
        } else {
          hideItems(false, node);
          setCollapse(!collapse);
          setClickedDomain(node);
        }
      }
      if (
        (node.height === 319 ||
          node.height === 439 ||
          node.height === 549 ||
          node.height === 559 ||
          node.height === 612 ||
          node.height === 739) &&
        node.className?.includes("group")
      ) {
        if (activeProjectActivityChanges) {
          showChangesToast();
        } else {
          hideItems(true, node);
          setClickedDomain(node);
          // console.log(node);
        }
      }
      if (
        (node.height === 246 ||
          node.height === 306 ||
          node.height === 364 ||
          node.height === 376 ||
          node.height === 436 ||
          node.height === 482 ||
          node.height === 494 ||
          node.height === 554 ||
          node.height === 672) &&
        node.className?.includes("group")
      ) {
        if (activeProjectActivityChanges) {
          showChangesToast();
        } else {
          hideItems(false, node);
          setClickedDomain(node);
          // console.log(node);
        }
      }
    }
  };

  const closeUnselect = () => {
    //come back
    if (activeProjectActivityChanges) {
      showChangesToast();
      // setChangesModal(true);
    } else {
      setSideMenu(false);
      setItemMenu(false);
      setClickedItem(null);
      setRightClickMenu(null, null);
      setRightClickedItem(null);
      clearDataLists();
      setActivityChangeState(false);
    }
    // console.log("pane click");
  };

  return (
    // <ReactFlowProvider>
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodesDraggable={editCanvas}
      defaultEdgeOptions={edgeOptions}
      onEdgeUpdate={onEdgeUpdate}
      onEdgeUpdateStart={onEdgeUpdateStart}
      onEdgeUpdateEnd={onEdgeUpdateEnd}
      onConnect={onConnect}
      connectionLineStyle={connectionLineStyle}
      onNodeClick={
        activeProjectActivityChanges ? showChangesToast : onNodeClick
      }
      nodeTypes={nodeTypes}
      // onPaneClick={closeUnselect}
      translateExtent={[
        [-10, -10],
        [projectPhase.length * 14570, 2500],
      ]}
      // maxZoom={1.5}
    >
      <Controls
        style={{
          position: "absolute",
          left: 58,
          bottom: 97,
        }}
      />
      <MiniMap
        zoomable
        pannable
        position="bottom-left"
        style={{
          position: "absolute",
          left: 88,
          bottom: 96,
          border: "1px solid black",
        }}
        maskStrokeColor="black"
        maskStrokeWidth={100}
        nodeColor={(node) => {
          switch (node.id) {
            case "PREDTitle":
            case "RPTitle":
            case "PMDDTitle":
            case "PTitle":
            case "CNITitle":
            case "PLNTitle":
            case "J1":
              return "#E97272";
            case "DTitle":
            case "RFTitle":
            case "MSATitle":
            case "ETitle":
            case "SATitle":
            case "DEVTitle":
            case "J2":
              return "#944F99";
            case "PADTitle":
            case "OPSSTitle":
            case "TMRRTitle":
            case "FRAPTitle":
            case "EXTitle":
            case "J3":
              return "#17BDAC";
            case "OSTitle":
            case "EMDTitle":
            case "ATDTitle":
            case "J4":
              return "#6071BF";
            case "PDTitle":
            case "OSTitle":
            case "CSTitle":
            case "J5":
            case "J6":
              return "#F3A08B";
            case "A1":
            case "B1":
            case "C1":
            case "D1":
            case "E1":
            case "F1":
            case "G1":
            case "H1":
            case "I1":
            case "A2":
            case "B2":
            case "C2":
            case "D2":
            case "E2":
            case "F2":
            case "G2":
            case "H2":
            case "I2":
            case "A3":
            case "B3":
            case "C3":
            case "D3":
            case "E3":
            case "F3":
            case "G3":
            case "H3":
            case "I3":
            case "A4":
            case "B4":
            case "C4":
            case "D4":
            case "E4":
            case "F4":
            case "G4":
            case "H4":
            case "I4":
            case "A5":
            case "B5":
            case "C5":
            case "D5":
            case "E5":
            case "F5":
            case "G5":
            case "H5":
            case "I5":
            case "A6":
            case "B6":
            case "C6":
            case "D6":
            case "E6":
            case "F6":
            case "G6":
            case "H6":
            case "I6":
            case "A1title":
            case "B1title":
            case "C1title":
            case "D1title":
            case "E1title":
            case "F1title":
            case "G1title":
            case "H1title":
            case "I1title":
            case "J1title":
            case "A2title":
            case "B2title":
            case "C2title":
            case "D2title":
            case "E2title":
            case "F2title":
            case "G2title":
            case "H2title":
            case "I2title":
            case "J2title":
            case "A3title":
            case "B3title":
            case "C3title":
            case "D3title":
            case "E3title":
            case "F3title":
            case "G3title":
            case "H3title":
            case "I3title":
            case "J3title":
            case "A4title":
            case "B4title":
            case "C4title":
            case "D4title":
            case "E4title":
            case "F4title":
            case "G4title":
            case "H4title":
            case "I4title":
            case "J4title":
            case "A5title":
            case "B5title":
            case "C5title":
            case "D5title":
            case "E5title":
            case "F5title":
            case "G5title":
            case "H5title":
            case "I5title":
            case "J5title":
            case "A6title":
            case "B6title":
            case "C6title":
            case "D6title":
            case "E6title":
            case "F6title":
            case "G6title":
            case "H6title":
            case "I6title":
            case "J6title":
              return "black";
            default:
              return "#eee";
          }
        }}
      />
      <CollabModal
        closeCollabM={closeCollabM}
        collabM={collabMenu}
        setEdges={setEdges}
        addEdge={addEdge}
      />
      {setZoomIn()}
      {setZoomOut()}
      {setFitView()}
      {setLock()}
    </ReactFlow>

    // </ReactFlowProvider>
  );

  function showChangesToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar6");
    if (element[0]) {
      element[0].className = "snackbar6 show";
      setTimeout(function () {
        element[0].className = "snackbar6";
      }, 5000);
      return;
    }
  }
  function setZoomIn() {
    const element = document.querySelectorAll<HTMLElement>(
      ".react-flow__controls-zoomin"
    );
    if (element[0]) {
      element[0].title = "Zoom In";
      return;
    }
  }
  function setZoomOut() {
    const element = document.querySelectorAll<HTMLElement>(
      ".react-flow__controls-zoomout"
    );
    if (element[0]) {
      element[0].title = "Zoom Out";
      return;
    }
  }
  function setFitView() {
    const element = document.querySelectorAll<HTMLElement>(
      ".react-flow__controls-fitview"
    );
    if (element[0]) {
      element[0].style.display = "none";
      // element[0].title = "Auto Fit";
      return;
    }
  }
  function setLock() {
    const element = document.querySelectorAll<HTMLElement>(
      ".react-flow__controls-interactive"
    );
    if (element[0]) {
      element[0].style.display = "none";
      // element[0].title = "Toggle Interactivity";
      return;
    }
  }
  function hideBrandLogo() {
    const element = document.querySelectorAll<HTMLElement>(
      ".react-flow__attribution"
    );
    if (element[0]) {
      element[0].style.display = "none";
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  activities: state.activities.activities,
  collaborations: state.activities.collaborations,
  activeProject: state.projects.activeProject,
  activeProjectActivity: state.projects.activeProjectActivity,
  collabSourceData: state.projects.collabSourceData,
  collabTargetData: state.projects.collabTargetData,
  collabSelection: state.projects.collabSelection,
  collabType: state.projects.collabType,
  activeProjectActivityChanges: state.projects.activeProjectActivityChanges,
  editCanvas: state.projects.editCanvas,
  panPosition: state.projects.panPosition,
  loadingActivities: state.activities.loadingActivities,
});

export default connect(mapStateToProps, {
  addCollaboration,
  setClickedItem,
  deleteCollaboration,
  setRightClickMenu,
  setRightClickedItem,
  clearDataLists,
  setCollabSource,
  setCollabTarget,
  setActivityChangeState,
  handlePanPosition,
  getActivities,
})(OverviewFlow) as any;
