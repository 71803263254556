import React from "react";
import { connect } from "react-redux";
import "./sidebar.css";
import { MdMessage } from "react-icons/md";
import { RiFoldersFill, RiUser3Fill } from "react-icons/ri";
import { convertTimeString } from "../../lib/helpers/convertTime";
import { ReactComponent as Recentsvg } from "../../lib/assets/img/Recent.svg";
import { ReactComponent as Frameworksvg } from "../../lib/assets/img/HSIFrameworkModelsOption1.svg";
import { ReactComponent as MyProjectssvg } from "../../lib/assets/img/MyProjects.svg";
import { ReactComponent as SharedWithMesvg } from "../../lib/assets/img/SharedWithMe.svg";
import { ReactComponent as Favoritessvg } from "../../lib/assets/img/Favorites.svg";
import { ReactComponent as Deletedsvg } from "../../lib/assets/img/Deleted.svg";
import { setActiveProject } from "../../redux/actions/Projects";
import { longDescription } from "../../lib/data/modelDescriprtions";

const Sidebar: React.FC<{
  sideOption: any;
  setSideOption: any;
  openNewProjectM: any;
  openImportProjectM: any;
  style: any;
  user: any;
  openAcronymM: any;
  openProductM: any;
  openReferenceM: any;
  activeProject: any;
  setHighlightProject: any;
  setActiveProject: (project: any) => void;
}> = ({
  sideOption,
  setSideOption,
  openNewProjectM,
  openImportProjectM,
  style,
  user,
  openAcronymM,
  openProductM,
  openReferenceM,
  activeProject,
  setHighlightProject,
  setActiveProject,
}) => {
  const modelList = [
    "Urgent Capability Acquisition Pathway",
    "Middle Tier Acquisition Pathway",
    "Major Capability Acquisition Pathway",
    "Software Acquisition Pathway",
    "Defense Business Systems Pathway",
    "Acquisition of Services Pathway",
  ];

  return (
    <div
      className="sidebar"
      style={{ backgroundColor: style.colors.grey2, overflowY: "auto" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "10px",
        }}
      >
        <button
          onClick={openNewProjectM}
          className="projectButtons"
          style={{
            marginBottom: "5px",
            backgroundColor: style.colors.primaryBlue,
            fontFamily: "OpenSans-Regular",
            cursor: "pointer",
            width: "80%",
          }}
        >
          NEW PROJECT
        </button>
        <button
          onClick={openImportProjectM}
          className="projectButtons"
          style={{
            marginBottom: "10px",
            backgroundColor: style.colors.primaryBlue,
            fontFamily: "OpenSans-Regular",
            cursor: "pointer",
            width: "80%",
          }}
        >
          IMPORT PROJECT
        </button>
      </div>

      <div
        style={{
          marginLeft: "-40px",
          marginTop: "-10px",
        }}
      >
        <ul className="orderedList">
          <li
            className="sideList"
            style={{
              color:
                sideOption === "Recent"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText,
              borderLeft:
                sideOption === "Recent"
                  ? `4px solid ${style.colors.primaryBlue}`
                  : "none",
              display: "flex",
              textAlign: "center",
            }}
            onClick={() => {
              setSideOption("Recent");
              setHighlightProject(null);
              setActiveProject(null);
            }}
          >
            <Recentsvg
              className="sidebarIcons"
              fill={
                sideOption === "Recent"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText
              }
              style={{ marginLeft: sideOption === "Recent" ? "41px" : "45px" }}
            />
            <span
              className="sideBarFont"
              style={{
                marginLeft: "16px",
                fontFamily: "OpenSans-Regular",
              }}
            >
              Recent
            </span>
          </li>
          <li
            className="sideList"
            style={{
              color:
                sideOption === "HSI Framework Models"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText,
              borderLeft:
                sideOption === "HSI Framework Models"
                  ? `4px solid ${style.colors.primaryBlue}`
                  : "none",
              display: "flex",
              textAlign: "center",
            }}
            onClick={() => {
              setSideOption("HSI Framework Models");
              setHighlightProject(null);
              setActiveProject(null);
            }}
          >
            <Frameworksvg
              className="sidebarIcons"
              fill={
                sideOption === "HSI Framework Models"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText
              }
              style={{
                marginLeft:
                  sideOption === "HSI Framework Models" ? "41px" : "45px",
              }}
            />
            <span
              className="sideBarFont"
              style={{
                marginLeft: "17px",
                fontFamily: "OpenSans-Regular",
              }}
            >
              AAF Pathway Templates
            </span>
          </li>
          <li
            className="sideList"
            style={{
              color:
                sideOption === "My Projects"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText,
              borderLeft:
                sideOption === "My Projects"
                  ? `4px solid ${style.colors.primaryBlue}`
                  : "none",
              display: "flex",
              textAlign: "center",
            }}
            onClick={() => {
              setSideOption("My Projects");
              setHighlightProject(null);
              setActiveProject(null);
            }}
          >
            <MyProjectssvg
              className="sidebarIcons"
              fill={
                sideOption === "My Projects"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText
              }
              style={{
                marginLeft: sideOption === "My Projects" ? "41px" : "45px",
              }}
            />
            <span
              className="sideBarFont"
              style={{
                marginLeft: "17px",
                fontFamily: "OpenSans-Regular",
              }}
            >
              My Projects
            </span>
          </li>
          <li
            className="sideList"
            style={{
              color:
                sideOption === "Shared With Me"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText,
              borderLeft:
                sideOption === "Shared With Me"
                  ? `4px solid ${style.colors.primaryBlue}`
                  : "none",
              display: "flex",
              textAlign: "center",
            }}
            onClick={() => {
              setSideOption("Shared With Me");
              setHighlightProject(null);
              setActiveProject(null);
            }}
          >
            <SharedWithMesvg
              className="sidebarIcons"
              fill={
                sideOption === "Shared With Me"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText
              }
              style={{
                marginLeft: sideOption === "Shared With Me" ? "41px" : "45px",
              }}
            />
            <span
              className="sideBarFont"
              style={{
                marginLeft: "17px",
                fontFamily: "OpenSans-Regular",
              }}
            >
              Shared With Me
            </span>
          </li>
          <li
            className="sideList"
            style={{
              color:
                sideOption === "Favorites"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText,
              borderLeft:
                sideOption === "Favorites"
                  ? `4px solid ${style.colors.primaryBlue}`
                  : "none",
              display: "flex",
              textAlign: "center",
            }}
            onClick={() => {
              setSideOption("Favorites");
              setHighlightProject(null);
              setActiveProject(null);
            }}
          >
            <Favoritessvg
              className="sidebarIcons"
              fill={
                sideOption === "Favorites"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText
              }
              style={{
                marginLeft: sideOption === "Favorites" ? "40px" : "44px",
              }}
            />
            <span
              className="sideBarFont"
              style={{
                marginLeft: "18px",
                fontFamily: "OpenSans-Regular",
              }}
            >
              Favorites
            </span>
          </li>
          <li
            className="sideList"
            style={{
              color:
                sideOption === "Deleted"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText,
              borderLeft:
                sideOption === "Deleted"
                  ? `4px solid ${style.colors.primaryBlue}`
                  : "none",
              display: "flex",
              textAlign: "center",
            }}
            onClick={() => {
              setSideOption("Deleted");
              setHighlightProject(null);
              setActiveProject(null);
            }}
          >
            <Deletedsvg
              className="sidebarIcons"
              fill={
                sideOption === "Deleted"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText
              }
              style={{ marginLeft: sideOption === "Deleted" ? "41px" : "45px" }}
            />
            <span
              className="sideBarFont"
              style={{
                marginLeft: "17px",
                fontFamily: "OpenSans-Regular",
              }}
            >
              Deleted
            </span>
          </li>
        </ul>
      </div>

      <div
        style={{
          marginTop: "-20px",
          display: user?.userRole
            ? user?.userRole?.includes("Admin")
              ? "initial"
              : "none"
            : "none",
        }}
      >
        <p
          className="sideBarFont"
          style={{
            marginLeft: "30px",

            fontFamily: "OpenSans-Regular",
          }}
        >
          Administrative Folders
        </p>
        <ul
          className="orderedList"
          style={{ marginLeft: "-40px", marginTop: "-5px" }}
        >
          <li
            className="sideList"
            style={{
              color:
                sideOption === "All Projects"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText,
              borderLeft:
                sideOption === "All Projects"
                  ? `4px solid ${style.colors.primaryBlue}`
                  : "none",
              display: "flex",
              textAlign: "center",
              cursor: user?.userRole ? "pointer" : "default",
            }}
            onClick={() => {
              if (user?.userRole) {
                setSideOption("All Projects");
                setHighlightProject(null);
                setActiveProject(null);
              }
            }}
          >
            <RiFoldersFill
              className="sidebarIcons"
              fill={
                sideOption === "All Projects"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText
              }
              style={{
                marginLeft: sideOption === 6 ? "42px" : "46px",
              }}
            />
            <span
              className="sideBarFont"
              style={{
                marginLeft: "17px",
                fontFamily: "OpenSans-Regular",
              }}
            >
              All Projects
            </span>
          </li>
          <li
            className="sideList"
            style={{
              color:
                sideOption === "User Accounts"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText,
              borderLeft:
                sideOption === "User Accounts"
                  ? `4px solid ${style.colors.primaryBlue}`
                  : "none",
              display: "flex",
              textAlign: "center",
              cursor: user?.userRole ? "pointer" : "default",
            }}
            onClick={() => {
              if (user?.userRole) {
                setSideOption("User Accounts");
                setHighlightProject(null);
                setActiveProject(null);
              }
            }}
          >
            <RiUser3Fill
              className="sidebarIcons"
              fill={
                sideOption === "User Accounts"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText
              }
              style={{
                marginLeft: sideOption === "User Accounts" ? "42px" : "45px",
              }}
            />
            <span
              className="sideBarFont"
              style={{
                marginLeft: "17px",
                fontFamily: "OpenSans-Regular",
              }}
            >
              User Accounts
            </span>
          </li>
          <li
            className="sideList"
            style={{
              color:
                sideOption === "Feedback"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText,
              borderLeft:
                sideOption === "Feedback"
                  ? `4px solid ${style.colors.primaryBlue}`
                  : "none",
              display: "flex",
              textAlign: "center",
              cursor: user?.userRole ? "pointer" : "default",
            }}
            onClick={() => {
              if (user?.userRole) {
                setSideOption("Feedback");
                setHighlightProject(null);
                setActiveProject(null);
              }
            }}
          >
            <MdMessage
              className="sidebarIcons"
              fill={
                sideOption === "Feedback"
                  ? style.colors.primaryBlue
                  : style.colors.primaryText
              }
              style={{
                marginLeft: sideOption === "Feedback" ? "41.5px" : "45.5px",
                marginTop: "1px",
              }}
            />
            <span
              className="sideBarFont"
              style={{
                marginLeft: "17px",
                fontFamily: "OpenSans-Regular",
              }}
            >
              Feedback
            </span>
          </li>
        </ul>
      </div>

      <div
        style={{
          marginTop: "0px",
          opacity: activeProject
            ? sideOption === "All Projects"
              ? 0
              : sideOption === "User Accounts"
              ? 0
              : sideOption === "Feedback"
              ? 0
              : 1
            : 0,
          marginLeft: "20px",
        }}
      >
        <div className="sideMeta">
          <span className="sideBarFont " style={{ fontWeight: "bold" }}>
            {activeProject ? activeProject.name : null}
          </span>
        </div>
        <div
          className="sideMeta"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <span className="sideBarFont " style={{ fontWeight: "bold" }}>
            Date Created:
          </span>
          <span className="sideBarFont ">
            {activeProject
              ? convertTimeString(activeProject.createdDate)
              : null}
          </span>
        </div>
        <div
          className="sideMeta"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <span className="sideBarFont " style={{ fontWeight: "bold" }}>
            Last Modified:
          </span>
          <span className="sideBarFont ">
            {activeProject
              ? convertTimeString(activeProject.modifiedDate)
              : null}
          </span>
        </div>
        {/* <div
          className="sideMeta"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <span className="sideBarFont " style={{ fontWeight: "bold" }}>
            Parent:
          </span>
        </div> */}
        <div
          className="sideMeta"
          style={{
            display: "flex",
            flexDirection: "row",
            paddingBottom: "70px",
          }}
        >
          <span className="sideBarFont ">
            <b>Pathway Template:</b>
            {` ${
              activeProject
                ? activeProject.projectId === "wihqi631etml3e0i83pwbh50"
                  ? longDescription[0]
                  : activeProject.projectId === "ddzggs33kgy0lcufh5y5r06c"
                  ? longDescription[1]
                  : activeProject.projectId === "fd0pctpxfka5g2lceky5v1pd"
                  ? longDescription[2]
                  : activeProject.projectId === "tqfd3n246mstuwgqqnbsn3rc"
                  ? longDescription[3]
                  : activeProject.projectId === "9frwcq3y4rcnerwksulke6qr"
                  ? longDescription[4]
                  : activeProject.projectId === "anhmqnq63o45ecqqdwx1fdnh"
                  ? longDescription[5]
                  : modelList[activeProject.modelId - 1]
                : null
            }`}
          </span>
          {/* <span className="sideBarFont">
            {activeProject ? modelList[activeProject.modelId - 1] : null}
          </span> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  user: state.user.user,
  activeProject: state.projects.activeProject,
});

export default connect(mapStateToProps, { setActiveProject })(Sidebar) as any;
