import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import Table from "./Table";
import Card from "../Card";
import { getAllProjects } from "../../../redux/actions/Projects";

const AllProjects: React.FC<{
  allProjects: any;
  getAllProjects: () => void;
  sideOption: string;
  hsiFrameworkModels: any;
}> = ({ allProjects, getAllProjects, sideOption, hsiFrameworkModels }) => {
  useEffect(() => {
    getAllProjects();
  }, []);
  return (
    <>
      <div
        className="subTitle"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p
          style={{ paddingLeft: "25px", fontWeight: "bold", fontSize: "16px" }}
        >
          All Projects
        </p>
      </div>
      <div className="header2" style={{ marginTop: 0 }}>
        <Table
          projectList={allProjects}
          // handleClick={() => console.log("CLicked")}
          // setProjectClicked={() => console.log("CLicked")}
          // openDeleteM={() => console.log("CLicked")}
          adminFolderState={true}
          sideOption={sideOption}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  allProjects: state.projects.recent,
  hsiFrameworkModels: state.projects.hsiFrameworkModels,
});

export default connect(mapStateToProps, { getAllProjects })(AllProjects) as any;
