import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import Table from "../Table";
import Card from "../Card";
import {
  getRecentProjects,
  getFavorites,
  getMyProjects,
  getHsiFrameworkModels,
} from "../../../redux/actions/Projects";
import { getAllUsers } from "../../../redux/actions/Data";

const Recent: React.FC<{
  recent_projects: any;
  getRecentProjects: () => void;
  highlightProject: any;
  setHighlightProject: any;
  setEllipsisClick: any;
  getFavorites: () => void;
  sideOption: any;
  getMyProjects: () => void;
  getHsiFrameworkModels: () => void;
  getAllUsers: () => void;
}> = ({
  recent_projects,
  getRecentProjects,
  highlightProject,
  setHighlightProject,
  setEllipsisClick,
  getFavorites,
  sideOption,
  getMyProjects,
  getHsiFrameworkModels,
  getAllUsers,
}) => {
  const [viewState, setViewState] = useState("box") as any;
  const handleBoxClick = () => {
    setViewState("box");
  };

  const handleListClick = () => {
    setViewState("list");
  };

  useEffect(() => {
    getRecentProjects();
    getFavorites();
    getMyProjects();
    getHsiFrameworkModels();
    getAllUsers();
  }, []);

  return (
    <>
      <div
        className="subTitle"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p
          style={{ paddingLeft: "25px", fontWeight: "bold", fontSize: "16px" }}
        >
          Recent
        </p>
        <div className="push">
          <i
            onClick={handleBoxClick}
            className="fa fa-th"
            style={{
              marginRight: "30px",
              color: viewState === "box" ? "black" : "lightgrey",
            }}
          ></i>
          <i
            onClick={handleListClick}
            style={{ color: viewState === "list" ? "black" : "lightgrey" }}
            className="fa fa-list-ul"
          ></i>
        </div>
      </div>
      <div
        className="header2"
        style={
          viewState === "box"
            ? {
                maxHeight: "100vh",
                overflowY: "auto",
              }
            : { marginTop: 0 }
        }
      >
        {/* all your projects here  */}
        {viewState === "box" ? (
          recent_projects?.length > 0 ? (
            recent_projects?.map((item: any, index: number) => (
              <Card
                key={index}
                item={item}
                index={index}
                bottom={index === recent_projects?.length - 1}
                highlightProject={highlightProject}
                setHighlightProject={setHighlightProject}
                setEllipsisClick={setEllipsisClick}
                sideOption={sideOption}
                // allItems={recent_projects}
              />
            ))
          ) : (
            <p style={{ marginLeft: "2vw", marginTop: "7vh" }}>
              You have not opened any projects yet.
            </p>
          )
        ) : (
          <Table
            projectList={recent_projects}
            // handleClick={() => console.log("CLicked")}
            // setProjectClicked={() => console.log("CLicked")}
            // openDeleteM={() => console.log("CLicked")}
            adminFolderState={false}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  recent_projects: state.projects.recent,
});

export default connect(mapStateToProps, {
  getRecentProjects,
  getFavorites,
  getMyProjects,
  getHsiFrameworkModels,
  getAllUsers,
})(Recent) as any;
