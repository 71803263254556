export const domains1 = [
  {
    id: "STM1title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 0, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM1title",
    phaseName: "PRE-DEV",
    domainName: "STM",
    rowID: "STM1",
  },
  {
    id: "A1title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 0, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A1title",
    phaseName: "PRE-DEV",
    domainName: "INT",
    rowID: "A1",
  },
  {
    id: "B1title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 0, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B1title",
    phaseName: "PRE-DEV",
    domainName: "HFE",
    rowID: "B1",
  },
  {
    id: "C1title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 0, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C1title",
    phaseName: "PRE-DEV",
    domainName: "MAN",
    rowID: "C1",
  },
  {
    id: "D1title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 0, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D1title",
    phaseName: "PRE-DEV",
    domainName: "PER",
    rowID: "D1",
  },
  {
    id: "E1title",
    type: "input",
    data: { label: "Training" },
    position: { x: 0, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E1title",
    phaseName: "PRE-DEV",
    domainName: "TRA",
    rowID: "E1",
  },
  {
    id: "F1title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 0, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F1title",
    phaseName: "PRE-DEV",
    domainName: "SAF",
    rowID: "F1",
  },
  {
    id: "G1title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 0, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G1title",
    phaseName: "PRE-DEV",
    domainName: "OCC",
    rowID: "G1",
  },
  {
    id: "H1title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 0, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H1title",
    phaseName: "PRE-DEV",
    domainName: "SUR",
    rowID: "H1",
  },
  {
    id: "I1title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 0, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I1title",
    phaseName: "PRE-DEV",
    domainName: "HAB",
    rowID: "I1",
  },
  {
    id: "J1title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 0, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J1title",
    phaseName: "PRE-DEV",
    domainName: "ENV",
    rowID: "J1",
  },
  {
    id: "Border1",
    data: { label: null },
    position: { x: 14440, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border1",
  },
  {
    id: "STM2title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 14462, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM2title",
    phaseName: "DEV",
    domainName: "STM",
    rowID: "STM2",
  },
  {
    id: "A2title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 14462, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A2title",
    phaseName: "DEV",
    domainName: "INT",
    rowID: "A2",
  },
  {
    id: "B2title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 14462, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B2title",
    phaseName: "DEV",
    domainName: "HFE",
    rowID: "B2",
  },
  {
    id: "C2title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 14462, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C2title",
    phaseName: "DEV",
    domainName: "MAN",
    rowID: "C2",
  },
  {
    id: "D2title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 14462, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D2title",
    phaseName: "DEV",
    domainName: "PER",
    rowID: "D2",
  },
  {
    id: "E2title",
    type: "input",
    data: { label: "Training" },
    position: { x: 14462, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E2title",
    phaseName: "DEV",
    domainName: "TRA",
    rowID: "E2",
  },
  {
    id: "F2title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 14462, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F2title",
    phaseName: "DEV",
    domainName: "SAF",
    rowID: "F2",
  },
  {
    id: "G2title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 14462, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G2title",
    phaseName: "DEV",
    domainName: "OCC",
    rowID: "G2",
  },
  {
    id: "H2title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 14462, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H2title",
    phaseName: "DEV",
    domainName: "SUR",
    rowID: "H2",
  },
  {
    id: "I2title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 14462, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I2title",
    phaseName: "DEV",
    domainName: "HAB",
    rowID: "I2",
  },
  {
    id: "J2title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 14462, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J2title",
    phaseName: "DEV",
    domainName: "ENV",
    rowID: "J2",
  },
  {
    id: "Border2",
    data: { label: null },
    position: { x: 28882, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border2",
  },
  {
    id: "STM3title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 28904, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM3title",
    phaseName: "PAD",
    domainName: "STM",
    rowID: "STM3",
  },
  {
    id: "A3title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 28904, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A3title",
    phaseName: "PAD",
    domainName: "INT",
    rowID: "A3",
  },
  {
    id: "B3title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 28904, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B3title",
    phaseName: "PAD",
    domainName: "HFE",
    rowID: "B3",
  },
  {
    id: "C3title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 28904, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C3title",
    phaseName: "PAD",
    domainName: "MAN",
    rowID: "C3",
  },
  {
    id: "D3title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 28904, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D3title",
    phaseName: "PAD",
    domainName: "PER",
    rowID: "D3",
  },
  {
    id: "E3title",
    type: "input",
    data: { label: "Training" },
    position: { x: 28904, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E3title",
    phaseName: "PAD",
    domainName: "TRA",
    rowID: "E3",
  },
  {
    id: "F3title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 28904, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F3title",
    phaseName: "PAD",
    domainName: "SAF",
    rowID: "F3",
  },
  {
    id: "G3title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 28904, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G3title",
    phaseName: "PAD",
    domainName: "OCC",
    rowID: "G3",
  },
  {
    id: "H3title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 28904, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H3title",
    phaseName: "PAD",
    domainName: "SUR",
    rowID: "H3",
  },
  {
    id: "I3title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 28904, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I3title",
    phaseName: "PAD",
    domainName: "HAB",
    rowID: "I3",
  },
  {
    id: "J3title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 28904, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J3title",
    phaseName: "PAD",
    domainName: "ENV",
    rowID: "J3",
  },
  {
    id: "Border3",
    data: { label: null },
    position: { x: 43324, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border3",
  },
  {
    id: "STM4title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 43346, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM4title",
    phaseName: "OAS",
    domainName: "STM",
    rowID: "STM4",
  },
  {
    id: "A4title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 43346, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A4title",
    phaseName: "OAS",
    domainName: "INT",
    rowID: "A4",
  },
  {
    id: "B4title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 43346, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B4title",
    phaseName: "OAS",
    domainName: "HFE",
    rowID: "B4",
  },
  {
    id: "C4title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 43346, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C4title",
    phaseName: "OAS",
    domainName: "MAN",
    rowID: "C4",
  },
  {
    id: "D4title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 43346, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D4title",
    phaseName: "OAS",
    domainName: "PER",
    rowID: "D4",
  },
  {
    id: "E4title",
    type: "input",
    data: { label: "Training" },
    position: { x: 43346, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E4title",
    phaseName: "OAS",
    domainName: "TRA",
    rowID: "E4",
  },
  {
    id: "F4title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 43346, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F4title",
    phaseName: "OAS",
    domainName: "SAF",
    rowID: "F4",
  },
  {
    id: "G4title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 43346, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G4title",
    phaseName: "OAS",
    domainName: "OCC",
    rowID: "G4",
  },
  {
    id: "H4title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 43346, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H4title",
    phaseName: "OAS",
    domainName: "SUR",
    rowID: "H4",
  },
  {
    id: "I4title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 43346, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I4title",
    phaseName: "OAS",
    domainName: "HAB",
    rowID: "I4",
  },
  {
    id: "J4title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 43346, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J4title",
    phaseName: "OAS",
    domainName: "ENV",
    rowID: "J4",
  },
  {
    id: "Border7",
    type: "input",
    data: { label: null },
    position: { x: 57766, y: 0 },
    draggable: false,
    style: {
      width: 100,
      height: 2077,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "Border7",
  },
];

export const domains2 = [
  {
    id: "STM1title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 0, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM1title",
    phaseName: "RP",
    domainName: "STM",
    rowID: "STM1",
  },
  {
    id: "A1title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 0, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A1title",
    phaseName: "RP",
    domainName: "INT",
    rowID: "A1",
  },
  {
    id: "B1title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 0, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B1title",
    phaseName: "RP",
    domainName: "HFE",
    rowID: "B1",
  },
  {
    id: "C1title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 0, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C1title",
    phaseName: "RP",
    domainName: "MAN",
    rowID: "C1",
  },
  {
    id: "D1title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 0, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D1title",
    phaseName: "RP",
    domainName: "PER",
    rowID: "D1",
  },
  {
    id: "E1title",
    type: "input",
    data: { label: "Training" },
    position: { x: 0, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E1title",
    phaseName: "RP",
    domainName: "TRA",
    rowID: "E1",
  },
  {
    id: "F1title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 0, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F1title",
    phaseName: "RP",
    domainName: "SAF",
    rowID: "F1",
  },
  {
    id: "G1title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 0, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G1title",
    phaseName: "RP",
    domainName: "OCC",
    rowID: "G1",
  },
  {
    id: "H1title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 0, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H1title",
    phaseName: "RP",
    domainName: "SUR",
    rowID: "H1",
  },
  {
    id: "I1title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 0, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I1title",
    phaseName: "RP",
    domainName: "HAB",
    rowID: "I1",
  },
  {
    id: "J1title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 0, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J1title",
    phaseName: "RP",
    domainName: "ENV",
    rowID: "J1",
  },
  {
    id: "Border1",
    data: { label: null },
    position: { x: 14440, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border1",
  },
  {
    id: "STM2title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 14462, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM2title",
    phaseName: "RF",
    domainName: "STM",
    rowID: "STM2",
  },
  {
    id: "A2title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 14462, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A2title",
    phaseName: "RF",
    domainName: "INT",
    rowID: "A2",
  },
  {
    id: "B2title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 14462, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B2title",
    phaseName: "RF",
    domainName: "HFE",
    rowID: "B2",
  },
  {
    id: "C2title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 14462, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C2title",
    phaseName: "RF",
    domainName: "MAN",
    rowID: "C2",
  },
  {
    id: "D2title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 14462, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D2title",
    phaseName: "RF",
    domainName: "PER",
    rowID: "D2",
  },
  {
    id: "E2title",
    type: "input",
    data: { label: "Training" },
    position: { x: 14462, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E2title",
    phaseName: "RF",
    domainName: "TRA",
    rowID: "E2",
  },
  {
    id: "F2title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 14462, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F2title",
    phaseName: "RF",
    domainName: "SAF",
    rowID: "F2",
  },
  {
    id: "G2title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 14462, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G2title",
    phaseName: "RF",
    domainName: "OCC",
    rowID: "G2",
  },
  {
    id: "H2title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 14462, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H2title",
    phaseName: "RF",
    domainName: "SUR",
    rowID: "H2",
  },
  {
    id: "I2title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 14462, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I2title",
    phaseName: "RF",
    domainName: "HAB",
    rowID: "I2",
  },
  {
    id: "J2title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 14462, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J2title",
    phaseName: "RF",
    domainName: "ENV",
    rowID: "J2",
  },
  {
    id: "Border2",
    data: { label: null },
    position: { x: 28882, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border2",
  },
  {
    id: "STM3title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 28904, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM3title",
    phaseName: "OPSS",
    domainName: "STM",
    rowID: "STM3",
  },
  {
    id: "A3title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 28904, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A3title",
    phaseName: "OPSS",
    domainName: "INT",
    rowID: "A3",
  },
  {
    id: "B3title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 28904, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B3title",
    phaseName: "OPSS",
    domainName: "HFE",
    rowID: "B3",
  },
  {
    id: "C3title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 28904, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C3title",
    phaseName: "OPSS",
    domainName: "MAN",
    rowID: "C3",
  },
  {
    id: "D3title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 28904, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D3title",
    phaseName: "OPSS",
    domainName: "PER",
    rowID: "D3",
  },
  {
    id: "E3title",
    type: "input",
    data: { label: "Training" },
    position: { x: 28904, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E3title",
    phaseName: "OPSS",
    domainName: "TRA",
    rowID: "E3",
  },
  {
    id: "F3title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 28904, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F3title",
    phaseName: "OPSS",
    domainName: "SAF",
    rowID: "F3",
  },
  {
    id: "G3title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 28904, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G3title",
    phaseName: "OPSS",
    domainName: "OCC",
    rowID: "G3",
  },
  {
    id: "H3title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 28904, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H3title",
    phaseName: "OPSS",
    domainName: "SUR",
    rowID: "H3",
  },
  {
    id: "I3title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 28904, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I3title",
    phaseName: "OPSS",
    domainName: "HAB",
    rowID: "I3",
  },
  {
    id: "J3title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 28904, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J3title",
    phaseName: "OPSS",
    domainName: "ENV",
    rowID: "J3",
  },
  {
    id: "Border7",
    type: "input",
    data: { label: null },
    position: { x: 43324, y: 0 },
    draggable: false,
    style: {
      width: 100,
      height: 2077,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "Border7",
  },
];

export const domains3 = [
  {
    id: "group1",
    type: "input",
    data: { label: "Development Planning" },
    position: { x: 0, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 559,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "group1",
    phaseName: "PRE-MDD",
  },
  {
    id: "group2",
    type: "input",
    data: { label: "Science & Technology" },
    position: { x: 0, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 549,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "group2",
    phaseName: "PRE-MDD",
  },
  {
    id: "group3",
    type: "input",
    data: { label: "Modeling & Simulation" },
    position: { x: 0, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 742,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "group3",
    phaseName: "PRE-MDD",
  },
  {
    id: "STM1title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 0, y: 65 },
    draggable: false,
    style: {
      width: 200,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM1title",
    phaseName: "PRE-MDD",
    domainName: "STM",
    rowID: "STM1",
  },
  {
    id: "A1title",
    type: "input",
    data: { label: "Prep" },
    position: { x: 100, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A1title",
    phaseName: "PRE-MDD",
    domainName: "DP-Prep",
    rowID: "A1",
  },
  {
    id: "B1title",
    type: "input",
    data: { label: "CP&A" },
    position: { x: 100, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B1title",
    phaseName: "PRE-MDD",
    domainName: "DP-CP&A",
    rowID: "B1",
  },
  {
    id: "C1title",
    type: "input",
    data: { label: "CD" },
    position: { x: 100, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C1title",
    phaseName: "PRE-MDD",
    domainName: "DP-CD",
    rowID: "C1",
  },
  {
    id: "D1title",
    type: "input",
    data: { label: "Plan" },
    position: { x: 100, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D1title",
    phaseName: "PRE-MDD",
    domainName: "ST-Plan",
    rowID: "D1",
  },
  {
    id: "E1title",
    type: "input",
    data: { label: "Conduct" },
    position: { x: 100, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E1title",
    phaseName: "PRE-MDD",
    domainName: "ST-Conduct",
    rowID: "E1",
  },
  {
    id: "F1title",
    type: "input",
    data: { label: "Document" },
    position: { x: 100, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F1title",
    phaseName: "PRE-MDD",
    domainName: "ST-Document",
    rowID: "F1",
  },
  {
    id: "G1title",
    type: "input",
    data: { label: "Plan" },
    position: { x: 100, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G1title",
    phaseName: "PRE-MDD",
    domainName: "MS-Plan",
    rowID: "G1",
  },
  {
    id: "H1title",
    type: "input",
    data: { label: "Conduct" },
    position: { x: 100, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H1title",
    phaseName: "PRE-MDD",
    domainName: "MS-Conduct",
    rowID: "H1",
  },
  {
    id: "I1title",
    type: "input",
    data: { label: "Document" },
    position: { x: 100, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I1title",
    phaseName: "PRE-MDD",
    domainName: "MS-Document",
    rowID: "I1",
  },
  {
    id: "J1title",
    type: "input",
    data: { label: "CD" },
    position: { x: 100, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J1title",
    phaseName: "PRE-MDD",
    domainName: "MS-CD",
    rowID: "J1",
  },
  {
    id: "Border1",
    data: { label: null },
    position: { x: 14440, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border1",
  },
  {
    id: "STM2title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 14462, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM2title",
    phaseName: "MSA",
    domainName: "STM",
    rowID: "STM2",
  },
  {
    id: "A2title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 14462, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A2title",
    phaseName: "MSA",
    domainName: "INT",
    rowID: "A2",
  },
  {
    id: "B2title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 14462, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B2title",
    phaseName: "MSA",
    domainName: "HFE",
    rowID: "B2",
  },
  {
    id: "C2title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 14462, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C2title",
    phaseName: "MSA",
    domainName: "MAN",
    rowID: "C2",
  },
  {
    id: "D2title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 14462, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D2title",
    phaseName: "MSA",
    domainName: "PER",
    rowID: "D2",
  },
  {
    id: "E2title",
    type: "input",
    data: { label: "Training" },
    position: { x: 14462, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E2title",
    phaseName: "MSA",
    domainName: "TRA",
    rowID: "E2",
  },
  {
    id: "F2title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 14462, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F2title",
    phaseName: "MSA",
    domainName: "SAF",
    rowID: "F2",
  },
  {
    id: "G2title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 14462, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G2title",
    phaseName: "MSA",
    domainName: "OCC",
    rowID: "G2",
  },
  {
    id: "H2title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 14462, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H2title",
    phaseName: "MSA",
    domainName: "SUR",
    rowID: "H2",
  },
  {
    id: "I2title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 14462, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I2title",
    phaseName: "MSA",
    domainName: "HAB",
    rowID: "I2",
  },
  {
    id: "J2title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 14462, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J2title",
    phaseName: "MSA",
    domainName: "ENV",
    rowID: "J2",
  },
  {
    id: "Border2",
    data: { label: null },
    position: { x: 28882, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border2",
  },
  {
    id: "STM3title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 28904, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM3title",
    phaseName: "TMRR",
    domainName: "STM",
    rowID: "STM3",
  },
  {
    id: "A3title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 28904, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A3title",
    phaseName: "TMRR",
    domainName: "INT",
    rowID: "A3",
  },
  {
    id: "B3title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 28904, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B3title",
    phaseName: "TMRR",
    domainName: "HFE",
    rowID: "B3",
  },
  {
    id: "C3title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 28904, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C3title",
    phaseName: "TMRR",
    domainName: "MAN",
    rowID: "C3",
  },
  {
    id: "D3title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 28904, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D3title",
    phaseName: "TMRR",
    domainName: "PER",
    rowID: "D3",
  },
  {
    id: "E3title",
    type: "input",
    data: { label: "Training" },
    position: { x: 28904, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E3title",
    phaseName: "TMRR",
    domainName: "TRA",
    rowID: "E3",
  },
  {
    id: "F3title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 28904, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F3title",
    phaseName: "TMRR",
    domainName: "SAF",
    rowID: "F3",
  },
  {
    id: "G3title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 28904, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G3title",
    phaseName: "TMRR",
    domainName: "OCC",
    rowID: "G3",
  },
  {
    id: "H3title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 28904, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H3title",
    phaseName: "TMRR",
    domainName: "SUR",
    rowID: "H3",
  },
  {
    id: "I3title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 28904, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I3title",
    phaseName: "TMRR",
    domainName: "HAB",
    rowID: "I3",
  },
  {
    id: "J3title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 28904, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J3title",
    phaseName: "TMRR",
    domainName: "ENV",
    rowID: "J3",
  },
  {
    id: "Border3",
    data: { label: null },
    position: { x: 43324, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border3",
  },
  {
    id: "STM4title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 43346, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM4title",
    phaseName: "EMD",
    domainName: "STM",
    rowID: "STM4",
  },
  {
    id: "A4title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 43346, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A4title",
    phaseName: "EMD",
    domainName: "INT",
    rowID: "A4",
  },
  {
    id: "B4title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 43346, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B4title",
    phaseName: "EMD",
    domainName: "HFE",
    rowID: "B4",
  },
  {
    id: "C4title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 43346, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C4title",
    phaseName: "EMD",
    domainName: "MAN",
    rowID: "C4",
  },
  {
    id: "D4title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 43346, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D4title",
    phaseName: "EMD",
    domainName: "PER",
    rowID: "D4",
  },
  {
    id: "E4title",
    type: "input",
    data: { label: "Training" },
    position: { x: 43346, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E4title",
    phaseName: "EMD",
    domainName: "TRA",
    rowID: "E4",
  },
  {
    id: "F4title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 43346, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F4title",
    phaseName: "EMD",
    domainName: "SAF",
    rowID: "F4",
  },
  {
    id: "G4title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 43346, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G4title",
    phaseName: "EMD",
    domainName: "OCC",
    rowID: "G4",
  },
  {
    id: "H4title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 43346, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H4title",
    phaseName: "EMD",
    domainName: "SUR",
    rowID: "H4",
  },
  {
    id: "I4title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 43346, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I4title",
    phaseName: "EMD",
    domainName: "HAB",
    rowID: "I4",
  },
  {
    id: "J4title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 43346, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J4title",
    phaseName: "EMD",
    domainName: "ENV",
    rowID: "J4",
  },
  {
    id: "Border4",
    data: { label: null },
    position: { x: 57766, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border4",
  },
  {
    id: "STM5title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 57788, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM5title",
    phaseName: "PD",
    domainName: "STM",
    rowID: "STM5",
  },
  {
    id: "A5title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 57788, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A5title",
    phaseName: "PD",
    domainName: "INT",
    rowID: "A5",
  },
  {
    id: "B5title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 57788, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B5title",
    phaseName: "PD",
    domainName: "HFE",
    rowID: "B5",
  },
  {
    id: "C5title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 57788, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C5title",
    phaseName: "PD",
    domainName: "MAN",
    rowID: "C5",
  },
  {
    id: "D5title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 57788, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D5title",
    phaseName: "PD",
    domainName: "PER",
    rowID: "D5",
  },
  {
    id: "E5title",
    type: "input",
    data: { label: "Training" },
    position: { x: 57788, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E5title",
    phaseName: "PD",
    domainName: "TRA",
    rowID: "E5",
  },
  {
    id: "F5title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 57788, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F5title",
    phaseName: "PD",
    domainName: "SAF",
    rowID: "F5",
  },
  {
    id: "G5title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 57788, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G5title",
    phaseName: "PD",
    domainName: "OCC",
    rowID: "G5",
  },
  {
    id: "H5title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 57788, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H5title",
    phaseName: "PD",
    domainName: "SUR",
    rowID: "H5",
  },
  {
    id: "I5title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 57788, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I5title",
    phaseName: "PD",
    domainName: "HAB",
    rowID: "I5",
  },
  {
    id: "J5title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 57788, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J5title",
    phaseName: "PD",
    domainName: "ENV",
    rowID: "J5",
  },
  {
    id: "Border5",
    data: { label: null },
    position: { x: 72208, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border5",
  },
  {
    id: "STM6title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 72230, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM6title",
    phaseName: "OS",
    domainName: "STM",
    rowID: "STM6",
  },
  {
    id: "A6title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 72230, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A6title",
    phaseName: "OS",
    domainName: "INT",
    rowID: "A6",
  },
  {
    id: "B6title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 72230, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B6title",
    phaseName: "OS",
    domainName: "HFE",
    rowID: "B6",
  },
  {
    id: "C6title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 72230, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C6title",
    phaseName: "OS",
    domainName: "MAN",
    rowID: "C6",
  },
  {
    id: "D6title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 72230, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D6title",
    phaseName: "OS",
    domainName: "PER",
    rowID: "D6",
  },
  {
    id: "E6title",
    type: "input",
    data: { label: "Training" },
    position: { x: 72230, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E6title",
    phaseName: "OS",
    domainName: "TRA",
    rowID: "E6",
  },
  {
    id: "F6title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 72230, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F6title",
    phaseName: "OS",
    domainName: "SAF",
    rowID: "F6",
  },
  {
    id: "G6title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 72230, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G6title",
    phaseName: "OS",
    domainName: "OCC",
    rowID: "G6",
  },
  {
    id: "H6title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 72230, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H6title",
    phaseName: "OS",
    domainName: "SUR",
    rowID: "H6",
  },
  {
    id: "I6title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 72230, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I6title",
    phaseName: "OS",
    domainName: "HAB",
    rowID: "I6",
  },
  {
    id: "J6title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 72230, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J6title",
    phaseName: "OS",
    domainName: "ENV",
    rowID: "J6",
  },
  {
    id: "Border6",
    type: "input",
    data: { label: null },
    position: { x: 86650, y: 0 },
    draggable: false,
    style: {
      width: 100,
      height: 2077,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "Border6",
  },
];

export const domains4 = [
  {
    id: "STM1title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 0, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM1title",
    phaseName: "PLAN",
    domainName: "STM",
    rowID: "STM1",
  },
  {
    id: "A1title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 0, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A1title",
    phaseName: "PLAN",
    domainName: "INT",
    rowID: "A1",
  },
  {
    id: "B1title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 0, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B1title",
    phaseName: "PLAN",
    domainName: "HFE",
    rowID: "B1",
  },
  {
    id: "C1title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 0, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C1title",
    phaseName: "PLAN",
    domainName: "MAN",
    rowID: "C1",
  },
  {
    id: "D1title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 0, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D1title",
    phaseName: "PLAN",
    domainName: "PER",
    rowID: "D1",
  },
  {
    id: "E1title",
    type: "input",
    data: { label: "Training" },
    position: { x: 0, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E1title",
    phaseName: "PLAN",
    domainName: "TRA",
    rowID: "E1",
  },
  {
    id: "F1title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 0, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F1title",
    phaseName: "PLAN",
    domainName: "SAF",
    rowID: "F1",
  },
  {
    id: "G1title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 0, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G1title",
    phaseName: "PLAN",
    domainName: "OCC",
    rowID: "G1",
  },
  {
    id: "H1title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 0, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H1title",
    phaseName: "PLAN",
    domainName: "SUR",
    rowID: "H1",
  },
  {
    id: "I1title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 0, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I1title",
    phaseName: "PLAN",
    domainName: "HAB",
    rowID: "I1",
  },
  {
    id: "J1title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 0, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J1title",
    phaseName: "PLAN",
    domainName: "ENV",
    rowID: "J1",
  },
  {
    id: "Border1",
    data: { label: null },
    position: { x: 14440, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border1",
  },
  {
    id: "STM2title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 14462, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM2title",
    phaseName: "EXEC",
    domainName: "STM",
    rowID: "STM2",
  },
  {
    id: "A2title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 14462, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A2title",
    phaseName: "EXEC",
    domainName: "INT",
    rowID: "A2",
  },
  {
    id: "B2title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 14462, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B2title",
    phaseName: "EXEC",
    domainName: "HFE",
    rowID: "B2",
  },
  {
    id: "C2title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 14462, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C2title",
    phaseName: "EXEC",
    domainName: "MAN",
    rowID: "C2",
  },
  {
    id: "D2title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 14462, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D2title",
    phaseName: "EXEC",
    domainName: "PER",
    rowID: "D2",
  },
  {
    id: "E2title",
    type: "input",
    data: { label: "Training" },
    position: { x: 14462, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E2title",
    phaseName: "EXEC",
    domainName: "TRA",
    rowID: "E2",
  },
  {
    id: "F2title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 14462, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F2title",
    phaseName: "EXEC",
    domainName: "SAF",
    rowID: "F2",
  },
  {
    id: "G2title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 14462, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G2title",
    phaseName: "EXEC",
    domainName: "OCC",
    rowID: "G2",
  },
  {
    id: "H2title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 14462, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H2title",
    phaseName: "EXEC",
    domainName: "SUR",
    rowID: "H2",
  },
  {
    id: "I2title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 14462, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I2title",
    phaseName: "EXEC",
    domainName: "HAB",
    rowID: "I2",
  },
  {
    id: "J2title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 14462, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J2title",
    phaseName: "EXEC",
    domainName: "ENV",
    rowID: "J2",
  },
  {
    id: "Border7",
    type: "input",
    data: { label: null },
    position: { x: 28882, y: 0 },
    draggable: false,
    style: {
      width: 100,
      height: 2077,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "Border7",
  },
];

export const domains5 = [
  {
    id: "STM1title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 0, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM1title",
    phaseName: "CNI",
    domainName: "STM",
    rowID: "STM1",
  },
  {
    id: "A1title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 0, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A1title",
    phaseName: "CNI",
    domainName: "INT",
    rowID: "A1",
  },
  {
    id: "B1title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 0, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B1title",
    phaseName: "CNI",
    domainName: "HFE",
    rowID: "B1",
  },
  {
    id: "C1title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 0, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C1title",
    phaseName: "CNI",
    domainName: "MAN",
    rowID: "C1",
  },
  {
    id: "D1title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 0, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D1title",
    phaseName: "CNI",
    domainName: "PER",
    rowID: "D1",
  },
  {
    id: "E1title",
    type: "input",
    data: { label: "Training" },
    position: { x: 0, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E1title",
    phaseName: "CNI",
    domainName: "TRA",
    rowID: "E1",
  },
  {
    id: "F1title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 0, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F1title",
    phaseName: "CNI",
    domainName: "SAF",
    rowID: "F1",
  },
  {
    id: "G1title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 0, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G1title",
    phaseName: "CNI",
    domainName: "OCC",
    rowID: "G1",
  },
  {
    id: "H1title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 0, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H1title",
    phaseName: "CNI",
    domainName: "SUR",
    rowID: "H1",
  },
  {
    id: "I1title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 0, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I1title",
    phaseName: "CNI",
    domainName: "HAB",
    rowID: "I1",
  },
  {
    id: "J1title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 0, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J1title",
    phaseName: "CNI",
    domainName: "ENV",
    rowID: "J1",
  },
  {
    id: "Border1",
    data: { label: null },
    position: { x: 14440, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border1",
  },
  {
    id: "STM2title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 14462, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM2title",
    phaseName: "SA",
    domainName: "STM",
    rowID: "STM2",
  },
  {
    id: "A2title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 14462, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A2title",
    phaseName: "SA",
    domainName: "INT",
    rowID: "A2",
  },
  {
    id: "B2title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 14462, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B2title",
    phaseName: "SA",
    domainName: "HFE",
    rowID: "B2",
  },
  {
    id: "C2title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 14462, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C2title",
    phaseName: "SA",
    domainName: "MAN",
    rowID: "C2",
  },
  {
    id: "D2title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 14462, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D2title",
    phaseName: "SA",
    domainName: "PER",
    rowID: "D2",
  },
  {
    id: "E2title",
    type: "input",
    data: { label: "Training" },
    position: { x: 14462, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E2title",
    phaseName: "SA",
    domainName: "TRA",
    rowID: "E2",
  },
  {
    id: "F2title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 14462, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F2title",
    phaseName: "SA",
    domainName: "SAF",
    rowID: "F2",
  },
  {
    id: "G2title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 14462, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G2title",
    phaseName: "SA",
    domainName: "OCC",
    rowID: "G2",
  },
  {
    id: "H2title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 14462, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H2title",
    phaseName: "SA",
    domainName: "SUR",
    rowID: "H2",
  },
  {
    id: "I2title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 14462, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I2title",
    phaseName: "SA",
    domainName: "HAB",
    rowID: "I2",
  },
  {
    id: "J2title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 14462, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J2title",
    phaseName: "SA",
    domainName: "ENV",
    rowID: "J2",
  },
  {
    id: "Border2",
    data: { label: null },
    position: { x: 28882, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border2",
  },
  {
    id: "STM3title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 28904, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM3title",
    phaseName: "FRAP",
    domainName: "STM",
    rowID: "STM3",
  },
  {
    id: "A3title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 28904, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A3title",
    phaseName: "FRAP",
    domainName: "INT",
    rowID: "A3",
  },
  {
    id: "B3title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 28904, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B3title",
    phaseName: "FRAP",
    domainName: "HFE",
    rowID: "B3",
  },
  {
    id: "C3title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 28904, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C3title",
    phaseName: "FRAP",
    domainName: "MAN",
    rowID: "C3",
  },
  {
    id: "D3title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 28904, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D3title",
    phaseName: "FRAP",
    domainName: "PER",
    rowID: "D3",
  },
  {
    id: "E3title",
    type: "input",
    data: { label: "Training" },
    position: { x: 28904, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E3title",
    phaseName: "FRAP",
    domainName: "TRA",
    rowID: "E3",
  },
  {
    id: "F3title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 28904, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F3title",
    phaseName: "FRAP",
    domainName: "SAF",
    rowID: "F3",
  },
  {
    id: "G3title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 28904, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G3title",
    phaseName: "FRAP",
    domainName: "OCC",
    rowID: "G3",
  },
  {
    id: "H3title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 28904, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H3title",
    phaseName: "FRAP",
    domainName: "SUR",
    rowID: "H3",
  },
  {
    id: "I3title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 28904, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I3title",
    phaseName: "FRAP",
    domainName: "HAB",
    rowID: "I3",
  },
  {
    id: "J3title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 28904, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J3title",
    phaseName: "FRAP",
    domainName: "ENV",
    rowID: "J3",
  },
  {
    id: "Border3",
    data: { label: null },
    position: { x: 43324, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border3",
  },
  {
    id: "STM4title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 43346, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM4title",
    phaseName: "ATD",
    domainName: "STM",
    rowID: "STM4",
  },
  {
    id: "A4title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 43346, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A4title",
    phaseName: "ATD",
    domainName: "INT",
    rowID: "A4",
  },
  {
    id: "B4title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 43346, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B4title",
    phaseName: "ATD",
    domainName: "HFE",
    rowID: "B4",
  },
  {
    id: "C4title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 43346, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C4title",
    phaseName: "ATD",
    domainName: "MAN",
    rowID: "C4",
  },
  {
    id: "D4title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 43346, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D4title",
    phaseName: "ATD",
    domainName: "PER",
    rowID: "D4",
  },
  {
    id: "E4title",
    type: "input",
    data: { label: "Training" },
    position: { x: 43346, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E4title",
    phaseName: "ATD",
    domainName: "TRA",
    rowID: "E4",
  },
  {
    id: "F4title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 43346, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F4title",
    phaseName: "ATD",
    domainName: "SAF",
    rowID: "F4",
  },
  {
    id: "G4title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 43346, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G4title",
    phaseName: "ATD",
    domainName: "OCC",
    rowID: "G4",
  },
  {
    id: "H4title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 43346, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H4title",
    phaseName: "ATD",
    domainName: "SUR",
    rowID: "H4",
  },
  {
    id: "I4title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 43346, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I4title",
    phaseName: "ATD",
    domainName: "HAB",
    rowID: "I4",
  },
  {
    id: "J4title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 43346, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J4title",
    phaseName: "ATD",
    domainName: "ENV",
    rowID: "J4",
  },
  {
    id: "Border4",
    data: { label: null },
    position: { x: 57766, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border4",
  },
  {
    id: "STM5title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 57788, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM5title",
    phaseName: "CS",
    domainName: "STM",
    rowID: "STM5",
  },
  {
    id: "A5title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 57788, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A5title",
    phaseName: "CS",
    domainName: "INT",
    rowID: "A5",
  },
  {
    id: "B5title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 57788, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B5title",
    phaseName: "CS",
    domainName: "HFE",
    rowID: "B5",
  },
  {
    id: "C5title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 57788, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C5title",
    phaseName: "CS",
    domainName: "MAN",
    rowID: "C5",
  },
  {
    id: "D5title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 57788, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D5title",
    phaseName: "CS",
    domainName: "PER",
    rowID: "D5",
  },
  {
    id: "E5title",
    type: "input",
    data: { label: "Training" },
    position: { x: 57788, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E5title",
    phaseName: "CS",
    domainName: "TRA",
    rowID: "E5",
  },
  {
    id: "F5title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 57788, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F5title",
    phaseName: "CS",
    domainName: "SAF",
    rowID: "F5",
  },
  {
    id: "G5title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 57788, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G5title",
    phaseName: "CS",
    domainName: "OCC",
    rowID: "G5",
  },
  {
    id: "H5title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 57788, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H5title",
    phaseName: "CS",
    domainName: "SUR",
    rowID: "H5",
  },
  {
    id: "I5title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 57788, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I5title",
    phaseName: "CS",
    domainName: "HAB",
    rowID: "I5",
  },
  {
    id: "J5title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 57788, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J5title",
    phaseName: "CS",
    domainName: "ENV",
    rowID: "J5",
  },
  {
    id: "Border7",
    type: "input",
    data: { label: null },
    position: { x: 72208, y: 0 },
    draggable: false,
    style: {
      width: 100,
      height: 2077,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "Border7",
  },
];

export const domains6 = [
  {
    id: "STM1title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 0, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM1title",
    phaseName: "PLANAOS",
    domainName: "STM",
    rowID: "STM1",
  },
  {
    id: "A1title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 0, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A1title",
    phaseName: "PLANAOS",
    domainName: "INT",
    rowID: "A1",
  },
  {
    id: "B1title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 0, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B1title",
    phaseName: "PLANAOS",
    domainName: "HFE",
    rowID: "B1",
  },
  {
    id: "C1title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 0, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C1title",
    phaseName: "PLANAOS",
    domainName: "MAN",
    rowID: "C1",
  },
  {
    id: "D1title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 0, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D1title",
    phaseName: "PLANAOS",
    domainName: "PER",
    rowID: "D1",
  },
  {
    id: "E1title",
    type: "input",
    data: { label: "Training" },
    position: { x: 0, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E1title",
    phaseName: "PLANAOS",
    domainName: "TRA",
    rowID: "E1",
  },
  {
    id: "F1title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 0, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F1title",
    phaseName: "PLANAOS",
    domainName: "SAF",
    rowID: "F1",
  },
  {
    id: "G1title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 0, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G1title",
    phaseName: "PLANAOS",
    domainName: "OCC",
    rowID: "G1",
  },
  {
    id: "H1title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 0, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H1title",
    phaseName: "PLANAOS",
    domainName: "SUR",
    rowID: "H1",
  },
  {
    id: "I1title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 0, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I1title",
    phaseName: "PLANAOS",
    domainName: "HAB",
    rowID: "I1",
  },
  {
    id: "J1title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 0, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J1title",
    phaseName: "PLANAOS",
    domainName: "ENV",
    rowID: "J1",
  },
  {
    id: "Border1",
    data: { label: null },
    position: { x: 14440, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border1",
  },
  {
    id: "STM2title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 14462, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM2title",
    phaseName: "DEVAOS",
    domainName: "STM",
    rowID: "STM2",
  },
  {
    id: "A2title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 14462, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A2title",
    phaseName: "DEVAOS",
    domainName: "INT",
    rowID: "A2",
  },
  {
    id: "B2title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 14462, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B2title",
    phaseName: "DEVAOS",
    domainName: "HFE",
    rowID: "B2",
  },
  {
    id: "C2title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 14462, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C2title",
    phaseName: "DEVAOS",
    domainName: "MAN",
    rowID: "C2",
  },
  {
    id: "D2title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 14462, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D2title",
    phaseName: "DEVAOS",
    domainName: "PER",
    rowID: "D2",
  },
  {
    id: "E2title",
    type: "input",
    data: { label: "Training" },
    position: { x: 14462, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E2title",
    phaseName: "DEVAOS",
    domainName: "TRA",
    rowID: "E2",
  },
  {
    id: "F2title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 14462, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F2title",
    phaseName: "DEVAOS",
    domainName: "SAF",
    rowID: "F2",
  },
  {
    id: "G2title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 14462, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G2title",
    phaseName: "DEVAOS",
    domainName: "OCC",
    rowID: "G2",
  },
  {
    id: "H2title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 14462, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H2title",
    phaseName: "DEVAOS",
    domainName: "SUR",
    rowID: "H2",
  },
  {
    id: "I2title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 14462, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I2title",
    phaseName: "DEVAOS",
    domainName: "HAB",
    rowID: "I2",
  },
  {
    id: "J2title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 14462, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J2title",
    phaseName: "DEVAOS",
    domainName: "ENV",
    rowID: "J2",
  },
  {
    id: "Border2",
    data: { label: null },
    position: { x: 28882, y: 0 },
    draggable: false,
    style: {
      width: 10,
      height: 2077,
      backgroundColor: "black",
      color: "white",
    },
    className: "Border2",
  },
  {
    id: "STM3title",
    type: "input",
    data: { label: "SE Tasks and Milestones" },
    position: { x: 28904, y: 65 },
    draggable: false,
    style: {
      width: 100,
      height: 100,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "STM3title",
    phaseName: "EXECU",
    domainName: "STM",
    rowID: "STM3",
  },
  {
    id: "A3title",
    type: "input",
    data: { label: "HSI Integrator" },
    position: { x: 28904, y: 165 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "A3title",
    phaseName: "EXECU",
    domainName: "INT",
    rowID: "A3",
  },
  {
    id: "B3title",
    type: "input",
    data: { label: "Human Factors Engineering" },
    position: { x: 28904, y: 358 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "B3title",
    phaseName: "EXECU",
    domainName: "HFE",
    rowID: "B3",
  },
  {
    id: "C3title",
    type: "input",
    data: { label: "Manpower" },
    position: { x: 28904, y: 541 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "C3title",
    phaseName: "EXECU",
    domainName: "MAN",
    rowID: "C3",
  },
  {
    id: "D3title",
    type: "input",
    data: { label: "Personnel" },
    position: { x: 28904, y: 724 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "D3title",
    phaseName: "EXECU",
    domainName: "PER",
    rowID: "D3",
  },
  {
    id: "E3title",
    type: "input",
    data: { label: "Training" },
    position: { x: 28904, y: 907 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "E3title",
    phaseName: "EXECU",
    domainName: "TRA",
    rowID: "E3",
  },
  {
    id: "F3title",
    type: "input",
    data: { label: "Safety" },
    position: { x: 28904, y: 1090 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "F3title",
    phaseName: "EXECU",
    domainName: "SAF",
    rowID: "F3",
  },
  {
    id: "G3title",
    type: "input",
    data: { label: "Occupational Health" },
    position: { x: 28904, y: 1273 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "G3title",
    phaseName: "EXECU",
    domainName: "OCC",
    rowID: "G3",
  },
  {
    id: "H3title",
    type: "input",
    data: { label: "Survivability" },
    position: { x: 28904, y: 1456 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "H3title",
    phaseName: "EXECU",
    domainName: "SUR",
    rowID: "H3",
  },
  {
    id: "I3title",
    type: "input",
    data: { label: "Habitability" },
    position: { x: 28904, y: 1639 },
    draggable: false,
    style: {
      width: 100,
      height: 183,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "I3title",
    phaseName: "EXECU",
    domainName: "HAB",
    rowID: "I3",
  },
  {
    id: "J3title",
    type: "input",
    data: { label: "Environment" },
    position: { x: 28904, y: 1822 },
    draggable: false,
    style: {
      width: 100,
      height: 193,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "J3title",
    phaseName: "EXECU",
    domainName: "ENV",
    rowID: "J3",
  },
  {
    id: "Border7",
    type: "input",
    data: { label: null },
    position: { x: 43324, y: 0 },
    draggable: false,
    style: {
      width: 100,
      height: 2077,
      backgroundColor: "black",
      cursor: "pointer",
      color: "white",
    },
    className: "Border7",
  },
];
