import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Navbar from "../../components/navbar/navbar";
import "./Report.css";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { AiOutlineLeft } from "react-icons/ai";
import {
  BarChart,
  Bar,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  Label,
} from "recharts";
import FeedbackModal from "../../components/feedbackModal/feedbackModal";
import NewProjectModal from "../../components/newProjectModal/newProjectModal";
import AcronymModal from "../../components/acronymModal/acronymModal";
import ProductLibraryModal from "../../components/productLibraryModal/productLibraryModal";
import ReferenceLibraryModal from "../../components/referenceLibraryModal/referenceLibraryModal";
import DidsModal from "../../components/didsModal/didsModal";
import ToolsModal from "../../components/toolsModal/toolsModal";
import { setNavSearch, setFilterBox } from "../../redux/actions/Data";
import { reportTime } from "../../lib/helpers/time";
import {
  editReportCategories,
  setUserReportType,
} from "../../redux/actions/Projects";
import ReportTables from "./ReportTables";
import ProgressBar from "./ProgressBar";
import SummaryTables from "./SummaryTables";
import { ReactFlowProvider } from "reactflow";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const data = [
  {
    name: "Low Risk",
    lr: 2,
    riskTotal: 2,
    color: "green",
  },
  {
    name: "Medium Risk",
    mr: 1,
    riskTotal: 1,
    color: "yellow",
  },
  {
    name: "High Risk",
    hr: 0,
    riskTotal: 0,
    color: "red",
  },
  {
    name: "Not Assigned",
    na: 0,
    riskTotal: 0,
    color: "grey",
  },
];

const Report: React.FC<{
  style: any;
  setNavSearch: (text: string) => void;
  setFilterBox: (box: boolean) => void;
  user: any;
  navSearch: string;
  phaseReportList: any;
  domainReportList: any;
  riskReportList: any;
  userReportType: string;
  setUserReportType: (report: string) => void;
  editReportCategories: (reportSettings: object) => void;
  userReportObject: any;
  activityStatusList: any;
}> = ({
  style,
  setNavSearch,
  setFilterBox,
  user,
  navSearch,
  phaseReportList,
  domainReportList,
  riskReportList,
  userReportType,
  setUserReportType,
  editReportCategories,
  userReportObject,
  activityStatusList,
}) => {
  const navigate = useNavigate();
  const [summaryView, setSummaryView] = useState(true) as any;
  const [tableView, setTableView] = useState(true) as any;
  const [activityView, setActivityView] = useState(true) as any;
  const [completedView, setCompletedView] = useState(true) as any;
  const [referenceView, setReferenceView] = useState(true) as any;
  const [reportType, setReportType] = useState("") as any;
  const [feedback, setFeedback] = useState(false) as any;
  const [settingMenu, setSettingMenu] = useState(false) as any;
  const [helpMenu, setHelpMenu] = useState(false) as any;
  const [newProjectM, setNewProjectM] = useState(false) as any;
  const [acronymM, setAcronymM] = useState(false) as any;
  const [productM, setProductM] = useState(false) as any;
  const [referenceM, setReferenceM] = useState(false) as any;
  const [didsM, setDidsM] = useState(false) as any;
  const [toolsM, setToolsM] = useState(false) as any;
  const [reportSummary, setReportSummary] = useState([]) as any;

  const clickFeedback = () => {
    setFeedback(!feedback);
  };

  const closeFeedback = () => {
    setFeedback(false);
  };

  const closePopups = () => {
    setSettingMenu(false);
    setHelpMenu(false);
  };

  const handleSettings = () => {
    setSettingMenu(!settingMenu);
    setHelpMenu(false);
  };

  const handleHelp = () => {
    setHelpMenu(!helpMenu);
    setSettingMenu(false);
  };

  const openNewProjectM = () => {
    setNewProjectM(true);
  };

  const closeNewProjectM = () => {
    setNewProjectM(false);
  };

  //Acronym Modal
  const openAcronymM = () => {
    setAcronymM(true);
  };
  const closeAcronymM = () => {
    setNavSearch("");
    setAcronymM(false);
  };

  //Product Modal
  const openProductM = () => {
    setProductM(true);
  };
  const closeProductM = () => {
    setProductM(false);
    setFilterBox(navSearch.length > 0 ? true : false);
    setNavSearch("");
  };

  //Reference Modal
  const openReferenceM = () => {
    setReferenceM(true);
  };
  const closeReferenceM = () => {
    setReferenceM(false);
    setFilterBox(navSearch.length > 0 ? true : false);
    setNavSearch("");
  };

  //DIDS Modal
  const openDIDSM = () => {
    setDidsM(true);
  };
  const closeDIDSM = () => {
    setDidsM(false);
  };

  //Tools Modal
  const openToolsM = () => {
    setToolsM(true);
  };
  const closeToolsM = () => {
    setNavSearch("");
    setToolsM(false);
  };

  const closeButton = () => {
    setUserReportType("");
    editReportCategories({
      userReportSelected: userReportObject.selectedProject,
      phase: [],
      domain: [],
      risk: [],
      activity: [],
      notes: [],
      content: [],
      management: [],
      riskStartDate: "",
      riskEndDate: "",
      statusStartDate: "",
      statusEndDate: "",
      updateStartDate: "",
      updateEndDate: "",
      addedStartDate: "",
      addedEndDate: "",
      modifiedStartDate: "",
      modifiedEndDate: "",
      deletedStartDate: "",
      deletedEndDate: "",
      reportType: userReportObject.userReportType,
    });
    navigate("/dashboard");
  };

  const downloadPDF = () => {
    const input = document.querySelector(".reportTableSection");
    // const div1 = document.querySelector(".reportTypeInfo");
    // const div2 = document.querySelector(".summarChart");
    // const div3 = document.querySelector(".reportTableSection");

    // // Create a new div to combine the selected divs
    // const combinedDiv = document.createElement("div");
    // combinedDiv.classList.add("combined-div");

    // // Append the selected divs to the combined div
    // combinedDiv.appendChild(div1 as HTMLElement);
    // combinedDiv.appendChild(div2 as HTMLElement);
    // combinedDiv.appendChild(div3 as HTMLElement);

    html2canvas(input as HTMLElement)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgWidth = 210; // A4 width in mm
        const componentWidth = pdf.internal.pageSize.getWidth();
        const componentHeight = pdf.internal.pageSize.getHeight();
        const pageHeight = (canvas.height * imgWidth) / canvas.width;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        // pdf.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("report.pdf");
      })
      .catch((error) => console.error("Error converting to PDF:", error));
  };

  useEffect(() => {
    setNavSearch("");
    let lrTotal = 0;
    let mrTotal = 0;
    let hrTotal = 0;
    let naTotal = 0;
    let nsTotal = 0;
    let ipTotal = 0;
    let cTotal = 0;
    let snaTotal = 0;

    for (let i = 0; i < userReportObject?.reportData?.length; i++) {
      // console.log("userReportType", userReportObject?.userReportType);
      if (userReportObject?.userReportType === "Risk Report") {
        if (userReportObject?.reportData[i].hsiRiskLvl === "Low")
          lrTotal = lrTotal + 1;
        else if (userReportObject?.reportData[i].hsiRiskLvl === "Medium")
          mrTotal = mrTotal + 1;
        else if (userReportObject?.reportData[i].hsiRiskLvl === "High")
          hrTotal = hrTotal + 1;
        else naTotal = naTotal + 1;
      } else if (userReportObject?.userReportType === "Status Report") {
        if (userReportObject?.reportData[i].activityStatus === "Not Started")
          nsTotal = nsTotal + 1;
        else if (
          userReportObject?.reportData[i].activityStatus === "In Progress"
        )
          ipTotal = ipTotal + 1;
        else if (userReportObject?.reportData[i].activityStatus === "Completed")
          cTotal = cTotal + 1;
        else snaTotal = snaTotal + 1;
      } else if (
        userReportObject?.userReportType === "Custom Activities Report"
      ) {
        if (userReportObject?.reportData[i].hsiRiskLvl === "Low")
          lrTotal = lrTotal + 1;
        else if (userReportObject?.reportData[i].hsiRiskLvl === "Medium")
          mrTotal = mrTotal + 1;
        else if (userReportObject?.reportData[i].hsiRiskLvl === "High")
          hrTotal = hrTotal + 1;
        else naTotal = naTotal + 1;
      } else if (
        userReportObject?.userReportType === "Custom Libraries Report"
      ) {
        if (userReportObject?.reportData[i].activityStatus === "Not Started")
          nsTotal = nsTotal + 1;
        else if (
          userReportObject?.reportData[i].activityStatus === "In Progress"
        )
          ipTotal = ipTotal + 1;
        else if (userReportObject?.reportData[i].activityStatus === "Completed")
          cTotal = cTotal + 1;
        else snaTotal = snaTotal + 1;

        if (userReportObject?.reportData[i].hsiRiskLvl === "Low")
          lrTotal = lrTotal + 1;
        else if (userReportObject?.reportData[i].hsiRiskLvl === "Medium")
          mrTotal = mrTotal + 1;
        else if (userReportObject?.reportData[i].hsiRiskLvl === "High")
          hrTotal = hrTotal + 1;
        else naTotal = naTotal + 1;
      }
    }

    let itemSummary: any = [];

    if (userReportObject?.userReportType === "Risk Report") {
      // itemSummary = [
      //   {
      //     name: "Low Risk",
      //     lr: lrTotal,
      //     itemTotal: lrTotal,
      //     color: "green",
      //   },
      //   {
      //     name: "Medium Risk",
      //     mr: mrTotal,
      //     itemTotal: mrTotal,
      //     color: "yellow",
      //   },
      //   {
      //     name: "High Risk",
      //     hr: hrTotal,
      //     itemTotal: hrTotal,
      //     color: "red",
      //   },
      //   {
      //     name: "Not Assigned",
      //     na: naTotal,
      //     itemTotal: naTotal,
      //     color: "grey",
      //   },
      // ];
      let summaryItems: any = [];
      // if (userReportObject?.riskReportList.includes("Low"))
      summaryItems.push({
        name: "Low Risk",
        lr: lrTotal,
        itemTotal: lrTotal,
        color: "green",
      });
      // if (userReportObject?.riskReportList.includes("Medium"))
      summaryItems.push({
        name: "Medium Risk",
        mr: mrTotal,
        itemTotal: mrTotal,
        color: "yellow",
      });
      // if (userReportObject?.riskReportList.includes("High"))
      summaryItems.push({
        name: "High Risk",
        hr: hrTotal,
        itemTotal: hrTotal,
        color: "red",
      });
      // if (userReportObject?.riskReportList.includes("Not Assigned"))
      summaryItems.push({
        name: "Not Assigned",
        na: naTotal,
        itemTotal: naTotal,
        color: "grey",
      });
      itemSummary = summaryItems;
    } else if (userReportObject?.userReportType === "Status Report") {
      //revisit let summaryItems: any = [];
      // if (userReportObject?.riskReportList.includes("Low"))
      //   summaryItems.push({
      //     name: "Low Risk",
      //     lr: lrTotal,
      //     itemTotal: lrTotal,
      //     color: "green",
      //   });
      itemSummary = [
        {
          name: "Not Started",
          lr: nsTotal,
          itemTotal: nsTotal,
          color: "green",
        },
        {
          name: "In Progress",
          mr: ipTotal,
          itemTotal: ipTotal,
          color: "yellow",
        },
        {
          name: "Completed",
          hr: cTotal,
          itemTotal: cTotal,
          color: "red",
        },
        {
          name: "Not Assigned",
          na: snaTotal,
          itemTotal: snaTotal,
          color: "grey",
        },
      ];
    } else if (
      userReportObject?.userReportType === "Custom Activities Report"
    ) {
      itemSummary = [
        {
          name: "Low Risk",
          lr: lrTotal,
          itemTotal: lrTotal,
          color: "green",
        },
        {
          name: "Medium Risk",
          mr: mrTotal,
          itemTotal: mrTotal,
          color: "yellow",
        },
        {
          name: "High Risk",
          hr: hrTotal,
          itemTotal: hrTotal,
          color: "red",
        },
        {
          name: "Not Assigned",
          na: naTotal,
          itemTotal: naTotal,
          color: "grey",
        },
      ];
    } else if (userReportObject?.userReportType === "Custom Libraries Report") {
      itemSummary = [
        {
          name: "Low Risk",
          lr: lrTotal,
          itemTotal: lrTotal,
          color: "green",
        },
        {
          name: "Medium Risk",
          mr: mrTotal,
          itemTotal: mrTotal,
          color: "yellow",
        },
        {
          name: "High Risk",
          hr: hrTotal,
          itemTotal: hrTotal,
          color: "red",
        },
        {
          name: "Not Assigned",
          na: naTotal,
          itemTotal: naTotal,
          color: "grey",
        },
      ];
    }

    setReportSummary(itemSummary);
    // console.log("reportSummary", itemSummary);
  }, [userReportObject?.reportData]);

  const renderLegend = () => {
    // console.log("pay", data);

    return (
      <div>
        <p
          style={{
            marginLeft: "22px",
            marginBottom: "-10px",
            fontWeight: "bold",
            fontSize: ".85vw",
          }}
        >
          Summary
        </p>
        <ul style={{ listStyleType: "square" }}>
          {reportSummary?.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.color }}>
              <p
                style={{ color: "black", fontSize: ".85vw" }}
              >{`${entry.itemTotal} ${entry.name}`}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: style.colors.white,
        paddingBottom: "30px",
      }}
    >
      <ReactFlowProvider>
        <Navbar
          clickFeedback={clickFeedback}
          settingMenu={settingMenu}
          helpMenu={helpMenu}
          handleSettings={handleSettings}
          handleHelp={handleHelp}
          closePopups={closePopups}
          screenName={`Reports`}
          openAcronymM={openAcronymM}
          openProductM={openProductM}
          openReferenceM={openReferenceM}
          openDIDSM={openDIDSM}
          openToolsM={openToolsM}
        />
      </ReactFlowProvider>
      <div
        id="reportBody"
        className="actual-report"
        style={{
          height: "100vh",
          width: "100vw",
          overflowY: "auto",
        }}
      >
        <div
          id="reportHeader"
          style={{
            backgroundColor: style.colors.primaryBlue,
            height: "40px",
            display: "flex",
            position: "relative",
          }}
        >
          <AiOutlineLeft
            onClick={() => navigate(-1)}
            style={{
              color: style.colors.white,
              fontSize: 20,
              // marginLeft: "40px",
              // marginTop: "9px",
              cursor: "pointer",
              position: "absolute",
              top: 9,
              left: 8,
            }}
          />
          <p
            style={{
              color: style.colors.white,
              paddingLeft: "6vw",
              marginTop: "11px",
            }}
          >
            {userReportType}
          </p>
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            <MdClose
              onClick={closeButton}
              style={{
                color: style.colors.white,
                marginLeft: "auto",
                marginRight: "40px",
                fontSize: 20,
                cursor: "pointer",
                marginTop: "11px",
              }}
            />
          </div>
        </div>
        <div
          id="content"
          style={{
            paddingTop: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "-20px",
            }}
          >
            <p
              className="responsiveReportText"
              style={{
                fontWeight: "bold",
                // fontSize: "1.65vh",
                marginBottom: 0,
              }}
            >
              {`${userReportObject?.selectedProject?.name}: ${userReportType}`}
            </p>
            <p
              className="responsiveReportText"
              style={{
                marginLeft: "10px",
                // fontSize: "12px",
                // marginTop: "19px",
                marginBottom: 0,
              }}
            >
              {reportTime()}
            </p>

            <div
              style={{
                marginLeft: "auto",
                marginTop: "10px",
                marginRight: "40px",
              }}
            >
              <button
                id="exportBtn"
                onClick={downloadPDF}
                style={{
                  backgroundColor: "grey",
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  marginRight: "30px",
                  cursor: "pointer",
                }}
              >
                Export
              </button>
              <button
                id="printBtn"
                // onClick={downloadPDF}
                onClick={() => window.print()}
                style={{
                  backgroundColor: "grey",
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Print
              </button>
            </div>
          </div>

          <div
            className="reportTypeInfo"
            style={{
              paddingLeft: "30px",
              marginTop: "30px",
              paddingBottom: "5px",
            }}
          >
            <span className="responsiveReportText">
              {"Phase(s): "}
              {phaseReportList.length > 0 ? phaseReportList.join(", ") : null}
            </span>
            <br />
            <span className="responsiveReportText">
              {"Domain(s): "}
              {domainReportList.length > 0 ? domainReportList.join(", ") : null}
            </span>
            <br />
            {userReportObject?.userReportType === "Risk Report" ? (
              <>
                <span className="responsiveReportText">
                  {"ENVHSI Risk(s): "}
                  {riskReportList.length > 0 ? riskReportList.join(", ") : null}
                </span>
                <br />
              </>
            ) : null}
            {userReportObject?.userReportType === "Risk Report" &&
            userReportObject?.riskReportList.includes("Include") ? (
              <>
                <span className="responsiveReportText">
                  {"Include HSI Risk Description: "}
                  {/* {riskReportList.length > 0 ? riskReportList.join(", ") : null} */}
                </span>
                <br />
              </>
            ) : null}
            {userReportObject?.userReportType === "Status Report" ? (
              <>
                <span className="responsiveReportText">
                  {"Status(es): "}
                  {activityStatusList?.length > 0
                    ? activityStatusList.join(", ")
                    : null}
                </span>
                <br />
              </>
            ) : null}
          </div>

          {/* </div> */}
        </div>{" "}
        {userReportObject?.userReportType === "Related Items Report" ? (
          <SummaryTables
            summaryView={summaryView}
            setSummaryView={setSummaryView}
          />
        ) : userReportObject?.userReportType === "Content Report" ? (
          <SummaryTables
            summaryView={summaryView}
            setSummaryView={setSummaryView}
          />
        ) : userReportObject?.userReportType !== "Custom Libraries Report" ? (
          <>
            <div
              className="reportRowHeaders"
              style={{
                // backgroundColor: "rgb(18, 18, 201)",
                // // width: "94vw",
                // minWidth: "970px",
                // maxWidth: "94vw",
                // height: "35px",
                // display: "flex",
                marginLeft: "20px",
              }}
            >
              <BsCaretDownFill
                className="responsiveReportText"
                onClick={() => setSummaryView(!summaryView)}
                style={{
                  color: style.colors.white,
                  // marginTop: "9px",
                  // fontSize: "1.65vh",
                  marginLeft: "10px",
                  cursor: "pointer",
                  transform: !summaryView ? "rotate(270deg)" : "rotate(0deg)",
                }}
              />
              <p
                className="responsiveReportText"
                style={{
                  color: style.colors.white,
                  // marginTop: "10px",
                  paddingLeft: "40px",
                  // fontSize: "1.65vh",
                }}
              >
                Risk Summary
              </p>
            </div>
            <div
              className="summarChart"
              style={{
                display: !summaryView ? "none" : "block",
                marginTop: "20px",
                paddingBottom: "10px",
              }}
            >
              {/* <ProgressBar /> */}
              <ResponsiveContainer width="90%" aspect={7 / 1}>
                <BarChart
                  width={500}
                  height={300}
                  data={reportSummary}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <YAxis allowDecimals={false} />
                  <Legend
                    content={renderLegend}
                    layout="vertical"
                    verticalAlign="middle"
                    align="right"
                    wrapperStyle={{
                      paddingLeft: "70px",
                    }}
                  />
                  <Bar dataKey="lr" fill="green" name="Low Risk" />
                  <Bar dataKey="mr" fill="yellow" name="Medium Risk" />
                  <Bar dataKey="hr" fill="red" name="High Risk" />
                  <Bar dataKey="na" fill="grey" name="Not Assigned" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </>
        ) : null}
        <ReportTables
          tableView={tableView}
          setTableView={setTableView}
          activityView={activityView}
          setActivityView={setActivityView}
          completedView={completedView}
          setCompletedView={setCompletedView}
          referenceView={referenceView}
          setReferenceView={setReferenceView}
        />
      </div>

      <FeedbackModal closeFeedback={closeFeedback} feedback={feedback} />
      <NewProjectModal
        closeNewProjectM={closeNewProjectM}
        newProjectM={newProjectM}
      />
      <AcronymModal closeAcronymM={closeAcronymM} acronymM={acronymM} />
      <ProductLibraryModal closeProductM={closeProductM} productM={productM} />
      <ReferenceLibraryModal
        closeReferenceM={closeReferenceM}
        referenceM={referenceM}
      />
      <DidsModal closeDIDSM={closeDIDSM} didsM={didsM} />
      <ToolsModal closeToolsM={closeToolsM} toolsM={toolsM} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  user: state.user.user,
  navSearch: state.data.navSearch,
  phaseReportList: state.projects.projectToReport.phaseReportList,
  domainReportList: state.projects.projectToReport.domainReportList,
  riskReportList: state.projects.projectToReport.riskReportList,
  userReportType: state.projects.projectToReport.userReportType,
  userReportObject: state.projects.projectToReport,
  activityStatusList: state.projects.projectToReport.activityStatusList,
});

export default connect(mapStateToProps, {
  setNavSearch,
  setFilterBox,
  setUserReportType,
  editReportCategories,
})(Report) as any;
