import { TEST } from "../actions/types";

const initialState = {
  placeholder: null,
  style: {
    colors: {
      grey1: "#eeedee",
      grey2: "#e1e2e1",
      grey3: "#d2d3d4",
      grey4: "#b4b4b4",
      grey5: "#9f9f9f",
      white: "#ffffff",
      textWhite: "#ffffff",
      disabledText: "#a2a2a2",
      secondaryText: "#6c6c6c",
      primaryText: "#2a2a2a",
      lightBlue: "#e7f4ff",
      primaryBlue: "#047cc0",
      darkBlue: "#003c64",
      lightRed: "#fce6e8",
      darkRed: "#e7343d",
      lightGreen: "#e9f5e9",
      darkGreen: "#5ab54d",
      lightYellow: "#fff5e6",
      darkYellow: "#ffa82b",
    },
    fonts: {
      light: "OpenSans-Light",
      regular: "OpenSans-Regular",
      semiBold: "OpenSans-Semibold",
      bold: "OpenSans-Bold",
    },
  },
};

export default function (state = initialState as any, action: any) {
  switch (action.type) {
    case TEST:
      return {
        ...state,
      };
    default:
      return state;
  }
}
