import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./productLibraryModal.css";
import {
  getProductLibrary,
  addNewProduct,
  updateProduct,
  deleteProduct,
} from "../../redux/actions/Data";
import {
  editActivityProductInputsList,
  editActivityProductOutputsList,
} from "../../redux/actions/Activities";
import {
  setActivityIDList,
  setActivityProductsChanged,
} from "../../redux/actions/Projects";
import { productSort } from "../../lib/helpers/columnFilters";
import { ReactComponent as Editsvg } from "../../lib/assets/img/Edit.svg";

const ProductLibraryModal: React.FC<{
  closeProductM: any;
  productM: any;
  productLibrary: any;
  getProductLibrary: () => void;
  style: any;
  navSearch: string;
  addNewProduct: (
    name: string,
    domain: string,
    phases: string,
    type: string,
    inputOutput: string,
    ConOwn: string
  ) => void;
  updateProduct: (
    name: string,
    domain: string,
    phases: string,
    type: string,
    inputOutput: string,
    ConOwn: string,
    id: number
  ) => void;
  deleteProduct: (id: number, intOut: string) => void;
  activityLibraryEdit: boolean;
  editActivityProductInputsList: (activityId: string, list: any) => void;
  editActivityProductOutputsList: (activityId: string, list: any) => void;
  activeProjectActivity: any;
  setActivityIDList: (itemID: any) => void;
  activityIDList: any;
  setActivityProductsChanged: (state: any) => void;
  activityProductsChanged: boolean;
  initialProductsInputs: any;
  initialProductsOutputs: any;
  detailsProduct: any;
  setDetailsProduct: any;
  user: any;
}> = ({
  closeProductM,
  productM,
  productLibrary,
  getProductLibrary,
  style,
  navSearch,
  addNewProduct,
  updateProduct,
  deleteProduct,
  activityLibraryEdit,
  editActivityProductInputsList,
  editActivityProductOutputsList,
  activeProjectActivity,
  setActivityIDList,
  activityIDList,
  setActivityProductsChanged,
  activityProductsChanged,
  initialProductsInputs,
  initialProductsOutputs,
  detailsProduct,
  setDetailsProduct,
  user,
}) => {
  const [newProductModal, setNewProductModal] = useState(false) as any;
  const [productSearch, setProductSearch] = useState("") as any;
  const [searchFiltered, setSearchFiltered] = useState([]) as any;
  const [nameAscending, setNameAscending] = useState(1) as any;
  const [domainAscending, setDomainAscending] = useState(0) as any;
  const [phaseAscending, setPhaseAscending] = useState(0) as any;
  const [typeAscending, setTypeAscending] = useState(0) as any;
  const [intOutAscending, setIntOutAscending] = useState(0) as any;
  const [conOwnAscending, setConOwnAscending] = useState(0) as any;
  const [dateAscending, setDateAscending] = useState(0) as any;
  const [newName, setNewName] = useState("") as any;
  const [newDomain, setNewDomain] = useState("") as any;
  const [phaseTMRR, setPhaseTMRR] = useState(false) as any;
  const [phaseMSA, setPhaseMSA] = useState(false) as any;
  const [phaseOS, setPhaseOS] = useState(false) as any;
  const [phasePD, setPhasePD] = useState(false) as any;
  const [phaseEMD, setPhaseEMD] = useState(false) as any;
  const [phasePREMDD, setPhasePREMDD] = useState(false) as any;
  const [newType, setNewType] = useState("SETR") as any;
  const [newInput, setNewInput] = useState(false) as any;
  const [newOutput, setNewOutput] = useState(false) as any;
  const [newConOwn, setNewConOwn] = useState("") as any;
  const [deleteOption, setDeleteOption] = useState(false) as any;
  const [focusId, setFocusId] = useState(0) as any;
  const [focusOccurr, setFocusOccurr] = useState([]) as any;
  const [nameError, setNameError] = useState(false) as any;
  const [typeError, setTypeError] = useState(false) as any;
  const [intOutError, setIntOutError] = useState(false) as any;
  const [conOwnError, setConOwnError] = useState(false) as any;
  const [idList, setIDList] = useState([]) as any;
  const [deleteModal, setDeleteModal] = useState(false) as any;
  const [currentSort, setCurrentSort] = useState("name") as any;
  const [dateCreated, setDateCreated] = useState() as any;

  useEffect(() => {
    getProductLibrary();
  }, []);

  useEffect(() => {
    setProductSearch(navSearch);
    if (detailsProduct.length > 0) {
      setProductSearch(detailsProduct);
    }
  }, [navSearch, detailsProduct]);

  useEffect(() => {
    if (productLibrary?.length) {
      let productList = productLibrary?.filter(
        (x) => !x.hasOwnProperty("init_id")
      );

      if (productSearch !== "" && productLibrary?.length > 0) {
        productList = productList?.filter(
          (item: any) => item.productId !== null
        );
        productList = productList?.filter(
          (item: any) =>
            item?.name?.toLowerCase().indexOf(productSearch.toLowerCase()) > -1
          //revisit  ||
          // item.domainId.toLowerCase().indexOf(productSearch.toLowerCase()) >
          //   -1 ||
          // item.phase.toLowerCase().indexOf(productSearch.toLowerCase()) >
          //   -1 ||
          // item.scope.toLowerCase().indexOf(productSearch.toLowerCase()) >
          //   -1 ||
          // item.type.toLowerCase().indexOf(productSearch.toLowerCase()) > -1 ||
          // item.contributorOwner
          //   .toLowerCase()
          //   .indexOf(productSearch.toLowerCase()) > -1
        );
      }

      productList = productSort(
        productList,
        nameAscending,
        domainAscending,
        phaseAscending,
        typeAscending,
        intOutAscending,
        conOwnAscending,
        dateAscending
      );

      setSearchFiltered(productList);
    }
  }, [
    productSearch,
    productLibrary,
    nameAscending,
    domainAscending,
    phaseAscending,
    typeAscending,
    intOutAscending,
    conOwnAscending,
    dateAscending,
  ]);

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // const dateColumn = () => {
  //   dateAscending === 0
  //     ? setDateAscending(1)
  //     : dateAscending === 1
  //     ? setDateAscending(2)
  //     : setDateAscending(0);
  // };

  const nameColumn = () => {
    setCurrentSort(currentSort !== "name" ? "name" : currentSort);
    setDomainAscending(0);
    setPhaseAscending(0);
    setTypeAscending(0);
    setIntOutAscending(0);
    setConOwnAscending(0);
    nameAscending === 1 ? setNameAscending(2) : setNameAscending(1);
  };

  const domainColumn = () => {
    setCurrentSort(currentSort !== "domain" ? "domain" : currentSort);
    setNameAscending(0);
    setPhaseAscending(0);
    setTypeAscending(0);
    setIntOutAscending(0);
    setConOwnAscending(0);
    domainAscending === 1 ? setDomainAscending(2) : setDomainAscending(1);
  };

  const phaseColumn = () => {
    setCurrentSort(currentSort !== "phase" ? "phase" : currentSort);
    setDomainAscending(0);
    setNameAscending(0);
    setTypeAscending(0);
    setIntOutAscending(0);
    setConOwnAscending(0);
    phaseAscending === 1 ? setPhaseAscending(2) : setPhaseAscending(1);
  };

  const typeColumn = () => {
    setCurrentSort(currentSort !== "type" ? "type" : currentSort);
    setDomainAscending(0);
    setPhaseAscending(0);
    setNameAscending(0);
    setIntOutAscending(0);
    setConOwnAscending(0);
    typeAscending === 1 ? setTypeAscending(2) : setTypeAscending(1);
  };

  const intOutColumn = () => {
    setCurrentSort(currentSort !== "intOut" ? "intOut" : currentSort);
    setDomainAscending(0);
    setPhaseAscending(0);
    setTypeAscending(0);
    setNameAscending(0);
    setConOwnAscending(0);
    intOutAscending === 1 ? setIntOutAscending(2) : setIntOutAscending(1);
  };

  const conOwnColumn = () => {
    setCurrentSort(currentSort !== "conOwn" ? "conOwn" : currentSort);
    setDomainAscending(0);
    setPhaseAscending(0);
    setTypeAscending(0);
    setIntOutAscending(0);
    setNameAscending(0);
    conOwnAscending === 1 ? setConOwnAscending(2) : setConOwnAscending(1);
  };

  const domainHSICheck = () => {
    if (newDomain === "HSI") {
      setNewDomain("");
    } else setNewDomain("HSI");
  };

  const domainTECheck = () => {
    if (newDomain === "T&E") {
      setNewDomain("");
    } else setNewDomain("T&E");
  };

  const domainMPTCheck = () => {
    if (newDomain === "MPT") {
      setNewDomain("");
    } else setNewDomain("MPT");
  };

  const domainESOHCheck = () => {
    if (newDomain === "ESOH") {
      setNewDomain("");
    } else setNewDomain("ESOH");
  };

  const domainHFECheck = () => {
    if (newDomain === "HFE") {
      setNewDomain("");
    } else setNewDomain("HFE");
  };

  const phaseTMRRCheck = () => {
    setPhaseTMRR(!phaseTMRR);
  };

  const phaseMSACheck = () => {
    setPhaseMSA(!phaseMSA);
  };

  const phaseOSCheck = () => {
    setPhaseOS(!phaseOS);
  };

  const phasePDCheck = () => {
    setPhasePD(!phasePD);
  };

  const phaseEMDCheck = () => {
    setPhaseEMD(!phaseEMD);
  };

  const phasePREMDDCheck = () => {
    setPhasePREMDD(!phasePREMDD);
  };

  const inputCheck = () => {
    setNewInput(!newInput);
  };

  const outputCheck = () => {
    setNewOutput(!newOutput);
  };

  const contributorCheck = () => {
    if (newConOwn === "Contributor") {
      setNewConOwn("");
    } else setNewConOwn("Contributor");
  };

  const ownerCheck = () => {
    if (newConOwn === "Owner") {
      setNewConOwn("");
    } else setNewConOwn("Owner");
  };

  const submitProduct = (submitType: string) => {
    var phaseString: any = [];
    if (phaseEMD) {
      phaseString.push("EMD");
    }
    if (phaseMSA) {
      phaseString.push("MSA");
    }
    if (phaseOS) {
      phaseString.push("OS");
    }
    if (phasePD) {
      phaseString.push("PD");
    }
    if (phasePREMDD) {
      phaseString.push("PRE:MDD");
    }
    if (phaseTMRR) {
      phaseString.push("TMRR");
    }
    phaseString = phaseString.join().replace(/,[s]*/g, ", ");
    let intOut;
    if (newInput && newOutput) {
      intOut = "InputOutput";
    } else if (newInput && !newOutput) {
      intOut = "Input";
    } else if (!newInput && newOutput) {
      intOut = "Output";
    } else {
      intOut = "";
    }

    if (submitType === "newProduct") {
      setNameAscending(nameAscending);
      setDomainAscending(domainAscending);
      setPhaseAscending(phaseAscending);
      setTypeAscending(typeAscending);
      setIntOutAscending(intOutAscending);
      setConOwnAscending(conOwnAscending);
      setDateAscending(dateAscending);
      if (intOut === "InputOutput") {
        // addNewProduct(
        //   newName,
        //   newDomain,
        //   phaseString,
        //   newType,
        //   "Input",
        //   newConOwn
        // );
        addNewProduct(
          newName,
          newDomain,
          phaseString,
          newType,
          intOut,
          newConOwn
        );
      } else {
        addNewProduct(
          newName,
          newDomain,
          phaseString,
          newType,
          intOut,
          newConOwn
        );
      }
    } else if (submitType === "updateProduct") {
      setNameAscending(nameAscending);
      setDomainAscending(domainAscending);
      setPhaseAscending(phaseAscending);
      setTypeAscending(typeAscending);
      setIntOutAscending(intOutAscending);
      setConOwnAscending(conOwnAscending);
      setDateAscending(dateAscending);
      if (intOut === "InputOutput") {
        // updateProduct(
        //   newName,
        //   newDomain,
        //   phaseString,
        //   newType,
        //   "Input",
        //   newConOwn,
        //   focusId
        // );
        updateProduct(
          newName,
          newDomain,
          phaseString,
          newType,
          intOut,
          newConOwn,
          focusId
        );
      } else {
        updateProduct(
          newName,
          newDomain,
          phaseString,
          newType,
          intOut,
          newConOwn,
          focusId
        );
      }
    }
    cancelEntry();
  };

  const cancelEntry = () => {
    setNewName("");
    setNewDomain("");
    setPhaseTMRR(false);
    setPhaseMSA(false);
    setPhaseOS(false);
    setPhasePD(false);
    setPhaseEMD(false);
    setPhasePREMDD(false);
    setNewType("SETR");
    setNewInput(false);
    setNewOutput(false);
    setNewConOwn(false);
    setFocusId(0);
    setFocusOccurr([]);
    setNewProductModal(false);
    setNameError(false);
    setTypeError(false);
    setIntOutError(false);
    setConOwnError(false);
    setDateCreated();
  };

  const deleteEntry = () => {
    let intOut;
    if (newInput && !newOutput) {
      intOut = "Input";
    } else if (!newInput && newOutput) {
      intOut = "Output";
    } else {
      intOut = "";
    }
    deleteProduct(focusId, intOut);
    cancelEntry();
  };

  const closingModal = () => {
    closeProductM();
    setProductSearch("");
    setActivityIDList("delete");
    setDetailsProduct("");
  };

  const closeMini = () => {
    setDeleteOption(false);
    cancelEntry();
  };

  const saveProduct = () => {
    if (
      newName.trim() !== "" &&
      // newType !== "" &&
      (newInput || newOutput)
      // &&
      // (newConOwn === "Contributor" || newConOwn === "Owner")
    ) {
      if (focusId === 0) {
        submitProduct("newProduct");
      } else if (focusId !== 0) {
        submitProduct("updateProduct");
      }
    } else {
      if (newName.trim() === "") {
        setNameError(true);
      } else {
        setNameError(false);
      }
      // if (newType === "") {
      //   setTypeError(true);
      // } else {
      //   setTypeError(false);
      // }
      if (!newInput && !newOutput) {
        setIntOutError(true);
      } else {
        setIntOutError(false);
      }
      // if (newConOwn !== "Contributor" && newConOwn !== "Owner") {
      //   setConOwnError(true);
      // } else {
      //   setConOwnError(false);
      // }
    }
  };

  const handleCheckBox = (selection) => {
    setActivityIDList(selection);
    if (activityIDList.some((obj) => obj._id === selection._id)) {
      setIDList(
        activityIDList.filter((item: any) => item._id !== selection._id)
      );
    } else {
      let newArray = activityIDList;
      newArray.push(selection);
      setIDList(newArray);
    }
  };

  const cancelDelete = () => {
    closeMini();
    setDeleteModal(false);
  };

  const deleteItem = () => {
    deleteEntry();
    setDeleteModal(false);
  };

  const cancelButton = () => {
    closeProductM();
    setProductSearch("");
    setActivityIDList("delete");
    setDetailsProduct("");
  };

  const checkProductChanges = (closeType: string) => {
    let inputState = false;
    let outputState = false;

    const sortByName = (arr) =>
      arr.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );

    const areArraysIdentical = (arr1, arr2) =>
      JSON.stringify(arr1) === JSON.stringify(arr2);

    const inputList = sortByName(
      activityIDList.filter((x) => x.type !== "Output")
    );
    const outputList = sortByName(
      activityIDList.filter((x) => x.type !== "Input")
    );
    const filteredInputList = sortByName(initialProductsInputs);
    const filteredOutputList = sortByName(initialProductsOutputs);

    if (
      initialProductsInputs.length === 0 &&
      initialProductsOutputs.length === 0
    ) {
      if (activityIDList.length > 0) {
        if (closeType === "save") {
          setActivityProductsChanged(true);
          editActivityProductInputsList(
            activeProjectActivity.activityId,
            activityIDList
          );
          editActivityProductOutputsList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else if (closeType === "exit") {
          showChangesToast();
          setActivityProductsChanged(true);
        } else {
          setActivityProductsChanged(false);
          cancelButton();
        }
      } else {
        if (closeType === "save") {
          setActivityProductsChanged(true);
          editActivityProductInputsList(
            activeProjectActivity.activityId,
            activityIDList
          );
          editActivityProductOutputsList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else {
          setActivityProductsChanged(false);
          cancelButton();
        }
      }
    } else {
      inputState = !areArraysIdentical(inputList, filteredInputList);
      outputState = !areArraysIdentical(outputList, filteredOutputList);

      if (inputState || outputState) {
        if (closeType === "save") {
          setActivityProductsChanged(true);
          editActivityProductInputsList(
            activeProjectActivity.activityId,
            activityIDList
          );
          editActivityProductOutputsList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else if (closeType === "exit") {
          showChangesToast();
          setActivityProductsChanged(true);
        } else {
          setActivityProductsChanged(false);
          cancelButton();
        }
      } else {
        if (closeType === "save") {
          setActivityProductsChanged(true);
          editActivityProductInputsList(
            activeProjectActivity.activityId,
            activityIDList
          );
          editActivityProductOutputsList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else if (closeType === "exit") {
          setActivityProductsChanged(false);
          cancelButton();
        } else {
          setActivityProductsChanged(false);
          cancelButton();
        }
      }
    }
  };

  return (
    <div style={{ visibility: productM ? "initial" : "hidden" }}>
      <div className="modal-container-product">
        <section className="projectModals-product">
          <i
            className="fa fa-times"
            onClick={() => {
              activityLibraryEdit
                ? checkProductChanges("exit")
                : closingModal();
            }}
            style={{
              cursor: "pointer",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          ></i>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "-20px",
            }}
          >
            <p>Product Library</p>
            <input
              className="productSearchBox"
              value={productSearch}
              onChange={(e) => setProductSearch(e.target.value)}
              style={{
                width: "160px",
                height: "20px",
                alignSelf: "center",
                marginRight: "40px",
                padding: "2px",
                border: "1px solid black",
                paddingLeft: "20px",
                borderRadius: "8px",
              }}
              placeholder="Search Products"
            />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              onClick={() => setNewProductModal(true)}
              style={{
                marginTop: "-5px",
                paddingBottom: "2px",
                fontSize: "13px",
                color: style.colors.primaryBlue,
                cursor: "pointer",
              }}
            >
              + Add New Product
            </p>

            <div
              style={{
                display: activityLibraryEdit ? "flex" : "none",
                gap: 10,
                marginLeft: "auto",
              }}
            >
              <button
                onClick={cancelButton}
                style={{
                  backgroundColor: "grey",
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => checkProductChanges("save")}
                style={{
                  backgroundColor: style.colors.primaryBlue,
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Save
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "6px",
              borderTop: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            <div
              onClick={nameColumn}
              style={{
                // width: "284px",
                width: "23vw",
                minWidth: "246px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont">
                {nameAscending === 0
                  ? "Name"
                  : nameAscending === 1
                  ? "Name ↓"
                  : "Name ↑"}
              </p>
            </div>
            {/* <div
              onClick={domainColumn}
              style={{
                // width: "154px",
                width: "8vw",
                minWidth: "87px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont">
                {domainAscending === 0
                  ? "Domain"
                  : domainAscending === 1
                  ? "Domain ↓"
                  : "Domain ↑"}
              </p>
            </div> */}
            {/* <div
              onClick={phaseColumn}
              style={{
                // width: "154px",
                width: "8vw",
                minWidth: "87px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont">
                {phaseAscending === 0
                  ? "Phase"
                  : phaseAscending === 1
                  ? "Phase ↓"
                  : "Phase ↑"}
              </p>
            </div> */}
            {/* <div
              onClick={typeColumn}
              style={{
                // width: "154px",
                width: "8vw",
                minWidth: "87px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont">
                {typeAscending === 0
                  ? "Type"
                  : typeAscending === 1
                  ? "Type ↓"
                  : "Type ↑"}
              </p>
            </div> */}
            <div
              onClick={intOutColumn}
              style={{
                // width: "154px",
                width: "8vw",
                minWidth: "87px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont">
                {intOutAscending === 0
                  ? "Input/Output"
                  : intOutAscending === 1
                  ? "Input/Output ↓"
                  : "Input/Output ↑"}
              </p>
            </div>
            {/* <div
              onClick={conOwnColumn}
              style={{
                // width: "154px",
                width: "8vw",
                minWidth: "87px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: `1px solid ${style.colors.primaryBlue}`,
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p className="responsiveFont">
                {conOwnAscending === 0
                  ? "Contributor/Owner"
                  : conOwnAscending === 1
                  ? "Contributor/Owner ↓"
                  : "Contributor/Owner ↑"}
              </p>
            </div> */}
            <div
              // onClick={dateColumn}
              style={{
                // width: "174px",
                // width: "9vw",
                // minWidth: "152px",
                width: "50px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: `2px solid ${style.colors.primaryBlue}`,
                borderRight: "1px solid black",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            ></div>
          </div>
          <div
            className="modal-body"
            style={{
              border: "1px solid black",
              paddingTop: ".75px",
            }}
          >
            {searchFiltered?.map((item: any, index: number) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                }}
              >
                <div
                  style={{
                    // width: "284px",
                    width: "23vw",
                    minWidth: "246px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "38px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="overFlowTextProduct responsiveFont"
                    style={{ paddingLeft: "5px" }}
                    title={item.name}
                  >
                    {item.name}
                  </p>
                </div>
                {/* <div
                  style={{
                    // width: "154px",
                    width: "8vw",
                    minWidth: "87px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "38px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="responsiveFont">{item.domainId}</p>
                </div> */}
                {/* <div
                  style={{
                    // width: "154px",
                    width: "8vw",
                    minWidth: "87px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "38px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="overFlowTextProduct responsiveFont">
                    {item.phaseId}
                  </p>
                </div> */}
                {/* <div
                  style={{
                    // width: "154px",
                    width: "8vw",
                    minWidth: "87px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "38px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="responsiveFont">{item.scope}</p>
                </div> */}
                <div
                  style={{
                    // width: "154px",
                    width: "8vw",
                    minWidth: "87px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "38px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="responsiveFont">
                    {item.type === "InputOutput"
                      ? item.type.slice(0, 5) + "/" + item.type.slice(5)
                      : item.type}
                  </p>
                </div>
                {/* <div
                  style={{
                    // width: "154px",
                    width: "8vw",
                    minWidth: "87px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "38px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="responsiveFont">{item.contributorOwner}</p>
                </div> */}
                <div
                  style={{
                    width: "1vw",
                    minWidth: "30px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "38px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {activityLibraryEdit ? (
                    <input
                      onClick={() => handleCheckBox(item)}
                      type="checkbox"
                      checked={
                        activityIDList.some((obj) => obj._id === item._id)
                          ? true
                          : false
                      }
                      id="productcheck"
                      name="productcheck"
                      value="productcheck"
                    />
                  ) : (
                    <Editsvg
                      className="modalPencil"
                      onClick={() => {
                        setDeleteOption(true);
                        setNewProductModal(true);
                        setFocusId(item.productId);
                        setFocusOccurr(item.occurrences);
                        setNewName(item.name);
                        setNewDomain(item.domainId);
                        let phaseList = item.phaseId
                          ? item.phaseId.length > 0
                            ? item.phaseId.split(", ")
                            : []
                          : [];
                        setPhaseTMRR(phaseList.includes("TMRR") ? true : false);
                        setPhaseMSA(phaseList.includes("MSA") ? true : false);
                        setPhaseOS(phaseList.includes("OS") ? true : false);
                        setPhasePD(phaseList.includes("PD") ? true : false);
                        setPhaseEMD(phaseList.includes("EMD") ? true : false);
                        setPhasePREMDD(
                          phaseList.includes("PRE:MDD") ? true : false
                        );
                        setNewType(item.scope);
                        setNewInput(
                          item.type === "Input" || item.type === "InputOutput"
                            ? true
                            : false
                        );
                        setNewOutput(
                          item.type === "Output" || item.type === "InputOutput"
                            ? true
                            : false
                        );
                        setNewConOwn(item.contributorOwner);
                        setDateCreated(item.dateAdded);
                      }}
                      fill={style.colors.darkBlue}
                      height="2vh"
                      width="2vw"
                      style={{
                        marginLeft: "auto",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>

      <div
        className="modal-container-product"
        style={{ visibility: newProductModal ? "initial" : "hidden" }}
      >
        {/* <div style={{ maxHeight: "420px" }}> */}
        <section className="projectModals-product-popupBox">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          >
            <i
              className="fa fa-times"
              onClick={closeMini}
              style={{
                cursor: "pointer",
                alignSelf: "center",
              }}
            ></i>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                // height: "70px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: nameError ? "red" : "black",
                }}
              >
                Name*:
              </p>
            </div>
            <input
              readOnly={
                dateCreated === 1695280517 || dateCreated === 1695280575
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                // marginTop: "-20px",
              }}
              placeholder="Product name..."
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "70px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: intOutError ? "red" : "black",
                }}
              >
                Input/Output*:
              </p>
            </div>
            <div style={{ marginLeft: "10px", marginTop: "5px" }}>
              <input
                readOnly={
                  dateCreated === 1695280517 || dateCreated === 1695280575
                    ? user?.userRole?.includes("Admin")
                      ? false
                      : true
                    : false
                }
                type="radio"
                id="ip1"
                name="ip1"
                value="Input"
                checked={newInput}
                onClick={inputCheck}
              />
              <label>Input</label> <br></br>
              <input
                readOnly={
                  dateCreated === 1695280517 || dateCreated === 1695280575
                    ? user?.userRole?.includes("Admin")
                      ? false
                      : true
                    : false
                }
                type="radio"
                id="ip2"
                name="ip2"
                value="Output"
                checked={newOutput}
                onClick={outputCheck}
              />
              <label>Output</label> <br></br>
            </div>
          </div>
          <div
            style={{
              display: focusId !== 0 ? "flex" : "none",
              flexDirection: "row",
            }}
          >
            <div>
              <p style={{ fontWeight: "bold" }}>Occurrences in Pathways</p>
              {focusOccurr?.length > 0 ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "29px",
                      borderTop: "1px solid black",
                      borderLeft: "1px solid black",
                    }}
                  >
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "209px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p className="responsiveFont">Pathway</p>
                    </div>
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "100px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        borderLeft: "1px solid white",
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p className="responsiveFont">Phase</p>
                    </div>
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "107px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        borderLeft: "1px solid white",
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRight: "1px solid black",
                      }}
                    >
                      <p className="responsiveFont">Domain</p>
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid black",
                      paddingTop: ".75px",
                    }}
                  >
                    {focusOccurr?.map((occur, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor:
                            index % 2 === 0 ? style.colors.lightBlue : "white",
                        }}
                      >
                        <div
                          style={{
                            width: "209px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? style.colors.lightBlue
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="responsiveFont"
                            style={{ paddingLeft: "5px" }}
                          >
                            {occur.pathwayName}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? style.colors.lightBlue
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="responsiveFont"
                            style={{ paddingLeft: "5px" }}
                          >
                            {occur.phaseName}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? style.colors.lightBlue
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="responsiveFont"
                            style={{ paddingLeft: "5px" }}
                          >
                            {occur.domainName}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p
                  style={{
                    width: "400px",
                  }}
                >
                  No occurrences since the product is currently not linked to
                  any activities.
                </p>
              )}
            </div>
          </div>

          <div
            style={{
              display:
                dateCreated === 1695280517 || dateCreated === 1695280575
                  ? user?.userRole?.includes("Admin")
                    ? "flex"
                    : "none"
                  : "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 20,
              marginTop: "10px",
              paddingBottom: focusOccurr?.length > 0 ? "20px" : 0,
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: deleteOption ? "pointer" : "default",
                opacity: deleteOption ? 1 : 0,
              }}
              onClick={() => {
                if (deleteOption) setDeleteModal(true);
              }}
            >
              Delete
            </button>
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={cancelEntry}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={saveProduct}
            >
              Save
            </button>
          </div>
        </section>
        {/* </div> */}
      </div>

      <div
        className="delete-modal-container-product"
        style={{ visibility: deleteModal ? "initial" : "hidden" }}
      >
        <section className="projectModals-product">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          >
            <i
              className="fa fa-times"
              onClick={cancelDelete}
              style={{
                cursor: "pointer",
                alignSelf: "center",
              }}
            ></i>
          </div>

          <p style={{ paddingBottom: "1px", textAlign: "center" }}>
            You are about to delete:
          </p>

          <div style={{ paddingBottom: "2px", textAlign: "center" }}>
            <p>{newName} from the Product Library</p>
            <p style={{ paddingBottom: "2px" }}>
              Are you sure you would like to continue?
            </p>
          </div>

          <div
            style={{
              display: user?.userRole?.includes("Admin") ? "flex" : "none",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 20,
              marginTop: "10px",
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={cancelDelete}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={deleteItem}
            >
              Delete
            </button>
          </div>
        </section>
      </div>
    </div>
  );

  function showChangesToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar6");
    if (element[0]) {
      element[0].className = "snackbar6 show";
      setTimeout(function () {
        element[0].className = "snackbar6";
      }, 5000);
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  productLibrary: state.data.productLibrary,
  style: state.local.style,
  navSearch: state.data.navSearch,
  activityLibraryEdit: state.projects.activityLibraryEdit,
  activeProjectActivity: state.projects.activeProjectActivity,
  activityIDList: state.projects.activityIDList,
  activityProductsChanged: state.projects.activityProductsChanged,
  initialProductsInputs: state.projects.initialProductsInputs,
  initialProductsOutputs: state.projects.initialProductsOutputs,
  user: state.user.user,
});

export default connect(mapStateToProps, {
  getProductLibrary,
  addNewProduct,
  updateProduct,
  deleteProduct,
  editActivityProductInputsList,
  editActivityProductOutputsList,
  setActivityIDList,
  setActivityProductsChanged,
})(ProductLibraryModal) as any;
