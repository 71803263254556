import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./StepTwo.css";
import { useNavigate } from "react-router-dom";
import { modelPhases, modelDomains } from "../../lib/data/modelInfo";
import { setFilterBox } from "../../redux/actions/Data";
import {
  editReportCategories,
  clearRiskDates,
} from "../../redux/actions/Projects";

const ReportCategories: React.FC<{
  userReportType: string;
  userReportObject: any;
  allPhase: any;
  phaseList: any;
  allPhaseCheck: any;
  phaseClick: any;
  allDomain: any;
  domainList: any;
  allDomainCheck: any;
  domainClick: any;
  allRisk: any;
  riskList: any;
  allRiskCheck: any;
  riskClick: any;
  allActivity: any;
  activityList: any;
  allActivityCheck: any;
  activityClick: any;
  allNotes: any;
  notesList: any;
  allActivityNotesCheck: any;
  activityNotesClick: any;
  allContent: any;
  contentList: any;
  allContentCheck: any;
  contentClick: any;
  allManagement: any;
  managementList: any;
  allManagementCheck: any;
  managementClick: any;
  riskItemStartDate: any;
  setRiskItemStartDate: any;
  riskItemEndDate: any;
  setRiskItemEndDate: any;
  statusItemStartDate: any;
  setStatusItemStartDate: any;
  statusItemEndDate: any;
  setStatusItemEndDate: any;
  updateItemStartDate: any;
  setUpdateItemStartDate: any;
  updateItemEndDate: any;
  setUpdateItemEndDate: any;
  addedStartDate: any;
  setAddedStartDate: any;
  addedEndDate: any;
  setAddedEndDate: any;
  modifiedStartDate: any;
  setModifiedStartDate: any;
  modifiedEndDate: any;
  setModifiedEndDate: any;
  deletedStartDate: any;
  setDeletedStartDate: any;
  deletedEndDate: any;
  setDeletedEndDate: any;
  productOwnership: any;
  allProductOwnershipCheck: any;
  ownershipItemsClick: any;
  riskDateError: any;
  statusDateError: any;
  updateDateError: any;
  addedDateError: any;
  modifiedDateError: any;
  deletedDateError: any;
  allContentProductCheck: any;
  contentProduct: any;
  developmentClick: any;
  scienceClick: any;
  modelingClick: any;
  clearRiskDates: () => void;
}> = ({
  userReportType,
  userReportObject,
  allPhase,
  phaseList,
  allPhaseCheck,
  phaseClick,
  allDomain,
  domainList,
  allDomainCheck,
  domainClick,
  allRisk,
  riskList,
  allRiskCheck,
  riskClick,
  allActivity,
  activityList,
  allActivityCheck,
  activityClick,
  allNotes,
  notesList,
  allActivityNotesCheck,
  activityNotesClick,
  allContent,
  contentList,
  allContentCheck,
  contentClick,
  allManagement,
  managementList,
  allManagementCheck,
  managementClick,
  riskItemStartDate,
  setRiskItemStartDate,
  riskItemEndDate,
  setRiskItemEndDate,
  statusItemStartDate,
  setStatusItemStartDate,
  statusItemEndDate,
  setStatusItemEndDate,
  updateItemStartDate,
  setUpdateItemStartDate,
  updateItemEndDate,
  setUpdateItemEndDate,
  addedStartDate,
  setAddedStartDate,
  addedEndDate,
  setAddedEndDate,
  modifiedStartDate,
  setModifiedStartDate,
  modifiedEndDate,
  setModifiedEndDate,
  deletedStartDate,
  setDeletedStartDate,
  deletedEndDate,
  setDeletedEndDate,
  productOwnership,
  allProductOwnershipCheck,
  ownershipItemsClick,
  riskDateError,
  statusDateError,
  updateDateError,
  addedDateError,
  modifiedDateError,
  deletedDateError,
  allContentProductCheck,
  contentProduct,
  developmentClick,
  scienceClick,
  modelingClick,
  clearRiskDates,
}) => {
  const phaseComponent = () => {
    let phaseObject = modelPhases.filter(
      (phases) => userReportObject?.selectedProject?.modelId === phases.modelId
    );

    let phaseShorts = phaseObject[0].phaseShortNames;
    let phaseLongs = phaseObject[0].phaseLongNames;

    return (
      <div>
        <p
          style={{
            color: "black",
            paddingTop: "10px",
            fontWeight: "bold",
            // visibility:
            //   userReportType === "Risk Report" ||
            //   userReportType === "Status Report" ||
            //   userReportType === "Custom Activities Report"
            //     ? "initial"
            //     : "hidden",
          }}
        >
          {userReportType}
        </p>

        <p style={{ fontWeight: "bold" }}>Phase</p>
        <div
          className="reportBoxes"
          style={{
            border: "1px solid black",
            overflowY: "auto",
          }}
        >
          <div style={{ marginLeft: "10px", marginTop: "5px" }}>
            <input
              onClick={allPhaseCheck}
              checked={allPhase}
              type="checkbox"
              id="phases1"
              name="phases"
              value="All"
              style={{ marginBottom: "12px" }}
            />
            <label onClick={allPhaseCheck}>All</label> <br></br>
            {phaseShorts.map((phaseItem: any, index: number) => (
              <>
                <input
                  key={index}
                  onClick={() => phaseClick(phaseItem)}
                  checked={phaseList?.includes(phaseItem)}
                  style={{ marginLeft: "20px", marginBottom: "12px" }}
                  type="checkbox"
                  id="phases"
                  name="phases"
                  value={phaseItem}
                />
                <label onClick={() => phaseClick(phaseItem)}>
                  {phaseLongs[index]}
                </label>
                <br></br>
              </>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const domainComponent = () => {
    let domainShorts =
      userReportObject?.selectedProject?.modelId === "3"
        ? modelDomains[1].domainShortNames
        : modelDomains[0].domainShortNames;
    let domainLongs =
      userReportObject?.selectedProject?.modelId === "3"
        ? modelDomains[1].domainLongNames
        : modelDomains[0].domainLongNames;

    return (
      <div>
        <p
          style={{
            color: "black",
            paddingTop: "10px",
            fontWeight: "bold",
            visibility: "hidden",
          }}
        >
          {userReportType}
        </p>
        <p style={{ fontWeight: "bold" }}>Domain</p>
        <div
          className="reportBoxes"
          style={{
            border: "1px solid black",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              marginLeft: "10px",
              marginTop: "5px",
            }}
          >
            <input
              onClick={allDomainCheck}
              checked={allDomain}
              type="checkbox"
              id="domains1"
              name="domains"
              value="All"
              style={{ marginBottom: "12px" }}
            />
            <label onClick={allDomainCheck}>All</label> <br></br>
            {domainShorts.map((domainItem: any, index: number) => (
              <>
                <input
                  key={index}
                  onClick={() => domainClick(domainItem)}
                  checked={domainList?.includes(domainItem)}
                  style={{ marginLeft: "20px", marginBottom: "12px" }}
                  type="checkbox"
                  id="domains"
                  name="domains"
                  value={domainItem}
                />
                <label onClick={() => domainClick(domainItem)}>
                  {domainLongs[index]}
                </label>
                <br></br>
              </>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const hsiRiskComponent = () => {
    return (
      <div>
        <p
          style={{
            color: "black",
            paddingTop: "10px",
            fontWeight: "bold",
            visibility: "hidden",
          }}
        >
          {userReportType}
        </p>

        <p style={{ fontWeight: "bold" }}>HSI Risk</p>
        <div
          className="reportBoxes"
          style={{
            border: "1px solid black",
            overflowY: "auto",
          }}
        >
          <div style={{ marginLeft: "10px", marginTop: "5px" }}>
            <input
              onClick={allRiskCheck}
              checked={allRisk}
              type="checkbox"
              id="risks1"
              name="risks"
              value="All"
              style={{ marginBottom: "12px" }}
            />
            <label onClick={allRiskCheck}>All</label> <br></br>
            <input
              onClick={() => riskClick("High")}
              checked={riskList?.includes("High")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="risks2"
              name="risks"
              value="High"
            />
            <label onClick={() => riskClick("High")}>High</label>
            <br></br>
            <input
              onClick={() => riskClick("Medium")}
              checked={riskList?.includes("Medium")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="risks3"
              name="risks"
              value="Medium"
            />
            <label onClick={() => riskClick("Medium")}>Medium</label> <br></br>
            <input
              onClick={() => riskClick("Low")}
              checked={riskList?.includes("Low")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="risks4"
              name="risks"
              value="Low"
            />
            <label onClick={() => riskClick("Low")}>Low</label> <br></br>
            <input
              onClick={() => riskClick("Not Assigned")}
              checked={riskList?.includes("Not Assigned")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="risks5"
              name="risks"
              value="Not Assigned"
            />
            <label onClick={() => riskClick("Not Assigned")}>
              Not Assigned
            </label>
            <br></br>
            <input
              onClick={() => riskClick("Include")}
              checked={riskList?.includes("Include")}
              style={{ marginLeft: "10px", marginBottom: "12px" }}
              type="checkbox"
              id="risks6"
              name="risks"
              value="Include"
            />
            <label onClick={() => riskClick("Include")}>
              Include HSI Risk Description
            </label>
            <br></br>
            <input
              onClick={() => {
                if (riskList?.includes("Date")) {
                  clearRiskDates();
                  setRiskItemStartDate("");
                  setRiskItemEndDate("");
                }
                riskClick("Date");
              }}
              checked={riskList?.includes("Date")}
              style={{ marginLeft: "10px" }}
              type="checkbox"
              id="risks7"
              name="risks"
              value="Date"
            />
            <label
              style={{ color: riskDateError ? "red" : "black" }}
              onClick={() => {
                if (riskList?.includes("Date")) {
                  clearRiskDates();
                  setRiskItemStartDate("");
                  setRiskItemEndDate("");
                }
                riskClick("Date");
              }}
            >
              Due Date
            </label>
            <br></br>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                marginLeft: "10px",
              }}
            >
              <input
                type="date"
                name="startDate"
                value={riskItemStartDate}
                style={{
                  width: "100px",
                  height: "20px",
                  alignSelf: "center",
                }}
                onChange={(e) => setRiskItemStartDate(e.target.value)}
              />
              <p> - </p>
              <input
                type="date"
                name="endDate"
                value={riskItemEndDate}
                style={{
                  width: "100px",
                  height: "20px",
                  alignSelf: "center",
                }}
                onChange={(e) => setRiskItemEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const activityStatusComponent = () => {
    return (
      <div>
        <p
          style={{
            color: "black",
            paddingTop: "10px",
            fontWeight: "bold",
            visibility: "hidden",
          }}
        >
          {userReportType}
        </p>
        <p style={{ fontWeight: "bold" }}>Activity Status</p>
        <div
          className="reportBoxes"
          style={{
            border: "1px solid black",
            overflowY: "auto",
          }}
        >
          <div style={{ marginLeft: "10px", marginTop: "5px" }}>
            <input
              onClick={allActivityCheck}
              checked={allActivity}
              type="checkbox"
              id="activity1"
              name="activity"
              value="All"
              style={{ marginBottom: "12px" }}
            />
            <label onClick={allActivityCheck}>All</label> <br></br>
            <input
              onClick={() => activityClick("Not Started")}
              checked={activityList?.includes("Not Started")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="activity2"
              name="activity"
              value="Not Started"
            />
            <label onClick={() => activityClick("Not Started")}>
              Not Started
            </label>
            <br></br>
            <input
              onClick={() => activityClick("In Progress")}
              checked={activityList?.includes("In Progress")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="activity3"
              name="activity"
              value="In Progress"
            />
            <label onClick={() => activityClick("In Progress")}>
              In Progress
            </label>
            <br></br>
            <input
              onClick={() => activityClick("Completed")}
              checked={activityList?.includes("Completed")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="activity4"
              name="activity"
              value="Completed"
            />
            <label onClick={() => activityClick("Completed")}>Completed</label>
            <br></br>
            <input
              onClick={() => activityClick("None")}
              checked={activityList?.includes("None")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="activity5"
              name="activity"
              value="Not Assigned"
            />
            <label onClick={() => activityClick("None")}>Not Assigned</label>
            <br></br>
            <input
              onClick={() => activityClick("Due Date")}
              checked={activityList?.includes("Due Date")}
              style={{ marginLeft: "10px" }}
              type="checkbox"
              id="activity7"
              name="activity"
              value="Date"
            />
            <label
              style={{ color: statusDateError ? "red" : "black" }}
              onClick={() => activityClick("Due Date")}
            >
              Due Date
            </label>
            <br></br>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                marginLeft: "10px",
              }}
            >
              <input
                type="date"
                name="startDate"
                value={statusItemStartDate}
                style={{
                  width: "100px",
                  height: "20px",
                  alignSelf: "center",
                }}
                onChange={(e) => setStatusItemStartDate(e.target.value)}
              />
              <p> - </p>
              <input
                type="date"
                name="endDate"
                value={statusItemEndDate}
                style={{
                  width: "100px",
                  height: "20px",
                  alignSelf: "center",
                }}
                onChange={(e) => setStatusItemEndDate(e.target.value)}
              />
            </div>
            <input
              onClick={() => activityClick("Due Status")}
              checked={activityList?.includes("Due Status")}
              style={{ marginLeft: "10px" }}
              type="checkbox"
              id="activity8"
              name="activity"
              value="Date"
            />
            <label
              style={{ color: updateDateError ? "red" : "black" }}
              onClick={() => activityClick("Due Status")}
            >
              Due Status Update
            </label>
            <br></br>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                marginLeft: "10px",
              }}
            >
              <input
                type="date"
                name="startDate"
                value={updateItemStartDate}
                style={{
                  width: "100px",
                  height: "20px",
                  alignSelf: "center",
                }}
                onChange={(e) => setUpdateItemStartDate(e.target.value)}
              />
              <p> - </p>
              <input
                type="date"
                name="endDate"
                value={updateItemEndDate}
                style={{
                  width: "100px",
                  height: "20px",
                  alignSelf: "center",
                }}
                onChange={(e) => setUpdateItemEndDate(e.target.value)}
              />
            </div>
            <input
              onClick={() => activityClick("Include")}
              checked={activityList?.includes("Include")}
              style={{ marginLeft: "10px", marginBottom: "12px" }}
              type="checkbox"
              id="activity6"
              name="activity"
              value="Include"
            />
            <label onClick={() => activityClick("Include")}>
              Include Completed Products
            </label>
            <br></br>
          </div>
        </div>
      </div>
    );
  };

  const notesComponent = () => {
    return (
      <div>
        <p
          style={{
            color: "black",
            paddingTop: "10px",
            fontWeight: "bold",
            visibility: userReportType === "Risk Report" ? "initial" : "hidden",
          }}
        >
          {userReportType}
        </p>
        <p style={{ fontWeight: "bold" }}>Activity Details: Notes</p>
        <div
          className="reportBoxes"
          style={{
            border: "1px solid black",
            overflowY: "auto",
          }}
        >
          <div style={{ marginLeft: "10px", marginTop: "5px" }}>
            <input
              onClick={allActivityNotesCheck}
              checked={allNotes}
              type="checkbox"
              id="notes1"
              name="notes"
              value="All"
              style={{ marginBottom: "12px" }}
            />
            <label onClick={allActivityNotesCheck}>All</label> <br></br>
            <input
              onClick={() => activityNotesClick("Trade")}
              checked={notesList?.includes("Trade")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="notes2"
              name="notes"
              value="Trade"
            />
            <label onClick={() => activityNotesClick("Trade")}>
              Trade-Offs
            </label>
            <br></br>
            <input
              onClick={() => activityNotesClick("Comments")}
              checked={notesList?.includes("Comments")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="notes3"
              name="activity"
              value="Comments"
            />
            <label onClick={() => activityNotesClick("Comments")}>
              Comments
            </label>
            <br></br>
            <input
              onClick={() => activityNotesClick("SME")}
              checked={notesList?.includes("SME")}
              style={{ marginLeft: "10px", marginBottom: "12px" }}
              type="checkbox"
              id="notes4"
              name="notes"
              value="SME"
            />
            <label onClick={() => activityNotesClick("SME")}>
              Requires SME Interaction
            </label>
          </div>
        </div>
      </div>
    );
  };

  const contentComponent = () => {
    return (
      <div>
        <p
          style={{
            color: "black",
            paddingTop: "10px",
            fontWeight: "bold",
            visibility: "hidden",
          }}
        >
          {userReportType}
        </p>
        <p style={{ fontWeight: "bold" }}>
          {userReportType === "Related Items Report"
            ? "Product Details"
            : "Content Details"}
        </p>
        <div
          className="reportBoxes"
          style={{
            border: "1px solid black",
            overflowY: "auto",
          }}
        >
          <div style={{ marginLeft: "10px", marginTop: "5px" }}>
            <input
              onClick={allContentProductCheck}
              checked={contentProduct}
              type="checkbox"
              id="content1"
              name="content"
              value="All"
              style={{ marginBottom: "12px" }}
            />
            <label onClick={allContentProductCheck}>Products</label> <br></br>
            <input
              onClick={() => contentClick("Inputs")}
              checked={contentList?.includes("Inputs")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="content2"
              name="content"
              value="Inputs"
            />
            <label onClick={() => contentClick("Inputs")}>
              Activity Inputs
            </label>
            <br></br>
            <input
              onClick={() => contentClick("Outputs")}
              checked={contentList?.includes("Outputs")}
              style={{ marginLeft: "20px", marginBottom: "12px" }}
              type="checkbox"
              id="content3"
              name="content"
              value="Outputs"
            />
            <label onClick={() => contentClick("Outputs")}>HSI Outputs</label>
            <br></br>
            <input
              onClick={() => contentClick("References")}
              checked={contentList?.includes("References")}
              type="checkbox"
              id="content5"
              name="content"
              value="References"
              style={{ marginBottom: "12px" }}
            />
            <label onClick={() => contentClick("References")}>References</label>
            <br></br>
            <input
              onClick={() => contentClick("Activities")}
              checked={contentList?.includes("Activities")}
              type="checkbox"
              id="content6"
              name="content"
              value="Activities"
              style={{ marginBottom: "12px" }}
            />
            <label onClick={() => contentClick("Activities")}>Activities</label>
            <br></br>
            {userReportType === "Related Items Report" ? (
              <>
                <span>{"Filter by (optional)"}</span>
                <br></br>
                <input
                  onClick={allProductOwnershipCheck}
                  checked={productOwnership}
                  style={{ marginLeft: "20px", marginBottom: "12px" }}
                  type="checkbox"
                  id="content3"
                  name="content"
                  value="Ownership"
                />
                <label onClick={allProductOwnershipCheck}>
                  Level of Product Ownership
                </label>
                <br></br>
                <input
                  onClick={() => ownershipItemsClick("Owner")}
                  checked={contentList?.includes("Owner")}
                  style={{ marginLeft: "35px", marginBottom: "12px" }}
                  type="checkbox"
                  id="content3"
                  name="content"
                  value="Owner"
                />
                <label onClick={() => ownershipItemsClick("Owner")}>
                  Owner
                </label>
                <br></br>
                <input
                  onClick={() => ownershipItemsClick("Contributor")}
                  checked={contentList?.includes("Contributor")}
                  style={{ marginLeft: "35px", marginBottom: "12px" }}
                  type="checkbox"
                  id="content3"
                  name="content"
                  value="Contributor"
                />
                <label onClick={() => ownershipItemsClick("Contributor")}>
                  Contributor
                </label>
                <br></br>
              </>
            ) : (
              <>
                <input
                  onClick={() => contentClick("Date Added")}
                  checked={contentList?.includes("Date Added")}
                  style={{ marginLeft: "10px" }}
                  type="checkbox"
                  id="content7"
                  name="content"
                  value="Date"
                />
                <label
                  style={{ color: addedDateError ? "red" : "black" }}
                  onClick={() => contentClick("Date Added")}
                >
                  Date Added
                </label>
                <br></br>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    marginLeft: "10px",
                  }}
                >
                  <input
                    type="date"
                    name="startDate"
                    value={addedStartDate}
                    style={{
                      width: "100px",
                      height: "20px",
                      alignSelf: "center",
                    }}
                    onChange={(e) => setAddedStartDate(e.target.value)}
                  />
                  <p> - </p>
                  <input
                    type="date"
                    name="endDate"
                    value={addedEndDate}
                    style={{
                      width: "100px",
                      height: "20px",
                      alignSelf: "center",
                    }}
                    onChange={(e) => setAddedEndDate(e.target.value)}
                  />
                </div>
                <input
                  onClick={() => contentClick("Date Modified")}
                  checked={contentList?.includes("Date Modified")}
                  style={{ marginLeft: "10px" }}
                  type="checkbox"
                  id="content8"
                  name="content"
                  value="Date"
                />
                <label
                  style={{ color: modifiedDateError ? "red" : "black" }}
                  onClick={() => contentClick("Date Modified")}
                >
                  Date Modified
                </label>
                <br></br>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    marginLeft: "10px",
                  }}
                >
                  <input
                    type="date"
                    name="startDate"
                    value={modifiedStartDate}
                    style={{
                      width: "100px",
                      height: "20px",
                      alignSelf: "center",
                    }}
                    onChange={(e) => setModifiedStartDate(e.target.value)}
                  />
                  <p> - </p>
                  <input
                    type="date"
                    name="endDate"
                    value={modifiedEndDate}
                    style={{
                      width: "100px",
                      height: "20px",
                      alignSelf: "center",
                    }}
                    onChange={(e) => setModifiedEndDate(e.target.value)}
                  />
                </div>
                <input
                  onClick={() => contentClick("Date Deleted")}
                  checked={contentList?.includes("Date Deleted")}
                  style={{ marginLeft: "10px" }}
                  type="checkbox"
                  id="content9"
                  name="content"
                  value="Date"
                />
                <label
                  style={{ color: deletedDateError ? "red" : "black" }}
                  onClick={() => contentClick("Date Deleted")}
                >
                  Date Deleted
                </label>
                <br></br>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    marginLeft: "10px",
                  }}
                >
                  <input
                    type="date"
                    name="startDate"
                    value={deletedStartDate}
                    style={{
                      width: "100px",
                      height: "20px",
                      alignSelf: "center",
                    }}
                    onChange={(e) => setDeletedStartDate(e.target.value)}
                  />
                  <p> - </p>
                  <input
                    type="date"
                    name="endDate"
                    value={deletedEndDate}
                    style={{
                      width: "100px",
                      height: "20px",
                      alignSelf: "center",
                    }}
                    onChange={(e) => setDeletedEndDate(e.target.value)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const managementComponent = () => {
    return (
      <div>
        <p
          style={{
            color: "black",
            paddingTop: "10px",
            fontWeight: "bold",
            visibility: "hidden",
          }}
        >
          {userReportType}
        </p>
        <p style={{ fontWeight: "bold" }}>
          {userReportType === "Custom Libraries Report"
            ? "Activity Details: Libraries"
            : "Activity Details: HSI Management"}
        </p>
        <div
          className="reportBoxes"
          style={{
            border: "1px solid black",
            overflowY: "auto",
          }}
        >
          <div style={{ marginLeft: "10px", marginTop: "5px" }}>
            {userReportType === "Custom Activities Report" ? (
              <>
                <input
                  onClick={allManagementCheck}
                  checked={allManagement}
                  type="checkbox"
                  id="notes1"
                  name="notes"
                  value="All"
                  style={{ marginBottom: "12px" }}
                />
                <label onClick={allActivityNotesCheck}>All</label> <br></br>
                <input
                  onClick={() => managementClick("Return")}
                  checked={managementList?.includes("Return")}
                  style={{ marginLeft: "20px", marginBottom: "12px" }}
                  type="checkbox"
                  id="notes2"
                  name="notes"
                  value="Return"
                />
                <label onClick={() => managementClick("Return")}>
                  Return on Investment
                </label>
                <br></br>
                <input
                  onClick={() => managementClick("Contractor")}
                  checked={managementList?.includes("Contractor")}
                  style={{ marginLeft: "20px", marginBottom: "12px" }}
                  type="checkbox"
                  id="notes3"
                  name="activity"
                  value="Contractor"
                />
                <label onClick={() => managementClick("Contractor")}>
                  Contractor Deliverables
                </label>
                <br></br>
                <input
                  onClick={() => managementClick("Challenges")}
                  checked={managementList?.includes("Challenges")}
                  style={{ marginLeft: "20px", marginBottom: "12px" }}
                  type="checkbox"
                  id="notes4"
                  name="notes"
                  value="Challenges"
                />
                <label onClick={() => managementClick("Challenges")}>
                  Challenges & Lessons Learned
                </label>
                <br></br>
                <input
                  onClick={() => managementClick("Recommendations")}
                  checked={managementList?.includes("Recommendations")}
                  style={{ marginLeft: "20px", marginBottom: "12px" }}
                  type="checkbox"
                  id="notes4"
                  name="notes"
                  value="Recommendations"
                />
                <label onClick={() => managementClick("Recommendations")}>
                  Recommendations
                </label>
                <br></br>
                <input
                  onClick={() => managementClick("Notes")}
                  checked={managementList?.includes("Notes")}
                  style={{ marginLeft: "20px", marginBottom: "12px" }}
                  type="checkbox"
                  id="notes4"
                  name="notes"
                  value="Notes"
                />
                <label onClick={() => managementClick("Notes")}>
                  Management Notes
                </label>
              </>
            ) : (
              <>
                <input
                  onClick={allManagementCheck}
                  checked={managementList?.includes("Products")}
                  style={{ marginBottom: "12px" }}
                  type="checkbox"
                  id="notes2"
                  name="notes"
                  value="Products"
                />
                <label onClick={allManagementCheck}>Products</label>
                <br></br>
                <input
                  onClick={() => managementClick("Inputs")}
                  checked={managementList?.includes("Inputs")}
                  style={{ marginLeft: "20px", marginBottom: "12px" }}
                  type="checkbox"
                  id="notes3"
                  name="activity"
                  value="Inputs"
                />
                <label onClick={() => managementClick("Inputs")}>
                  Activity Inputs
                </label>
                <br></br>
                <input
                  onClick={() => managementClick("Outputs")}
                  checked={managementList?.includes("Outputs")}
                  style={{ marginLeft: "20px", marginBottom: "12px" }}
                  type="checkbox"
                  id="notes4"
                  name="notes"
                  value="Outputs"
                />
                <label onClick={() => managementClick("Outputs")}>
                  HSI Outputs
                </label>
                <br></br>
                <input
                  onClick={() => managementClick("References")}
                  checked={managementList?.includes("References")}
                  style={{ marginBottom: "12px" }}
                  type="checkbox"
                  id="notes4"
                  name="notes"
                  value="References"
                />
                <label onClick={() => managementClick("References")}>
                  References
                </label>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const emptyComponent = () => {
    return (
      <div>
        {userReportType === "Risk Report" ||
        userReportType === "Status Report" ||
        userReportType === "Custom Activities Report" ? (
          <p
            style={{
              color: "black",
              paddingTop: "10px",
              fontWeight: "bold",
              visibility:
                userReportType === "Risk Report" ? "initial" : "hidden",
            }}
          >
            {userReportType}
          </p>
        ) : null}
        {/* <p style={{ color: "white" }}>.</p> */}
        <div
          className="reportBoxes"
          style={{
            border: "1px solid white",
            overflowY: "auto",
          }}
        >
          <div style={{ marginLeft: "10px", marginTop: "5px" }}></div>
        </div>
      </div>
    );
  };

  const RiskReport = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {phaseComponent()}
        {domainComponent()}
        {hsiRiskComponent()}
      </div>
    );
  };

  const StatusReport = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {phaseComponent()}
        {domainComponent()}
        {activityStatusComponent()}
      </div>
    );
  };

  const CustomActReport = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          {phaseComponent()}
          {domainComponent()}
          {activityStatusComponent()}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            // paddingLeft: "115px",
          }}
        >
          {notesComponent()}
          {managementComponent()}
          {hsiRiskComponent()}
        </div>
      </>
    );
  };

  const CustomLibReport = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          {phaseComponent()}
          {domainComponent()}
          {managementComponent()}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            // paddingLeft: "115px",
          }}
        >
          {activityStatusComponent()}
          {hsiRiskComponent()}
          {emptyComponent()}
        </div>
      </>
    );
  };

  const RelatedReport = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {phaseComponent()}
        {domainComponent()}
        {contentComponent()}
      </div>
    );
  };

  const ContentReport = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {phaseComponent()}
        {domainComponent()}
        {contentComponent()}
      </div>
    );
  };

  return (
    <div className="checkboxSections">
      {/* <p
        style={{
          color: "black",
          paddingTop: "10px",
          paddingLeft: "120px",
          fontWeight: "bold",
        }}
      >
        {userReportType}
      </p> */}

      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      > */}
      {userReportType === "Risk Report"
        ? RiskReport()
        : userReportType === "Status Report"
        ? StatusReport()
        : userReportType === "Custom Activities Report"
        ? CustomActReport()
        : userReportType === "Custom Libraries Report"
        ? CustomLibReport()
        : userReportType === "Related Items Report"
        ? RelatedReport()
        : ContentReport()}
      {/* </div> */}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          // paddingLeft: "115px",
        }}
      >
        {notesComponent()}
        {activityStatusComponent()}
        {contentComponent()}
      </div> */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  style: state.local.style,
  userReportType: state.projects.projectToReport.userReportType,
  userReportObject: state.projects.projectToReport,
});

export default connect(mapStateToProps, {
  setFilterBox,
  editReportCategories,
  clearRiskDates,
})(ReportCategories) as any;
