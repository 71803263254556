import React from "react";
import { connect } from "react-redux";
import TabBorders from "./TabBorders";

const MenuTabs: React.FC<{
  tabState: any;
  setTabState: any;
  activeProjectActivityChanges: boolean;
  style: any;
  itemMenu: any;
}> = ({
  tabState,
  setTabState,
  activeProjectActivityChanges,
  style,
  itemMenu,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100vw" }}>
      <div
        style={{
          background: style.colors.disabledText,
          paddingTop: "10px",
          // paddingLeft: "10px",
          // position: "relative",
        }}
      >
        {/* <div
        style={{
          position: "absolute",
          borderBottom: "2px solid black",
          width: "1.5%",
          marginLeft: "-10px",
          top: 53
        }}
      ></div> */}
        <button
          style={{
            backgroundColor:
              tabState === "details" ? "white" : style.colors.secondaryText,
            color: tabState === "details" ? "black" : "white",
            // float: "left",
            borderTop: "2px solid black",
            borderRight:
              tabState === "details" || tabState === "management"
                ? "2px solid black"
                : "2px solid black",
            borderLeft: "2px solid black",
            borderBottom:
              tabState === "details"
                ? "2px solid white"
                : `2px solid ${style.colors.secondaryText}`,
            outline: "none",
            cursor: "pointer",
            padding: "14px 16px",
            fontSize: "1vw",
            width: "10vw",
            fontWeight: "bold",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            marginLeft: ".3vw",
          }}
          onClick={() => {
            if (activeProjectActivityChanges) {
              showChangesToast();
            } else {
              setTabState("details");
            }
          }}
        >
          <span style={{ visibility: itemMenu ? "initial" : "hidden" }}>
            Details
          </span>
        </button>
        <button
          style={{
            backgroundColor:
              tabState === "libraries" ? "white" : style.colors.secondaryText,
            color: tabState === "libraries" ? "black" : "white",
            // float: "left",
            borderTop: "2px solid black",
            borderRight:
              tabState !== "libraries" ? "2px solid black" : "2px solid black",
            borderLeft:
              tabState !== "libraries" ? "2px solid black" : "2px solid black",
            borderBottom:
              tabState === "libraries"
                ? "2px solid white"
                : `2px solid ${style.colors.secondaryText}`,
            outline: "none",
            cursor: "pointer",
            padding: "14px 16px",
            fontSize: "1vw",
            width: "10vw",
            fontWeight: "bold",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            marginLeft: ".3vw",
          }}
          onClick={() => {
            if (activeProjectActivityChanges) {
              showChangesToast();
            } else {
              setTabState("libraries");
            }
          }}
        >
          <span style={{ visibility: itemMenu ? "initial" : "hidden" }}>
            Libraries
          </span>
        </button>
        <button
          style={{
            backgroundColor:
              tabState === "management" ? "white" : style.colors.secondaryText,
            color: tabState === "management" ? "black" : "white",
            // float: "left",
            borderTop: "2px solid black",
            borderRight: "2px solid black",
            borderLeft:
              tabState === "details" || tabState === "management"
                ? "2px solid black"
                : "2px solid black",
            borderBottom:
              tabState === "management"
                ? "2px solid white"
                : `2px solid ${style.colors.secondaryText}`,
            outline: "none",
            cursor: "pointer",
            padding: "14px 16px",
            fontSize: "1vw",
            minWidth: "10vw",
            fontWeight: "bold",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            marginLeft: ".3vw",
          }}
          onClick={() => {
            if (activeProjectActivityChanges) {
              showChangesToast();
            } else {
              setTabState("management");
            }
          }}
        >
          <span style={{ visibility: itemMenu ? "initial" : "hidden" }}>
            HSI Management
          </span>
        </button>
      </div>
      {/* <TabBorders tabState={tabState} /> */}
    </div>
  );

  function showChangesToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar6");
    if (element[0]) {
      element[0].className = "snackbar6 show";
      setTimeout(function () {
        element[0].className = "snackbar6";
      }, 5000);
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  activeProjectActivityChanges: state.projects.activeProjectActivityChanges,
  style: state.local.style,
});

export default connect(mapStateToProps, {})(MenuTabs) as any;
