import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { convertTimeNumeric } from "../../../lib/helpers/convertTime";
import { useNavigate } from "react-router-dom";

import "./Table.css";
import {
  updateRecent,
  setActiveProject,
  addFavorite,
  editProject,
  saveAs,
  deleteProject,
} from "../../../redux/actions/Projects";

const Table: React.FC<{
  projectList: any;
  handleClick: any;
  setProjectClicked: any;
  openDeleteM: any;
  setSearchText: any;
  style: any;
  activeProject: any;
  setActiveProject: (project: any) => void;
  updateRecent: (projectId: string) => void;
  addFavorite: (projectId: string) => void;
  editProject: (project: any) => void;
  saveAs: (project: any) => void;
  deleteProject: (project: any) => void;
  adminFolderState: boolean;
  sideOption: string;
}> = ({
  projectList,
  handleClick,
  setProjectClicked,
  openDeleteM,
  setSearchText,
  style,
  activeProject,
  setActiveProject,
  updateRecent,
  addFavorite,
  editProject,
  saveAs,
  deleteProject,
  adminFolderState,
  sideOption,
}) => {
  const [tableRowMenu, setTableRowMenu] = useState(false) as any;
  const [projectHover, setProjectHover] = useState() as any;
  const [xPosition, setXPosition] = useState(0) as any;
  const [yPosition, setYPosition] = useState(0) as any;
  const [viewOption, setViewOption] = useState(
    sideOption === "Feedback" ? "current" : "all"
  ) as any;
  const navigate = useNavigate();

  const openMenu = () => {
    setTableRowMenu(true);
  };

  const openProject = () => {
    updateRecent(activeProject.projectId);
    navigate("/project");
  };

  const addFavoriteProject = () => {
    addFavorite(activeProject.projectId);
    setActiveProject(null);
  };

  const editNameTitle = () => {
    editProject(activeProject);
    setActiveProject(null);
  };

  const saveAsNew = () => {
    saveAs(activeProject);
    setActiveProject(null);
  };

  const goDelete = () => {
    deleteProject(activeProject);
    setActiveProject(null);
  };

  const handleReportClick = () => {
    navigate("/stepone");
  };

  return (
    <div>
      <select
        value={viewOption}
        name="allProjectOption"
        id="allProjectOption"
        style={{
          marginLeft: "4vw",
          width: "80px",
          height: "25px",
          marginTop: "20px",
          opacity: adminFolderState ? 1 : 0,
          cursor: adminFolderState ? "pointer" : "default",
        }}
        onChange={(e) => setViewOption(e.target.value)}
      >
        {sideOption === "Feedback" ? (
          <>
            <option value="current">Current</option>
            <option value="archived">Archived</option>
          </>
        ) : (
          <>
            <option value="all">All</option>
            <option value="current">Current</option>
            <option value="deleted">Deleted</option>
          </>
        )}
      </select>
      <div
        style={{
          marginLeft: "4vw",
          marginTop: "40px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="tableHeaders"
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "1.5px solid black",
            height: "5.5vh",
          }}
        >
          <div
            style={{
              width: "4vw",
              alignSelf: "center",
            }}
          >
            <input
              type="checkbox"
              id="id"
              name="id"
              value="Input"
              // checked={newInput}
              // onClick={inputCheck}
            />
            <label className="responsiveTableFont">ID</label>
          </div>
          <div
            style={{
              width: "28vw",
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
            }}
          >
            <p className="responsiveTableFont" style={{ fontSize: "0.825vw" }}>
              Description
            </p>
          </div>
          <div
            style={{
              width: "8vw",
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
            }}
          >
            <p className="responsiveTableFont" style={{ fontSize: "0.825vw" }}>
              Location
            </p>
          </div>
          <div
            style={{
              width: "21vw",
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
            }}
          >
            <p className="responsiveTableFont" style={{ fontSize: "0.825vw" }}>
              Submitted By
            </p>
          </div>
          <div
            style={{
              width: "7vw",
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
            }}
          >
            <p className="responsiveTableFont" style={{ fontSize: "0.825vw" }}>
              Date Created
            </p>
          </div>
        </div>
        <div
          style={{
            overflowY: "auto",
            maxHeight: "70vh",
          }}
        >
          {/* // .sort((a: any, b: any) => Number(a.modelId) - Number(b.modelId))
            // ? */}
          {projectList.map((item: any, index: number) => (
            <div
              key={index}
              // onDoubleClick={() => {
              //   setActiveProject(item);
              //   updateRecent(item.projectId);
              //   navigate("/project");
              // }}
              // onMouseOver={() => {
              //   setProjectHover(item.name);
              //   setProjectClicked(item.name);
              // }}
              className="tableRowData"
              style={{
                display: "flex",
                flexDirection: "row",
                // maxHeight: "9vh",
              }}
            >
              <div
                style={{
                  width: "4vw",
                  alignSelf: "center",
                }}
              >
                <input
                  type="checkbox"
                  id="id"
                  name="id"
                  value="Input"
                  // checked={newInput}
                  // onClick={inputCheck}
                />
                <label className="responsiveTableFont">{item.feedbackID}</label>
              </div>
              <div
                style={{
                  width: "28vw",
                }}
              >
                <p className="responsiveTableFont">{item.text}</p>
              </div>
              <div
                style={{
                  width: "8vw",
                }}
              >
                <p className="responsiveTableFont">{item.locationType}</p>
              </div>
              <div
                style={{
                  width: "21vw",
                  // alignSelf: "center",
                }}
              >
                <p className="responsiveTableFont" style={{ marginBottom: 0 }}>
                  {item.name}
                </p>
                <p
                  className="responsiveTableFont"
                  style={{ margin: 0 }}
                >{`(${item.email})`}</p>
              </div>
              <div
                style={{
                  width: "7vw",
                }}
              >
                <p className="responsiveTableFont">
                  {convertTimeNumeric(item.dateAdded)}
                </p>
                
              </div>
              {/* <div
                style={{
                  width: "1vw",
                }}
              >
                <p>
                  <i
                    onClick={(event) => {
                      openMenu();
                      setActiveProject({
                        ...item,
                      });
                      setXPosition(event.clientX);
                      setYPosition(event.clientY);
                      setSearchText("");
                    }}
                    style={{
                      alignSelf: "center",
                      cursor: "pointer",
                    }}
                    className="fa fa-ellipsis-v rowIcon"
                  ></i>
                </p>
              </div> */}
              {tableRowMenu && projectHover === item.name ? (
                <div
                  onMouseLeave={() => {
                    setTableRowMenu(false);
                  }}
                  style={{
                    position: "absolute",
                    backgroundColor: style.colors.white,
                    border: "1px solid grey",
                    left: xPosition,
                    top: yPosition,
                    zIndex: 3,
                  }}
                >
                  <ul
                    style={{
                      listStyle: "none",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    <li className="TableRowMenuItems">
                      <span>Open Upon Startup</span>
                    </li>
                    <li
                      className="TableRowMenuItems"
                      onClick={editNameTitle}
                      style={{ borderBottom: "1px solid grey" }}
                    >
                      <span>Edit Name/Description</span>
                    </li>
                    <li
                      className="TableRowMenuItems"
                      onClick={handleReportClick}
                    >
                      <span>Reports</span>
                    </li>
                    <li className="TableRowMenuItems" onClick={saveAsNew}>
                      <span>Save As</span>
                    </li>
                    <li className="TableRowMenuItems">
                      <span>Export</span>
                    </li>
                    <li
                      className="TableRowMenuItems"
                      style={{ borderBottom: "1px solid grey" }}
                    >
                      <span>Share</span>
                    </li>
                    <li
                      className="TableRowMenuItems"
                      // onClick={goDelete}
                    >
                      <span>Delete</span>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  activeProject: state.projects.activeProject,
  style: state.local.style,
});

export default connect(mapStateToProps, {
  setActiveProject,
  updateRecent,
  addFavorite,
  editProject,
  saveAs,
  deleteProject,
})(Table) as any;
