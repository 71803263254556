import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./referenceLibraryModal.css";
import {
  getReferenceLibrary,
  addNewReference,
  updateReference,
  deleteReference,
} from "../../redux/actions/Data";
import { editActivityReferenceList } from "../../redux/actions/Activities";
import {
  setActivityIDList,
  setActivityReferencesChanged,
} from "../../redux/actions/Projects";
import { referenceSort } from "../../lib/helpers/columnFilters";
import { ReactComponent as Editsvg } from "../../lib/assets/img/Edit.svg";

const ReferenceLibraryModal: React.FC<{
  closeReferenceM: any;
  referenceM: any;
  referenceLibrary: any;
  getReferenceLibrary: () => void;
  style: any;
  navSearch: string;
  addNewReference: (
    short: string,
    long: string,
    version: string,
    section: string,
    year: string,
    link: string,
    type: string,
    domain: string,
    phases: string,
    service: string
  ) => void;
  updateReference: (
    short: string,
    long: string,
    version: string,
    section: string,
    year: string,
    link: string,
    type: string,
    domain: string,
    phases: string,
    service: string,
    id: number
  ) => void;
  deleteReference: (id: number) => void;
  activityLibraryEdit: boolean;
  editActivityReferenceList: (activityId: string, list: any) => void;
  activeProjectActivity: any;
  setActivityIDList: (itemID: any) => void;
  activityIDList: any;
  setActivityReferencesChanged: (state: any) => void;
  activityReferencesChanged: boolean;
  initialReferences: any;
  detailsReference: any;
  setDetailsReference: any;
  user: any;
}> = ({
  closeReferenceM,
  referenceM,
  referenceLibrary,
  getReferenceLibrary,
  style,
  navSearch,
  addNewReference,
  updateReference,
  deleteReference,
  activityLibraryEdit,
  editActivityReferenceList,
  activeProjectActivity,
  setActivityIDList,
  activityIDList,
  setActivityReferencesChanged,
  activityReferencesChanged,
  initialReferences,
  detailsReference,
  setDetailsReference,
  user,
}) => {
  const [newReferenceModal, setNewReferenceModal] = useState(false) as any;
  const [referenceSearch, setReferenceSearch] = useState("") as any;
  const [searchFiltered, setSearchFiltered] = useState([]) as any;
  const [shortAscending, setShortAscending] = useState(1) as any;
  const [longAscending, setLongAscending] = useState(0) as any;
  const [versionAscending, setVersionAscending] = useState(0) as any;
  const [sectionAscending, setSectionAscending] = useState(0) as any;
  const [yearAscending, setYearAscending] = useState(0) as any;
  const [typeAscending, setTypeAscending] = useState(0) as any;
  const [domainAscending, setDomainAscending] = useState(0) as any;
  const [phaseAscending, setPhaseAscending] = useState(0) as any;
  const [serviceAscending, setServiceAscending] = useState(0) as any;
  const [dateAscending, setDateAscending] = useState(0) as any;
  const [newShort, setNewShort] = useState("") as any;
  const [newLong, setNewLong] = useState("") as any;
  const [newVersion, setNewVersion] = useState("") as any;
  const [newSection, setNewSection] = useState("") as any;
  const [newYear, setNewYear] = useState("") as any;
  const [newType, setNewType] = useState("Standard") as any;
  const [newDomain, setNewDomain] = useState("") as any;
  const [phaseTMRR, setPhaseTMRR] = useState(false) as any;
  const [phaseMSA, setPhaseMSA] = useState(false) as any;
  const [phaseOS, setPhaseOS] = useState(false) as any;
  const [phasePD, setPhasePD] = useState(false) as any;
  const [phaseEMD, setPhaseEMD] = useState(false) as any;
  const [phasePREMDD, setPhasePREMDD] = useState(false) as any;
  const [newService, setNewService] = useState("FedGov") as any;
  const [newLink, setNewLink] = useState("") as any;
  const [deleteOption, setDeleteOption] = useState(false) as any;
  const [focusId, setFocusId] = useState(0) as any;
  const [focusOccurr, setFocusOccurr] = useState([]) as any;
  const [shortError, setShortError] = useState(false) as any;
  const [longError, setLongError] = useState(false) as any;
  const [idList, setIDList] = useState([]) as any;
  const [deleteModal, setDeleteModal] = useState(false) as any;
  const [currentSort, setCurrentSort] = useState("short") as any;
  const [dateCreated, setDateCreated] = useState() as any;

  useEffect(() => {
    getReferenceLibrary();
  }, []);

  useEffect(() => {
    if (navSearch) {
      setReferenceSearch(navSearch);
    }
    if (detailsReference.length > 0) {
      setReferenceSearch(detailsReference);
    }
  }, [navSearch, detailsReference]);

  useEffect(() => {
    let referenceList = referenceLibrary?.filter(
      (x) => !x.hasOwnProperty("init_id")
    );

    if (referenceSearch !== "" && referenceLibrary?.length > 0) {
      referenceList = referenceList?.filter(
        (item: any) =>
          item.fullName.toLowerCase().indexOf(referenceSearch.toLowerCase()) >
            -1 ||
          item.shortName.toLowerCase().indexOf(referenceSearch.toLowerCase()) >
            -1
      );
    }

    referenceList = referenceSort(
      referenceList,
      shortAscending,
      longAscending,
      versionAscending,
      sectionAscending,
      yearAscending,
      typeAscending,
      domainAscending,
      phaseAscending,
      serviceAscending,
      dateAscending
    );

    setSearchFiltered(referenceList);
  }, [
    referenceSearch,
    referenceLibrary,
    shortAscending,
    longAscending,
    versionAscending,
    sectionAscending,
    yearAscending,
    typeAscending,
    domainAscending,
    phaseAscending,
    serviceAscending,
    dateAscending,
  ]);

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const shortColumn = () => {
    setCurrentSort(currentSort !== "short" ? "short" : currentSort);
    setLongAscending(0);
    setYearAscending(0);
    setTypeAscending(0);
    setDomainAscending(0);
    setPhaseAscending(0);
    setServiceAscending(0);
    setDateAscending(0);
    shortAscending === 1 ? setShortAscending(2) : setShortAscending(1);
  };

  const longColumn = () => {
    setCurrentSort(currentSort !== "long" ? "long" : currentSort);
    setShortAscending(0);
    setYearAscending(0);
    setTypeAscending(0);
    setDomainAscending(0);
    setPhaseAscending(0);
    setServiceAscending(0);
    setDateAscending(0);
    longAscending === 1 ? setLongAscending(2) : setLongAscending(1);
  };

  const yearColumn = () => {
    setCurrentSort(currentSort !== "year" ? "year" : currentSort);
    setLongAscending(0);
    setShortAscending(0);
    setTypeAscending(0);
    setDomainAscending(0);
    setPhaseAscending(0);
    setServiceAscending(0);
    setDateAscending(0);
    yearAscending === 1 ? setYearAscending(2) : setYearAscending(1);
  };

  const typeColumn = () => {
    setCurrentSort(currentSort !== "type" ? "type" : currentSort);
    setLongAscending(0);
    setYearAscending(0);
    setShortAscending(0);
    setDomainAscending(0);
    setPhaseAscending(0);
    setServiceAscending(0);
    setDateAscending(0);
    typeAscending === 1 ? setTypeAscending(2) : setTypeAscending(1);
  };

  const domainColumn = () => {
    setCurrentSort(currentSort !== "domain" ? "domain" : currentSort);
    setLongAscending(0);
    setYearAscending(0);
    setTypeAscending(0);
    setShortAscending(0);
    setPhaseAscending(0);
    setServiceAscending(0);
    setDateAscending(0);
    domainAscending === 1 ? setDomainAscending(2) : setDomainAscending(1);
  };

  const phaseColumn = () => {
    setCurrentSort(currentSort !== "phase" ? "phase" : currentSort);
    setLongAscending(0);
    setYearAscending(0);
    setTypeAscending(0);
    setDomainAscending(0);
    setShortAscending(0);
    setServiceAscending(0);
    setDateAscending(0);
    phaseAscending === 1 ? setPhaseAscending(2) : setPhaseAscending(1);
  };

  const serviceColumn = () => {
    setCurrentSort(currentSort !== "service" ? "service" : currentSort);
    setLongAscending(0);
    setYearAscending(0);
    setTypeAscending(0);
    setDomainAscending(0);
    setPhaseAscending(0);
    setShortAscending(0);
    setDateAscending(0);
    serviceAscending === 1 ? setServiceAscending(2) : setServiceAscending(1);
  };

  const dateColumn = () => {
    setCurrentSort(currentSort !== "date" ? "date" : currentSort);
    setLongAscending(0);
    setYearAscending(0);
    setTypeAscending(0);
    setDomainAscending(0);
    setPhaseAscending(0);
    setServiceAscending(0);
    setShortAscending(0);
    dateAscending === 1 ? setDateAscending(2) : setDateAscending(1);
  };

  const domainHSICheck = () => {
    if (newDomain === "HSI") {
      setNewDomain("");
    } else setNewDomain("HSI");
  };

  const domainTECheck = () => {
    if (newDomain === "T&E") {
      setNewDomain("");
    } else setNewDomain("T&E");
  };

  const domainMPTCheck = () => {
    if (newDomain === "MPT") {
      setNewDomain("");
    } else setNewDomain("MPT");
  };

  const domainESOHCheck = () => {
    if (newDomain === "ESOH") {
      setNewDomain("");
    } else setNewDomain("ESOH");
  };

  const domainHFECheck = () => {
    if (newDomain === "HFE") {
      setNewDomain("");
    } else setNewDomain("HFE");
  };

  const phaseTMRRCheck = () => {
    setPhaseTMRR(!phaseTMRR);
  };

  const phaseMSACheck = () => {
    setPhaseMSA(!phaseMSA);
  };

  const phaseOSCheck = () => {
    setPhaseOS(!phaseOS);
  };

  const phasePDCheck = () => {
    setPhasePD(!phasePD);
  };

  const phaseEMDCheck = () => {
    setPhaseEMD(!phaseEMD);
  };

  const phasePREMDDCheck = () => {
    setPhasePREMDD(!phasePREMDD);
  };

  const submitReference = (submitType: string) => {
    var phaseString: any = [];
    if (phaseEMD) {
      phaseString.push("EMD");
    }
    if (phaseMSA) {
      phaseString.push("MSA");
    }
    if (phaseOS) {
      phaseString.push("OS");
    }
    if (phasePD) {
      phaseString.push("PD");
    }
    if (phasePREMDD) {
      phaseString.push("PRE:MDD");
    }
    if (phaseTMRR) {
      phaseString.push("TMRR");
    }
    phaseString = phaseString.join().replace(/,[s]*/g, ", ");

    if (submitType === "newReference") {
      setShortAscending(0);
      setLongAscending(0);
      setVersionAscending(0);
      setSectionAscending(0);
      setYearAscending(0);
      setTypeAscending(0);
      setDomainAscending(0);
      setPhaseAscending(0);
      setServiceAscending(0);
      setDateAscending(0);
      addNewReference(
        newShort,
        newLong,
        newVersion,
        newSection,
        newYear,
        newLink,
        newType,
        newDomain,
        phaseString,
        newService
      );
    } else if (submitType === "updateReference") {
      setShortAscending(0);
      setLongAscending(0);
      setVersionAscending(0);
      setSectionAscending(0);
      setYearAscending(0);
      setTypeAscending(0);
      setDomainAscending(0);
      setPhaseAscending(0);
      setServiceAscending(0);
      setDateAscending(0);
      updateReference(
        newShort,
        newLong,
        newVersion,
        newSection,
        newYear,
        newLink,
        newType,
        newDomain,
        phaseString,
        newService,
        focusId
      );
    }
    cancelEntry();
  };

  const cancelEntry = () => {
    setNewShort("");
    setNewLong("");
    setNewVersion("");
    setNewSection("");
    setNewYear("");
    setNewType("Standard");
    setNewDomain("");
    setPhaseTMRR(false);
    setPhaseMSA(false);
    setPhaseOS(false);
    setPhasePD(false);
    setPhaseEMD(false);
    setPhasePREMDD(false);
    setNewService("FedGov");
    setNewLink("");
    setFocusId(0);
    setFocusOccurr([]);
    setNewReferenceModal(false);
    setShortError(false);
    setLongError(false);
    setDateCreated();
  };

  const deleteEntry = () => {
    deleteReference(focusId);
    cancelEntry();
  };

  const closingModal = () => {
    closeReferenceM();
    setReferenceSearch("");
    setActivityIDList("delete");
    setDetailsReference("");
  };

  const closeMini = () => {
    setDeleteOption(false);
    cancelEntry();
  };

  const saveProduct = () => {
    if (newShort.trim() !== "" && newLong.trim() !== "") {
      if (focusId === 0) {
        submitReference("newReference");
      } else if (focusId !== 0) {
        submitReference("updateReference");
      }
    } else {
      if (newShort.trim() === "") {
        setShortError(true);
      } else {
        setShortError(false);
      }
      if (newLong.trim() === "") {
        setLongError(true);
      } else {
        setLongError(false);
      }
    }
  };

  const handleCheckBox = (selection) => {
    setActivityIDList(selection);
    if (
      activityIDList.some((obj) => obj.referenceId === selection.referenceId)
    ) {
      setIDList(
        activityIDList.filter(
          (item: any) => item.referenceId !== selection.referenceId
        )
      );
    } else {
      let newArray = activityIDList;
      newArray.push(selection);
      setIDList(newArray);
    }
  };

  const cancelDelete = () => {
    closeMini();
    setDeleteModal(false);
  };

  const deleteItem = () => {
    deleteEntry();
    setDeleteModal(false);
  };

  const cancelButton = () => {
    closeReferenceM();
    setReferenceSearch("");
    setActivityIDList("delete");
    setDetailsReference("");
  };

  const checkReferenceChanges = (closeType: string) => {
    if (initialReferences.length === 0) {
      if (activityIDList.length > 0) {
        if (closeType === "save") {
          setActivityReferencesChanged(true);
          editActivityReferenceList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else if (closeType === "exit") {
          showChangesToast();
          setActivityReferencesChanged(true);
        } else {
          setActivityReferencesChanged(false);
          cancelButton();
        }
      } else {
        if (closeType === "save") {
          setActivityReferencesChanged(true);
          editActivityReferenceList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else {
          setActivityReferencesChanged(false);
          cancelButton();
        }
      }
    } else {
      if (activityIDList.length === initialReferences.length) {
        if (
          activityIDList.sort().join(",") === initialReferences.sort().join(",")
        ) {
          if (closeType === "save") {
            setActivityReferencesChanged(true);
            editActivityReferenceList(
              activeProjectActivity.activityId,
              activityIDList
            );
            cancelButton();
          } else if (closeType === "exit") {
            setActivityReferencesChanged(false);
            cancelButton();
          } else {
            setActivityReferencesChanged(false);
            cancelButton();
          }
        } else {
          showChangesToast();
          setActivityReferencesChanged(true);
        }
      } else {
        if (closeType === "save") {
          setActivityReferencesChanged(true);
          editActivityReferenceList(
            activeProjectActivity.activityId,
            activityIDList
          );
          cancelButton();
        } else if (closeType === "exit") {
          showChangesToast();
          setActivityReferencesChanged(true);
        } else {
          setActivityReferencesChanged(false);
          cancelButton();
        }
      }
    }
  };

  return (
    <div style={{ visibility: referenceM ? "initial" : "hidden" }}>
      <div className="modal-container-reference">
        <section className="projectModals-reference">
          <i
            className="fa fa-times"
            onClick={() => {
              activityLibraryEdit
                ? checkReferenceChanges("exit")
                : closingModal();
            }}
            style={{
              cursor: "pointer",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          ></i>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "-20px",
            }}
          >
            <p>Reference Library</p>
            <input
              className="referenceSearchBox"
              value={referenceSearch}
              onChange={(e) => setReferenceSearch(e.target.value)}
              style={{
                width: "160px",
                height: "20px",
                alignSelf: "center",
                marginRight: "40px",
                padding: "2px",
                border: "1px solid black",
                paddingLeft: "20px",
                borderRadius: "8px",
              }}
              placeholder="Search References"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p
              onClick={() => setNewReferenceModal(true)}
              style={{
                marginTop: "-5px",
                paddingBottom: "2px",
                fontSize: "13px",
                color: style.colors.primaryBlue,
                cursor: "pointer",
              }}
            >
              + Add New Reference
            </p>

            <div
              style={{
                display: activityLibraryEdit ? "flex" : "none",
                gap: 10,
                marginLeft: "auto",
              }}
            >
              <button
                onClick={cancelButton}
                style={{
                  backgroundColor: "grey",
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => checkReferenceChanges("save")}
                style={{
                  backgroundColor: style.colors.primaryBlue,
                  width: "100px",
                  height: "30px",
                  color: style.colors.white,
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Save
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "6px",
              borderTop: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            <div
              onClick={shortColumn}
              style={{
                // width: "153px",
                width: "8vw",
                minWidth: "96px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="responsiveFont">
                {shortAscending === 0
                  ? "Short Name"
                  : shortAscending === 1
                  ? "Short Name ↓"
                  : "Short Name ↑"}
              </p>
            </div>
            <div
              onClick={longColumn}
              style={{
                // width: "289px",
                width: "15vw",
                minWidth: "181px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="responsiveFont">
                {longAscending === 0
                  ? "Long Name"
                  : longAscending === 1
                  ? "Long Name ↓"
                  : "Long Name ↑"}
              </p>
            </div>
            {/* <div
              onClick={versionColumn}
              style={{
                // width: "117px",
                width: "6vw",
                minWidth: "73px",
                backgroundColor: "rgb(18, 18, 201)",
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="responsiveFont">Version</p>
            </div>
            <div
              onClick={sectionColumn}
              style={{
                // width: "114px",
                width: "6vw",
                minWidth: "73px",
                backgroundColor: "rgb(18, 18, 201)",
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="responsiveFont">Section</p>
            </div> */}
            <div
              onClick={yearColumn}
              style={{
                // width: "77px",
                width: "4vw",
                minWidth: "49px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="responsiveFont">
                {yearAscending === 0
                  ? "Year"
                  : yearAscending === 1
                  ? "Year ↓"
                  : "Year ↑"}
              </p>
            </div>
            <div
              onClick={typeColumn}
              style={{
                // width: "77px",
                width: "4vw",
                minWidth: "49px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="responsiveFont">
                {typeAscending === 0
                  ? "Type"
                  : typeAscending === 1
                  ? "Type ↓"
                  : "Type ↑"}
              </p>
            </div>
            {/* <div
              onClick={domainColumn}
              style={{
                // width: "116px",
                width: "6vw",
                minWidth: "73px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="responsiveFont">
                {domainAscending === 0
                  ? "Domain"
                  : domainAscending === 1
                  ? "Domain ↓"
                  : "Domain ↑"}
              </p>
            </div> */}
            {/* <div
              onClick={phaseColumn}
              style={{
                // width: "116px",
                width: "6vw",
                minWidth: "73px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="responsiveFont">
                {phaseAscending === 0
                  ? "Phase"
                  : phaseAscending === 1
                  ? "Phase ↓"
                  : "Phase ↑"}
              </p>
            </div> */}
            <div
              onClick={serviceColumn}
              style={{
                // width: "154px",
                width: "8vw",
                minWidth: "97px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "none",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="responsiveFont">
                {serviceAscending === 0
                  ? "Service/Organization"
                  : serviceAscending === 1
                  ? "Service/Organization ↓"
                  : "Service/Organization ↑"}
              </p>
            </div>
            <div
              onClick={dateColumn}
              style={{
                // width: "173px",
                width: "9.7vw",
                minWidth: "117px",
                backgroundColor: style.colors.primaryBlue,
                color: style.colors.white,
                borderLeft: "1px solid white",
                borderRight: "1px solid black",
                height: "29px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p className="responsiveFont" style={{ marginLeft: "10px" }}>
                {dateAscending === 0
                  ? "Date Added"
                  : dateAscending === 1
                  ? "Date Added ↓"
                  : "Date Added ↑"}
              </p>
            </div>
          </div>
          <div
            className="modal-body"
            style={{
              border: "1px solid black",
              paddingTop: ".75px",
            }}
          >
            {searchFiltered?.map((item: any, index: number) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor:
                    index % 2 === 0 ? style.colors.lightBlue : "white",
                }}
              >
                <div
                  style={{
                    // width: "153px",
                    width: "8vw",
                    minWidth: "96px",
                    borderLeft: "none",
                    borderRight: "none",
                    color: "black",
                    // height: "53px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="overFlowTextReference responsiveFont linkText"
                    onClick={() => window.open(item.links, "_blank")}
                    style={{ cursor: "pointer", paddingLeft: "5px" }}
                  >
                    {item.shortName}
                  </p>
                </div>
                <div
                  style={{
                    // width: "289px",
                    width: "15vw",
                    minWidth: "181px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "53px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="overFlowTextReference responsiveFont"
                    style={{
                      paddingLeft: "5px",
                    }}
                  >
                    {item.fullName}
                  </p>
                </div>
                {/* <div
                  style={{
                    // width: "117px",
                    width: "6vw",
                    minWidth: "73px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "53px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="overFlowTextReference responsiveFont">
                    {item.version}
                  </p>
                </div>
                <div
                  style={{
                    // width: "114px",
                    width: "6vw",
                    minWidth: "73px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "53px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p className="overFlowTextReference responsiveFont">
                    {item.section}
                  </p>
                </div> */}
                <div
                  style={{
                    // width: "77px",
                    width: "4vw",
                    minWidth: "49px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "53px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p className="responsiveFont" style={{ textAlign: "center" }}>
                    {item.year}
                  </p>
                </div>
                <div
                  style={{
                    // width: "77px",
                    width: "4vw",
                    minWidth: "49px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "53px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="responsiveFont">{item.type}</p>
                </div>
                {/* <div
                  style={{
                    // width: "116px",
                    width: "6vw",
                    minWidth: "73px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "53px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="responsiveFont">{item.domainId}</p>
                </div> */}
                {/* <div
                  style={{
                    // width: "116px",
                    width: "6vw",
                    minWidth: "73px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "53px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="overFlowTextReference responsiveFont">
                    {item.phaseId}
                  </p>
                </div> */}
                <div
                  style={{
                    // width: "154px",
                    width: "8vw",
                    minWidth: "97px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "53px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="responsiveFont">{item.serviceOrganization}</p>
                </div>
                <div
                  style={{
                    // width: "154px",
                    width: "8vw",
                    minWidth: "97px",
                    borderLeft:
                      index % 2 === 0
                        ? `1px solid ${style.colors.lightBlue}`
                        : "1px solid white",
                    borderRight: "none",
                    color: "black",
                    // height: "53px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p className="responsiveFont" style={{ marginLeft: "10px" }}>
                    {new Date(item.dateAdded * 1000).getDate() < 10 ? "0" : ""}
                    {new Date(item.dateAdded * 1000).getDate() +
                      " " +
                      months[new Date(item.dateAdded * 1000).getMonth()] +
                      " " +
                      new Date(item.dateAdded * 1000).getFullYear()}
                  </p>
                  {activityLibraryEdit ? (
                    <input
                      onClick={() => handleCheckBox(item)}
                      type="checkbox"
                      checked={
                        activityIDList.some(
                          (obj) => obj.referenceId === item.referenceId
                        )
                          ? true
                          : false
                      }
                      id="referencecheck"
                      name="referencecheck"
                      value="referencecheck"
                    />
                  ) : (
                    <Editsvg
                      className="modalPencil"
                      onClick={() => {
                        setDeleteOption(true);
                        setNewReferenceModal(true);
                        setNewShort(item.shortName);
                        setNewLong(item.fullName);
                        setNewVersion(item.version);
                        setNewSection(item.section);
                        setNewYear(
                          typeof item.year === "number"
                            ? item.year.toString()
                            : item.year
                        );
                        setNewType(item.type);
                        setNewDomain(item.domainId);
                        let phaseList = item.phaseId.split(", ");
                        setPhaseTMRR(phaseList.includes("TMRR") ? true : false);
                        setPhaseMSA(phaseList.includes("MSA") ? true : false);
                        setPhaseOS(phaseList.includes("OS") ? true : false);
                        setPhasePD(phaseList.includes("PD") ? true : false);
                        setPhaseEMD(phaseList.includes("EMD") ? true : false);
                        setPhasePREMDD(
                          phaseList.includes("PRE:MDD") ? true : false
                        );
                        setNewService(item.serviceOrganization);
                        setNewLink(item.links);
                        setFocusId(item.referenceId);
                        setFocusOccurr(item.occurrences);
                        setDateCreated(item.dateAdded);
                      }}
                      fill={style.colors.darkBlue}
                      height="2vh"
                      width="2vw"
                      style={{
                        marginLeft: "auto",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <div
        className="modal-container-reference"
        style={{ visibility: newReferenceModal ? "initial" : "hidden" }}
      >
        {/* <div style={{ maxHeight: "420px" }}> */}
        <section className="projectModals-reference-popupBox">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          >
            <i
              className="fa fa-times"
              onClick={closeMini}
              style={{
                cursor: "pointer",
                alignSelf: "center",
              }}
            ></i>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "40px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: shortError ? "red" : "black",
                }}
              >
                Short Name*:
              </p>
            </div>
            <input
              readOnly={
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              value={newShort}
              onChange={(e) => setNewShort(e.target.value)}
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "10px",
              }}
              placeholder="Short name..."
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "40px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: longError ? "red" : "black",
                }}
              >
                Full Name*:
              </p>
            </div>
            <input
              readOnly={
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              value={newLong}
              onChange={(e) => setNewLong(e.target.value)}
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "10px",
              }}
              placeholder="Full name..."
            />
          </div>

          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "40px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Version:</p>
            </div>
            <input
              value={newVersion}
              onChange={(e) => setNewVersion(e.target.value)}
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "10px",
              }}
              placeholder="Version..."
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "40px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Section:</p>
            </div>
            <input
              value={newSection}
              onChange={(e) => setNewSection(e.target.value)}
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "10px",
              }}
              placeholder="Section..."
            />
          </div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "40px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Year:</p>
            </div>
            <input
              readOnly={
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              value={newYear}
              onChange={(e) => setNewYear(e.target.value)}
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "10px",
              }}
              placeholder="Year..."
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "40px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: "black",
                }}
              >
                Link:
              </p>
            </div>
            <input
              readOnly={
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              value={newLink}
              onChange={(e) => setNewLink(e.target.value)}
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                marginTop: "10px",
              }}
              placeholder="Link..."
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "40px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Type:</p>
            </div>

            <div style={{ marginLeft: "10px", marginTop: "13px" }}>
              <select
                disabled={
                  dateCreated === 1695257977
                    ? user?.userRole?.includes("Admin")
                      ? false
                      : true
                    : false
                }
                value={newType}
                onChange={(e) => setNewType(e.target.value)}
                style={{ height: "25px", width: "248px" }}
              >
                <option value="Standard">Standard</option>
                <option value="Guidance">Guidance</option>
                <option value="Policy">Policy</option>
                <option value="DID">DID</option>
                <option value="Specification">Specification</option>
                <option value="Other">Other</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "65px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Domain:</p>
            </div>
            <div style={{ marginLeft: "10px", marginTop: "7px" }}>
              <input
                type="checkbox"
                id="domain1"
                name="domain"
                value="HSI"
                checked={newDomain === "HSI" ? true : false}
                onClick={domainHSICheck}
              />
              <label onClick={domainHSICheck}>HSI</label> <br></br>
              <input
                type="checkbox"
                id="domain2"
                name="domain"
                value="ESOH"
                checked={newDomain === "ESOH" ? true : false}
                onClick={domainESOHCheck}
              />
              <label onClick={domainESOHCheck}>ESOH</label> <br></br>
            </div>
            <div style={{ marginLeft: "10px", marginTop: "7px" }}>
              <input
                type="checkbox"
                id="domain3"
                name="domain"
                value="T&E"
                checked={newDomain === "T&E" ? true : false}
                onClick={domainTECheck}
              />
              <label onClick={domainTECheck}>T&E</label> <br></br>
              <input
                type="checkbox"
                id="domain4"
                name="domain"
                value="HFE"
                checked={newDomain === "HFE" ? true : false}
                onClick={domainHFECheck}
              />
              <label onClick={domainHFECheck}>HFE</label> <br></br>
            </div>
            <div style={{ marginLeft: "10px", marginTop: "7px" }}>
              <input
                type="checkbox"
                id="domain5"
                name="domain"
                value="MPT"
                checked={newDomain === "MPT" ? true : false}
                onClick={domainMPTCheck}
              />
              <label onClick={domainMPTCheck}>MPT</label> <br></br>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "65px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Phase:</p>
            </div>
            <div style={{ marginLeft: "10px", marginTop: "-4px" }}>
              <input
                type="checkbox"
                id="phase1"
                name="phase"
                value="TMRR"
                checked={phaseTMRR}
                onClick={phaseTMRRCheck}
              />
              <label onClick={phaseTMRRCheck}>TMRR</label> <br></br>
              <input
                type="checkbox"
                id="phase2"
                name="phase"
                value="MSA"
                checked={phaseMSA}
                onClick={phaseMSACheck}
              />
              <label onClick={phaseMSACheck}>MSA</label> <br></br>
              <input
                type="checkbox"
                id="phase3"
                name="phase"
                value="OS"
                checked={phaseOS}
                onClick={phaseOSCheck}
              />
              <label onClick={phaseOSCheck}>OS</label> <br></br>
            </div>
            <div style={{ marginLeft: "10px", marginTop: "-4px" }}>
              <input
                type="checkbox"
                id="phase4"
                name="phase"
                value="PD"
                checked={phasePD}
                onClick={phasePDCheck}
              />
              <label onClick={phasePDCheck}>PD</label> <br></br>
              <input
                type="checkbox"
                id="phase5"
                name="phase"
                value="EMD"
                checked={phaseEMD}
                onClick={phaseEMDCheck}
              />
              <label onClick={phaseEMDCheck}>EMD</label> <br></br>
              <input
                type="checkbox"
                id="phase6"
                name="phaseco"
                value="PRE:MDD"
                checked={phasePREMDD}
                onClick={phasePREMDDCheck}
              />
              <label onClick={phasePREMDDCheck}>PRE:MDD</label> <br></br>
            </div>
          </div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                height: "40px",
              }}
            >
              <p style={{ paddingRight: "10px" }}>Service/Organization:</p>
            </div>

            <div style={{ marginLeft: "10px", marginTop: "13px" }}>
              <select
                disabled={
                  dateCreated === 1695257977
                    ? user?.userRole?.includes("Admin")
                      ? false
                      : true
                    : false
                }
                value={newService}
                onChange={(e) => setNewService(e.target.value)}
                style={{ height: "25px", width: "248px" }}
              >
                <option value="Guidance">Federal Government</option>
                <option value="Specification">USAF</option>
              </select>
            </div>
          </div>

          <div
            style={{
              display: focusId !== 0 ? "flex" : "none",
              flexDirection: "row",
            }}
          >
            <div>
              <p style={{ fontWeight: "bold" }}>Occurrences in Pathways</p>
              {focusOccurr?.length > 0 ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "29px",
                      borderTop: "1px solid black",
                      borderLeft: "1px solid black",
                    }}
                  >
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "209px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p className="responsiveFont">Pathway</p>
                    </div>
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "100px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        borderLeft: "1px solid white",
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p className="responsiveFont">Phase</p>
                    </div>
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "107px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        borderLeft: "1px solid white",
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        borderRight: "1px solid black",
                      }}
                    >
                      <p className="responsiveFont">Domain</p>
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid black",
                      paddingTop: ".75px",
                    }}
                  >
                    {focusOccurr?.map((occur, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor:
                            index % 2 === 0 ? style.colors.lightBlue : "white",
                        }}
                      >
                        <div
                          style={{
                            width: "209px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p className="responsiveFont">{occur.pathwayName}</p>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p className="responsiveFont">{occur.phaseName}</p>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p className="responsiveFont">{occur.domainName}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p
                  style={{
                    width: "400px",
                  }}
                >
                  No occurrences since the reference is currently not linked to
                  any activities.
                </p>
              )}
            </div>
          </div>
          <div
            style={{
              display:
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? "flex"
                    : "none"
                  : "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 20,
              marginTop: "10px",
              paddingBottom: focusOccurr?.length > 0 ? "20px" : 0,
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: deleteOption ? "pointer" : "default",
                opacity: deleteOption ? 1 : 0,
              }}
              onClick={() => {
                if (deleteOption) setDeleteModal(true);
              }}
            >
              Delete
            </button>
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={cancelEntry}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={saveProduct}
            >
              Save
            </button>
          </div>
        </section>
        {/* </div> */}
      </div>

      <div
        className="delete-modal-container-reference"
        style={{ visibility: deleteModal ? "initial" : "hidden" }}
      >
        <section className="projectModals-reference">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          >
            <i
              className="fa fa-times"
              onClick={cancelDelete}
              style={{
                cursor: "pointer",
                alignSelf: "center",
              }}
            ></i>
          </div>

          <p style={{ paddingBottom: "1px", textAlign: "center" }}>
            You are about to delete:
          </p>

          <div style={{ paddingBottom: "2px", textAlign: "center" }}>
            <p>{newShort} from the Reference Library</p>
            <p style={{ paddingBottom: "2px" }}>
              Are you sure you would like to continue?
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 20,
              marginTop: "10px",
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={cancelDelete}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={deleteItem}
            >
              Delete
            </button>
          </div>
        </section>
      </div>
    </div>
  );

  function showChangesToast() {
    const element = document.querySelectorAll<HTMLElement>(".snackbar6");
    if (element[0]) {
      element[0].className = "snackbar6 show";
      setTimeout(function () {
        element[0].className = "snackbar6";
      }, 5000);
      return;
    }
  }
};

const mapStateToProps = (state: any) => ({
  referenceLibrary: state.data.referenceLibrary,
  style: state.local.style,
  navSearch: state.data.navSearch,
  activityLibraryEdit: state.projects.activityLibraryEdit,
  activeProjectActivity: state.projects.activeProjectActivity,
  activityIDList: state.projects.activityIDList,
  activityReferencesChanged: state.projects.activityReferencesChanged,
  initialReferences: state.projects.initialReferences,
  user: state.user.user,
});

export default connect(mapStateToProps, {
  getReferenceLibrary,
  addNewReference,
  updateReference,
  deleteReference,
  editActivityReferenceList,
  setActivityIDList,
  setActivityReferencesChanged,
})(ReferenceLibraryModal) as any;
