import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import Table from "./Table";
import Card from "../Card";
import { getAllUsers } from "../../../redux/actions/Data";
import { getHsiFrameworkModels } from "../../../redux/actions/Projects";

const Accounts: React.FC<{
  allUsers: any;
  getAllUsers: () => void;
  hsiFrameworkModels: any;
  getHsiFrameworkModels: () => void;
  highlightProject: any;
  setHighlightProject: any;
  setEllipsisClick: any;
}> = ({
  allUsers,
  getAllUsers,
  hsiFrameworkModels,
  getHsiFrameworkModels,
  highlightProject,
  setHighlightProject,
  setEllipsisClick,
}) => {
  const [viewState, setViewState] = useState("box") as any;
  const handleBoxClick = () => {
    setViewState("box");
  };

  const handleListClick = () => {
    setViewState("list");
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>
      <div
        className="subTitle"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p
          style={{ paddingLeft: "25px", fontWeight: "bold", fontSize: "16px" }}
        >
          User Accounts
        </p>
      </div>
      <div className="header2" style={{ marginTop: 0 }}>
        {/* all your projects here  */}
        <Table
          projectList={allUsers}
          // handleClick={() => console.log("CLicked")}
          // setProjectClicked={() => console.log("CLicked")}
          // openDeleteM={() => console.log("CLicked")}
          adminFolderState={false}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  allUsers: state.data.allUsers,
  hsiFrameworkModels: state.projects.hsiFrameworkModels,
});

export default connect(mapStateToProps, { getAllUsers, getHsiFrameworkModels })(
  Accounts
) as any;
