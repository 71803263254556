import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./didsModal.css";
import Modal from "react-modal";
import {
  addNewDIDs,
  deleteDIDs,
  getDIDSLibrary,
  updateDIDs,
} from "../../redux/actions/Data";
import { didsSort } from "../../lib/helpers/columnFilters";
import { ReactComponent as Editsvg } from "../../lib/assets/img/Edit.svg";
import DeleteLibraryItemModal from "../deleteLibraryItemModal/deleteLibraryItemModal";

const DIDSModal: React.FC<{
  closeDIDSM: any;
  didsM: any;
  didsLibrary: any;
  getDIDSLibrary: () => void;
  style: any;
  addNewDIDs: (documentID: string, name: string, link: string) => void;
  updateDIDs: (
    documentID: string,
    name: string,
    link: string,
    didsID: string
  ) => void;
  deleteDIDs: (id: string) => void;
  navSearch: string;
  user: any;
}> = ({
  closeDIDSM,
  didsM,
  didsLibrary,
  getDIDSLibrary,
  style,
  addNewDIDs,
  updateDIDs,
  deleteDIDs,
  navSearch,
  user,
}) => {
  const [newDIDSModal, setNewDIDSModal] = useState(false) as any;
  const [didsSearch, setDidsSearch] = useState("") as any;
  const [searchFiltered, setSearchFiltered] = useState([]) as any;
  const [newDocumentID, setNewDocumentID] = useState("") as any;
  const [newName, setNewName] = useState("") as any;
  const [newLink, setNewLink] = useState("") as any;
  const [documentIDError, setDocumentIDError] = useState(false) as any;
  const [nameError, setNameError] = useState(false) as any;
  const [linkError, setLinkError] = useState(false) as any;
  const [deleteOption, setDeleteOption] = useState(false) as any;
  const [focusId, setFocusId] = useState("") as any;
  const [focusOccurr, setFocusOccurr] = useState([]) as any;
  const [documentIDAscending, setDocumentIDAscending] = useState(1) as any;
  const [nameAscending, setNameAscending] = useState(0) as any;
  const [linkAscending, setLinkAscending] = useState(0) as any;
  const [deleteModal, setDeleteModal] = useState(false) as any;
  const [currentSort, setCurrentSort] = useState("document") as any;
  const [dateCreated, setDateCreated] = useState() as any;

  const customStyles = {
    content: {
      top: "40%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
  };

  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
    overlay: {
      background: "none",
    },
  };

  useEffect(() => {
    getDIDSLibrary();
  }, [getDIDSLibrary]);

  useEffect(() => {
    setDidsSearch(navSearch);
  }, [navSearch]);

  useEffect(() => {
    let didsList = didsLibrary.filter((x) => !x.hasOwnProperty("init_id"));

    if (didsSearch !== "" && didsLibrary?.length > 0) {
      didsList = didsList.filter(
        (item: any) =>
          item?.documentID?.toLowerCase().indexOf(didsSearch.toLowerCase()) >
            -1 ||
          item?.name?.toLowerCase().indexOf(didsSearch.toLowerCase()) > -1
      );
    }

    didsList = didsSort(
      didsList,
      documentIDAscending,
      nameAscending,
      linkAscending
    );

    setSearchFiltered(didsList);
  }, [
    didsSearch,
    didsLibrary,
    documentIDAscending,
    nameAscending,
    linkAscending,
  ]);

  const documentIDColumn = () => {
    setCurrentSort(currentSort !== "document" ? "document" : currentSort);
    setNameAscending(0);
    setLinkAscending(0);
    documentIDAscending === 1
      ? setDocumentIDAscending(2)
      : setDocumentIDAscending(1);
  };

  const nameColumn = () => {
    setCurrentSort(currentSort !== "name" ? "name" : currentSort);
    setDocumentIDAscending(0);
    setLinkAscending(0);
    nameAscending === 1 ? setNameAscending(2) : setNameAscending(1);
  };

  const linkColumn = () => {
    setCurrentSort(currentSort !== "link" ? "link" : currentSort);
    setDocumentIDAscending(0);
    setNameAscending(0);
    linkAscending === 1 ? setLinkAscending(2) : setLinkAscending(1);
  };

  const cancelDids = () => {
    setNewDIDSModal(false);
    setDocumentIDError(false);
    setNameError(false);
    setLinkError(false);
    setFocusId("");
    setFocusOccurr([]);
    setNewDocumentID("");
    setNewName("");
    setNewLink("");
    setDateCreated();
  };

  const saveDIDs = () => {
    if (
      newDocumentID.trim() !== "" &&
      newName.trim() !== "" &&
      newLink.trim() !== ""
    ) {
      if (focusId === "") {
        setDocumentIDAscending(documentIDAscending);
        setNameAscending(nameAscending);
        setLinkAscending(linkAscending);
        addNewDIDs(newDocumentID, newName, newLink);
        setNewDIDSModal(false);
        setFocusId("");
        setFocusOccurr([]);
        setNewDocumentID("");
        setNewName("");
        setNewLink("");
        setDateCreated();
        setDocumentIDError(false);
        setNameError(false);
        setLinkError(false);
      } else if (focusId !== "") {
        setDocumentIDAscending(documentIDAscending);
        setNameAscending(nameAscending);
        setLinkAscending(linkAscending);
        updateDIDs(newDocumentID, newName, newLink, focusId);
        setNewDIDSModal(false);
        setFocusId("");
        setFocusOccurr([]);
        setNewDocumentID("");
        setNewName("");
        setNewLink("");
        setDateCreated();
        setDocumentIDError(false);
        setNameError(false);
        setLinkError(false);
      }
    } else {
      if (newDocumentID.trim() === "") {
        setDocumentIDError(true);
      } else {
        setDocumentIDError(false);
      }
      if (newName.trim() === "") {
        setNameError(true);
      } else {
        setNameError(false);
      }
      if (newLink.trim() === "") {
        setLinkError(true);
      } else {
        setLinkError(false);
      }
    }
  };

  const deleteDIDSAction = () => {
    deleteDIDs(focusId);
    setDeleteOption(false);
    setNewDIDSModal(false);
    setFocusId("");
    setFocusOccurr([]);
    setNewDocumentID("");
    setNewName("");
    setNewLink("");
    setDateCreated();
  };

  const closeMini = () => {
    setDeleteOption(false);
    setFocusId("");
    setFocusOccurr([]);
    setNewDocumentID("");
    setNewName("");
    setNewLink("");
    setDateCreated();
    cancelDids();
  };

  return (
    <div>
      <Modal isOpen={didsM} onRequestClose={closeDIDSM} style={customStyles}>
        <i
          className="fa fa-times"
          onClick={closeDIDSM}
          style={{
            cursor: "pointer",
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-10px",
          }}
        ></i>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "-20px",
          }}
        >
          <p>DIDs Library</p>
          <input
            className="didsSearchBox"
            value={didsSearch}
            onChange={(e) => setDidsSearch(e.target.value)}
            style={{
              width: "130px",
              height: "20px",
              alignSelf: "center",
              marginRight: "40px",
              padding: "2px",
              border: "1px solid black",
              paddingLeft: "20px",
              borderRadius: "8px",
            }}
            placeholder="Search DIDs"
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <p
            onClick={() => setNewDIDSModal(true)}
            style={{
              marginTop: "-5px",
              paddingBottom: "2px",
              fontSize: "13px",
              color: style.colors.primaryBlue,
              cursor: "pointer",
            }}
          >
            + Add New DID
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <div
            onClick={documentIDColumn}
            style={{
              // width: "192px",
              width: "7vw",
              minWidth: "91px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {documentIDAscending === 0
                ? "Doucument ID"
                : documentIDAscending === 1
                ? "Doucument ID ↓"
                : "Doucument ID ↑"}
            </p>
          </div>
          <div
            onClick={nameColumn}
            style={{
              // width: "518px",
              width: "23vw",
              minWidth: "302px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              borderLeft: "1px solid white",
              borderRight: "1px solid white",
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont">
              {nameAscending === 0
                ? "Name"
                : nameAscending === 1
                ? "Name ↓"
                : "Name ↑"}
            </p>
          </div>
          <div
            onClick={linkColumn}
            style={{
              // width: "172px",
              width: "27vw",
              minWidth: "351px",
              backgroundColor: style.colors.primaryBlue,
              color: style.colors.white,
              height: "29px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRight: "1px solid black",
              cursor: "pointer",
            }}
          >
            <p className="responsiveFont" style={{ marginLeft: "15px" }}>
              {linkAscending === 0
                ? "Link"
                : linkAscending === 1
                ? "Link ↓"
                : "Link ↑"}
            </p>
          </div>
        </div>
        <div
          className="modal-body"
          style={{
            border: "1px solid black",
            paddingTop: ".75px",
          }}
        >
          {searchFiltered?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor:
                  index % 2 === 0 ? style.colors.lightBlue : "white",
              }}
            >
              <div
                style={{
                  // width: "192px",
                  width: "7vw",
                  minWidth: "91px",
                  borderLeft: "none",
                  borderRight: "none",
                  color: "black",
                  // height: "29px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="overFlowTextDIDS responsiveFont">
                  {item.documentID}
                </p>
              </div>
              <div
                style={{
                  // width: "518px",
                  width: "23vw",
                  minWidth: "302px",
                  borderLeft:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  borderRight:
                    index % 2 === 0
                      ? `1px solid ${style.colors.lightBlue}`
                      : "1px solid white",
                  color: "black",
                  // height: "29px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextDIDS responsiveFont"
                  title={item.name}
                >
                  {item.name}
                </p>
              </div>
              <div
                style={{
                  // width: "153px",
                  width: "26vw",
                  minWidth: "335px",
                  borderLeft: "none",
                  borderRight: "none",
                  color: "black",
                  // height: "29px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="overFlowTextDIDS responsiveFont linkText"
                  style={{ cursor: "pointer", paddingLeft: "5px" }}
                  onClick={() => window.open(item.links, "_blank")}
                >
                  {item.links}
                </p>
                <Editsvg
                  className="modalPencil"
                  onClick={() => {
                    setDeleteOption(true);
                    setNewDIDSModal(true);
                    setFocusId(item.didsID);
                    setFocusOccurr(item.occurrences);
                    setNewDocumentID(item.documentID);
                    setNewName(item.name);
                    setNewLink(item.links);
                    setDateCreated(item.dateAdded);
                  }}
                  fill={style.colors.darkBlue}
                  height="1.5vh"
                  width="1.5vw"
                  style={{
                    marginLeft: "auto",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </Modal>

      <Modal
        isOpen={newDIDSModal}
        onRequestClose={closeMini}
        style={customStyles2}
      >
        <div style={{ maxHeight: "420px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-10px",
            }}
          >
            <i
              className="fa fa-times"
              onClick={closeMini}
              style={{
                cursor: "pointer",
                alignSelf: "center",
              }}
            ></i>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                // height: "70px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: documentIDError ? "red" : "black",
                }}
              >
                Document ID*:
              </p>
            </div>
            <input
              readOnly={
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                // marginTop: "-20px",
              }}
              value={newDocumentID}
              onChange={(e) => setNewDocumentID(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                // height: "70px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: nameError ? "red" : "black",
                }}
              >
                Document Name*:
              </p>
            </div>
            <input
              readOnly={
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                // marginTop: "-20px",
              }}
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "150px",
                borderRight: "2px solid lightgrey",
                justifyContent: "flex-end",
                // height: "70px",
              }}
            >
              <p
                style={{
                  paddingRight: "10px",
                  color: linkError ? "red" : "black",
                }}
              >
                Link*:
              </p>
            </div>
            <input
              readOnly={
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? false
                    : true
                  : false
              }
              style={{
                width: "240px",
                height: "20px",
                cursor: "default",
                alignSelf: "center",
                marginLeft: "10px",
                // marginTop: "-20px",
              }}
              value={newLink}
              onChange={(e) => setNewLink(e.target.value)}
            />
          </div>

          <div
            style={{
              display: focusId !== 0 ? "flex" : "none",
              flexDirection: "row",
            }}
          >
            <div>
              <p style={{ fontWeight: "bold" }}>Occurrences in Pathways</p>
              {focusOccurr?.length > 0 ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "29px",
                      borderTop: "1px solid black",
                      borderLeft: "1px solid black",
                    }}
                  >
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "209px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p className="responsiveFont">Pathway</p>
                    </div>
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "100px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        borderLeft: "1px solid white",
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p className="responsiveFont">Phase</p>
                    </div>
                    <div
                      // onClick={nameColumn}
                      style={{
                        width: "107px",
                        // width: "14.8vw",
                        // minWidth: "159px",
                        backgroundColor: style.colors.primaryBlue,
                        color: style.colors.white,
                        borderLeft: "1px solid white",
                        // height: "38px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        borderRight: "1px solid black",
                      }}
                    >
                      <p className="responsiveFont">Domain</p>
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid black",
                      paddingTop: ".75px",
                    }}
                  >
                    {focusOccurr?.map((occur, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor:
                            index % 2 === 0 ? style.colors.lightBlue : "white",
                        }}
                      >
                        <div
                          style={{
                            width: "209px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p className="responsiveFont">{occur.pathwayName}</p>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p className="responsiveFont">{occur.phaseName}</p>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            // width: "14.8vw",
                            // minWidth: "159px",
                            borderLeft:
                              index % 2 === 0
                                ? `1px solid ${style.colors.lightBlue}`
                                : "1px solid white",
                            borderRight: "none",
                            color: "black",
                            // height: "38px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p className="responsiveFont">{occur.domainName}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p
                  style={{
                    width: "400px",
                  }}
                >
                  No occurrences since the DID is currently not linked to any
                  activities.
                </p>
              )}
            </div>
          </div>

          <div
            style={{
              display:
                dateCreated === 1695257977
                  ? user?.userRole?.includes("Admin")
                    ? "flex"
                    : "none"
                  : "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 20,
              marginTop: "10px",
              paddingBottom: focusOccurr?.length > 0 ? "20px" : 0,
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: deleteOption ? "pointer" : "default",
                opacity: deleteOption ? 1 : 0,
              }}
              onClick={() => {
                if (deleteOption) setDeleteModal(true);
              }}
            >
              Delete
            </button>
            <button
              style={{
                backgroundColor: "grey",
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={cancelDids}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: style.colors.primaryBlue,
                width: "100px",
                height: "30px",
                color: style.colors.white,
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={saveDIDs}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <DeleteLibraryItemModal
        itemTitle={newDocumentID}
        itemLibrary="DIDs"
        itemCancel={cancelDids}
        itemDelete={deleteDIDSAction}
        deleteM={deleteModal}
        setDeleteModal={setDeleteModal}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  didsLibrary: state.data.didsLibrary,
  style: state.local.style,
  navSearch: state.data.navSearch,
  user: state.user.user,
});

export default connect(mapStateToProps, {
  getDIDSLibrary,
  addNewDIDs,
  updateDIDs,
  deleteDIDs,
})(DIDSModal) as any;
