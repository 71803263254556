import React, { useRef, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import Table from "../Table";
import Card from "../Card";
import { getDeleted } from "../../../redux/actions/Projects";

const Deleted: React.FC<{
  deleted: any;
  getDeleted: () => void;
  highlightProject: any;
  setHighlightProject: any;
  setEllipsisClick: any;
}> = ({
  deleted,
  getDeleted,
  highlightProject,
  setHighlightProject,
  setEllipsisClick,
}) => {
  const [viewState, setViewState] = useState("box") as any;
  const handleBoxClick = () => {
    setViewState("box");
  };

  const handleListClick = () => {
    setViewState("list");
  };

  useEffect(() => {
    getDeleted();
  }, []);

  return (
    <>
      <div
        className="subTitle"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p style={{ paddingLeft: "25px", fontWeight: "bold", fontSize: "16px" }}>Deleted</p>
        <div className="push">
          <i
            onClick={handleBoxClick}
            className="fa fa-th"
            style={{
              marginRight: "30px",
              color: viewState === "box" ? "black" : "lightgrey",
            }}
          ></i>
          <i
            onClick={handleListClick}
            style={{ color: viewState === "list" ? "black" : "lightgrey" }}
            className="fa fa-list-ul"
          ></i>
        </div>
      </div>
      <div
        className="header2"
        style={
          viewState === "box"
            ? {
                maxHeight: "100vh",
                overflowY: "auto",
              }
            : { marginTop: 0 }
        }
      >
        {/* all your projects here  */}
        {viewState === "box" ? (
          deleted?.map((item: any, index: number) => (
            <Card
              key={index}
              item={item}
              index={index}
              bottom={index === deleted.length - 1}
              highlightProject={highlightProject}
              setHighlightProject={setHighlightProject}
              setEllipsisClick={setEllipsisClick}
              // allItems={deleted}
            />
          ))
        ) : (
          <Table
            projectList={deleted}
            // handleClick={() => console.log("CLicked")}
            // setProjectClicked={() => console.log("CLicked")}
            // openDeleteM={() => console.log("CLicked")}
            adminFolderState={false}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  deleted: state.projects.deleted,
});

export default connect(mapStateToProps, { getDeleted })(Deleted) as any;
