export const phases1 = [
  {
    id: "PREDTitle",
    data: { label: "Pre-Development" },
    position: { x: 0, y: 0 },
    draggable: false,
    phaseShortName: "PRE-DEV",
    phaseFullName: "Pre-Development Phase",
    phaseDescription:
      "Analyze and select a course of action that develops an acquisition approach to quickly field a capability.",
    jcidsActivity: [],
    color: "#E97272",
    style: {
      width: 14440,
      height: 65,
      backgroundColor: "#E97272",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "PREDTitle",
  },
  {
    id: "DTitle",
    data: { label: "Development" },
    position: { x: 14462, y: 0 },
    draggable: false,
    phaseShortName: "DEV",
    phaseFullName: "Development Phase",
    phaseDescription:
      "Assess the performance, safety, suitability, survivability, and supportability of the capability. Determine which deficiencies must be resolved and which risks are acceptable before production or deployment.",
    jcidsActivity: [],
    color: "#944F99",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#944F99",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "DTitle",
  },
  {
    id: "PADTitle",
    data: { label: "Production and Development" },
    position: { x: 28904, y: 0 },
    draggable: false,
    phaseShortName: "PAD",
    phaseFullName: "Production and Development Phase",
    phaseDescription:
      "Provide the user with the needed capability, along with required training materials and additional logistics support necessary for operation.",
    jcidsActivity: [],
    color: "#17BDAC",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#17BDAC",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "PDTitle",
  },
  {
    id: "OSTitle",
    data: { label: "Operations and Support" },
    position: { x: 43346, y: 0 },
    draggable: false,
    phaseShortName: "OAS",
    phaseFullName: "Operations and Support Phase",
    phaseDescription:
      "Execute a supportability strategy that ensures material readiness and meets operational support performance requirements.",
    jcidsActivity: [],
    color: "#6071BF",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#6071BF",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "OSTitle",
  },
];

export const phases2 = [
  {
    id: "RPTitle",
    data: { label: "Rapid Prototyping" },
    position: { x: 0, y: 0 },
    draggable: false,
    phaseShortName: "RP",
    phaseFullName: "Rapid Prototyping Phase",
    phaseDescription:
      "Rapidly develop a fieldable prototype utilizing innovative technologies to demonstrate new capabilities and meet emerging military needs.",
    jcidsActivity: [],
    color: "#E97272",
    style: {
      width: 14440,
      height: 65,
      backgroundColor: "#E97272",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "RPTitle",
  },
  {
    id: "RFTitle",
    data: { label: "Rapid Fielding" },
    position: { x: 14462, y: 0 },
    draggable: false,
    phaseShortName: "RF",
    phaseFullName: "Rapid Fielding Phase",
    phaseDescription:
      "Use proven technologies to field production quantities of new or upgraded systems which require minimal development to meet emerging military needs. ",
    jcidsActivity: [],
    color: "#944F99",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#944F99",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "RFTitle",
  },
  {
    id: "OPSSTitle",
    data: { label: "Operation and Sustainment" },
    position: { x: 28904, y: 0 },
    draggable: false,
    phaseShortName: "OPSS",
    phaseFullName: "Operation and Sustainment Phase",
    phaseDescription:
      "Execute a supportability strategy that ensures material readiness and meets operational support performance requirements. ",
    jcidsActivity: [],
    color: "#17BDAC",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#17BDAC",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "OPSSTitle",
  },
];

export const phases3 = [
  {
    id: "PMDDTitle",
    data: { label: "Pre-Material Development Decision" },
    position: { x: 0, y: 0 },
    draggable: false,
    phaseShortName: "PRE-MDD",
    phaseFullName: "Pre-Materiel Development Decision (MDD) Phase",
    phaseDescription:
      "Analyze both materiel and non-materiel solutions to fulfill capability need.",
    jcidsActivity: [
      "Develop Strategic Guidance.",
      "Formulate Joint Operations Concepts.",
      "Define CONOPS.",
      "Draft Operations Plans.",
      "Perform CBA.",
    ],
    color: "#E97272",
    style: {
      width: 14440,
      height: 65,
      backgroundColor: "#E97272",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "PMDDTitle",
  },
  {
    id: "MSATitle",
    data: { label: "Material Solution Analysis" },
    position: { x: 14462, y: 0 },
    draggable: false,
    phaseShortName: "MSA",
    phaseFullName: "Materiel Solution Analysis Phase",
    phaseDescription:
      "Analyze and recommend materiel solutions that fulfill capability need.​",
    jcidsActivity: [
      "Establish Joint Operations Concepts and CONOPS.",
      "Formulate TDS.",
      "Propose DoD Enterprise Architecture & Solution Architecture.",
      "Prepare Technical Standards Profile.",
    ],
    color: "#944F99",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#944F99",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "MSATitle",
  },
  {
    id: "TMRRTitle",
    data: { label: "Technology Maturation & Risk Reduction" },
    position: { x: 28904, y: 0 },
    draggable: false,
    phaseShortName: "TMRR",
    phaseFullName: "Technology Maturation and Risk Reduction Phase",
    phaseDescription:
      "Assess system preliminary design to ensure system meets stated performance requirements.​",
    jcidsActivity: [
      "Evaluate threshold/objective tradeoffs and revise performance attributes.",
      "Conduct STA.",
      "Hold Joint Staff Review.",
      "Obtain component/JROC validation and approval.",
    ],
    color: "#17BDAC",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#17BDAC",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "TMRRTitle",
  },
  {
    id: "EMDTitle",
    data: { label: "Engineering & Manufacturing Development" },
    position: { x: 43346, y: 0 },
    draggable: false,
    phaseShortName: "EMD",
    phaseFullName: "Engineering & Manufacturing Development Phase",
    phaseDescription:
      "Assess and confirm design maturity and initial product baseline.​",
    jcidsActivity: [
      "Continue developing and refining Joint Operations Concepts and CONOPS.",
      "Draft STA.",
      "Re-evaluate AoA, if it has been updated.",
      "Develop DoD Enterprise Architecture & Solution Architecture.",
      "Prepare ISP.",
    ],
    color: "#6071BF",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#6071BF",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "EMDTitle",
  },
  {
    id: "PDTitle",
    data: { label: "Production & Deployment" },
    position: { x: 57788, y: 0 },
    draggable: false,
    phaseShortName: "PD",
    phaseFullName:
      "Production & Deployment: Low-Rate Initial Production / Full-Rate Production and Deployment Phase",
    phaseDescription:
      "Ensure system achieves operation capability that satisfies the original mission need.​",
    jcidsActivity: [
      "Low-Rate Initial Production Phase:",
      "Refine Joint Operations Concepts, CONOPS, and STA.",
      "Re-evaluate AoA, if it has been updated.",
      "Revise DoD Enterprise Architecture & Solution Architecture.",
      "Full-Rate Production and Deployment Phase:",
      "Conduct STA.",
      "Hold Joint Staff Review.",
      "Obtain component/JROC validation and approval.",
    ],
    color: "#F3A08B",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#F3A08B",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "PDTitle",
  },
  {
    id: "OSTitle",
    data: { label: "Operation & Support" },
    position: { x: 72230, y: 0 },
    draggable: false,
    phaseShortName: "OS",
    phaseFullName: "Operation & Support Phase",
    phaseDescription:
      "Begin with deployment of first system in the field. Addresses sustainment efforts and final disposal of system.​",
    jcidsActivity: [],
    color: "#F3A08B",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#F3A08B",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "OSTitle",
  },
];

export const phases4 = [
  {
    id: "PTitle",
    data: { label: "Planning" },
    position: { x: 0, y: 0 },
    draggable: false,
    phaseShortName: "PLAN",
    phaseFullName: "Planning Phase",
    phaseDescription:
      "Analyze the users’ needs and plan an approach to develop and deliver software capabilities that will meet those needs.​",
    jcidsActivity: [],
    color: "#E97272",
    style: {
      width: 14440,
      height: 65,
      backgroundColor: "#E97272",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
      // display: "flex",
      // justifyContent: "start",
    },
    className: "PTitle",
  },
  {
    id: "ETitle",
    data: { label: "Execution" },
    position: { x: 14462, y: 0 },
    draggable: false,
    phaseShortName: "EXEC",
    phaseFullName: "Execution Phase",
    phaseDescription:
      "Rapidly and iteratively design, develop, integrate, test, and deliver the software that meets the users’ needs along with necessary training and operation materials.",
    jcidsActivity: [],
    color: "#944F99",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#944F99",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "ETitle",
  },
];

export const phases5 = [
  {
    id: "CNITitle",
    data: { label: "Capability Need Identification" },
    position: { x: 0, y: 0 },
    draggable: false,
    phaseShortName: "CNI",
    phaseFullName: "Capability Need Identification Phase",
    phaseDescription:
      "Assess needed business capabilities so the functional sponsor and acquisition officials can effectively invest time and resources into investigating possible business solutions.",
    jcidsActivity: [],
    color: "#E97272",
    style: {
      width: 14440,
      height: 65,
      backgroundColor: "#E97272",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "CNITitle",
  },
  {
    id: "SATitle",
    data: { label: "Solution Analysis" },
    position: { x: 14462, y: 0 },
    draggable: false,
    phaseShortName: "SA",
    phaseFullName: "Solution Analysis Phase",
    phaseDescription:
      "Determine the high-level business processes that support future capabilities that maximize the use of existing business solutions and minimize creation of requirements that are only satisfied by a business system.",
    jcidsActivity: [],
    color: "#944F99",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#944F99",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "SATitle",
  },
  {
    id: "FRAPTitle",
    data: { label: "Functional Requirements and Acquisition Planning" },
    position: { x: 28904, y: 0 },
    draggable: false,
    phaseShortName: "FRAP",
    phaseFullName: "Functional Requirements and Acquisition Planning Phase",
    phaseDescription:
      "Establish an acquisition strategy and identify the capability support approach required to meet the functional requirements.",
    jcidsActivity: [],
    color: "#17BDAC",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#17BDAC",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "FRAPTitle",
  },
  {
    id: "ATDTitle",
    data: { label: "Acquisition, Testing, and Deployment" },
    position: { x: 43346, y: 0 },
    draggable: false,
    phaseShortName: "ATD",
    phaseFullName: "Acquisition, Testing, and Deployment Phase",
    phaseDescription:
      "Implement business capability with minimal customization to achieve organizational change.",
    jcidsActivity: [],
    color: "#6071BF",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#6071BF",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "ATDTitle",
  },
  {
    id: "CSTitle",
    data: { label: "Capability Support" },
    position: { x: 57788, y: 0 },
    draggable: false,
    phaseShortName: "CS",
    phaseFullName: "Capability Support Phase",
    phaseDescription:
      "Provide support for the business capability, including continued cybersecurity readiness and upgrades for support of the system.​",
    jcidsActivity: [],
    color: "#F3A08B",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#F3A08B",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "CSTitle",
  },
];

export const phases6 = [
  {
    id: "PLNTitle",
    data: { label: "Planning" },
    position: { x: 0, y: 0 },
    draggable: false,
    phaseShortName: "PLANAOS",
    phaseFullName: "Planning Phase",
    phaseDescription:
      "Develop an effective team and strategy that captures concerns, priorities, and projected requirements that will guide market research in this phase.",
    jcidsActivity: [],
    color: "#E97272",
    style: {
      width: 14440,
      height: 65,
      backgroundColor: "#E97272",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "PTitle",
  },
  {
    id: "DEVTitle",
    data: { label: "Development" },
    position: { x: 14462, y: 0 },
    draggable: false,
    phaseShortName: "DEVAOS",
    phaseFullName: "Development Phase",
    phaseDescription:
      "Define requirements to efficiently develop an acquisition strategy that guides procuring and managing the needed service.​",
    jcidsActivity: [],
    color: "#944F99",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#944F99",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "DTitle",
  },
  {
    id: "EXTitle",
    data: { label: "Execution" },
    position: { x: 28904, y: 0 },
    draggable: false,
    phaseShortName: "EXECU",
    phaseFullName: "Execution Phase",
    phaseDescription:
      "Execute acquisition strategy to effectively implement the service and manage ongoing performance after implementation.",
    jcidsActivity: [],
    color: "#17BDAC",
    style: {
      width: 14420,
      height: 65,
      backgroundColor: "#17BDAC",
      color: "white",
      textDecoration: "underline",
      fontSize: "28px",
    },
    className: "ETitle",
  },
];
