export const modelPhases = [
  {
    modelId: "1",
    phaseShortNames: ["PRE-DEV", "DEV", "PAD", "OAS"],
    phaseLongNames: [
      "Pre-Development",
      "Development",
      "Production and Development",
      "Operations and Support",
    ],
  },
  {
    modelId: "2",
    phaseShortNames: ["RP", "RF", "OPSS"],
    phaseLongNames: [
      "Rapid Prototyping",
      "Rapid Fielding",
      "Operation and Sustainment",
    ],
  },
  {
    modelId: "3",
    phaseShortNames: ["PRE-MDD", "MSA", "TMRR", "EMD", "PD", "OS"],
    phaseLongNames: [
      "Pre-Material Development Decision",
      "Material Solution Analysis",
      "Technology Maturation & Risk Reduction",
      "Engineering & Manufacturing Development",
      "Production & Deployment",
      "Operation & Support",
    ],
  },
  {
    modelId: "4",
    phaseShortNames: ["PLAN", "EXEC"],
    phaseLongNames: ["Planning", "Execution"],
  },
  {
    modelId: "5",
    phaseShortNames: ["CNI", "SA", "FRAP", "ATD", "CS"],
    phaseLongNames: [
      "Capability Need Identification",
      "Solution Analysis",
      "Functional Requirements and Acquisition Planning",
      "Acquisition, Testing, and Deployment",
      "Capability Support",
    ],
  },
  {
    modelId: "6",
    phaseShortNames: ["PLANAOS", "DEVAOS", "EXECU"],
    phaseLongNames: ["Planning", "Development", "Execution"],
  },
];

export const modelDomains = [
  {
    mcaModel: false,
    domainShortNames: [
      "INT",
      "HFE",
      "MAN",
      "PER",
      "TRA",
      "SAF",
      "OCC",
      "SUR",
      "HAB",
      "ENV",
    ],
    domainLongNames: [
      "HSI Integrator",
      "Human Factors Engineering",
      "Manpower",
      "Personnel",
      "Training",
      "Safety",
      "Occupational Health",
      "Survivability",
      "Habitability",
      "Environment",
    ],
  },
  {
    mcaModel: true,
    domainShortNames: [
      "DP-Prep",
      "DP-CP&A",
      "DP-CD",
      "ST-Plan",
      "ST-Conduct",
      "ST-Document",
      "MS-Plan",
      "MS-Conduct",
      "MS-Document",
      "MS-CD",
      "INT",
      "HFE",
      "MAN",
      "PER",
      "TRA",
      "SAF",
      "OCC",
      "SUR",
      "HAB",
      "ENV",
    ],
    domainLongNames: [
      "Development Planning Prep",
      "Development Planning CP&A",
      "Development Planning CD",
      "Science & Technology Plan",
      "Science & Technology Conduct",
      "Science & Technology Document",
      "Modeling & Simulation Plan",
      "Modeling & Simulation Conduct",
      "Modeling & Simulation Document",
      "Modeling & Simulation CD",
      "HSI Integrator",
      "Human Factors Engineering",
      "Manpower",
      "Personnel",
      "Training",
      "Safety",
      "Occupational Health",
      "Survivability",
      "Habitability",
      "Environment",
    ],
  },
];
